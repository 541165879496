import "./style.scss";

import { CallNumber } from "@ionic-native/call-number";
import { formatPhone } from "src/lib/utils";
import { isPlatform } from "@ionic/core";
import { logEvent } from "src/lib/analytics";
import React from "react";
import { SMS } from "@ionic-native/sms";
import { call, chatbubbles, mail } from "ionicons/icons";
import {
  EmailComposer,
  EmailComposerOptions,
} from "@ionic-native/email-composer";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  IonBadge,
} from "@ionic/react";

import { environment } from "../../environments/environment";

import { AppPage } from "../layout/page";
import { Store } from "../store/store.model";
import { USER_EVENTS } from "../../constants";
import { useSelector } from "react-redux";
import { Plugins } from "@capacitor/core";
const { SegmentCapacitorPlugin } = Plugins as any;

const SupportPage: React.FC<{}> = () => {
  const { support, mobileCode } = environment;
  const { VIEWED_HELP, TAPPED_HELP_CALL, TAPPED_HELP_EMAIL } = USER_EVENTS;
  const unreadCount = useSelector(
    (state: Store) => state.notificationStore.unreadCount
  );
  const isCapacitor = isPlatform(window, "capacitor");

  useIonViewDidEnter(() => {
    logEvent(VIEWED_HELP);
  });

  const callSupport = () => {
    if (isCapacitor) {
      logEvent(TAPPED_HELP_CALL);
      CallNumber.callNumber(`${mobileCode}${support.phone}`, true);
    } else {
      window.open(`tel:${mobileCode}${support.phone}`);
    }
  };

  const textSupport = () => {
    if (isCapacitor) {
      SegmentCapacitorPlugin.showMessenger();
    } else {
      window["Intercom"]("showNewMessage");
    }
  };

  const emailSupport = () => {
    if (isCapacitor) {
      logEvent(TAPPED_HELP_EMAIL);
      const email: EmailComposerOptions = {
        to: support.email,
      };
      EmailComposer.open(email);
    } else {
      window.open(`mailto:${support.email}`);
    }
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Need Help?</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem color="primary" class="menu-item" onClick={textSupport}>
            <IonIcon icon={chatbubbles} />
            <IonLabel>
              <h4 className="chat-title">Chat with us</h4>
            </IonLabel>
            {unreadCount != 0 && (
              <IonBadge color="danger" mode="ios" class="chat-badge">
                {unreadCount}
              </IonBadge>
            )}
          </IonItem>
          <IonItem class="menu-item" onClick={emailSupport}>
            <IonIcon icon={mail} color="primary" />
            <IonLabel>
              <h4>Email us</h4>
              <p>{support.email}</p>
            </IonLabel>
          </IonItem>
          <IonItem class="menu-item" onClick={callSupport}>
            <IonIcon icon={call} mode="ios" color="primary" />
            <IonLabel>
              <h4>Call us</h4>
              <p>{formatPhone(support.phone)}</p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export { SupportPage };
