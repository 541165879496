import { RoutePathInfo } from "./interface";

enum GlobalRouterPath {
  LOADING_PAGE = "/loading",
  NO_USER_PAGE = "/noUserDetails",
  HOME = "/home",
  WELCOME = "/welcome",
  Link = "/link",
}

const GlobalRouterPathInfo: Record<
  keyof typeof GlobalRouterPath,
  RoutePathInfo
> = {
  LOADING_PAGE: {
    name: "Loading Page",
  },
  NO_USER_PAGE: {
    name: "No User Page",
  },
  HOME: {
    name: "",
  },
  WELCOME: {
    name: "",
  },
  Link: {
    name: "Smart Link Page",
  },
};

export { GlobalRouterPath, GlobalRouterPathInfo };
