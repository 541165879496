import { Shift, Specialities } from "src/lib/interface";
import { DateRange } from "src/lib/utils";

enum TimeSlot {
  AM = "AM",
  PM = "PM",
  NOC = "NOC",
}

enum ShiftItemCategory {
  MISSING_CBH_DOCUMENTS = "MISSING_CBH_DOCUMENTS",
  BOOKING_WARNING = "BOOKING_WARNING",
  WAITING_FOR_APPROVE = "WAITING_FOR_APPROVE",
  NOT_READY_TO_BOOK_SHIFT = "NOT_READY_TO_BOOK_SHIFT",
  /**
   * We need to inform to HCP when they're booking at instantpay shift
   */
  WELCOME_TO_INSTANTPAY = "WELCOME_TO_INSTANTPAY",
  NO_LOCATION_PERMISSION = "NO_LOCATION_PERMISSION",
}

enum ShiftItemActionType {
  /**
   * Based on all the missing document category is
   * set to show action button and alerts.
   */
  SET_CATEGORY = "SET_CATEGORY",
  /**
   * When an API call to book a shift is in progress.
   */
  SUBMITTING = "SUBMITTING",
  /**
   * when API request is completed and booking a shift is complete.
   * This state help in triggering logEvent when an action is done,
   * in it's respective components outside the custom-hook.
   */
  SUBMITTED = "SUBMITTED",
  /**
   * Flag used to reset state, when an error is occurred.
   */
  ERROR_SUBMITTING = "ERROR_SUBMITTING",
  /**
   * Shows alert message based on CTA
   * Ex: See What's Missing or Upload a COVID Test
   */
  OPEN_ALERT = "OPEN_ALERT",
  /**
   * Closes the alert that is opened by `OPEN_ALERT`
   */
  CLOSE_ALERT = "CLOSE_ALERT",
  OPEN_TOAST = "OPEN_TOAST",
  CLOSE_TOAST = "CLOSE_TOAST",
  CONFIGURE_PUSH_NOTIFICATION = "CONFIGURE_PUSH_NOTIFICATION",
  /**
   * This action doesn't guarantee opening of push notification,
   * it opens based on push notification configurations.
   */
  OPEN_PUSH_NOTIFICATION = "OPEN_PISH_NOTIFICATION",
  CLOSE_PUSH_NOTIFICATION = "CLOSE_PUSH_NOTIFICATION",
  WORK_APPROVED = "WORK_APPROVED",
  SHOWED_INTEREST = "SHOWED_INTEREST",
}

interface ShiftDateMap {
  [date: string]: Shift[];
}

interface GroupItemDescriptionProps {
  shiftName: string;
  openShiftLength: number;
  myShiftLength: number;
}
interface GroupItemProps {
  shiftName: string;
  myShiftMap: ShiftDateMap;
  openShiftMap: ShiftDateMap;
  onClick: (shiftName: string) => void;
}

interface RequestOpenShiftOptions {
  range: DateRange;
  coordinates: number[];
  distance: number;
  minPayHourly: number;
  minPayShift: number;
  qualification: string;
  specialities?: Specialities;
  isPlain?: boolean;
  facilityId?: string;
}

interface RequestRatingsOptions {
  reviewFor: "FACILITY" | "AGENT";
  facilityId?: string;
  agentId?: string;
}

interface RequestDetailedRatings {
  filter: RequestRatingsOptions;
  page: number;
}

interface ResponseDetailedRatings {
  reasons: string[];
  createdAt: string;
  otherReason: string;
  rating: number;
  review: string;
  agent: {
    userId: string;
    name: string;
  };
}

interface RequestSlotDetails {
  date: string;
  hcpType: string;
  timeSlot: string;
}

interface RequestSlotClaim {
  spotType: string;
}

interface SlotTimings {
  startingTime: string;
  endingTime: string;
  cost: string;
  type?: string;
}

interface ResponseSlotDetails {
  [key: string]: {
    timeSlot: string;
    spots?: {
      firstHalf?: SlotTimings;
      secondHalf?: SlotTimings;
      fullTime?: SlotTimings;
    };
    facilities?: [];
  };
}

interface ShiftItemState extends Pick<ShiftItemProps, "pushNotification"> {
  isLoading: boolean;
  category: ShiftItemCategory;
  alertOpen: boolean;
  /**
   * set true while API call is active during booking
   * of a shift by HCP.
   */
  submitting: boolean;
  /**
   * set true when API call is completed.
   */
  submitted: boolean;
  toast: ToastMessage;
  // Decided based on properties inside `pushNotification`
  pushNotificationOpen: boolean;
  workApproved: boolean;
  interested: boolean;
}

interface UseShiftItemHookReturnType extends ShiftItemState {
  /**
   * on Calling opens the alert dialog.
   */
  loadAlertDialog: () => void;
  /**
   * on clicking on proceed/next action the alert dialog box
   */
  onCancel: () => void;
  onConfirm: () => void;
  interested: boolean;
  /**
   * Called when toast message is closed,
   * used to update toast state.
   */
  onToastDidDismiss: () => void;
  closePushNotification: () => void;
  workApproved: boolean;
  isLastMinuteShift?: boolean;
  isUrgentShift?: boolean;
}

interface ShiftItemViewProps
  extends Pick<
      UseShiftItemHookReturnType,
      | "category"
      | "submitting"
      | "submitted"
      | "isLoading"
      | "loadAlertDialog"
      | "workApproved"
      | "interested"
      | "isLastMinuteShift"
    >,
    Pick<
      ShiftItemProps,
      | "payVersion"
      | "isInstantBookingShift"
      | "showFacilityDetails"
      | "onItemClick"
    > {
  shift: Shift;
  onClickOnFacilityDetails: (displayExtraTimePayCard?: boolean) => void;
  isInstantPayAllowed: boolean;
  isUrgentShift?: boolean;
}

interface ShiftItemProps {
  isAnimated?: boolean;
  shiftName: string;
  shift: Shift;
  showFacilityDetails?: boolean;
  /**
   * Flag to determine if the shift supports instant booking?
   */
  isInstantBookingShift?: boolean;
  isInterested?: boolean;
  shiftActivityLog?: Function;
  updateShiftList?: Function;
  isHcpInstantPayEnabled?: boolean;
  handleShiftFlagFound?: (type: "holiday" | "claim") => void;
  onFacilityDetailsClick?: (
    shift: Shift,
    isInstantBook: boolean,
    displayExtraTimePayCard?: boolean
  ) => void;
  goToDocumentsPage?: () => void;
  onShiftClaim?: (
    shiftId: string,
    isAlreadyGrabbed?: boolean,
    startAt?: string
  ) => void;
  className?: string;
  payVersion?: string;
  shiftPay?: number;
  setClickedInterestInShift?: Function;
  isHCFInstantPayProhibited?: boolean;
  isDisabled?: boolean;
  onMarkedInterest?: (shiftId?: string) => void;
  handleShiftActivityLog?: (shiftId?: string, type?: string) => void;
  isLastMinuteShift?: boolean;
  /**
   * Finds any conflicting shifts with the current booking
   * shift.
   */
  validator?: (shift: Shift) => boolean;
  pushNotification?: {
    accepted?: boolean;
    pushFlag?: boolean;
    isPushSet?: boolean;
    isNotificationAlertShown?: boolean;
  };
  /**
   * CH-4051 logger
   */
  loggerSearchMode?: string;
  onItemClick?: () => void;
  isUrgentShift?: boolean;
}

interface ShiftItemAlertsProps
  extends Pick<ShiftItemProps, "shift" | "isInstantBookingShift">,
    Pick<
      UseShiftItemHookReturnType,
      | "category"
      | "alertOpen"
      | "onCancel"
      | "onConfirm"
      | "pushNotificationOpen"
      | "closePushNotification"
      | "isUrgentShift"
    > {}

interface ToolTipProps {
  toolTipHandler?: Function;
  content: any;
  buttonName?: string;
  toolTipClass?: string;
}

interface ConfirmationModalProps {
  confirmationModalHandler?: Function;
  saveConfirmationModalHandler?: Function;
  hideModal?: Function;
  shift?: {};
  visible: boolean;
  confirm?: Function;
  cancel?: Function;
}

interface ToastMessage {
  isOpen: boolean;
  color?: "success" | "danger" | "warning";
  message?: string;
  method?: string;
  messageId?: string;
  duration?: number;
  cssClass?: string;
}

interface ShiftListGroupState {
  /**
   * open shifts.
   */
  open: Shift[];
  /**
   * shifts owned by the user.
   */
  assigned: Shift[];
}
interface ShiftListProps {
  day: string;
  shiftName: string;
  shifts: ShiftListGroupState;
  onClose: () => void;
  goToDocumentsPage?: () => void;
  getOpenAndMyShifts?: () => void;
  loadingShiftList?: boolean;
}

interface ShiftNameLoaderProps {
  listLength: number;
}

interface CovidStatusCustomHookReturnType {
  daysOlder?: null | number;
  expired?: null | boolean;
  validity?: null | "1 month" | "7 days" | "14 days";
  /**
   * Helps determine if HCP has taken Covid Test atleast once.
   */
  dateOfTest?: null | Date;
  /**
   * Determines if a facility require a covid test document.
   */
  requiresCovidDoc?: null | boolean;
  /**
   * Boolean value while documents are loading.
   */
  loading?: boolean;
}

interface ShiftInlineAlertsProps {
  shift: Shift;
  /**
   * determines if agent showed interest in present shift.
   */
  interested: boolean;
  category: ShiftItemCategory;
}

interface MissingDocumentsItemProps {
  isOpen: boolean;
  /**
   * Function to call when the alert is closed to update the state.
   */
  onDidDismiss: () => void;
  onConfirm: () => void;
  shift: Shift;
  category: ShiftItemCategory;
}

interface RequestRatings {
  reviewFor: string;
  facilityId?: string;
  agentId?: string;
}

interface ResponseRatings {
  total: number;
  avg: number;
}

export {
  MissingDocumentsItemProps,
  ShiftDateMap,
  RequestOpenShiftOptions,
  GroupItemDescriptionProps,
  GroupItemProps,
  ShiftItemProps,
  ToastMessage,
  ShiftListProps,
  ShiftNameLoaderProps,
  RequestSlotDetails,
  ResponseSlotDetails,
  SlotTimings,
  RequestSlotClaim,
  TimeSlot,
  ToolTipProps,
  ConfirmationModalProps,
  ShiftItemCategory,
  ShiftInlineAlertsProps,
  ShiftItemActionType,
  ShiftItemState,
  CovidStatusCustomHookReturnType,
  UseShiftItemHookReturnType,
  ShiftItemViewProps,
  ShiftItemAlertsProps,
  ShiftListGroupState,
  RequestDetailedRatings,
  ResponseDetailedRatings,
  RequestRatings,
  ResponseRatings,
};
