import "./style.scss";
import "antd/es/timeline/style/css";
import moment from "moment-timezone";
import { ShiftTimelineItem } from "./shiftTimelineItem";
import { Shift } from "src/lib/interface";
import { getIsEarlyOrIsLateMessage } from "../../../../utils/shiftTimeMessage";
import React, { Fragment } from "react";

interface ShiftTimelineProps {
  shift: Shift;
}

const ShiftTimeline = ({ shift }: ShiftTimelineProps) => {
  if (!shift) {
    return <Fragment />;
  }

  const { clockInOut = {}, lunchInOut = {} } = shift;
  const lunchTime =
    lunchInOut && moment(lunchInOut.end).diff(lunchInOut.start, "hours", true);

  const shiftTime =
    clockInOut?.start &&
    clockInOut?.end &&
    moment(clockInOut?.end).diff(clockInOut?.start, "hours", true);

  const timeElapsed = (shiftTime as number) - (lunchTime || 0);

  const hours = Math.trunc(timeElapsed);
  const minutes = Math.trunc((timeElapsed - hours) * 60);

  const startTimeHelperTxt = getIsEarlyOrIsLateMessage(
    clockInOut?.start
      ? moment(clockInOut?.start).diff(moment(shift?.start), "seconds")
      : 0
  );

  //Break time helper text
  {
    /*const breakTimeHelperTxt = getIsEarlyOrIsLateMessage(
    lunchInOut?.end
      ? moment(lunchInOut?.end).diff(
          moment(lunchInOut?.start).add(30, "minutes"),
          "minutes"
        )
      : 0
  );*/
  }

  const endTimeHelperTxt = getIsEarlyOrIsLateMessage(
    moment(clockInOut?.end).diff(moment(shift?.end), "seconds")
  );

  return (
    <div className="hcp-timeline-list">
      {/* Condition: disabled if
            - Is not clocked out
              - clockin start is not there
              - If lunch start is not there and lunch is not skipped */}
      <ShiftTimelineItem
        title="CLOCK IN"
        timeRange={clockInOut?.start}
        disabled={!clockInOut?.start && !clockInOut.end}
        helperText={startTimeHelperTxt}
      />
      {/* Condition: disabled if
            - Is not clocked out
              - clockin start is not there
              - If lunch start is not there and lunch is not skipped */}
      <ShiftTimelineItem
        title="START BREAK"
        timeRange={lunchInOut?.start}
        disabled={
          (!clockInOut?.start ||
            (!lunchInOut?.start && !lunchInOut?.isSkipped)) &&
          !clockInOut.end
        }
      />
      {/* 
          Condition: disabled if
            - Is not clocked out
              - clockin start is not there
              - If lunch start is not there and lunch is not skipped */}
      <ShiftTimelineItem
        title="END BREAK"
        timeRange={lunchInOut?.end}
        disabled={
          ((!lunchInOut?.start && !lunchInOut?.isSkipped) ||
            (!lunchInOut?.end && !lunchInOut?.isSkipped)) &&
          !clockInOut.end
        }
      />
      <ShiftTimelineItem
        title="CLOCK OUT"
        timeRange={clockInOut?.end}
        disabled={!clockInOut?.end}
        helperText={endTimeHelperTxt}
      />
    </div>
  );
};
export { ShiftTimeline };
