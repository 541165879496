import "./style.scss";

import React, { useCallback, useEffect, useState } from "react";
import { get } from "lodash";
import { informationCircleOutline } from "ionicons/icons";
import {
  IonAlert,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
} from "@ionic/react";

import { ShiftItem } from "../../dayView/shiftItem";
import { useHistory } from "react-router-dom";
import ShiftBookingInfoModal from "./shiftBookingInfoModal";
import { Store } from "../../store/store.model";
import { useSelector } from "react-redux";
import { FacilityDetails } from "../../dayView/facilityDetails";
import {
  Facility,
  PaymentServiceAccountStatus,
  Shift,
  ShiftName,
} from "src/lib/interface";
import { instantPayStatus } from "../../dayView/api";
import { ShiftsListProps } from "./model";
import { showToast } from "../../layout/toast";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";
import moment from "moment";
import { isHCPInstantPayAllowed } from "@app/dayView/api";

const ShiftsList: React.FC<ShiftsListProps> = ({
  shifts,
  goToDocumentsPage,
  onShiftClaim,
}) => {
  const history = useHistory();

  const [facilityDetails, setFacilitydetails] = useState<Facility | null>();
  const [shiftDetails, setShiftDetails] = useState<Shift | null>();
  const [isHcpInstantPayEnabled, setIsHcpInstantPayEnabled] = useState(false);
  const [openHolidayAlert, setOpenHolidayAlert] = useState<boolean>(false);
  const [isHoliday, setIsHoliday] = useState<boolean>(false);

  const agent = useSelector((state: Store) => get(state, "session.agent"));

  const payVersion = useSelector((state: Store) =>
    get(state, "session.payVersion")
  );

  const onClickOnItemFacilityDetails = (shift: Shift) => {
    setFacilitydetails(shift.facility);
    setShiftDetails(shift);
  };

  const closeFacilityDetails = () => {
    setFacilitydetails(null);
    setShiftDetails(null);
  };

  useEffect(() => {
    if (!isHoliday) {
      return;
    }
    const holidayCheck = getShiftFlag("holidayCheck");
    if (!holidayCheck) {
      setOpenHolidayAlert(true);
      localStorage.setItem("holidayCheck", "true");
    }
  }, [isHoliday]);

  const handleShiftFlagFound = (type: "holiday" | "claim") => {
    if (type === "holiday") {
      !isHoliday && setIsHoliday(true);
    }
  };

  const closeHolidayAlert = () => {
    setOpenHolidayAlert(false);
  };

  const fetchHcpInstantPayStatus = async () => {
    const status = await instantPayStatus();
    setIsHcpInstantPayEnabled(isHCPInstantPayAllowed(status));
  };

  const onMarkedInterest = async (shiftId) => {
    const index = shifts.findIndex((shift) => shift._id === shiftId);
    shifts[index].interested?.push(agent.userId);
    history.push("/home/myShifts");
  };

  const updateShiftList = (shift, isInstantBooking, response) => {
    if (isInstantBooking) {
      if (response && response.isAlreadyGrabbed) {
        showToast(response.message, 3000, "bottom", "danger");
        onShiftClaim?.(shift._id, true, shift.start);
        return;
      } else if (response) {
        logEvent(USER_EVENTS.SUCCESSFULLY_INSTANT_BOOKED_LAST_MINUTE_SHIFT, {
          hcpUserId: agent.userId,
          hcpName: agent.name,
          shiftId: shift._id,
          shiftPay: shift.finalPay,
          facilityName: shift.facility.name,
          facilityId: shift.facility._id,
          startAt: shift.start,
          endAt: shift.end,
          timestamp: moment().format(),
        });
        onShiftClaim?.(response._id);
      } else {
        logEvent(USER_EVENTS.SUCCESSFULLY_INSTANT_BOOKED_A_SHIFT);
      }

      setTimeout(() => {
        onShiftClaim?.(response._id);
      }, 4000);
    } else {
      onMarkedInterest(shift._id);
    }
  };

  const getShiftFlag = (flag: "holidayCheck") => {
    const shiftFlagString = localStorage.getItem(flag);
    let shiftFlag;
    if (shiftFlagString !== null && shiftFlagString !== "null") {
      shiftFlag = JSON.parse(shiftFlagString);
    }
    return shiftFlag;
  };

  useEffect(() => {
    fetchHcpInstantPayStatus();
  }, []);

  return (
    <>
      <IonList className="shift-list">
        {shifts && shifts.length > 0 && (
          <div className="urgent-shift-list-container">
            {shifts.map((shift) => (
              <ShiftItem
                updateShiftList={updateShiftList}
                key={shift._id}
                shift={shift}
                shiftName={shift.name as ShiftName}
                isInterested={
                  !!shift.interested?.find(
                    (agentId) => agentId === agent.userId
                  )
                }
                className="ion-padding-horizontal urgent-shift-item"
                isInstantBookingShift={shift.isInstantForAgent}
                goToDocumentsPage={goToDocumentsPage}
                handleShiftFlagFound={handleShiftFlagFound}
                onFacilityDetailsClick={onClickOnItemFacilityDetails}
                isHcpInstantPayEnabled={isHcpInstantPayEnabled}
                payVersion={payVersion}
                isUrgentShift
              />
            ))}
          </div>
        )}
      </IonList>

      <IonModal isOpen={!!facilityDetails} onDidDismiss={closeFacilityDetails}>
        {facilityDetails && shiftDetails && (
          <FacilityDetails
            onClose={closeFacilityDetails}
            facility={facilityDetails}
            shift={shiftDetails}
          />
        )}
      </IonModal>

      <IonAlert
        cssClass="shift-alerts"
        isOpen={openHolidayAlert}
        onDidDismiss={closeHolidayAlert}
        subHeader="You just clicked on a Holiday Shift!"
        message={`The Holiday Shift you just clicked on indicates a Facility will pay time-and-a-half as a means of thanking you for working on this particular day.<br /><br />
<strong>Interested in finding more of these Time-and-a-Half Shifts?</strong><br /><br />

Look out for the shifts with Holiday Icons ( examples include: 🎄, 🎊, ⭐️) Shifts with Holiday Icons represent time-and-half pay. Enjoy!`}
        buttons={["OK"]}
      />
    </>
  );
};

export { ShiftsList };
