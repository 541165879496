import "./style.scss";
import React, { useState } from "react";
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonText,
  IonButton,
  IonSpinner,
  IonToast,
} from "@ionic/react";

import { logErrors } from "@app/errorBoundary/api";
import { getCheckrRequirementStatusData } from "./utils";
import { CheckrPillProps } from "./checkrPill.types";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "@store/session";
import { CheckrApplicationStatus } from "@app/documents/model";
import { resendCheckrInvite } from "@app/documents/api";
import { setCheckrApplicationStatus } from "@store/documents";
import { DocumentStatus } from "@store/documents/model";
import { openBrowser } from "src/lib/ionic-components";

const CheckrPill = ({
  requirementStatusType,
  expiry,
  requirementStatus,
}: CheckrPillProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const checkrApplicationStatus = useSelector(
    (state: Store) => state.documentStore.checkrApplicationStatus
  ) as CheckrApplicationStatus;

  const checkrRequirementData = getCheckrRequirementStatusData(
    checkrApplicationStatus,
    requirementStatusType,
    expiry,
    requirementStatus
  );

  const handleCheckrPillClick = async () => {
    try {
      setLoading(true);
      if (requirementStatusType === DocumentStatus.REJECTED.toLowerCase()) {
        openBrowser(
          "http://help.clipboard.health/en/articles/6075320-understanding-background-checks"
        );
        return;
      }
      const { invitationStatus, reportStatus } =
        (await resendCheckrInvite()) || {};
      if (invitationStatus !== "pending") {
        setToastOpen(true);
        return;
      }
      dispatch(
        setCheckrApplicationStatus({ invitationStatus, reportStatus: "" })
      );
    } catch (error: any) {
      logErrors({
        message: `Error while updating checkr invite status. ${JSON.stringify(
          error?.stack || error
        )}`,
        app: "hcpMobile",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!checkrRequirementData) {
    return null;
  }

  return (
    <IonItem>
      <IonItem lines="none" color="#E0E0E0" className="checkr-document-item">
        <IonLabel className="ion-text-wrap">
          <h4>Background Check - Checkr</h4>
          <p>
            <IonText
              color={checkrRequirementData?.listTitleColor}
              data-testid="checkr-pill-description"
            >
              {checkrRequirementData?.description}
            </IonText>
          </p>
        </IonLabel>
        {checkrRequirementData?.pillDescription ? (
          <IonButton
            disabled={!checkrRequirementData?.isButtonEnabled || loading}
            onClick={handleCheckrPillClick}
            shape="round"
            className="checkr-pill"
            color={`${checkrRequirementData?.pillColor}`}
            slot="end"
          >
            {loading ? (
              <IonSpinner name="lines" className="checkr-spinner" />
            ) : (
              <IonIcon icon={checkrRequirementData?.listIcon} />
            )}
            <IonText
              className="checkr-pill-text"
              data-testid="checkr-pill-button-description"
            >
              {checkrRequirementData?.pillDescription}
            </IonText>
          </IonButton>
        ) : (
          checkrRequirementData?.listIcon && (
            <IonIcon
              color={checkrRequirementData?.listTitleColor}
              slot="end"
              icon={checkrRequirementData?.listIcon}
            />
          )
        )}
      </IonItem>
      <IonToast
        isOpen={toastOpen}
        onDidDismiss={() => setToastOpen(false)}
        message="Something went wrong while sending invite. Please try again."
        duration={5000}
        color="danger"
        position="top"
      />
    </IonItem>
  );
};

export default CheckrPill;
