// Shift Urgency Values
export enum UrgencyType {
  COMMUTE_MODULE = 1,
  LATE_CANCELLATION = 2,
}
export enum PrimerPage {
  GENERAL = 1,
  ALWAYS_ALLOW = 2,
}

export enum UrgentShiftLocalStorage {
  GENERAL_PRIMER_PAGE_VIDEWED = "generalPrimerPageViewed",
  LOCATION_ACCESS_WAS_DENIED = "locationAccessDenied",
  LAST_LOCATION_ACCESS_ASKED = "lastLocationAccessAsked",
  LAST_FULL_LOCATION_ACCESS_ASKED = "lastFullLocationAccessAsked",
}
