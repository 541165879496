import { RangeChangeEventDetail, RangeValue } from "@ionic/core";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRange,
  IonText,
} from "@ionic/react";
import { closeOutline, logoUsd } from "ionicons/icons";
import { get, round } from "lodash";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAgent } from "src/app/store/session";
import { Store } from "src/app/store/store.model";
import { updatePreference } from "src/app/openShifts/api";
import { MinPayPreferenceModalProps } from "./interface";
import "./style.scss";
import { logEvent, addDataToIdentity } from "src/lib/analytics";
import { USER_EVENTS } from "src/constants";
import { Agent } from "src/lib/interface";
import { useFlags } from "launchdarkly-react-client-sdk";

export const MinPayPreference: React.FC<MinPayPreferenceModalProps> = ({
  isOpen,
  minPayHourlyPreference,
  minPayShiftPreference,
  closeModal,
  updateOpenShifts,
  baseRate,
}) => {
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const [minPayHourly, setMinPayHourly] = useState<RangeValue>(
    minPayHourlyPreference
  );
  const [minPayShift, setMinPayShift] = useState<RangeValue>(
    minPayShiftPreference
  );

  const ldFlags = useFlags();

  const baseRateData = useMemo(() => {
    const baseRateFlag = ldFlags["base-rate-pay-filters"];
    const baseRateMultipleFlag = ldFlags["base-rate-multiple-pay-filters"];
    if (baseRateFlag !== 17 && baseRate == 17) {
      baseRate = baseRateFlag;
    }
    const baseRateMultiple = baseRate * baseRateMultipleFlag;
    const baseRateMultipleHourly =
      round(baseRateMultiple) % 2 === 0
        ? round(baseRateMultiple)
        : round(baseRateMultiple) + 1;
    const baseRateMultipleShift = round(baseRateMultiple * 0.8) * 10;

    return {
      maxPayHourlySlider: baseRateMultipleHourly,
      maxPayShiftSlider: baseRateMultipleShift,
    };
  }, [baseRate, ldFlags]);

  const dispatch = useDispatch();

  const onChange = (
    event: CustomEvent<RangeChangeEventDetail>,
    preference: string
  ) => {
    const { value } = event.detail;
    if (preference == "minPayHourly") {
      setMinPayHourly(value);
    } else if (preference == "minPayShift") {
      setMinPayShift(value);
    }
  };

  const onSave = useCallback(async () => {
    if (
      minPayHourly !== minPayHourlyPreference ||
      minPayShift !== minPayShiftPreference
    ) {
      let updatedAgent: Agent = agent;
      if (minPayHourly !== minPayHourlyPreference) {
        updatedAgent = await updatePreference(
          "minPayHourly",
          minPayHourly as number
        );
        logEvent(USER_EVENTS.UPDATED_MIN_PAY_HOURLY_PREFERENCE, {
          minPayHourly: minPayHourly as number,
        });
        addDataToIdentity(agent.userId, {
          preference_min_pay_hourly: minPayHourly as number,
        });
      }
      if (minPayShift !== minPayShiftPreference) {
        updatedAgent = await updatePreference(
          "minPayShift",
          minPayShift as number
        );
        logEvent(USER_EVENTS.UPDATED_MIN_PAY_SHIFT_PREFERENCE, {
          minPayShift: minPayShift as number,
        });
        addDataToIdentity(agent.userId, {
          preference_min_pay_shift: minPayShift as number,
        });
      }

      updateOpenShifts?.();
      dispatch(updateAgent(updatedAgent));

      closeModal?.();
    }
  }, [minPayHourly, minPayShift]);

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false} cssClass="pay-modal">
      <IonContent id="pay-modal-container">
        <div className="pay-slider-container">
          <IonText className="modal-title">
            <h2>Minimum Pay</h2>
          </IonText>
          <IonText className="modal-subtitle">
            <p>
              What&apos;s the smallest amount you&apos;ll work for?
              <br />
              (We&apos;ll show you shifts if <b>either</b> is true)
            </p>
          </IonText>
          <IonItem lines="none">
            <IonIcon icon={closeOutline} color="black" />
            <IonLabel className="ion-text-center">
              <h1>
                {minPayHourly > 0 ? `$${minPayHourly}/hour` : "any hourly rate"}
              </h1>
            </IonLabel>
            <IonIcon icon={logoUsd} color="black" />
          </IonItem>
          <IonRange
            min={0}
            max={baseRateData.maxPayHourlySlider}
            value={minPayHourly}
            onIonChange={(event) => onChange(event, "minPayHourly")}
            style={{
              "--bar-background": "#10B981",
              "--bar-background-active": "lightgray",
              "--knob-background": "#10B981",
            }}
          ></IonRange>
          <div className="divider">
            <div className="line">
              <div></div>
            </div>
            <div className="divider-text">or</div>
            <div className="line">
              <div></div>
            </div>
          </div>
          <IonItem lines="none">
            <IonIcon icon={closeOutline} color="black" />
            <IonLabel className="ion-text-center">
              <h1>
                {minPayShift > 0 ? `$${minPayShift}/shift` : "any shift total"}
              </h1>
            </IonLabel>
            <IonIcon icon={logoUsd} color="black" />
          </IonItem>
          <IonRange
            min={0}
            max={baseRateData.maxPayShiftSlider}
            value={minPayShift}
            onIonChange={(event) => onChange(event, "minPayShift")}
            style={{
              "--bar-background": "#10B981",
              "--bar-background-active": "lightgray",
              "--knob-background": "#10B981",
            }}
          ></IonRange>
        </div>
        <div className="pay-modal-action-container">
          <IonButton expand="full" shape="round" onClick={onSave}>
            Update
          </IonButton>
          <IonButton
            expand="full"
            shape="round"
            style={{
              "--border-color": "#828282",
              "--color": "#828282",
              marginTop: "10px",
            }}
            fill="outline"
            onClick={() => closeModal?.()}
          >
            Cancel
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
