/* eslint-disable prefer-destructuring */
import { logEvent } from "src/lib/analytics";
import { Agent, Shift } from "src/lib/interface";
import {
  IonAlert,
  IonIcon,
  IonLabel,
  IonModal,
  IonSpinner,
} from "@ionic/react";
import { get, groupBy, isEmpty } from "lodash";
import moment from "moment-timezone";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import IconOpenShifts from "../../../assets/images/facilityDetails/open-shifts.svg";
import {
  USER_EVENTS,
  DISTANCE_PREFERENCE_MAX_VALUE,
  SHIFT_PREFERENCE,
  SEARCH_MODE,
} from "src/constants";
import InstantUpdate from "../../InstantUpdate";
import { getNotificationPreference } from "../../privacyPolicy/notificationPreference/api";
import { ReviewPrompt } from "../../rating";
import { groupByShiftName } from "../../store/shift";
import { ShiftDateMap } from "../../store/shift/model";
import { Store } from "../../store/store.model";
import {
  fetchCurrentTimezone,
  fetchOpenShifts,
  fetchYourShifts,
  instantPayStatus,
  isHCPInstantPayAllowed,
} from "../api";
import { ShiftFilters } from "src/app/components/shiftFilters";
import { ShiftListGroupState } from "../model";
import { FacilityShiftItem } from "./facilityShiftItem";
import { FacilityShifts } from "./model";
import { RequestOpenShiftOptions } from "./model";
import "./style.scss";
import { api } from "@app/api";

type FacilityOpenShiftsProps = {
  facility: any;
  onClose: () => void;
};
const FacilityOpenShifts: React.FC<FacilityOpenShiftsProps> = ({
  facility,
  onClose,
}) => {
  const history = useHistory();
  const location = useLocation();
  // stripe Account Needs Updating Hook condition should automatically close the modal
  useEffect(() => {
    const validPaths =
      location?.pathname?.startsWith("/home/openShifts") ||
      location?.pathname?.startsWith("/home/myShifts");
    if (!validPaths) {
      onClose();
    }
  }, [location, onClose]);

  const { agent } = useSelector((state: Store) => state.session);

  const [facilityShifts, setFaclitiyShifts] = useState<Array<FacilityShifts>>(
    []
  );
  const [facilityShiftsProcess, setFaclitiyShiftsProcess] = useState<
    Record<string, any>
  >({
    isLoading: true,
    errMessage: "",
  });

  const [myShiftMap, setMyShiftMap] = useState<ShiftDateMap>({});
  const [openShiftMap, setOpenShiftMap] = useState<ShiftDateMap>({});
  const [shiftList, setShiftList] = useState<ShiftListGroupState>({
    open: [],
    assigned: [],
  });

  const distancePreference = useMemo(() => {
    return get(agent, "preference.distance", DISTANCE_PREFERENCE_MAX_VALUE);
  }, [agent?.preference?.distance]);

  const minPayHourlyPreference = useMemo(() => {
    return get(agent, "preference.minPayHourly", SHIFT_PREFERENCE.PAY_HOUR);
  }, [agent?.preference?.minPayHourly]);

  const minPayShiftPreference = useMemo(() => {
    return get(agent, "preference.minPayShift", SHIFT_PREFERENCE.PAY_SHIFT);
  }, [agent?.preference?.minPayShift]);

  const baseRate = useMemo(() => {
    return get(agent, "baseRate", SHIFT_PREFERENCE.BASE_RATE);
  }, [agent?.baseRate]);

  const getFacilityShifts = useCallback(async () => {
    try {
      setFaclitiyShiftsProcess({
        isLoading: true,
        errMessage: "",
      });
      const dateRange = {
        start: moment().add(1, "minutes").format("YYYY-MM-DD"),
        end: moment().add(30, "days").format("YYYY-MM-DD"),
      };
      const options: RequestOpenShiftOptions = {
        range: dateRange,
        coordinates: get(agent, "geoLocation.coordinates", []),
        distance: distancePreference,
        minPayHourly: minPayHourlyPreference,
        minPayShift: minPayShiftPreference,
        qualification: agent?.qualification as string,
        specialities: get(agent, "specialities", {}),
        facilityId: facility.userId,
      };

      const [fetchOpenShiftsResult, myShifts, tmz]: [
        Shift[],
        void | ShiftDateMap,
        string
      ] = await Promise.all([
        fetchOpenShifts(options),
        fetchYourShifts(dateRange),
        fetchCurrentTimezone(),
      ]);
      const { openShiftsGroupByDate, openShiftsCount } =
        transformFacilityOpenShifts({
          fetchOpenShiftsResult,
          tmz,
        });
      const myShiftsList = flatMyShifts(myShifts);
      setOpenShiftMap(groupBy(fetchOpenShiftsResult, groupByShiftName));
      setMyShiftMap(groupBy(myShiftsList, groupByShiftName));

      setFaclitiyShifts(openShiftsGroupByDate);
      setFaclitiyShiftsProcess({
        isLoading: false,
        errMessage: "",
      });
      logEvent(USER_EVENTS.VIEWED_FACILITY_SHIFTS, {
        open_shifts: openShiftsCount,
      });
    } catch (err) {
      setFaclitiyShiftsProcess({
        isLoading: false,
        errMessage: `${err}`,
      });
    }
  }, [agent, facility]);

  useEffect(() => {
    getFacilityShifts();
  }, [getFacilityShifts]);

  useEffect(() => {
    setShiftList({ open: [], assigned: [] });
  }, [myShiftMap, openShiftMap]);

  // Instant Pay check
  const payVersion = useSelector((state: Store) =>
    get(state, "session.payVersion")
  );
  const [isHcpInstantPayEnabled, setIsHcpInstantPayEnabled] = useState(false);
  const [isLoadingInstantPay, setIsLoadingInstantPay] = useState(true);

  const fetchHcpInstantPayStatus = async () => {
    setIsLoadingInstantPay(true);
    const status = await instantPayStatus();
    setIsHcpInstantPayEnabled(isHCPInstantPayAllowed(status));
    setIsLoadingInstantPay(false);
  };

  useEffect(() => {
    fetchHcpInstantPayStatus();
  }, []);

  // App Rating
  const [clickedInterestInShift, setClickedInterestInShift] = useState(false);
  const [openAppRatingPrompt, setOpenAppRating] = useState(false);
  const meetsCriteriaToShowAppRatingPrompt = (agent): boolean => {
    const { appRatingStatus, attendance } = agent;
    if (!appRatingStatus) return false;

    const hasCompetedThreeShiftsSinceLastSeenPrompt =
      attendance.completedShifts -
        appRatingStatus.countCompletedShiftsWhenLastShownPrompt >=
      3;

    return (
      appRatingStatus.appRatingSubmitted === false &&
      appRatingStatus.countPromptShown < 3 &&
      attendance.completedShifts >= 3 &&
      hasCompetedThreeShiftsSinceLastSeenPrompt
    );
  };
  useEffect(() => {
    const { appRatingStatus } = agent as Agent;
    if (
      clickedInterestInShift &&
      appRatingStatus &&
      !appRatingStatus.appRatingSubmitted
    ) {
      setOpenAppRating(meetsCriteriaToShowAppRatingPrompt(agent));
    }
  }, [clickedInterestInShift, agent]);

  // Push Notification
  const [pushNotification, setPushNotification] = useState<{
    [key in string]: boolean;
  }>({});
  const { isPushSet } = useSelector((state: Store) => state.notificationStore);
  const [isFirstTimeInterested, setIsFirstTimeInterested] =
    useState<boolean>(false);
  const prevAgentInterestedAtRef = useRef<object>();

  useEffect(() => {
    (async function () {
      const { isNotificationAlertShown } = agent as Agent;
      const response = await Promise.all([
        api.env.fetchEnvVariablesWithPushNotificationOnAccountTab(),
        getNotificationPreference(),
      ]);
      setPushNotification({
        accepted: response[1]?.data?.notificationPreferences?.push,
        pushFlag: response[0]?.PushNotificationOnAccountTab as boolean,
        isNotificationAlertShown: isNotificationAlertShown as boolean,
        isPushSet,
      });
    })();
  }, [setPushNotification, agent, isPushSet]);

  useEffect(() => {
    prevAgentInterestedAtRef.current = agent?.interestedAt;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevAgentInterestedAtRef.current === null && agent?.interestedAt) {
      setIsFirstTimeInterested(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent?.interestedAt]);

  // Holiday Check
  const [isHoliday, setIsHoliday] = useState<boolean>(false);
  const [openHolidayAlert, setOpenHolidayAlert] = useState<boolean>(false);
  const closeHolidayAlert = () => {
    setOpenHolidayAlert(false);
  };
  useEffect(() => {
    if (!isHoliday) {
      return;
    }
    const holidayCheck: any = getShiftFlag("holidayCheck");
    if (!holidayCheck) {
      setOpenHolidayAlert(true);
      localStorage.setItem("holidayCheck", "true");
    }
  }, [isHoliday]);
  const handleShiftFlagFound = (type: "holiday" | "claim") => {
    if (type === "holiday") {
      !isHoliday && setIsHoliday(true);
    }
  };

  const goToDocumentsPage = (queryParameters?: string) => {
    onClose();
    // query parameters are required to show edit view for the covid document uploads.
    history.push(
      `/home/account/documents${queryParameters ? `?${queryParameters}` : ""}`
    );
  };

  return (
    <Fragment>
      <div className="facility-openshifts">
        {!facilityShiftsProcess.isLoading && facilityShifts.length < 1 ? (
          <div>
            <div className="facility-openshifts-title no-shifts">
              <IonIcon icon={IconOpenShifts} mode="ios" className="hcf-icon" />
              No Shifts Right now
            </div>
            <p className="no-shifts-subtitle">
              This facility doesn’t have any open shifts for you right now, but
              they have posted shifts for your {agent?.qualification}{" "}
              qualification within the last three months. Check back soon!
            </p>
          </div>
        ) : (
          <>
            <div className="facility-openshifts-title">
              <IonIcon icon={IconOpenShifts} mode="ios" className="hcf-icon" />
              Open Shifts
            </div>
            <ShiftFilters
              openShiftsEmpty={false}
              baseRate={baseRate}
              distancePreference={distancePreference}
              minPayHourlyPreference={minPayHourlyPreference}
              minPayShiftPreference={minPayShiftPreference}
              searchMode={SEARCH_MODE.HCF_PROFILE}
              updateOpenShifts={getFacilityShifts}
            />
          </>
        )}
        {facilityShiftsProcess.errMessage && (
          <div className="facility-openshifts-item">
            <div className="openshifts-date errorColor">
              {facilityShiftsProcess.errMessage}
            </div>
          </div>
        )}
        {facilityShiftsProcess.isLoading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IonSpinner slot="start" name="lines" />
            <IonLabel color="text">
              <h2 className="facility-srteet-map-view"> Loading Shifts </h2>
            </IonLabel>
          </div>
        )}
        {!facilityShiftsProcess.isLoading &&
          !isLoadingInstantPay &&
          facilityShifts?.length > 0 &&
          facilityShifts?.map((shiftsDateGroup) => {
            return (
              <div
                key={shiftsDateGroup.date}
                className="facility-openshifts-item"
              >
                <div className="openshifts-date">
                  {formatDate(shiftsDateGroup.date)}
                </div>
                {shiftsDateGroup.shifts?.map((shift) => {
                  return (
                    <FacilityShiftItem
                      getFacilityShifts={getFacilityShifts}
                      key={shift._id}
                      day={shiftsDateGroup.date}
                      shift={shift}
                      shifts={shiftList}
                      goToDocumentsPage={goToDocumentsPage}
                      setClickedInterestInShift={setClickedInterestInShift}
                      isHcpInstantPayEnabled={isHcpInstantPayEnabled}
                      payVersion={payVersion}
                      pushNotification={pushNotification}
                      handleShiftFlagFound={handleShiftFlagFound}
                    />
                  );
                })}
              </div>
            );
          })}
      </div>

      {openAppRatingPrompt && (
        <ReviewPrompt onDidDismiss={() => setOpenAppRating(false)} />
      )}
      <IonModal isOpen={pushNotification.pushFlag && isFirstTimeInterested}>
        <InstantUpdate onClose={() => setIsFirstTimeInterested(false)} />
      </IonModal>
      <IonAlert
        cssClass="shift-alerts"
        isOpen={openHolidayAlert}
        onDidDismiss={closeHolidayAlert}
        subHeader="One of the shifts is a Holiday Shift!"
        message={`The Holiday Shift indicates a Facility will pay time-and-a-half as a means of thanking you for working on this particular day.<br /><br />
<strong>Interested in finding more of these Time-and-a-Half Shifts?</strong><br /><br />
On the <strong>Calendar View</strong> Look out for the shifts with Holiday Icons ( examples include: 🎄, 🎊, ⭐️) Shifts with Holiday Icons represent time-and-half pay. Enjoy!`}
        buttons={["OK"]}
      />
    </Fragment>
  );
};

const formatDate = (date) => {
  return moment(date, "YYYY-MM-DD").format("dddd, MMMM DD");
};

const transformFacilityOpenShifts = ({ fetchOpenShiftsResult, tmz }) => {
  const groupByDate: { [key: string]: { date: string; shifts: Shift[] } }[] =
    [];
  let openShiftsCount = 0;
  fetchOpenShiftsResult.sort((a, b) => {
    if (a.start < b.start) return -1;
    if (a.start > b.start) return 1;
    return 0;
  });
  fetchOpenShiftsResult.forEach((shift) => {
    const startDate: string = moment(shift.start).tz(tmz).format("YYYY-MM-DD");
    if (!groupByDate.hasOwnProperty(startDate)) {
      Object.defineProperty(groupByDate, startDate, {
        value: {
          date: startDate,
          shifts: [],
        },
      });
    }
    openShiftsCount++;
    groupByDate[startDate].shifts.push(shift);
  });
  const openShiftsGroupByDate = Object.getOwnPropertyNames(groupByDate)
    .map((date) => groupByDate[date])
    .filter((data) => data);
  return {
    openShiftsGroupByDate,
    openShiftsCount,
  };
};

const flatMyShifts = (myShifts) => {
  const flatResult: Shift[] = [];
  Object.keys(myShifts).forEach((date) => {
    myShifts[date].forEach((shift) => {
      flatResult.push(shift);
    });
  });

  return flatResult;
};

// Holiday Check Alert
const getShiftFlag = (flag: "holidayCheck" | "claimCheck") => {
  const shiftFlagString = localStorage.getItem(flag);
  let shiftFlag;
  if (shiftFlagString !== null && shiftFlagString !== "null") {
    shiftFlag = JSON.parse(shiftFlagString);
  }
  return shiftFlag;
};

export { FacilityOpenShifts };
