import { IonSpinner, useIonViewDidEnter } from "@ionic/react";
import React, { Fragment } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { RouteState } from "./model";
import { NotFoundContainer } from "./notFoundContainer";
import { GlobalRouterPath } from "../routing/constant/globalRoute";

interface AppLoadingProps extends RouteComponentProps {
  hasSession: boolean;
}

const AppLoading: React.FC<AppLoadingProps> = ({ hasSession, location }) => {
  if (location.pathname !== "/loading") return <Fragment />;

  if (hasSession) {
    const { from } = (location.state || {}) as RouteState;
    const path = from || GlobalRouterPath.HOME;

    return <Redirect to={path} />;
  }

  return (
    <NotFoundContainer>
      <IonSpinner color="light" />
    </NotFoundContainer>
  );
};

export { AppLoading };
