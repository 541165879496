import { Plugins } from "@capacitor/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import React, { useState, FormEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { isPlatform } from "@ionic/core";
import { LogoutButton, openBrowser } from "src/lib/ionic-components";

import { logout } from "../../store/session";
import { Store } from "../../store/store.model";
import { OnBoardingComponentProps } from "./model";
import { updateAgentData } from "./api";
import { StyledIonPage } from "./style";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import Alert from "./Alert";
const { Keyboard } = Plugins;

const AgentFirstName: React.FC<OnBoardingComponentProps> = ({
  agent,
  nextStagePath,
}) => {
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    if (agent && agent.name) {
      const nameParts = { first: agent.firstName, last: agent.lastName };
      setFirstName(nameParts.first as string);
      setLastName(nameParts.last as string);
    }
  }, [agent]);

  const onFirstNameChange = (event: Event) => {
    const firstName = event.target?.["value"]
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    setFirstName(firstName.trim());
  };

  const onNext = async (event?: FormEvent | MouseEvent) => {
    try {
      setFirstName(firstName.trim());
      event && event.preventDefault();
      if (isPlatform("capacitor")) Keyboard.hide();
      setShowErrorModal(false);
      setUpdatingData(true);
      fireOnboardingSegmentEvent(
        ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_FIRST_NAME,
        {
          hcpId: agent?.userId as string,
          hcpEventValue: firstName,
        }
      );
      history.push("/home/agentLastName", { firstName, lastName });
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setUpdatingData(false);
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentEmail"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
          position="top"
        />

        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>What's your first name?</h4>
                  </div>
                  <div>
                    <IonLabel className="form-label">Legal First Name</IonLabel>
                    <IonInput
                      className="form-input"
                      type="text"
                      placeholder="First Name"
                      onIonChange={onFirstNameChange}
                      value={firstName}
                      autofocus
                      required
                    />
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              onClick={onNext}
              disabled={updatingData || (firstName?.trim() || "").length < 2}
            >
              Continue
              {updatingData && (
                <IonSpinner slot="end" class="ion-margin-start" name="lines" />
              )}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentFirstName };
