const workerTypes = ["CNA", "LVN", "RN", "NP"];

const surgeryWorkerTypes = [
  "PACU RN",
  "OR RN",
  "SPD TECH",
  "SURG TECH",
  "CRNA",
];

const workerTypeObj = {
  CNA: "CNA",
  LVN: "LVN",
  RN: "RN",
  NP: "NP",
  PACU_RN: "PACU RN",
  OR_RN: "OR RN",
  SPD_TECH: "SPD TECH",
  SURG_TECH: "SURG TECH",
  CRNA: "CRNA",
  PHLEBOTOMIST: "Phlebotomist",
  MLT: "MLT",
  MEDICAL_ASSISTANT: "Medical Assistant",
  PT: "PT",
  PTA: "PTA",
  HHA: "HHA",
  NON_CLINICAL: "Non Clinical",
  SITE_LEAD: "Site Lead",
};

const workerSpecialities = [
  "General Surgery",
  "Thoracic Surgery",
  "Colon and Rectal Surgery",
  "Obstetrics and Gynecology",
  "Gynecologic Oncology",
  "Neurological Surgery",
  "Ophthalmic Surgery",
  "Oral Surgery and Maxillofacial Surgery",
  "Minor Orthopaedic Surgery",
  "Total Orthopaedic Surgery",
  "Otolaryngology (ENT)",
  "Pediatric Surgery",
  "Plastic Surgery",
  "Urology",
  "Vascular Surgery",
] as const;

const allWorkerTypes = Object.values(workerTypeObj);

const workerTypeDefaults = workerTypes.reduce(
  (acc, type) => ({ ...acc, [type]: 0 }),
  {}
);

const workerTypeModel = workerTypes.reduce(
  (acc, type) => ({ ...acc, [type]: { type: Number, default: 0 } }),
  {}
);

export {
  workerTypes,
  workerTypeDefaults,
  workerTypeModel,
  workerSpecialities,
  surgeryWorkerTypes,
  allWorkerTypes,
  workerTypeObj,
};
