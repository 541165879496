const modifierKeys = [
  { key: 35, note: "Home Key" },
  { key: 36, note: "End Key" },
  { key: 8, note: "Backspace Key" },
  { key: 9, note: "Tab Key" },
  { key: 13, note: "Enter Key" },
  { key: 46, note: "Delete Key" },
];

const commandKeys = [
  { key: 65, note: "All Command" },
  { key: 67, note: "Copy Command" },
  { key: 86, note: "Paste Command" },
  { key: 88, note: "Cut Command" },
  { key: 90, note: "Undo Command" },
];

export { modifierKeys, commandKeys };
