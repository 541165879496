import { Plugins } from "@capacitor/core";
import { AgentStages } from "src/lib/interface";
import { USER_EVENTS } from "@constants/userEvent";
import { isPlatform } from "@ionic/core";
import { IonButton, IonCol, IonLabel, IonRow, IonSpinner } from "@ionic/react";
import { Store } from "@store/store.model";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { segmentInstance } from "../../../utils/cbhSegementHelper";
import { checkRolloutMsa } from "../../utils/shiftHelper";
import { useMissingDocuments } from "../custom-hooks/useMissingDocuments";
import { ShiftItemCategory } from "../model";

const { SegmentCapacitorPlugin } = Plugins as any;

const ShiftActionButtonRow = ({
  shift,
  onClick,
  category,
  submitting,
  missingDocs,
  isInterested,
}) => {
  const { agent = {}, bookingRolloutMsas } = useSelector(
    (state: Store) => state.session
  );
  const { missingDocs: documentsMissing, pending } = useMissingDocuments(shift);
  const isCapacitor = isPlatform(window, "capacitor");

  const agentIsEmptyObject = useMemo(() => {
    return Object.keys(agent).length === 0 && agent.constructor === Object;
  }, [agent]);

  const onChatClick = useCallback(() => {
    segmentInstance.track(
      agent.userId?.toString() as string,
      USER_EVENTS.CHAT_STARTED,
      {
        hcpId: agent._id,
        hcpName: `${agent.firstName} ${agent.lastName}`,
        shiftId: shift._id,
        shiftStartTime: shift.start,
        shiftGroupId: shift.groupId ? shift.groupId : "empty",
        shiftEndTime: shift.end,
        hcpType: agent.qualification,
        facilityId: shift.facility && shift.facility._id,
        facilityName: shift.facility && shift.facility.name,
      } as any
    );

    if (isCapacitor) {
      SegmentCapacitorPlugin.showMessenger();
    } else {
      window["Intercom"]("showNewMessage");
    }
  }, [agent, shift, isCapacitor]);

  const checkDocuments = useCallback(() => {
    const props = {
      isMissing: false,
      fill: "solid",
      label: "",
    };
    const DOCUMENT =
      documentsMissing && documentsMissing.size > 1 ? "Documents" : "Document";
    const DOCUMENT_PENDING =
      pending && pending.size > 1 ? "Documents" : "Document";

    if ([ShiftItemCategory.MISSING_CBH_DOCUMENTS].indexOf(category) !== -1) {
      props.fill = "outline";
      props.label = `Upload Required ${DOCUMENT}`;
      props.isMissing = true;
    }
    if (category === ShiftItemCategory.WAITING_FOR_APPROVE) {
      props.fill = "outline";
      props.label = `View Pending ${DOCUMENT_PENDING}`;
      props.isMissing = true;
    }

    return props;
  }, [category]);

  /**
   * When no content present avoid showing the button.
   */
  const buttonProps = useMemo(() => {
    let defaultProps: {
      onClick: typeof onClick;
      color: string;
      label: string | null;
      fill: "solid" | "outline" | "clear" | "default" | undefined;
    } = {
      onClick,
      color: "primary",
      label: null,
      fill: undefined,
    };

    if (
      agent.stage === AgentStages.Deactivated ||
      agent.stage === AgentStages.Suspended
    ) {
      defaultProps.color = "secondary";
      defaultProps.onClick = onChatClick;
      defaultProps.label = `Your account is ${agent.stage.toLowerCase()}. Click here to chat`;

      return defaultProps;
    }
    const { isMissing, ...missingDocsProps } = checkDocuments();

    if (checkRolloutMsa(shift, bookingRolloutMsas as string[])) {
      if (!isMissing) {
        defaultProps.label = "Book This Shift";
        defaultProps.fill = "solid";
        return defaultProps;
      }

      // @ts-ignore
      defaultProps = { ...defaultProps, ...missingDocsProps };
    } else {
      if (missingDocs || isInterested) {
        // @ts-ignore
        defaultProps = { ...defaultProps, ...missingDocsProps };
      } else {
        defaultProps.label = "Book This Shift";
        defaultProps.fill = "solid";
      }
    }

    return defaultProps;
  }, [
    shift,
    agent.stage,
    onChatClick,
    onClick,
    checkDocuments,
    bookingRolloutMsas,
  ]);

  if (!buttonProps.label) return null;
  if (!agent || agentIsEmptyObject) return null;

  return (
    <IonRow>
      <IonCol className="ion-text-center">
        <IonButton
          {...buttonProps}
          shape="round"
          strong={true}
          className="shift-ion-button ion-button"
          disabled={submitting}
        >
          <IonLabel>
            {submitting ? <IonSpinner name="dots" /> : buttonProps.label}
          </IonLabel>
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

export { ShiftActionButtonRow };
