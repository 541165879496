import { IonButton, IonDatetime, IonLabel, IonNote } from "@ionic/react";
import "./style.scss";
import moment from "moment-timezone";
const ShiftActionTime = ({
  shiftActionTime,
  title,
  isEditable,
  helperText,
  facilityTimeZone,
  onChange,
}: {
  shiftActionTime?: string | undefined;
  title: string;
  isEditable: boolean;
  helperText: String;
  facilityTimeZone: string;
  onChange: (value: string) => void;
}) => {
  return (
    <div className="edit-time-component">
      <IonLabel className="edit-time-label">{title}</IonLabel>
      <br />
      <div className="shift-time-component">
        <IonButton
          disabled={!isEditable}
          color="transparent"
          className="time-selector-button"
        >
          <IonDatetime
            displayTimezone={facilityTimeZone}
            hour-cycle="h12"
            className={"time-selector"}
            placeholder="--:--"
            displayFormat="h:mm A"
            value={shiftActionTime}
            onIonChange={(e) => onChange(e.detail.value!)}
          ></IonDatetime>
        </IonButton>
        <br />
        {!isEditable && helperText && (
          <IonNote className="edit-helper-text">
            Edited from{" "}
            {!helperText.includes("skipped")
              ? moment(helperText.toString()).format("hh:mm A")
              : helperText}
          </IonNote>
        )}
      </div>
    </div>
  );
};
export { ShiftActionTime };
