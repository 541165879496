import { IonChip, IonLabel } from "@ionic/react";
import React from "react";

import { PayRateChipProps } from "./model";

const PayRateChip: React.FC<PayRateChipProps> = ({ payVersion, time, pay }) => {
  let text = "";
  if (payVersion === "a") {
    const amount = !pay || !time ? 0 : pay * time;
    text = `~$${amount}`;
  } else if (payVersion === "b") {
    text = `$${pay || 0}/hr`;
  }

  return (
    <IonChip
      color="primary"
      style={{ fontWeight: "medium", fontsize: "16px" }}
      className="small-chip"
    >
      <IonLabel>{text}</IonLabel>
    </IonChip>
  );
};

export { PayRateChip };
