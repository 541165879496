import { IonBackButton, IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import React, { ErrorInfo } from "react";

type PropTypes = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

export const Fallback = ({ error, errorInfo }: PropTypes): JSX.Element => {
  return (
    <>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          marginTop: 20,
        }}
      >
        <div style={{ width: "80%" }}>
          <p style={{ color: "black", fontSize: 18, fontWeight: "bold" }}>
            Oops, Something went wrong.
          </p>
          <p style={{ color: "gray" }}>
            Please send us a screenshot of this page so we can fix the problem!
          </p>
          <p style={{ color: "gray" }}>You can go back or restart the app.</p>
          <div
            style={{
              backgroundColor: "#f2f2f2",
              borderRadius: "12px",
              padding: "16px",
            }}
          >
            {error && error.toString()}
            <br />
            {errorInfo && errorInfo.componentStack}
          </div>
        </div>
      </div>
    </>
  );
};
