import { getBuildNameAwareEnv } from "./getByBuildName";

export const sharedEnvironment = {
  mobileCode: "+1",
  mobileLength: 10,
  holidayAmount: 1.5,
  firebaseConfig: {
    apiKey: "AIzaSyDTI5wYMxrvW_PqyPr4X5F1qSrWhNdJtgU",
    authDomain: "clipboard-app.firebaseapp.com",
    databaseURL: "https://clipboard-app.firebaseio.com",
    projectId: "clipboard-app",
    storageBucket: "clipboard-app.appspot.com",
    messagingSenderId: "651599910014",
    appId: "1:651599910014:web:1690f2328ca70ced14342f",
    measurementId: "G-EFPXTPH5QT",
    dynamicShortLinkApiUrl:
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
  },
  support: {
    phone: "4088370116",
    email: "support@clipboardhealth.com",
    call: "1 (444) 444-4444",
  },
  fileUploadURL: "https://api.cloudinary.com/v1_1/bharanicbh/auto/upload",
  fileUploadPreset: "afvkimgz",
  googleMapApi: "AIzaSyB_SP5vECJvO40dN1WbFSvPri5xpmL7_ow",
  googleMapApiFindShifts: "AIzaSyA3NbLPDS51SHDnNkEV9gGoOQhbSEoGEmA", // Google Map API for Find Shifts CH-4124
  bundleId: "health.clipboard.worker",
  dynamicLinkDomain: "clipboard.page.link",
  shift: {
    isLocationCheck: false,
  },
  instantPayInformationUrl:
    "https://clipboardhealth.com/apply-today/stripe-pay",
  ReviewAppURL: {
    ios: "1472962791",
    android:
      "https://play.google.com/store/apps/details?id=health.clipboard.worker",
  },
  documentServiceUrl: "http://localhost:3337/graphql",
  mixpanelToken: "526833eb22638ba90aa0b358e4e4157c",
  googleStreetMapViewApiUrl: "https://maps.googleapis.com/maps/api/streetview",
  googleMapSearchUrl: "https://www.google.com/maps/search/?api=1",
  appleMapSearchUrl: "http://maps.apple.com",
  featureFlag: {
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID || "",
  },
  adjustToken: process.env.REACT_APP_ADJUST_APP_TOKEN,
  sendBirdAppId: process.env.REACT_APP_SENDBIRD_APP_ID || "",
  datadogRUM: {
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID || "",
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || "",
    service: "hcp-mobile-app",
    // this is an independent version for Datadog sourcemap
    // but if devops can use real production version, you can set it in your deployment script
    version: process.env.REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION || "1.0.0",
    sampleRate: 100,
    trackInteractions: true,
    env: getBuildNameAwareEnv(),
  },
  braze: {
    key: process.env.REACT_APP_HCP_WEB_BRAZE_KEY,
    sdkEndpoint: process.env.REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT,
  },
};
