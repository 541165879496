import { NetworkStatus } from "@capacitor/core";
import { FirebaseAuthentication } from "src/lib/firebase";
import { Agent } from "src/lib/interface";
import { IonicSafeString } from "@ionic/core";

interface DeviceFlags {
  isDistanceInfo?: boolean;
  missingDocFlags?: {};
  referralAlert?: {};
}

interface PendingActions {
  pending: boolean;
  action: {
    documents?: boolean;
    distance?: boolean;
    address?: boolean;
  };
}
interface Popups {
  ICAgreement?: { visible: boolean; [key: string]: unknown };
  deleteDocumentPopup?: { visible: boolean; [key: string]: unknown };
  replacementRejectedPopup?: { visible: boolean; [key: string]: unknown };
  covidTestingPopup?: { visible: boolean; [key: string]: unknown };
  profileUpdatePopup?: { visible: boolean; updateType?: "EMAIL" | "PHONE" };
}

// from [api]/envVariables/index.js
interface SessionEnv {
  disputedTimeDistance: number;
  instantPay: {
    amountPercentage: number;
    gracePeriod: number;
    defaultLunchTime: number;
  };
  facilitySafeDistanceMiles: number;
  PushNotificationOnAccountTab: boolean;
  enableNewFlow: boolean;
  minToAllowTimeCardUpload: number;
  minBeforeShiftStart: number;
  minInstantpayShiftWorkTime: number;
  shiftEndGracePeriod: number;
  callOffWindow: number;
  cancellationHoursMin: number;
  cancellationHoursMax: number;
}

interface Session {
  hasSession: boolean;
  firebaseAuth: FirebaseAuthentication;
  isLogging: boolean;
  isLoggingOut: boolean;
  isVerifying: boolean;
  isAuthorized: boolean;
  isSignup: boolean;
  verification?: {
    phone?: string;
    email?: string;
  } | null;
  loginError?: IonicSafeString | string | null;
  loginErrorType?: string | null;
  userId?: string;
  profile?: {
    name: string;
    email: string;
    phone: string;
  };
  agent?: Agent;
  deviceFlags?: DeviceFlags;
  networkStatus?: NetworkStatus | null;
  pendingActions: PendingActions;
  showAlert: boolean;
  isNewTimeCardFeatureEnabled: boolean;
  payVersion?: string;
  sendBirdAccessToken?: string;
  env?: SessionEnv | null;
  bookingRolloutMsas?: Array<string>;
  popups?: Popups;
  referralRate?: string;
}

const POPUP_ACTIONS = {
  SHOW_IC_AGREEMENT_POPUP: "SHOW_IC_AGREEMENT_POPUP",
  HIDE_IC_AGREEMENT_POPUP: "HIDE_IC_AGREEMENT_POPUP",
  SHOW_DELETE_DOCUMENT_POPUP: "SHOW_DELETE_DOCUMENT_POPUP",
  HIDE_DELETE_DOCUMENT_POPUP: "HIDE_DELETE_DOCUMENT_POPUP",
  SHOW_REPLACEMENT_DOCUMENT_REJECTED_POPUP:
    "SHOW_REPLACEMENT_DOCUMENT_REJECTED_POPUP",
  HIDE_REPLACEMENT_DOCUMENT_REJECTED_POPUP:
    "HIDE_REPLACEMENT_DOCUMENT_REJECTED_POPUP",
  SHOW_COVID_TESTING_POPUP: "SHOW_COVID_TESTING_POPUP",
  HIDE_COVID_TESTING_POPUP: "HIDE_COVID_TESTING_POPUP",
  SHOW_PROFILE_UPDATE_POPUP: "SHOW_PROFILE_UPDATE_POPUP",
  HIDE_PROFILE_UPDATE_POPUP: "HIDE_PROFILE_UPDATE_POPUP",
};

const ActionType = {
  FIREBASE_INIT: "FIREBASE_INIT",
  LOGIN_REQUESTED: "LOGIN_REQUESTED",
  VERIFICATION_ID_RECEIVED: "VERIFICATION_ID_RECEIVED",
  VERIFICATION_REQUESTED: "VERIFICATION_REQUESTED",
  VERIFICATION_COMPLETED: "VERIFICATION_COMPLETED",
  LOGGED_IN: "LOGGED_IN",
  LOGIN_ERROR: "LOGIN_ERROR",
  CLEAR_LOGIN_ERROR: "CLEAR_LOGIN_ERROR",
  LOGGED_OUT: "LOGGED_OUT",
  LOGOUT_REQUESTED: "LOGOUT_REQUESTED",
  AGENT_PROFILE: "AGENT_PROFILE",
  UPDATE_AGENT: "UPDATE_AGENT",
  UPDATE_DEVICE_FLAGS: "UPDATE_DEVICE_FLAGS",
  UPDATE_NETWORK_STATUS: "UPDATE_NETWORK_STATUS",
  UPDATE_PENDING_ACTIONS: "UPDATE_PENDING_ACTIONS",
  CHECKING_AUTHORIZATION: "CHECKING_AUTHORIZATION",
  SET_IS_SIGNUP: "SET_IS_SIGNUP",
  SET_SHOW_ALERT: "SET_SHOW_ALERT",
  SET_PAY_VERSION: "SET_PAY_VERSION",
  SET_ENV_VARIABLES: "SET_ENV_VARIABLES",
  SET_BOOKING_ROLLOUT_MSAS: "SET_BOOKING_ROLLOUT_MSAS",
  UPDATE_REFERRAL_RATE: "UPDATE_REFERRAL_RATE",
  ...POPUP_ACTIONS,
};

const LocalStorage = {
  AUTH_TOKEN: "authToken",
  DEVICE_FLAGS: "deviceFlags",
  AGENT: "agent",
  SEEN_FACILITY_COVID_TESTINGS: "seenFacilityCovidTestings",
  NEW_PHONE: "newPhoneNumber",
};

interface SessionAction {
  type: string;
  data?: Partial<Session>;
}

interface PayVersion {
  payVersion: string;
}

interface AgentProfileResp {
  error: string;
  message: string;
  data?: {
    firebaseToken?: string;
  };
}

interface AdjustFeatureFlagValidationResponse {
  success: boolean;
  triggerAdjustEvent: boolean;
}

export {
  AgentProfileResp,
  Session,
  ActionType,
  SessionAction,
  LocalStorage,
  PendingActions,
  PayVersion,
  Popups,
  AdjustFeatureFlagValidationResponse,
  SessionEnv,
};
