import { Plugins } from "@capacitor/core";
import { setReferralRate } from "@store/session/actions";
import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateAgentVersion } from "./api";

const { Device } = Plugins;

const WorkerAppVersion: React.FC<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const updateVersionInfo = async () => {
      const { appVersion, appBuild, platform } = await Device.getInfo();

      await updateAgentVersion({
        platform,
        version: appVersion,
        build: Number(appBuild),
      });
      dispatch(setReferralRate());
    };

    updateVersionInfo();
  }, []);

  return <Fragment />;
};

export { WorkerAppVersion };
