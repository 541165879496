import { Agent, User } from "src/lib/interface";
import { FirebaseAuthentication } from "src/lib/firebase";
import { logApiFailureEvent } from "src/lib/analytics";
import request from "superagent";

import {
  AgentProfileResp,
  AdjustFeatureFlagValidationResponse,
} from "./session.model";
import { environment } from "../../../environments/environment";
import { logFailedApiRetry } from "../../utils/api_retry";

import { get } from "lodash";
import { getAuthHeader } from "@app/superagent";

const getAgentByPhone = async (phone: string): Promise<Agent> => {
  return await request
    .get(`${environment.baseUrl}/agentProfile/byPhone`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/agentProfile/byPhone`);
      return true;
    })
    .query({ phone })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const getUserByEmail = async (token: string): Promise<User> => {
  return await request
    .get(`${environment.baseUrl}/user/getByEmail`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/user/getByEmail`);
      return true;
    })
    .set("Authorization", token)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const updateAgentLoggedInTime = async (
  firebaseAuth: FirebaseAuthentication
): Promise<Agent> => {
  const token = (await firebaseAuth.currentUser?.getIdToken()) as string;
  return await request
    .post(`${environment.baseUrl}/agentProfile/loggedIn`)
    .set("Authorization", token)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchEnvVariables = async () => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/envVariables`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/envVariables`);
        return true;
      });

    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const sendAgentLoginLink = async (email: string, url: string) => {
  return await request
    .post(`${environment.baseUrl}/agentProfile/sendLoginLink`)
    .send({ email, url })
    .then(({ body }) => body)
    .catch((error) => ({ success: false, ...error }));
};

const requestAgentOTP = async (
  phone: string,
  isSignup: boolean
): Promise<AgentProfileResp> => {
  return await request
    .post(`${environment.baseUrl}/agentProfile/requestOTP`)
    .send({ phone, isSignup })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};
const validateAgentOTP = async (
  phone: string,
  code: string
): Promise<AgentProfileResp> => {
  return await request
    .post(`${environment.baseUrl}/agentProfile/validateOTP`)
    .send({ phone, otp: code })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const getReferralRate = async (): Promise<string> => {
  return await request
    .get(`${environment.baseUrl}/agentProfile/referralBonusAmount`)
    .set(await getAuthHeader())
    .then(({ body }) => body?.referralRate)
    .catch(logApiFailureEvent);
};

const triggerAdjustEventTracking =
  async (): Promise<AdjustFeatureFlagValidationResponse> => {
    try {
      const { body } = await request
        .get(
          `${environment.baseUrl}/agentprofile/validateAdjustEventFeatureFlag`
        )
        .retry(1, (err) => {
          logFailedApiRetry(
            err,
            `/agentprofile/validateAdjustEventFeatureFlag`
          );
          return true;
        });
      return body;
    } catch (error) {
      logApiFailureEvent(error);
      throw error;
    }
  };

export {
  getAgentByPhone,
  getUserByEmail,
  updateAgentLoggedInTime,
  fetchEnvVariables,
  sendAgentLoginLink,
  requestAgentOTP,
  validateAgentOTP,
  triggerAdjustEventTracking,
  getReferralRate,
};
