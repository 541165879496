import { IonItem, IonLabel, IonList, IonSkeletonText } from "@ionic/react";
import React from "react";

const ListLoader: React.FC = () => {
  return (
    <IonList>
      <IonItem>
        <IonLabel>
          <IonSkeletonText animated style={{ width: "100%" }} />
          <IonSkeletonText animated style={{ width: "100%" }} />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <IonSkeletonText animated style={{ width: "100%" }} />
          <IonSkeletonText animated style={{ width: "100%" }} />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <IonSkeletonText animated style={{ width: "100%" }} />
          <IonSkeletonText animated style={{ width: "100%" }} />
        </IonLabel>
      </IonItem>
    </IonList>
  );
};

export { ListLoader };
