import { RangeChangeEventDetail, RangeValue } from "@ionic/core";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRange,
  IonText,
} from "@ionic/react";
import { home, business } from "ionicons/icons";
import { get } from "lodash";
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAgent } from "src/app/store/session";
import { Store } from "src/app/store/store.model";
import { updatePreference } from "src/app/openShifts/api";
import { DistancePreferenceModalProps } from "./interface";
import "./style.scss";
import { logEvent, addDataToIdentity } from "src/lib/analytics";
import { USER_EVENTS, DISTANCE_PREFERENCE_MAX_VALUE } from "src/constants";

export const DistancePreference: React.FC<DistancePreferenceModalProps> = ({
  isOpen,
  distancePreference,
  closeModal,
  updateOpenShifts,
}) => {
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const [distance, setDistance] = useState<RangeValue>(distancePreference);
  const dispatch = useDispatch();

  const onChange = (event: CustomEvent<RangeChangeEventDetail>) => {
    const { value } = event.detail;
    setDistance(value);
  };

  const onSave = useCallback(async () => {
    const updatedAgent = await updatePreference("distance", distance as number);

    dispatch(updateAgent(updatedAgent));
    updateOpenShifts?.();

    logEvent(USER_EVENTS.UPDATED_DISTANCE_PREFERENCE, {
      distance: distance as number,
    });
    addDataToIdentity(agent.userId, {
      preference_distance: distance as number,
    });
    closeModal?.();
  }, [distance]);

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false} cssClass="distance-modal">
      <IonContent id="distance-modal-container">
        <div className="distance-slider-container">
          <IonText className="modal-title">
            <h2>Distance Preference</h2>
          </IonText>
          <IonText className="modal-subtitle">
            <p>How far are you willing to travel for work?</p>
          </IonText>
          <IonItem lines="none">
            <IonIcon icon={home} color="black" />
            <IonLabel className="ion-text-center">
              <h1>
                {distance} {distance > 1 ? "miles" : "mile"}
              </h1>
            </IonLabel>
            <IonIcon icon={business} color="black" />
          </IonItem>
          <IonRange
            min={1}
            max={DISTANCE_PREFERENCE_MAX_VALUE}
            value={distance}
            onIonChange={onChange}
          ></IonRange>
        </div>
        <div className="distance-modal-action-container">
          <IonButton expand="full" shape="round" onClick={onSave}>
            Update
          </IonButton>
          <IonButton
            expand="full"
            shape="round"
            style={{
              "--border-color": "#828282",
              "--color": "#828282",
              marginTop: "10px",
            }}
            fill="outline"
            onClick={() => closeModal?.()}
          >
            Cancel
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
