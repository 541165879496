import { CovidTestingPopup } from "./CovidTestingPopup";
import { DeleteDocumentModal } from "./DeleteDocumentModal";
import { ReplacementRejected } from "./RepacementRejectedPopup";
import { SignICAgreementModal } from "./SignICAgreementModal";
import { ProfileUpdatePopup } from "./ProfileUpdatePopup";

const PopupAlerts = () => {
  return (
    <>
      <ProfileUpdatePopup />
      <SignICAgreementModal />
      <DeleteDocumentModal />
      <ReplacementRejected />
      <CovidTestingPopup />
    </>
  );
};

export { PopupAlerts };
