import { Plugins } from "@capacitor/core";
import {
  getAddressByGeoCode,
  PlacesAutoComplete,
} from "src/lib/ionic-components";
import { enableGeoLocation, isGeoLocationEnabled } from "@app/common/location";
import { IonIcon, IonItem, IonLabel, IonAlert } from "@ionic/react";
import { locate } from "ionicons/icons";
import { get } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { environment } from "../../environments/environment";
import { LocationEditProps, Place } from "./model";

const { Geolocation } = Plugins;
const { googleMapApi } = environment;

const LocationEdit: React.FC<LocationEditProps> = ({
  searchLocation,
  onPlaceChange,
}) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [geoLocationError, setGeoLocationError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [loadingCurrentLocation, setLoadingCurrentLocation] =
    useState<boolean>(false);
  const [enablingServices, setEnablingServices] = useState<boolean>(false);

  useEffect(() => {
    isGeoLocationEnabled()
      .then(setIsEnabled)
      .catch(() => setIsEnabled(false));
  }, []);

  const enableLocation = async () => {
    try {
      setEnablingServices(true);
      const { success } = await enableGeoLocation();
      setIsEnabled(success);
    } finally {
      setEnablingServices(false);
    }
  };

  const onCurrentLocation = async () => {
    try {
      setLoadingCurrentLocation(true);
      const position = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });
      const lat = get(position, "coords.latitude", "");
      const lng = get(position, "coords.longitude", "");
      const address = await getAddressByGeoCode({ lat, lng });
      if (address && address.formatted) {
        onPlaceChange({ address, location: { lat, lng } });
      } else {
        setGeoLocationError({
          isError: true,
          message: `Could not get current location, try entering your address`,
        });
      }
    } catch (error) {
      setGeoLocationError({
        isError: true,
        message: "Please allow Geo Location access",
      });
    } finally {
      setLoadingCurrentLocation(false);
    }
  };

  const handleCurrentLocationItemClick = () => {
    isEnabled ? onCurrentLocation() : enableLocation();
  };

  const handlePlaceChange = (addressData: Place) => {
    if (!addressData) {
      return;
    }
    const {
      address: {
        streetName,
        streetNumber,
        city,
        state,
        country,
        postalCode,
      } = {},
    } = addressData;
    if (
      !(streetName && streetNumber && city && state && country && postalCode)
    ) {
      setGeoLocationError({
        isError: true,
        message:
          "That doesn’t look like a complete address. Make sure to enter the full address of your current residence.",
      });
      return;
    }
    onPlaceChange(addressData);
  };

  return (
    <Fragment>
      <IonAlert
        mode="ios"
        isOpen={geoLocationError.isError}
        backdropDismiss={false}
        header="Please input a full address"
        message={geoLocationError.message}
        buttons={[
          {
            text: "OK",
            handler: () => setGeoLocationError({ isError: false, message: "" }),
          },
        ]}
      />
      <IonItem className="form-item-wrapper">
        <PlacesAutoComplete
          defaultValue={searchLocation}
          onChange={handlePlaceChange}
          googleApiKey={googleMapApi}
          className="form-input"
        />
      </IonItem>
      <IonItem
        lines="none"
        button
        detail={false}
        onClick={handleCurrentLocationItemClick}
        className="form-item-wrapper"
      >
        <IonIcon icon={locate} mode="md" slot="start" className="form-icon" />
        <IonLabel>
          <h4>Use your current location</h4>
          <p>
            {loadingCurrentLocation || enablingServices
              ? "Loading..."
              : isEnabled
              ? "Locate using GPS"
              : "Enable Location Services"}
          </p>
        </IonLabel>
      </IonItem>
    </Fragment>
  );
};

export { LocationEdit };
