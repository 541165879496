import "./style.scss";
import _ from "lodash";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../constants/userEvents";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { LoginRouteState, VerifyProps } from "./login.model";
import React, { useState } from "react";
import { OtpInputs } from "../components/auth/otpInputs";

const CODE_LENGTH = 6;

const VerifyComponent: React.FC<VerifyProps> = ({
  isVerifying,
  history,
  location,
  reVerifyPhone,
  ...props
}) => {
  const [code, setCode] = useState<string>("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [otpResendStatus, setOtpResendStatus] = useState(false);

  const { phone, redirectPath } = (location.state as LoginRouteState) || {};

  const verify = async (newCode?) => {
    if (isVerifying) return;
    if (newCode.trim().length !== CODE_LENGTH) return;
    if (isButtonDisabled) setButtonDisabled(false);
    logEvent(USER_EVENTS.ENTERED_TWO_FACTOR_CODE);
    await props.loginWithCode(newCode.trim(), phone as string);
    if (redirectPath) {
      history.push(redirectPath);
    }
  };

  const resendOTP = async () => {
    const verification = await reVerifyPhone(phone as string);
    /**
     *we are still yet to figure  out why typescript
     **/
    // @ts-ignore
    if (verification) {
      setOtpResendStatus(true);
    }
  };

  return (
    <IonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/welcome/login"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent class="ion-padding">
        <div className="content-layout verifyotp-content signup-content">
          <div className="form-container">
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>Enter your verification code</h4>
                  <p>We’ve just sent a verification code to {phone} </p>
                </div>
                <div>
                  <IonLabel className="form-label">Verification Code</IonLabel>
                  <OtpInputs verify={verify} code={code} setCode={setCode} />
                  <a className="resend-otp-link" onClick={resendOTP}>
                    Didn't get a code? Resend it now.
                  </a>
                </div>
              </IonCol>
            </IonRow>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              onClick={verify}
              disabled={isVerifying || isButtonDisabled}
              id="verify-button"
            >
              Continue
              {isVerifying && (
                <IonSpinner slot="end" class="ion-margin-start" name="lines" />
              )}
            </IonButton>
          </div>
        </div>

        <IonToast
          isOpen={otpResendStatus}
          onDidDismiss={() => setOtpResendStatus(false)}
          message="OTP re-sent successfully."
          duration={5000}
          color="success"
          position="top"
        />
        <IonToast
          isOpen={!!props.loginError}
          onDidDismiss={props.clearLoginError}
          message={props.loginError}
          duration={1000}
          color="danger"
          position="top"
        />
      </IonContent>
    </IonPage>
  );
};

export { VerifyComponent };
