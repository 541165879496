import { IonRouterOutlet } from "@ionic/react";
import React from "react";
import { Route } from "react-router-dom";

import { LandingPage, VerifyPage as VerifyOTP } from ".";
import { LoginPage, VerifyPage } from "../login";
import { EmailVerify } from "../login/emailVerify";
import { VerifyLogin } from "../login/verifyLogin";
import { EmailVerifySent } from "../login/verifySent";
import { WelcomeRouterPath } from "../routing/constant/welcomeRoute";

const WelcomeRoute: React.FC<{}> = () => (
  <IonRouterOutlet>
    <Route exact path={WelcomeRouterPath.LOGIN} component={LoginPage} />
    <Route exact path={WelcomeRouterPath.LOGIN_VERIFY} component={VerifyPage} />
    <Route
      exact
      path={WelcomeRouterPath.LOGIN_EMAIL_VERIFY}
      component={EmailVerify}
    />
    <Route
      exact
      path={WelcomeRouterPath.LOGIN_VERIFY_LINK}
      component={VerifyLogin}
    />
    <Route
      exact
      path={WelcomeRouterPath.LOGIN_EMAIL_SENT}
      component={EmailVerifySent}
    />
    <Route exact path={WelcomeRouterPath.SIGNUP_VERIFY} component={VerifyOTP} />
    <Route
      exact
      path={WelcomeRouterPath.LANDING_PAGE}
      component={LandingPage}
    />
  </IonRouterOutlet>
);

export { WelcomeRoute };
