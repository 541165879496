import * as Sentry from "@sentry/react";
import { Moment } from "moment";
import moment from "moment-timezone";
import { actionUploadTimeCardV2 } from "@store/ongoingShifts";
import { useDispatch } from "react-redux";
import { IonIcon, IonSpinner, IonText, IonToast } from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";
import { logEvent } from "src/lib/analytics";
import { Shift } from "src/lib/interface";
import { USER_EVENTS } from "../../../constants/userEvents";
import { SelectedFile } from "../../shiftSignature/timecard/model";

const FIVE_MINUTES_IN_MS = 300000;

const FilePreviewAndUpload: React.FC<{
  selectedFile: SelectedFile;
  shift: Shift;
  onPreviewClosed: () => void;
  onSuccessfulUpload: () => void;
}> = ({ selectedFile, shift, onPreviewClosed, onSuccessfulUpload }) => {
  const { file, type } = selectedFile;
  const isImage = ["jpg", "jpeg", "png"].includes(type);

  const dispatch = useDispatch();

  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");

  let timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const maxTimeout = () => {
    return new Promise((_, reject) => {
      timeoutRef.current = setTimeout(() => {
        reject();
      }, FIVE_MINUTES_IN_MS);
    });
  };

  const uploadFile = async () => {
    const uploadTimeStart = moment();
    try {
      setUploading(true);
      logEvent(
        USER_EVENTS.UPLOAD_TIMESHEET_STARTED,
        makeUploadTimeLog(shift, file, uploadTimeStart, false, null)
      );
      await Promise.race([
        maxTimeout(),
        dispatch(actionUploadTimeCardV2(file, shift._id as string)),
      ]);
      logEvent(
        shift.isInstantPay
          ? USER_EVENTS.SUBMITTED_INSTANTPAY_TIMECARD_PHOTO
          : USER_EVENTS.SUBMITTED_TIMECARD_PHOTO,
        {
          instant_pay: shift?.isInstantPay,
        }
      );
      setUploading(false);
      logEvent(USER_EVENTS.UPLOAD_TIMESHEET_SUCCESS, {
        ...makeUploadTimeLog(shift, file, uploadTimeStart, true, null),
      });
      onSuccessfulUpload();
    } catch (err) {
      const error = err as Error | string;
      Sentry.captureException(error);
      logEvent(
        USER_EVENTS.UPLOAD_TIMESHEET_FAILED,
        makeUploadTimeLog(shift, file, uploadTimeStart, true, error)
      );
      if (error?.toString().includes("Request has been terminated")) {
        setUploadErrorMessage("Fail while uploading, please try again");
      } else {
        setUploadErrorMessage(error?.["message"] || error);
      }
      setUploading(false);
    }
  };

  if (!isImage) {
    return null;
  }

  return (
    <>
      <IonToast
        isOpen={!!uploadErrorMessage}
        message={uploadErrorMessage}
        onDidDismiss={() => {
          setUploadErrorMessage("");
        }}
        color="danger"
        position="top"
        duration={5000}
      />
      <div className="upload-preview">
        <div className="icon" onClick={onPreviewClosed}>
          <IonIcon name="close-circle" />
        </div>
        <img className="uploaded-timesheet" alt="timecard" src={file} />
      </div>
      <div
        className="ion-text-center ion-margin file-upload-container btn"
        style={{ zIndex: 9999 }}
      >
        <IonText onClick={uploadFile}>
          {uploading ? (
            <>
              <IonSpinner slot="start" name="crescent" color="dark" />
              <span>Submitting...</span>
            </>
          ) : (
            "Submit Photo"
          )}
        </IonText>
      </div>
    </>
  );
};

export { FilePreviewAndUpload, makeUploadTimeLog as MakeUploadTimeLog };

const readFileSize = (file: SelectedFile["file"]) => {
  const stringLength = file.length;
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
  const sizeInKb = sizeInBytes / 1000;
  const sizeInMb = sizeInKb / 1000;
  return `${sizeInMb.toFixed(2)} MB`;
};

const makeUploadTimeLog = (
  shift: Shift,
  file: SelectedFile["file"],
  uploadTimeStart: Moment,
  isUploadEnd: boolean,
  error: Error | string | null
) => {
  let uploadTimeEnd;
  let uploadTime = "";
  if (uploadTimeStart && isUploadEnd) {
    uploadTimeEnd = moment();
    uploadTime = moment
      .duration(uploadTimeEnd.diff(uploadTimeStart))
      .asSeconds()
      .toString();
  }
  return {
    imageSize: file ? readFileSize(file) : "",
    shiftId: shift._id,
    isInstantPay: `${shift.isInstantPay}`,
    uploadTimeStart: uploadTimeStart?.format
      ? uploadTimeStart.format("YYYY MMM DD HH:mm:SS")
      : "",
    uploadTimeEnd: uploadTimeEnd?.format
      ? uploadTimeEnd.format("YYYY MMM DD HH:mm:SS")
      : "",
    uploadTime,
    error: error ? `${error}` : "",
  };
};
