import { IonAlert } from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Store } from "@store/store.model";

import { ActionType } from "@store/session";
import "./style.scss";
import { useMutation } from "@apollo/client";
import { DELETE_HCP_DOCUMENT } from "@app/documents/gql";
import { TabRouterPath } from "@app/routing/constant/tabRoute";

const DeleteDocumentModal = () => {
  const popups = useSelector((state: Store) => state?.session?.popups);
  const {
    deleteDocumentPopup: { visible, documentId } = {
      visible: false,
      documentId: "",
    },
  } = popups || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteDocument] = useMutation(DELETE_HCP_DOCUMENT);

  const onCancelDeleteDocument = () => {
    dispatch({ type: ActionType.HIDE_DELETE_DOCUMENT_POPUP });
  };

  const onDeleteDocument = async () => {
    await deleteDocument({ variables: { _id: documentId } });
    history.replace(TabRouterPath.DOCUMENTS, { forceReload: true });
  };

  return (
    <>
      {visible && (
        <IonAlert
          isOpen={true}
          header="Delete this document?"
          message="Are you sure you want to delete this document? This action cannot be undone."
          cssClass="alert-popup-container"
          mode="ios"
          backdropDismiss={true}
          onDidDismiss={onCancelDeleteDocument}
          buttons={[
            {
              text: "Delete",
              cssClass: "negative-action",
              handler: onDeleteDocument,
            },
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "neutral-action",
              handler: onCancelDeleteDocument,
            },
          ]}
        />
      )}
    </>
  );
};

export { DeleteDocumentModal };
