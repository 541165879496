import { Address } from "./address";
import { GeoLocation } from "./util";

interface AgentOnboardingFlags {
  isSignupInitiated?: boolean;
  isSignupCompleted?: boolean;
  signupStage?: string;
}

interface License {
  number?: string;
  expires?: string;
  state?: string;
}

interface AgentAttendance {
  sortMeta?: 0 | 1;
  completedShifts?: number;
  cancelledShifts?: number;
  totalShifts?: number;
  consecutive?: number;
  consecutiveCancels?: number;
  percentage?: number;
  updatedAt?: string;
}

interface AgentRequirement {
  requirement?: string;
  expires?: string;
}

interface AgentUploads {
  requirement?: string;
  status?: string;
}

interface Specialities {
  experience?: string[];
  hasSedationExperience?: boolean;
  hasTrayAssemblyExperience?: boolean;
}

interface AgentRating {
  count?: number;
  value?: number;
}

interface NotificationPreference {
  off: boolean; // deprecated field
  push: boolean;
  email: boolean;
  sms: boolean;
  marketingPush: boolean;
  marketingEmail: boolean;
  marketingSMS: boolean;
  long_code_sms: boolean; // deprecated field
  last_minute_notifications?: {
    isOn: boolean;
    snoozedOn: Date | null;
    snoozedForDays: number | null;
  };
}

interface preference {
  distance?: number;
  minPayHourly?: number;
  minPayShift?: number;
}

interface AppRating {
  appRatingSubmitted?: boolean;
  countPromptShown?: number;
  appRating?: string;
  countCompletedShiftsWhenLastShownPrompt?: number;
}

interface ContractorAgreement {
  agreementVersion: String;
  signedDate: Date;
}

interface BackgroundCheckApplication {
  applicationType?: string;
  candidateId?: string;
}
interface Mobile {
  openedAt?: Date;
  version?: string;
  build?: number;
  platform?: string;
  isLocationServicesEnabled?: boolean;
  locationServicesPermission?: string;
}
interface Agent {
  _id?: string;
  active?: boolean;
  userId?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  qualification?: string;
  geoLocation?: GeoLocation;
  address?: Address;
  specialities?: Specialities;
  license?: License;
  onboardingFlags?: AgentOnboardingFlags;
  socialSecurityNumber?: string;
  fullSocialSecurityNumber?: string;
  noCallNoShow?: number;
  attendance?: AgentAttendance;
  requirements?: AgentRequirement[];
  profileImageUrl?: string;
  rating?: AgentRating;
  stage?: AgentStages;
  otherQualification?: string;
  referralCode?: string;
  showReferralAlert?: boolean;
  rate?: number;
  uploads?: AgentUploads[];
  isFirstSession?: boolean;
  isNotificationAlertShown?: boolean;
  isFirstTimeLoggedIn?: boolean;
  applicantId?: string;
  signupExperimentId?: string;
  signupConfig?: {
    signingBonusAmount1?: number;
    signingBonusAmount2?: number;
  };
  hasClaimedBonus?: boolean;
  notificationPreferences?: NotificationPreference;
  enableNewFlow?: boolean;
  appRatingStatus?: AppRating;
  status?: number;
  phoneNumber?: string | number;
  onboardAt?: string;
  lastLogAt?: string;
  preference?: preference;
  interestedAt?: object;
  canBeOnboardedInStripe?: boolean;
  alreadyOnboarded?: boolean;
  dob?: string;
  paymentAccountInfo?: PaymentAccountInfo;
  signedDate?: string;
  tmz?: string;
  alreadyWorked?: boolean;
  referralCodeUsed?: {
    code: string;
    createdAt: Date;
    referredBonusId?: string;
    referrerBonusId?: string;
    referrer: string;
  };
  signedContractorAgreements?: ContractorAgreement[];
  attendancePolicy?: {
    callOffsCancellations: number;
    standardCancellations: number;
    isnewAttendancePolicyFeatureFlagEnabled: boolean;
  };
  shouldSignLatestAgreement?: boolean;
  backgroundCheckApplications?: BackgroundCheckApplication[];
  mobile?: Mobile;
  baseRate?: number;
}

interface PaymentAccountInfo {
  _id: string;
  accountId: string;
  createdAt: string;
  enabled: boolean;
  enabledAt: string;
  updatedAt: string;
  autoWithdrawEnabled: boolean;
  withdrawalFee: number;
  status: PaymentServiceAccountStatus;
  showOnboard?: boolean;
  schedule?: {
    delayDays?: number;
    interval?: "daily" | "weekly" | "manual";
  };
}

enum AgentStages {
  Onboarding = "ONBOARDING",
  Enrolled = "ENROLLED",
  Probation = "PROBATION",
  Suspended = "SUSPENDED",
  Deactivated = "DEACTIVATED",
}
enum PaymentServiceAccountStatus {
  ACCOUNT_CREATED = "Account Created",
  ACCOUNT_NEEDS_UPDATE = "Account Needs Updating",
  STANDARD_PAYOUT_PENDING = "Standard Payouts Pending Approval",
  INSTANT_PAYOUT_PENDING = "Instant Payouts Pending Approval",
  STANDARD_PAYOUT_ENABLED = "Standard Payouts Enabled",
  INSTANT_PAYOUT_ENABLED = "Instant Payouts Enabled",
  NO_DOCUMENTS_REQUIRED = "No Documents Required",
}

type SignUpStatus = "inprogress" | "complete" | "unstarted" | "unknown";

export type {
  Agent,
  AgentAttendance,
  License,
  AgentRequirement,
  Specialities,
  AgentUploads,
  NotificationPreference,
  PaymentAccountInfo,
  SignUpStatus,
};

export { AgentStages, PaymentServiceAccountStatus };
