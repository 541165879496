import { IonAlert } from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "@store/store.model";
import { ActionType, LocalStorage } from "@store/session";

const CovidTestingPopup = () => {
  const popups = useSelector((state: Store) => state?.session?.popups);

  const {
    covidTestingPopup: { visible, facilityName, facilityId } = {
      visible: false,
      facilityName: "",
      facilityId: "",
    },
  } = popups || {};

  const dispatch = useDispatch();

  const onOk = async () => {
    const seenCovidTestings: Array<string> = JSON.parse(
      localStorage.getItem(LocalStorage.SEEN_FACILITY_COVID_TESTINGS) || "[]"
    );
    if (facilityId) {
      seenCovidTestings.push(String(facilityId));
      localStorage.setItem(
        LocalStorage.SEEN_FACILITY_COVID_TESTINGS,
        JSON.stringify(seenCovidTestings)
      );
    }
    dispatch({ type: ActionType.HIDE_COVID_TESTING_POPUP });
  };

  const onCancel = async () => {
    dispatch({ type: ActionType.HIDE_COVID_TESTING_POPUP });
  };

  return (
    <>
      {visible && (
        <IonAlert
          isOpen={true}
          header={``}
          message={`<div> 
            <div class='covid-testing-header'>
             
                <img src='../../assets/icons/test-tubes.svg' alert='covid-alert' />
                <span style='font-size: 1rem; font-wight: bold'>COVID Testing</span>
            </div>
            <div class='covid-testing-message'>
              <p> Some facilities including ${facilityName} administer a <strong>rapid COVID test</strong> before all shifts.</p>
              <p> Look out for the testing icon(<img src='../../assets/icons/test-tubes.svg' alert='covid-alert' />) to know which shifts have on-site testing beforehand.
            </div>
            </div>
          `}
          mode="ios"
          backdropDismiss={true}
          onDidDismiss={onCancel}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "OK",
              handler: onOk,
            },
          ]}
        />
      )}
    </>
  );
};

export { CovidTestingPopup };
