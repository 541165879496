import { IonAlert } from "@ionic/react";
import { logEvent } from "src/lib/analytics";
import React from "react";
import { useHistory } from "react-router-dom";
import { Events, MessageString } from "src/lib/constants";
import { AlertButton } from "@ionic/core";

import { AlertProps } from "./calendar.model";

const ForwardAlertPopup: React.FC<AlertProps> = ({
  toggleOpen,
  closeModal,
  month,
}) => {
  const closeModalHandler = () => {
    closeModal();
  };

  if (toggleOpen) {
    logEvent(Events.NO_SHIFT_ALERT, {
      "View Future Month": MessageString.viewFutureMonth,
    });
  }

  return (
    <IonAlert
      isOpen={toggleOpen}
      header={`No open shifts in ${month} yet.`}
      message={`Please check back later.`}
      backdropDismiss={false}
      buttons={[
        {
          text: "ok",
          handler: closeModalHandler,
        },
      ]}
    />
  );
};

const BackAlertPopup: React.FC<AlertProps> = ({ toggleOpen, closeModal }) => {
  const history = useHistory();

  const goToMyShift = () => {
    closeModal();
    history.push("/home/myShifts");
  };

  if (toggleOpen) {
    logEvent(Events.NO_SHIFT_ALERT, {
      "View Past Month": MessageString.viewPastMonth,
    });
  }

  const alertButtons: AlertButton[] = [
    {
      text: "Stay here",
      handler: () => closeModal(),
    },
    {
      text: "Take me there",
      handler: goToMyShift,
    },
  ];

  return (
    <IonAlert
      isOpen={toggleOpen}
      header="Trying to see your past shifts?"
      message={`Go to My Shifts to see your past shifts.`}
      backdropDismiss={false}
      buttons={alertButtons}
    />
  );
};

export { BackAlertPopup, ForwardAlertPopup };
