import { GlobalRouterPath } from "./globalRoute";
import { RoutePathInfo } from "./interface";

const WelcomeRouterPath = {
  LANDING_PAGE: GlobalRouterPath.WELCOME,
  SIGNUP: `${GlobalRouterPath.WELCOME}/signup`,
  SIGNUP_VERIFY: `${GlobalRouterPath.WELCOME}/signup/verify`,
  LOGIN: `${GlobalRouterPath.WELCOME}/login`,
  LOGIN_VERIFY: `${GlobalRouterPath.WELCOME}/login/verify`,
  LOGIN_EMAIL_VERIFY: `${GlobalRouterPath.WELCOME}/login/emailVerify`,
  LOGIN_EMAIL_SENT: `${GlobalRouterPath.WELCOME}/login/emailVerify/sent`,
  LOGIN_VERIFY_LINK: `${GlobalRouterPath.WELCOME}/login/linkVerify`,
};

const WelcomeRouterPathInfo: Record<
  keyof typeof WelcomeRouterPath,
  RoutePathInfo
> = {
  LANDING_PAGE: {
    name: "Landing Page",
  },
  SIGNUP: {
    name: "Signup",
  },
  SIGNUP_VERIFY: {
    name: "Signup OTP Verification",
  },
  LOGIN: {
    name: "Login",
  },
  LOGIN_VERIFY: {
    name: "Login OTP Verification",
  },
  LOGIN_EMAIL_VERIFY: {
    name: "Login Email Verification",
  },
  LOGIN_EMAIL_SENT: {
    name: "Login Email SentNotification",
  },
  LOGIN_VERIFY_LINK: {
    name: "Login Link Verification",
  },
};

export { WelcomeRouterPath, WelcomeRouterPathInfo };
