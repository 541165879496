import React, { useEffect, useState, FormEvent } from "react";
import {
  IonButtons,
  IonButton,
  IonBackButton,
  IonContent,
  IonHeader,
  IonLabel,
  IonTitle,
  IonToolbar,
  IonToast,
  IonSpinner,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";
import { LogoutButton, openBrowser } from "src/lib/ionic-components";

import { logout, updateAgent } from "../../store/session";
import { Store } from "../../store/store.model";

import { StyledIonPage } from "./style";
import { updateAgentData, showSSNPage, showDoBPage } from "./api";
import { OnBoardingComponentProps, AddressPageData } from "./model";
import { LocationEdit } from "../../address/locationEdit";
import { Place } from "../../address/model";
import timezoneLookup from "tz-lookup";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { OnboardingRouterPath } from "../../routing/constant/onboardingRoute";
import Alert from "./Alert";
import { logOnboardingError } from "../util/logging";

const AgentAddress: React.FC<OnBoardingComponentProps> = ({
  nextStagePath,
}) => {
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [addressData, setAddressData] = useState<
    AddressPageData | Partial<AddressPageData>
  >({});

  const { agent, profile } = useSelector((state: Store) => state.session);

  const history = useHistory();
  const dispatch = useDispatch();

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();
    setShowErrorModal(false);
    try {
      setUpdatingData(true);
      await updateAgentData({
        stage: ONBOARDING_STAGES.ADDRESS,
        address: addressData.address,
        location: addressData.location,
        tmz: timezoneLookup(
          addressData?.location?.lat,
          addressData?.location?.lng
        ),
      });
      dispatch(
        updateAgent({
          ...agent,
          address: addressData.address,
          tmz: timezoneLookup(
            addressData?.location?.lat,
            addressData?.location?.lng
          ),
        })
      );
      fireOnboardingSegmentEvent(
        ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_ADDRESS,
        {
          hcpId: agent?.userId as string,
          hcpEventValue: addressData?.address?.formatted,
        }
      );

      const { enabled } = await showDoBPage();
      if (enabled) {
        history.push(`${OnboardingRouterPath.ONBOARDING_DOB}`);
        return;
      }
      const { showSSNFlag } = await showSSNPage();
      if (showSSNFlag) {
        history.push(`${OnboardingRouterPath.ONBOARDING_SSN}`);
        return;
      }
      history.push(`${nextStagePath}`);
    } catch (error) {
      if (
        !error.response ||
        !error.response.text ||
        error.response.status > 500
      ) {
        logOnboardingError(
          ONBOARDING_STAGES.ADDRESS,
          (error as Error).message,
          agent?.userId
        );
        setShowErrorModal(true);
        return;
      }
      setPageError({
        isError: true,
        message: "Unexpected error occurred while saving address",
      });
    } finally {
      setUpdatingData(false);
    }
  };

  useEffect(() => {
    if (agent) {
      const { geoLocation, address } = agent;
      const lat = get(geoLocation, "coordinates[1]");
      const lng = get(geoLocation, "coordinates[0]");
      if (!isEmpty(address) && lat && lng) {
        setAddressData({
          address,
          location: { lat, lng },
          searchLocation: address?.formatted || "",
        });
      }
    }
  }, [agent]);

  const onPlaceChange = (place: Place) => {
    if (isEmpty(place.location) || isEmpty(place.address)) {
      return;
    }

    setAddressData({
      address: place.address,
      location: place.location,
      searchLocation: place.address.formatted || "",
    });
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentEmail"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
          position="top"
        />

        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>Where do you live?</h4>
                  </div>

                  <div>
                    <IonLabel className="form-label">Home Address</IonLabel>
                    <LocationEdit
                      searchLocation={addressData.searchLocation || ""}
                      onPlaceChange={onPlaceChange}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={
                updatingData ||
                !addressData.address ||
                !addressData.address.formatted
              }
              onClick={onNext}
            >
              Continue
              {updatingData && (
                <IonSpinner slot="end" class="ion-margin-start" name="lines" />
              )}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentAddress };
