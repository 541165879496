import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";

const updatePushToken = async (token: string): Promise<void> => {
  try {
    await request
      .post(`${environment.baseUrl}/agentProfile/pushToken`)
      .set(await getAuthHeader())
      .send({ token });
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const recordImpression = async (
  messageId: string,
  channel: string
): Promise<void> => {
  try {
    await request
      .post(`${environment.baseUrl}/notificationInteraction/record`)
      .set(await getAuthHeader())
      .send({ messageId, channel });
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { updatePushToken, recordImpression };
