import React, { useEffect, useState } from "react";
import SignaturePad from "signature_pad";
import { IonContent, IonButton, IonSpinner } from "@ionic/react";
import { SelectedFile } from "@app/shiftSignature/timecard/model";

const SignatureConfirmation = ({
  onSubmit,
  isUploadingImage,
  isShiftTimeEdit,
}: {
  onSubmit: (file: SelectedFile) => void;
  isUploadingImage: boolean;
  isShiftTimeEdit: boolean;
}) => {
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const [signaturePad, setSignaturePad] = useState<SignaturePad>();
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const onClear = () => {
    signaturePad?.clear();
    setDisableSubmit(true);
  };

  const setSignaturePadEvents = () => {
    signaturePad?.addEventListener("endStroke", () => {
      setDisableSubmit(signaturePad?.isEmpty() || false);
    });
  };

  const submitFile = () => {
    const file = {
      file: signaturePad?.toDataURL(),
      type: "png",
    };
    onSubmit(file);
  };

  useEffect(() => {
    if (
      !signaturePad &&
      canvasRef !== undefined &&
      canvasRef.current !== null
    ) {
      setSignaturePad(new SignaturePad(canvasRef.current));
      canvasRef.current.width = window.screen.width * 0.73;
      canvasRef.current.height = window.screen.height * 0.6;
    }
  });

  useEffect(() => {
    setSignaturePadEvents();
  }, [signaturePad]);

  return (
    <>
      <IonContent>
        <div>
          <h4 className="timesheet-summary-text signature-confirmation-prompt">
            Please ask the nurse director or coordinator to sign here and verify
            your times.
          </h4>
        </div>
        <div className="signature-pad">
          <div>
            <canvas
              className="signature-pad-canvas"
              id="canvas"
              width="90"
              ref={canvasRef}
            ></canvas>
          </div>
        </div>
        <div className="sign-button-container">
          <IonButton
            fill="outline"
            shape="round"
            size="large"
            expand="block"
            className="ion-margin sign-button"
            onClick={onClear}
          >
            Clear
          </IonButton>
          <IonButton
            size="large"
            expand="block"
            shape="round"
            color="primary"
            className="ion-margin sign-button"
            onClick={submitFile}
            disabled={disableSubmit || isUploadingImage || isShiftTimeEdit}
          >
            <IonSpinner
              hidden={!isUploadingImage}
              slot="start"
              name="crescent"
            />
            {!isUploadingImage ? "Submit" : "Submitting"}
          </IonButton>
        </div>
      </IonContent>
    </>
  );
};

export { SignatureConfirmation };
