/* eslint-disable @typescript-eslint/no-unused-vars */
import "antd/es/calendar/style/css";
import "./style.scss";
import { Moment } from "moment-timezone";
import Calendar from "antd/es/calendar";
import { CalendarProps } from "antd/es/calendar/generateCalendar";
import React, { useState } from "react";
import { BackAlertPopup, ForwardAlertPopup } from "./alert";
import { ArrowButton, headerRenderer } from "./headerRender";
import { get, identity } from "lodash";
import { useSelector } from "react-redux";
import { Store } from "@store/session";
import moment from "moment";

const ShiftCalendar: React.FC<Partial<CalendarProps<Moment>>> = (props) => {
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const [backArrowFlag, setbackArrowFlag] = useState<boolean>(false);
  const [forwardArrowFlag, setForwardArrowFlag] = useState<boolean>(false);
  const [month, setMonth] = useState<string>("");
  const [allowedRangeStart, setAllowedRangeStart] = useState<Moment>(
    moment().startOf("month")
  );
  const [allowedRangeEnd, setAllowedRangeEnd] = useState<Moment>(
    moment().endOf("month")
  );

  const closeBackwardModal = () => {
    setbackArrowFlag(false);
  };
  const closeForwardArrowFlag = () => {
    setForwardArrowFlag(false);
  };

  return (
    <React.Fragment>
      <Calendar
        className="calendar"
        fullscreen={false}
        headerRender={headerRenderer(
          setbackArrowFlag,
          setForwardArrowFlag,
          setMonth,
          agent,
          setAllowedRangeStart,
          setAllowedRangeEnd
        )}
        // validRange={[allowedRangeStart, allowedRangeEnd]} // Uncomment this to disable dates not of current month
        {...props}
      />

      <BackAlertPopup
        toggleOpen={backArrowFlag}
        closeModal={closeBackwardModal}
      />

      <ForwardAlertPopup
        toggleOpen={forwardArrowFlag}
        closeModal={closeForwardArrowFlag}
        month={month}
      />
    </React.Fragment>
  );
};

export { ShiftCalendar, ArrowButton };
