import { LocalStorage } from "@store/session/session.model";
import { firebaseAuth } from "../store/session/init";

const getAuthHeader = async () => {
  // Fetches local token if not expired or makes API call to return refreshed token
  // onIdTokenChanged() event triggers for new token that sets token in local storage
  let token =
    (await firebaseAuth.currentUser?.getIdToken()) ||
    localStorage.getItem(LocalStorage.AUTH_TOKEN);

  return { Authorization: token };
};

export { getAuthHeader };
