import { IonItem } from "@ionic/react";
import React, { useRef } from "react";
import SignaturePad from "react-signature-pad-wrapper";

import { SignatureContainerProps } from "./model";

const SignatureContainer: React.FC<SignatureContainerProps> = ({
  onChange,
}) => {
  const signaturePad = useRef<HTMLCanvasElement>();

  const onSigned = () => {
    const signature = signaturePad?.current?.toDataURL();
    if (!signature) return;
    onChange(signature);
  };

  return (
    <IonItem>
      <SignaturePad ref={signaturePad} options={{ onEnd: onSigned }} />
    </IonItem>
  );
};

export { SignatureContainer };
