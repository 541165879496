import "./style.scss";
import React, {
  FormEvent,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  IonButton,
  IonContent,
  IonSpinner,
  IonImg,
  IonRow,
  IonCol,
  IonAlert,
  IonToast,
  useIonViewDidEnter,
} from "@ionic/react";
import { BrowserLinkButton } from "src/lib/ionic-components";
import { logEvent } from "src/lib/analytics";
import { isPlatform } from "@ionic/core";
import { Plugins } from "@capacitor/core";
import { get } from "lodash";
import { LandingPageProps } from "./landing.model";
import { USER_EVENTS } from "../../constants/userEvents";
import { LoginButton, StyledIonPage } from "./style";
import { ActionType, getAgentByPhone, GetState } from "../store/session";
import PhoneInput from "../components/phoneInput";
import { LoginErrorType } from "../../constants/loginErrorConstants";
const { Keyboard } = Plugins;

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.

const Landing: React.FC<LandingPageProps> = (props) => {
  const {
    verifyPhone,
    isLogging,
    history,
    firebaseAuth,
    loginError,
    loginErrorType,
  } = props;
  const [phone, setPhone] = useState("");
  const [agentFirstName, setAgentFirstName] = useState("");
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const captchaRef: React.MutableRefObject<HTMLDivElement | null | undefined> =
    useRef();
  const handleClickLearnMore = () => {
    logEvent(USER_EVENTS.TAPPED_LEARN_MORE);
  };
  const handleClickLogin = (event: FormEvent | MouseEvent) => {
    event.preventDefault();
    logEvent(USER_EVENTS.TAPPED_SIGN_IN);
    history.push("/welcome/login");
  };

  const handleClickSignUp = useCallback(
    async (event: FormEvent | MouseEvent) => {
      event.preventDefault();
      const unformattedPhone = phone?.replace(/\D/g, "");
      const agent = await getAgentByPhone(unformattedPhone);
      const isSignUpComplete = get(agent, "onboardingFlags.isSignupCompleted");
      if (agent && isSignUpComplete) {
        if (agent?.firstName) {
          setAgentFirstName(agent?.firstName);
        }
        setShowLoginAlert(true);
        return;
      }
      logEvent(USER_EVENTS.ENTERED_SIGN_UP_CREDENTIAL, { phone });
      logEvent(USER_EVENTS.TAPPED_SIGN_UP);
      loginWithPhone(unformattedPhone);
    },
    [phone]
  );

  useIonViewDidEnter(() => {
    if (firebaseAuth) {
      firebaseAuth.initRecaptchaVerifier(
        "recaptcha-container",
        captchaRef.current
      );
    }
  }, [firebaseAuth]);

  useEffect(() => {
    if (loginError && firebaseAuth) {
      firebaseAuth.initRecaptchaVerifier(
        "recaptcha-container",
        captchaRef.current
      );
    }
  }, [loginError, firebaseAuth]);

  const loginWithPhone = async (phoneNumber) => {
    const verified = await verifyPhone(phoneNumber, true);
    // @ts-ignore
    if (verified) {
      const path = "/welcome/signup/verify";
      history.push(path, { phone: phoneNumber });
    }
  };

  const onEnterNumber = useCallback(
    async (event: FormEvent | MouseEvent) => {
      event.preventDefault();
      const unformattedPhone = phone?.replace(/\D/g, "");
      if (isPlatform("capacitor")) Keyboard.hide();
      const agent = await getAgentByPhone(unformattedPhone);
      const isSignUpComplete = get(agent, "onboardingFlags.isSignupCompleted");
      if (agent && isSignUpComplete) {
        if (agent?.firstName) {
          setAgentFirstName(agent?.firstName);
        }
        setShowLoginAlert(true);
        return;
      }
      logEvent(USER_EVENTS.ENTERED_SIGN_UP_CREDENTIAL, { email: "", phone });
      loginWithPhone(unformattedPhone);
    },
    [phone]
  );

  /**
   * redirect to login screen
   */
  const onLogIn = () => {
    setShowLoginAlert(false);
    history.push(`/welcome/login?phone=${phone}`);
  };

  return (
    <StyledIonPage>
      <IonContent className="page-content">
        <div className="content-layout">
          <div className="top-container">
            <div className="landing-content">
              <h1>Great rates.</h1>
              <h1>Instant payouts.</h1>
              <h1>Lots of shifts.</h1>
            </div>
            <div className="cbh-logo-wrapper">
              <IonImg src="/assets/logo/cbh-logo.png" className="logo" />
            </div>
            <div className="form-container">
              <form onSubmit={onEnterNumber}>
                <IonRow>
                  <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                    <PhoneInput
                      onChange={setPhone}
                      onEnterNumber
                      label="Sign up with your phone number:"
                      value={phone}
                    />
                    <IonButton
                      expand="block"
                      size="large"
                      className="ion-margin-top ion-margin-bottom signup-button"
                      onClick={handleClickSignUp}
                      disabled={isLogging || phone.length < 14}
                      id="login-button continue-button"
                    >
                      {isLogging && (
                        <IonSpinner
                          slot="end"
                          className="ion-margin-start"
                          name="lines"
                        />
                      )}
                      Sign Up
                    </IonButton>
                  </IonCol>
                </IonRow>
                <div ref={captchaRef as React.Ref<HTMLDivElement>}></div>
              </form>
              <div className="cbh-login-link cbh-link-wrapper">
                <span>Already have an account?</span>
                <LoginButton
                  onClick={handleClickLogin}
                  className="cbh-link"
                  fill="clear"
                  color="#1C69D1"
                >
                  Log In
                </LoginButton>
              </div>
            </div>
          </div>
          <div className="footer-container">
            <div
              className="learn-more-link cbh-link-wrapper"
              onClick={handleClickLearnMore}
            >
              <BrowserLinkButton
                url="https://clipboardhealth.com"
                color="dark"
                className="cbh-link"
              >
                Learn more
              </BrowserLinkButton>
            </div>
          </div>
        </div>
        <IonToast
          isOpen={
            !!props.loginError &&
            loginErrorType === LoginErrorType.INVALID_PHONE_NUMBRER
          }
          onDidDismiss={props.clearLoginError}
          message={props.loginError as string | undefined}
          cssClass="error-text-black"
          color="medium"
          position="top"
          duration={3000}
        />
        <IonAlert
          isOpen={showLoginAlert}
          cssClass="ion-login-alert-popup"
          onDidDismiss={() => setShowLoginAlert(false)}
          header="Phone number already exists"
          message={`Hey ${
            agentFirstName || "there"
          }, you already have an account with this phone number. Log in to continue.`}
          backdropDismiss={true}
          buttons={[
            {
              text: "Log In",
              handler: onLogIn,
            },
          ]}
        />
      </IonContent>
    </StyledIonPage>
  );
};

export { Landing };
