import "../style.scss";
import { Plugins } from "@capacitor/core";
import { chevronForwardOutline } from "ionicons/icons";
import {
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSpinner,
  IonToast,
  IonBackButton,
  IonInput,
  IonAlert,
  IonIcon,
  IonDatetime,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import React, { useState, useRef, FormEvent, useEffect } from "react";
import { openBrowser } from "src/lib/ionic-components";
import { isPlatform } from "@ionic/core";
import { Select, AutoComplete } from "antd";
import { useSelector } from "react-redux";
import { states } from "src/lib/utils";
import moment from "moment";
import { Store } from "../../store/store.model";
import { OnBoardingComponentProps, LicenseType, LicenseDetails } from "./model";
import {
  fireVerifyLicenseZapierHook,
  saveSalesforceContact,
  updateAgentData,
} from "./api";
import { StyledIonPage } from "./style";
import { licenseTypes, otherLicenseTypes } from "../licenseTypes";
import { customCheckmark } from "../../../icons/customCheckmark";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { workerTypeObj } from "src/lib/constants/src/lib/workers";
import Alert from "./Alert";
import { logOnboardingError } from "../util/logging";

const { Keyboard } = Plugins;
const { Option } = Select;

const LicenseDetailsForm = ({
  license,
  licenseType,
  onInputChange,
  onStateChange,
  qualification,
  onOtherQualificationChange,
  otherQualification,
  onSubmitLicenseForm,
}) => {
  const { number, expires, state } = license;
  const tomorrow = moment().add(1, "days");
  const showLicenseField = (licenseType, state) => {
    if (
      licenseType === workerTypeObj.SITE_LEAD ||
      licenseType === workerTypeObj.NON_CLINICAL ||
      (licenseType === workerTypeObj.CNA && state === "Illinois")
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className="license-form">
      {qualification === "other" && (
        <div className="form-control">
          <IonItem
            lines="none"
            className="form-item-wrapper qualification-item other-item"
          >
            <IonLabel position="stacked">Add license details</IonLabel>
            <AutoComplete
              className="form-input"
              style={{ width: "100%", border: "none", marginTop: "2px" }}
              options={otherLicenseTypes}
              placeholder="Please provide license details"
              defaultValue={otherQualification || ""}
              filterOption={(inputValue, option) =>
                option!.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) => onOtherQualificationChange(value)}
            />
          </IonItem>
        </div>
      )}
      <div className="form-control">
        <IonLabel className="form-label">State</IonLabel>
        <Select
          showSearch
          value={state}
          onChange={onStateChange}
          className="form-input"
          style={{ width: "100%", border: "none", marginTop: "2px" }}
        >
          {states.map((state) => (
            <Option key={state.key} value={state.name}>
              {state.name}
            </Option>
          ))}
        </Select>
      </div>
      {showLicenseField(licenseType, state) && (
        <div className="form-control">
          <IonLabel className="form-label">License Number</IonLabel>
          <IonInput
            className="form-input"
            name="number"
            type="text"
            placeholder="123456"
            onIonChange={onInputChange}
            value={number}
            required
          />
        </div>
      )}
      {licenseType !== workerTypeObj.NON_CLINICAL &&
        licenseType !== workerTypeObj.SITE_LEAD && (
          <div className="form-control">
            <IonLabel className="form-label">Date of Expiration</IonLabel>
            <IonItem className="form-item-wrapper">
              <IonDatetime
                className="date-time-picker"
                displayFormat="MMM DD, YYYY"
                min={moment(tomorrow).format("YYYY-MM-DD")}
                max={moment().add(50, "years").format("YYYY-MM-DD")}
                name="expires"
                placeholder="mm/dd/yyyy"
                value={expires}
                onIonChange={onInputChange}
              />
            </IonItem>
          </div>
        )}
      <div className="form-control">
        <IonButton
          expand="block"
          class="ion-margin-top ion-margin-bottom continue-button slim"
          disabled={
            (showLicenseField(licenseType, state) ? !number : false) ||
            (licenseType !== workerTypeObj.NON_CLINICAL &&
            licenseType !== workerTypeObj.SITE_LEAD
              ? !expires
              : false) ||
            !state ||
            (qualification === "other" &&
              (otherQualification === "" || otherQualification === undefined))
          }
          onClick={onSubmitLicenseForm}
        >
          Next
        </IonButton>
      </div>
    </div>
  );
};

const AgentQualification: React.FC<OnBoardingComponentProps> = ({
  agent,
  nextStagePath,
}) => {
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [isReadyToContinue, setIsReadyToContinue] = useState<boolean>(false);
  const [otherQualification, setOtherQualification] = useState<string>();
  const [selectedLicenseType, setSelectedLicenseType] = useState<LicenseType>(
    {}
  );
  const [isAccordOpen, setAccordOpen] = useState<number | null>(null);
  const [licenseDetails, setLicenseDetails] = useState<{
    [key: string]: LicenseDetails;
  }>({});
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [isPrompt, showPrompt] = useState<number | null>(null);
  const { profile } = useSelector((state: Store) => state.session);

  const history = useHistory();
  const continueBtnRef = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    if (!agent) return;
    if (agent.qualification) {
      licenseTypes.forEach((item: LicenseType, index: number) => {
        if (item.value === agent.qualification) {
          setSelectedLicenseType(item);
          if (!licenseDetails[index]) {
            setLicenseDetails((prevLicenseDetails) => ({
              ...prevLicenseDetails,
              [index]: {
                number: "",
                expires: "",
                state: agent?.address?.state || "",
              },
            }));
          }
          setAccordOpen(index);
        }
      });
    } else if (agent.otherQualification) {
      const otherQualification = otherLicenseTypes.find(
        (lt) => lt.type === agent.otherQualification
      );
      setOtherQualification(
        otherQualification ? otherQualification.value : agent.otherQualification
      );
      setSelectedLicenseType({
        type: "other",
        name: agent.otherQualification,
        value: "other",
      });
    }
    if (agent.license) {
      const index = licenseTypes.findIndex(
        (licenseType) => agent.qualification === licenseType.value
      );
      setLicenseDetails((prevLicenseDetails) => ({
        ...prevLicenseDetails,
        [index]: agent.license,
      }));
      setIsReadyToContinue(true);
    }
  }, [agent]);

  const onQualificationChange = (
    event: React.MouseEvent<HTMLIonItemElement, MouseEvent>,
    index: number
  ) => {
    if (!index && index !== 0) return;
    if (isAccordOpen === index) {
      setAccordOpen(null);
      return;
    }
    if (!licenseDetails[index]) {
      setLicenseDetails((prevLicenseDetails) => ({
        ...prevLicenseDetails,
        [index]: {
          number: "",
          expires: "",
          state: agent?.address?.state || "",
        },
      }));
    }
    if (!isReadyToContinue) {
      setAccordOpen(index);
      setSelectedLicenseType(licenseTypes[index]);
    } else {
      const selectedIndex = licenseTypes.findIndex(
        (licenseType) => selectedLicenseType.value === licenseType.value
      );
      if (selectedIndex === index) {
        setAccordOpen(index);
        setSelectedLicenseType(licenseTypes[index]);
      } else {
        showPrompt(index);
      }
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const onOtherQualificationChange = (value: string) => {
    setOtherQualification(value);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const onLicenseInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setLicenseDetails((prevLicenseDetails) => ({
      ...prevLicenseDetails,
      [index]: {
        ...(licenseDetails[index] || {
          number: "",
          expires: "",
          state: agent?.address?.state || "",
        }),
        [event.target.name]:
          event.target.name === "expires"
            ? event.target.value.substring(0, 10)
            : event.target.value,
      },
    }));
  };

  const onStateChange = (value: string, index: number) => {
    setLicenseDetails((prevLicenseDetails) => ({
      ...prevLicenseDetails,
      [index]: {
        ...(licenseDetails[index] || {
          number: "",
          expires: "",
          state: agent?.address?.state || "",
        }),
        state: value,
      },
    }));
  };

  const onSubmitLicenseForm = () => {
    setIsReadyToContinue(true);
    showPrompt(null);
    setAccordOpen(null);
    setTimeout(() => {
      continueBtnRef?.current?.scrollToBottom(400);
    }, 600);
    const index = licenseTypes.findIndex(
      (licenseType) => selectedLicenseType.value === licenseType.value
    );
    fireOnboardingSegmentEvent(
      ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LICENSE_INFORMATION,
      {
        hcpId: agent?.userId as string,
        licenseType: selectedLicenseType.type,
        licenseState: licenseDetails[index].state,
        licenseExpiration: licenseDetails[index].expires,
        licenseNumber: licenseDetails[index].number,
      }
    );
  };

  const closePrompt = () => showPrompt(null);

  const changeLicense = () => {
    if (isPrompt !== null) {
      setAccordOpen(isPrompt);
      setIsReadyToContinue(false);
      setSelectedLicenseType(licenseTypes[isPrompt]);
    }
  };

  const onContinue = async (event?: FormEvent | MouseEvent) => {
    event?.preventDefault();
    if (isPlatform("capacitor")) Keyboard.hide();
    setShowErrorModal(false);
    const index = licenseTypes.findIndex(
      (licenseType) => selectedLicenseType.value === licenseType.value
    );
    try {
      setUpdatingData(true);
      const otherLicenseType = otherLicenseTypes.find(
        (lt) => lt.value === otherQualification
      );

      await updateAgentData({
        stage: ONBOARDING_STAGES.LICENSE,
        license: licenseDetails[index],
        qualification: selectedLicenseType.value,
        otherQualification: otherLicenseType
          ? otherLicenseType.type
          : otherQualification,
      });
      const updatedAgent = {
        ...agent,
        license: licenseDetails[index],
        qualification: selectedLicenseType.value,
      };
      saveSalesforceContact(agent?.userId as string);
      fireVerifyLicenseZapierHook(updatedAgent);
      history.push(`${nextStagePath}`);
    } catch (error) {
      if (
        !error.response ||
        !error.response.text ||
        error.response.status > 500
      ) {
        logOnboardingError(
          ONBOARDING_STAGES.QUALIFICATION,
          (error as Error).message,
          agent?.userId
        );
        setShowErrorModal(true);
        return;
      }
      setPageError({
        isError: true,
        message: error.response.text,
      });
    } finally {
      setUpdatingData(false);
    }
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentSignupInfo2"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent
        className="ion-padding"
        scrollEvents={true}
        ref={continueBtnRef}
      >
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
          position="top"
        />

        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <IonAlert
          mode="ios"
          isOpen={isPrompt !== null}
          backdropDismiss={false}
          header="Change Your License?"
          message="You can only select one license type. Confirm below if you want to change."
          buttons={[
            {
              text: "Change It",
              handler: changeLicense,
            },
            {
              text: "Never Mind",
              handler: closePrompt,
            },
          ]}
        />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onContinue}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>Which license do you have?</h4>
                    <p>
                      Select the role that you’re licensed for and would like
                      find shifts
                    </p>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  {licenseTypes.map((license: LicenseType, i: number) => (
                    <div
                      key={i}
                      className={`form-item-wrapper shadowedCard qualification-card accordion${
                        isAccordOpen === i ? ` active` : ""
                      }${
                        selectedLicenseType.value === license.value
                          ? ` selected`
                          : ""
                      }`}
                    >
                      <IonItem
                        lines="none"
                        className="qualification-item"
                        onClick={(event) => onQualificationChange(event, i)}
                      >
                        <IonLabel className="aqf-label">
                          {license.type !== "other" && (
                            <p className="selectedLicenseType">
                              {license.type}
                            </p>
                          )}
                          <p className="licenseName">{license.name}</p>
                        </IonLabel>
                        {isReadyToContinue &&
                          selectedLicenseType.value === license.value && (
                            <IonIcon
                              icon={customCheckmark}
                              slot="end"
                              mode="ios"
                              color="medium"
                            />
                          )}
                        <IonIcon
                          icon={chevronForwardOutline}
                          slot="end"
                          mode="ios"
                          color="medium"
                          className={isAccordOpen === i ? `active` : ""}
                        />
                      </IonItem>

                      <div className="license-form-wrap">
                        {isAccordOpen === i && (
                          <LicenseDetailsForm
                            license={licenseDetails[i]}
                            licenseType={license.type}
                            onInputChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => onLicenseInputChange(event, i)}
                            qualification={selectedLicenseType.value}
                            onStateChange={(value: string) =>
                              onStateChange(value, i)
                            }
                            onOtherQualificationChange={
                              onOtherQualificationChange
                            }
                            otherQualification={otherQualification}
                            onSubmitLicenseForm={onSubmitLicenseForm}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={updatingData || !isReadyToContinue}
              onClick={onContinue}
            >
              Continue
              {updatingData && (
                <IonSpinner slot="end" class="ion-margin-start" name="lines" />
              )}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentQualification };
