import { useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonLabel,
  IonSpinner,
  IonText,
  IonToast,
  isPlatform,
} from "@ionic/react";
import { ShiftActionTime } from "./shiftActionTime";
import "./style.scss";
import moment from "moment-timezone";
import {
  isValidClockInTime,
  isValidClockOutTime,
  isValidLunchInTime,
  isValidShiftTime,
} from "../../../utils/shiftEventTimeValidation";
import {
  getClockOutEditHelperText,
  getClockInEditHelperText,
  getLunchOutEditHelperText,
  getLunchInEditHelperText,
} from "../../../utils/shiftTimeEditHelperText";
import { ClockInMethods, GeoLocation, TimeSystems } from "src/lib/interface";
import {
  getOrWatchCurrentLocation,
  ConnectionMode,
} from "@app/common/location";
import { useAlertsForShiftDetails } from "../shiftDetails/alerts";
import { geoDistance } from "src/lib/utils";
import { checkLocationAwareness } from "@app/utils/locationHelper";
import { useAppSelector } from "@store/index";
import { ShiftDetailsAlerts } from "../shiftDetails/alerts";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import { Plugins } from "@capacitor/core";

const ShiftTimeSummary = ({
  shift,
  submitClockInOut,
  submitLunchInOut,
  onShiftTimeEdit,
  onShiftTimeSave,
}) => {
  let shiftTime = 0;
  let lunchTime = 0;

  if (!!submitClockInOut.start && !!submitClockInOut.end) {
    lunchTime =
      submitLunchInOut.start &&
      submitLunchInOut.end &&
      moment(submitLunchInOut.end)
        .set({ second: 0, millisecond: 0 })
        .diff(
          moment(submitLunchInOut.start).set({ second: 0, millisecond: 0 }),
          "hours",
          true
        );

    shiftTime =
      submitClockInOut.start &&
      submitClockInOut.end &&
      moment(submitClockInOut.end)
        .set({ second: 0, millisecond: 0 })
        .diff(
          moment(submitClockInOut.start).set({ second: 0, millisecond: 0 }),
          "hours",
          true
        );
  }

  const { Network } = Plugins;
  const FACILITY_SAFE_DISTANCE_MILES = 0.75;

  const facilityTimezone = shift?.facility?.tmz ? shift.facility.tmz : "";

  const [isEditable, setIsEditable] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const timeElapsed = (shiftTime as number) - (lunchTime || 0);

  const hours = Math.trunc(timeElapsed);
  const minutes = Math.round((timeElapsed - hours) * 60);

  const [submitClockIn, setSubmitClockIn] = useState(submitClockInOut.start);
  const [submitClockOut, setSubmitClockOut] = useState(submitClockInOut.end);
  const [submitLunchOut, setSubmitLunchOut] = useState(submitLunchInOut.start);
  const [submitLunchIn, setSubmitLunchIn] = useState(submitLunchInOut.end);

  const [clockInEditText, setClockInEditText] = useState("");
  const [lunchInEditText, setLunchInEditText] = useState("");
  const [lunchOutEditText, setLunchOutEditText] = useState("");
  const [clockOutEditText, setClockOutEditText] = useState("");

  const [timeErrorMessage, setTimeErrorMessage] = useState("");

  const { env } = useAppSelector((state) => state.session);
  const shiftDetailsAlerts: ShiftDetailsAlerts = useAlertsForShiftDetails();
  const { alert } = shiftDetailsAlerts;
  const editShiftTimeAllowed =
    shift?.facility?.timeSystem == TimeSystems.PAPER &&
    shift?.clockInMethod == ClockInMethods.PRIMARY
      ? false
      : true;
  const computeDateChange = (time: string) => {
    if (moment(time).isAfter(moment(shift.end).add(2, "h")))
      time = moment(time).subtract(1, "d").format();
    if (moment(time).isBefore(moment(shift.start).subtract(3, "h")))
      time = moment(time).add(1, "d").format();
    return time;
  };

  const openLocationSettings = async () => {
    if (isPlatform("capacitor")) {
      await OpenNativeSettings.open("location");
    } else {
      setTimeout(() => {
        shiftDetailsAlerts.alertBrowserLocationAccess();
      }, 500);
    }
  };

  const cancelEdit = async () => {
    onCancel();
  };

  const locationCheck = async () => {
    //network check
    const status = await Network.getStatus();
    const connectivityMode = status.connected
      ? ConnectionMode.ONLINE
      : ConnectionMode.OFFLINE;
    if (connectivityMode === ConnectionMode.OFFLINE) {
      shiftDetailsAlerts.alertNoNetworkConnection({ isEdit: true });
      return false;
    }
    //location access check
    const { location, error: positionError } =
      await getOrWatchCurrentLocation();
    if (positionError) {
      shiftDetailsAlerts.alertLocationAccess({
        openLocationSettingsFn: openLocationSettings,
        skipLocationBtnHandler: cancelEdit,
        isEdit: true,
      });
      return false;
    }
    //safe distance check
    const { coordinates } = shift.facility?.geoLocation as GeoLocation;
    const distance = geoDistance(coordinates, location as number[]);
    const isLocationAware = checkLocationAwareness(
      shift?.facility?.locationAwareness as string
    );
    const safeDistanceFromFacility =
      env?.facilitySafeDistanceMiles || FACILITY_SAFE_DISTANCE_MILES;

    if (isLocationAware && distance > safeDistanceFromFacility) {
      shiftDetailsAlerts.alertReturnToTheFacility({
        facilityName: shift.facility?.name,
        isEdit: true,
        tryAgainBtnHandler: async () => {
          await onSave();
        },
        skipLocationBtnHandler: cancelEdit,
      });
      return false;
    }
    return true;
  };

  const onClockInChange = (time: string) => {
    time = computeDateChange(time);
    let errorMessage = isValidClockInTime(time, shift.start, shift.end);
    if (errorMessage) setTimeErrorMessage(errorMessage);
    else setSubmitClockIn(time);
  };

  const onLunchOutChange = (time: string) => {
    time = computeDateChange(time);
    setSubmitLunchOut(time);
    return true;
  };

  const onLunchInChange = (time: string) => {
    time = computeDateChange(time);
    let errorMessage = isValidLunchInTime(time, submitLunchOut);
    if (errorMessage) setTimeErrorMessage(errorMessage);
    else setSubmitLunchIn(time);
  };

  const onClockOutChange = (time: string) => {
    time = computeDateChange(time);
    let errorMessage = isValidClockOutTime(
      time,
      shift.start,
      shift?.clockInOut?.end,
      shift.end
    );
    if (errorMessage) setTimeErrorMessage(errorMessage);
    else setSubmitClockOut(time);
  };

  const onSave = async () => {
    setShowSpinner(true);
    const updatedSubmitClockIn = computeDateChange(submitClockIn);
    const updatedSubmitClockOut = computeDateChange(submitClockOut);
    const updatedSubmitLunchOut = computeDateChange(submitLunchOut);
    const updatedSubmitLunchIn = computeDateChange(submitLunchIn);
    setSubmitClockIn(updatedSubmitClockIn);
    setSubmitClockOut(updatedSubmitClockOut);
    setSubmitLunchOut(updatedSubmitLunchOut);
    setSubmitLunchIn(updatedSubmitLunchIn);
    let errorMessage = isValidShiftTime(
      updatedSubmitClockIn,
      updatedSubmitClockOut,
      updatedSubmitLunchOut,
      updatedSubmitLunchIn
    );
    if (errorMessage) {
      setTimeErrorMessage(errorMessage);
      setShowSpinner(false);
      return;
    }
    let isLocationCheckPassed = await locationCheck();
    if (isLocationCheckPassed) {
      setClockInEditText(
        getClockInEditHelperText(updatedSubmitClockIn, shift?.clockInOut?.start)
      );
      setClockOutEditText(
        getClockOutEditHelperText(updatedSubmitClockOut, shift?.clockInOut?.end)
      );
      setLunchOutEditText(
        getLunchOutEditHelperText(
          updatedSubmitLunchOut,
          shift?.lunchInOut?.start
        )
      );
      setLunchInEditText(
        getLunchInEditHelperText(updatedSubmitLunchIn, shift?.lunchInOut?.end)
      );
      onShiftTimeSave(
        updatedSubmitClockIn,
        updatedSubmitClockOut,
        updatedSubmitLunchOut,
        updatedSubmitLunchIn
      );
      setIsEditable(false);
    }
    setShowSpinner(false);
  };

  const onCancel = () => {
    setSubmitClockIn(submitClockInOut.start);
    setSubmitClockOut(submitClockInOut.end);
    setSubmitLunchOut(submitLunchInOut.start);
    setSubmitLunchIn(submitLunchInOut.end);
    setIsEditable(false);
    onShiftTimeEdit(false);
  };

  const onShiftTimeEditClick = () => {
    setIsEditable(true);
    onShiftTimeEdit(true);
  };

  return (
    <>
      <IonAlert
        header={alert?.header}
        message={alert?.message}
        isOpen={!!alert}
        // onDidDismiss will override alert to null if we try to change the alert inside a modal button handler
        onWillDismiss={shiftDetailsAlerts.dismissAlert}
        buttons={alert?.buttons}
        mode="ios"
      />
      <IonToast
        isOpen={!!timeErrorMessage}
        message={timeErrorMessage}
        onDidDismiss={() => {
          setTimeErrorMessage("");
        }}
        color="danger"
        position="top"
        duration={5000}
      />
      {!isEditable && editShiftTimeAllowed && (
        <IonButton
          className="edit-button"
          color="transparent"
          onClick={onShiftTimeEditClick}
        >
          Edit
        </IonButton>
      )}
      <ShiftActionTime
        shiftActionTime={submitClockIn}
        title="CLOCK IN"
        isEditable={isEditable}
        helperText={clockInEditText}
        facilityTimeZone={facilityTimezone}
        onChange={onClockInChange}
      ></ShiftActionTime>
      <ShiftActionTime
        shiftActionTime={submitLunchOut}
        title="START BREAK"
        isEditable={isEditable}
        helperText={lunchOutEditText}
        facilityTimeZone={facilityTimezone}
        onChange={onLunchOutChange}
      ></ShiftActionTime>
      <ShiftActionTime
        shiftActionTime={submitLunchIn}
        title="END BREAK"
        isEditable={isEditable}
        helperText={lunchInEditText}
        facilityTimeZone={facilityTimezone}
        onChange={onLunchInChange}
      ></ShiftActionTime>
      <ShiftActionTime
        shiftActionTime={submitClockOut}
        title="CLOCK OUT"
        isEditable={isEditable}
        helperText={clockOutEditText}
        facilityTimeZone={facilityTimezone}
        onChange={onClockOutChange}
      ></ShiftActionTime>
      {!isEditable && (
        <>
          <div className="divider-line"></div>
          <IonLabel className="total-work-time-label">
            TOTAL SHIFT TIME
          </IonLabel>
          <br></br>
          <IonText className="total-work-time">
            {hours} hr, {minutes} min
          </IonText>
        </>
      )}
      {isEditable && (
        <>
          <IonButton
            className="cancel-button"
            disabled={showSpinner}
            onClick={() => onCancel()}
          >
            Cancel
          </IonButton>
          <IonButton
            disabled={showSpinner}
            className="save-button"
            onClick={() => {
              onSave();
            }}
          >
            {showSpinner && <IonSpinner className="spinner-style" />}Save
          </IonButton>
        </>
      )}
    </>
  );
};
export { ShiftTimeSummary };
