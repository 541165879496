export type BuildAwareEnv = "local" | "dev" | "staging" | "production";

export const getBuildNameAwareEnv = (): BuildAwareEnv => {
  switch (process.env.NODE_ENV) {
    case "production":
      return "production";
    case "staging":
      if (process.env.BUILD_NAME === "staging") {
        return "staging";
      }
      return "dev";
    default:
      return "local";
  }
};
