import { IonIcon, IonNote } from "@ionic/react";
import { homeOutline, navigate } from "ionicons/icons";
import React from "react";

import { DistanceNoteProps } from "./model";

const formatDistance = (distance: number): string => {
  if (!distance) {
    return "0";
  }

  return distance.toFixed(1);
};

const DistanceNote: React.FC<DistanceNoteProps> = ({
  distance,
  isLastMinuteShift,
  homeIcon = false,
  isApproxDistance = false,
}) => (
  <IonNote
    style={
      isLastMinuteShift
        ? { fontSize: "14px", display: "inline-block", color: "#707070" }
        : { fontSize: "10px", display: "inline-block" }
    }
  >
    <IonIcon icon={homeIcon ? homeOutline : navigate} mode="md" /> &nbsp;
    {`${isApproxDistance ? "~" : ""}${formatDistance(distance)} mile(s) away`}
  </IonNote>
);

export { DistanceNote };
