import React from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from "@ionic/react";
import "./unverifiedShiftsList.scss";

const UnverifiedShiftsCount = ({ unverifiedShiftsData }) => {
  const history = useHistory();
  const unverifiedShiftsCount = unverifiedShiftsData
    ? unverifiedShiftsData.length
    : 0;
  const missingTimesheetsShifts =
    unverifiedShiftsData &&
    unverifiedShiftsData.filter((shift) => {
      if (shift?.timecardNotAvailable?.reason) {
        return false;
      } else if (shift.timecard.files.length > 0) {
        return false;
      } else if (!shift.facility.verificationPreference?.usesTimesheets) {
        return false;
      } else {
        const shiftVerificationTimeout = shift?.businessrules?.value;
        if (shiftVerificationTimeout) {
          return !moment(shift.end)
            .add(shiftVerificationTimeout, "hours")
            .isBefore();
        } else {
          return true;
        }
      }
    });
  const missingTimesheetsShiftsCount = missingTimesheetsShifts.length;

  const goToUnverifiedShiftsListPage = () => {
    history.push("myShifts/unverifiedShifts");
  };

  return (
    <IonCard onClick={goToUnverifiedShiftsListPage}>
      <IonCardContent>
        <IonGrid className="no-padding-left">
          <IonRow className="ion-align-items-center">
            {missingTimesheetsShiftsCount > 0 ? (
              <IonCol className="icon ion-no-padding" size="auto">
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.56348 22.9535H15.4365C16.8965 22.9535 17.6938 22.4706 18.4688 21.6058L22.5566 17.0126C23.3315 16.1366 23.5337 15.5751 23.5337 14.3846V9.47693C23.5337 8.2865 23.3315 7.72498 22.5566 6.849L18.4688 2.25574C17.6938 1.40222 16.8965 0.908081 15.4365 0.908081H8.56348C7.10352 0.908081 6.31738 1.40222 5.53125 2.25574L1.45459 6.849C0.668457 7.72498 0.466309 8.2865 0.466309 9.47693V14.3846C0.466309 15.5751 0.668457 16.1366 1.45459 17.0126L5.53125 21.6058C6.31738 22.4706 7.10352 22.9535 8.56348 22.9535ZM12 13.4974C11.3374 13.4974 10.9668 13.1268 10.9443 12.453L10.7759 7.29822C10.7534 6.61316 11.27 6.11902 11.9888 6.11902C12.7075 6.11902 13.2466 6.62439 13.2129 7.30945L13.0444 12.453C13.0107 13.1268 12.6401 13.4974 12 13.4974ZM12 17.7089C11.2588 17.7089 10.6299 17.181 10.6299 16.4286C10.6299 15.6761 11.2476 15.1483 12 15.1483C12.7524 15.1483 13.3701 15.6649 13.3701 16.4286C13.3701 17.1923 12.7524 17.7089 12 17.7089Z"
                    fill="#EB5757"
                  />
                </svg>
              </IonCol>
            ) : null}
            {missingTimesheetsShiftsCount === 0 ? (
              <IonCol className="icon ion-no-padding" size="auto">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9888 23.515C18.3564 23.515 23.6123 18.2592 23.6123 11.9027C23.6123 5.53503 18.3452 0.279175 11.9775 0.279175C5.62109 0.279175 0.376465 5.53503 0.376465 11.9027C0.376465 18.2592 5.63232 23.515 11.9888 23.515ZM11.9888 21.1903C6.83398 21.1903 2.7124 17.0463 2.7124 11.9027C2.7124 6.74792 6.83398 2.60388 11.9775 2.60388C17.1323 2.60388 21.2764 6.74792 21.2876 11.9027C21.2988 17.0463 17.1436 21.1903 11.9888 21.1903ZM6.32861 13.3065H11.9775C12.4941 13.3065 12.8872 12.9135 12.8872 12.4081V5.06335C12.8872 4.55798 12.4941 4.16492 11.9775 4.16492C11.4834 4.16492 11.0903 4.55798 11.0903 5.06335V11.5096H6.32861C5.82324 11.5096 5.43018 11.9027 5.43018 12.4081C5.43018 12.9135 5.82324 13.3065 6.32861 13.3065Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </IonCol>
            ) : null}
            <IonCol size="10">
              <IonLabel className="unverified-shifts-count">
                {unverifiedShiftsCount}{" "}
                {unverifiedShiftsCount < 2 ? "shift " : "shifts "}waiting for
                verification
              </IonLabel>
              <br></br>
              {missingTimesheetsShiftsCount > 0 ? (
                <IonLabel className="unverified-shifts-timesheet-count">
                  {`${missingTimesheetsShiftsCount} Missing${
                    missingTimesheetsShiftsCount < 2
                      ? " Timesheet "
                      : " Timesheets "
                  }`}
                </IonLabel>
              ) : null}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export { UnverifiedShiftsCount };
