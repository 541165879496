import { chevronForwardOutline } from "ionicons/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { LogoutButton, openBrowser } from "src/lib/ionic-components";

import { customCheckmark } from "../../../icons/customCheckmark";
import { Plugins } from "@capacitor/core";
import { states } from "src/lib/utils";
import { Store } from "../../store/store.model";
import { StyledIonPage } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { AgentDocumentUploader } from "./AgentDocumentUploader";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonText,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { OnBoardingComponentProps } from "./model";
import React, { useState, useRef, FormEvent, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_REQUIREMENTS_STATUS,
  CREATE_HCP_DOCUMENT,
} from "../../documents/gql";
import { ActionType, Requirement } from "@store/documents/model";
import { SelectedFile } from "../../shiftSignature/timecard/model";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { dispatch } from "rxjs/internal/observable/range";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";
import { LocalStorage } from "@store/session";
import { updateAgentData, fireVerifyDocumentZapierHook } from "./api";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { Agent } from "src/lib/interface";
import { isImage } from "../../utils/isImage";
import { constructPDFFromMultipleImages } from "../../utils/constructPDFFromMultipleImages";
import { updateCache, uploadSelectedDocument } from "../../documents/api";
import Alert from "./Alert";
import { logOnboardingError } from "../util/logging";

const { Keyboard } = Plugins;

const AgentUploadDocuments: React.FC<OnBoardingComponentProps> = ({
  agent,
  nextStagePath,
}) => {
  const [documents, setDocuments] = useState<Requirement[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<string | null>();
  const [uploadedDocuments, setUploadedDocuments] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<{
    [key: string]: SelectedFile[];
  }>({});
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [error, setError] = useState({ header: "", message: "" });
  const [showError, setShowError] = useState(false);
  const [uploadingDocuments, setUploadingDocuments] = useState<string[]>([]);

  const { data, loading } = useQuery(GET_REQUIREMENTS_STATUS, {
    variables: { hcpId: agent?.userId },
  });
  const [showContinueAlert, setShowContinueAlert] = useState<boolean>(false);
  const [showFileUploadAlert, setShowFileUploadAlert] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const history = useHistory();
  const [createHcpDocument] = useMutation(CREATE_HCP_DOCUMENT);
  const { profile } = useSelector((state: Store) => state.session);

  useEffect(() => {
    if (data) {
      const {
        hcpRequirementStatus: {
          pending = [],
          completed = [],
          requirements = [],
        },
      } = data;
      const missingAgentDocuments = requirements.filter(
        (requirement) => requirement.visibleToHCP && requirement.level !== "HCF"
      );
      setDocuments(missingAgentDocuments);
      setUploadedDocuments([...pending, ...completed]);
    }
  }, [data]);

  const handleStageUpdate = async (): Promise<boolean> => {
    try {
      await updateAgentData({
        stage: ONBOARDING_STAGES.DOCUMENTS,
      });
      return true;
    } catch (error) {
      setError({
        header: "Oops! It looks like we ran into an issue",
        message:
          "We were unable to process your request. This is likely a network issue. Please try again.",
      });
      setShowError(true);
      return false;
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const onUploadingDocument = (requirement: Requirement) => {
    setUploadingDocuments([
      ...(uploadingDocuments as string[]),
      requirement.reqId as string,
    ]);
    const newUploadedDocuments = uploadedDocuments.filter(
      (document) => document !== requirement.reqId
    );
    setUploadedDocuments(newUploadedDocuments);
  };

  const onDocumentUploaded = (requirement: Requirement) => {
    setUploadedDocuments([
      ...(uploadedDocuments as string[]),
      requirement.reqId as string,
    ]);
    const newUploadingDocuments = uploadingDocuments.filter(
      (document) => document !== requirement.reqId
    );
    setUploadingDocuments(newUploadingDocuments);
  };

  const onErrorUploadingDocument = (requirement: Requirement) => {
    const newUploadingDocuments = uploadingDocuments.filter(
      (document) => document !== requirement.reqId
    );
    const newUploadedDocuments = uploadedDocuments.filter(
      (document) => document !== requirement.reqId
    );
    setUploadingDocuments(newUploadingDocuments);
    setUploadedDocuments(newUploadedDocuments);
    setPageError({
      isError: true,
      message: "Unable to upload document, please try again!",
    });
  };

  const onNext = async () => {
    try {
      const isStageUpdated = await handleStageUpdate();
      fireOnboardingSegmentEvent(
        ONBOARDING_SEGMENT_EVENT_NAMES.FINISHED_SUBMITTING_DOCUMENTS,
        {
          hcpId: agent?.userId as string,
          email: agent?.email as string,
        }
      );
      if (!isStageUpdated) {
        return;
      }
      if (documents.length && uploadedDocuments.length !== documents.length) {
        setShowContinueAlert(true);
      } else {
        // @ts-ignore
        history.push(nextStagePath);
      }
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, { message: error?.message });
    }
  };

  const onSelectedFile = (documentId: string) => (file: SelectedFile) => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [documentId]: [...(selectedFiles[documentId] || []), file],
    }));
  };

  const clearSelectedFiles = (documentId: string) => () => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [documentId]: [],
    }));
  };

  const toggleSelectedDocument = (documentId: string) => () => {
    const selectedDocumentObj = documents.find(
      (document) => document._id === (selectedDocument as string)
    );
    if (
      !uploadedDocuments.includes(selectedDocumentObj?.reqId as string) &&
      selectedDocument &&
      (selectedFiles[selectedDocument] || []).length > 0
    ) {
      setShowFileUploadAlert(documentId);
    } else {
      setSelectedDocument(selectedDocument === documentId ? null : documentId);
    }
  };

  const handleFileUpload = async (document: Requirement) => {
    const hcpId = agent?.userId;
    onUploadingDocument(document);
    try {
      const documentSelectedFiles = selectedFiles[document._id] || [];
      const canUploadMultiplePages = isImage(documentSelectedFiles[0]?.type);
      let documentFile;
      if (canUploadMultiplePages && documentSelectedFiles.length > 1) {
        const base64EncodedPDF = await constructPDFFromMultipleImages(
          documentSelectedFiles
        );
        documentFile = { file: base64EncodedPDF, type: "PDF" };
      } else {
        documentFile = documentSelectedFiles[0];
      }
      const selectedRequirement = { requirement: document };
      const { name, url } = await uploadSelectedDocument(
        documentFile,
        selectedRequirement
      );
      await createHcpDocument({
        variables: {
          name,
          hcpId,
          fileUrl: url,
          uploadedFor: document.reqId,
          covidTest: null,
        },
        update: updateCache({ hcpId, selectedRequirement, covidTest: null }),
      });

      onDocumentUploaded(document);
      fireOnboardingSegmentEvent(
        ONBOARDING_SEGMENT_EVENT_NAMES.ADDED_DOCUMENT_DURING_SIGNUP,
        {
          hcpId: agent?.userId as string,
          requirementId: document.reqId,
        }
      );
      fireVerifyDocumentZapierHook(agent as Agent, { document, url });
    } catch (error) {
      const eventDetails = {
        context: "handleFileUpload",
        requirement: document?.name,
        id: document?._id,
        reqId: document?.reqId,
      };
      logOnboardingError(
        ONBOARDING_STAGES.DOCUMENTS,
        (error as Error).message,
        agent?.userId,
        eventDetails
      );
      onErrorUploadingDocument(document);
    }
  };

  function closeAlert() {
    setShowError(false);
  }

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentSignupInfo2"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding" scrollEvents={true}>
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
          position="top"
        />

        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <IonAlert
          isOpen={showContinueAlert}
          onDidDismiss={() => setShowContinueAlert(false)}
          header={`${
            documents.length - uploadedDocuments.length
          } Documents missing`}
          message={`If you don’t have these documents handy, that’s ok.
          Are you done adding documents for now?`}
          buttons={[
            {
              text: "Add more",
              role: "cancel",
            },
            {
              text: "I'm done",
              handler: () => {
                fireOnboardingSegmentEvent(
                  ONBOARDING_SEGMENT_EVENT_NAMES.FINISHED_SUBMITTING_DOCUMENTS,
                  {
                    hcpId: agent?.userId?.toString() || "",
                    email: agent?.email as string,
                  }
                );
                // @ts-ignore
                history.push(nextStagePath);
              },
            },
          ]}
        />

        <IonAlert
          isOpen={!!showFileUploadAlert}
          onDidDismiss={() => setShowFileUploadAlert("")}
          header={`Upload this document?`}
          message={`Are you sure you would like to continue without uploading this document?`}
          buttons={[
            {
              text: "Skip",
              handler: () => {
                setSelectedDocument(
                  selectedDocument === showFileUploadAlert
                    ? null
                    : showFileUploadAlert
                );
              },
            },
            {
              text: "Finish and upload",
              handler: () => {
                const selectedDocumentObj = documents.find(
                  (document) => document._id === (selectedDocument as string)
                );
                handleFileUpload(selectedDocumentObj as Requirement);
              },
            },
          ]}
        />

        <Alert isOpen={showError} closeAlert={closeAlert} />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>Add required documents</h4>
                    <p>
                      If you don’t have everything with you right now, don’t
                      worry, you can always add them later.
                    </p>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  {loading && (
                    <IonSpinner class="ion-margin-start" name="lines" />
                  )}

                  {!loading && documents.length === 0 && (
                    <IonText>
                      <h4>No documents to upload</h4>
                    </IonText>
                  )}
                  {documents.map((document: Requirement, i: number) => (
                    <div
                      id="uploadDocument"
                      key={i}
                      className={`form-item-wrapper shadowedCard document-card 
                      ${selectedDocument === document._id ? ` selected` : ""}`}
                    >
                      <IonItem
                        lines="none"
                        className="document-item"
                        onClick={toggleSelectedDocument(document._id)}
                      >
                        <IonLabel className="aqf-label">
                          {document.name}
                        </IonLabel>
                        {uploadedDocuments.includes(
                          document.reqId as string
                        ) && (
                          <IonIcon
                            id="uploadCheck"
                            icon={customCheckmark}
                            slot="end"
                            mode="ios"
                            color="medium"
                          />
                        )}
                        <IonIcon
                          id="chevronButton"
                          icon={chevronForwardOutline}
                          slot="end"
                          mode="ios"
                          color="medium"
                          className={
                            selectedDocument === document._id ? `active` : ""
                          }
                        />
                      </IonItem>

                      <div className="">
                        {selectedDocument === document._id && (
                          <AgentDocumentUploader
                            agent={agent as Agent}
                            document={document}
                            uploading={uploadingDocuments.includes(
                              document.reqId as string
                            )}
                            uploaded={uploadedDocuments.includes(
                              document.reqId as string
                            )}
                            selectedFiles={selectedFiles[document._id]}
                            setSelectedFiles={onSelectedFile(document._id)}
                            clearSelectedFiles={clearSelectedFiles(
                              document._id
                            )}
                            handleFileUpload={handleFileUpload}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              onClick={onNext}
              disabled={loading}
            >
              Continue
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentUploadDocuments };
