import { Plugins } from "@capacitor/core";
import {
  IonButton,
  IonBackButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonSpinner,
  IonNote,
  IonIcon,
  IonToast,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import React, {
  useState,
  FormEvent,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import { getPhoneWithoutCode } from "src/lib/utils";
import { LogoutButton, openBrowser } from "src/lib/ionic-components";
import { isPlatform } from "@ionic/core";

import { Store } from "../../store/store.model";
import {
  reVerifyWithPhone,
  logout,
  reloadAgentProfile,
  reVerifyPhoneRequestOTP,
  reloadAgentAuth,
  logOutAgentAuth,
} from "../../store/session";
import { OnBoardingComponentProps } from "./model";
import { updateAgentData, checkIfEmailExists, verifyEmail } from "./api";
import { StyledIonPage } from "./style";
import { logEvent, addDataToIdentity } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import Alert from "./Alert";
import { logOnboardingError } from "../util/logging";
const { Keyboard } = Plugins;

const emailCheck = async (email, callback) => {
  if (!email) {
    return callback(false);
  }
  const { exists } = await checkIfEmailExists(email);
  callback(exists);
};

const debounceEmailCheck = debounce(emailCheck, 800);

const AgentEmail: React.FC<OnBoardingComponentProps> = ({
  agent,
  nextStagePath,
}) => {
  const { firebaseAuth, profile } = useSelector(
    (state: Store) => state.session
  );
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false);
  const [emailExists, setEmailExists] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const captchaRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (agent && agent.email) {
      setEmail(agent.email);
    }
  }, [agent]);

  useEffect(() => {
    if (firebaseAuth && emailError.isError) {
      firebaseAuth.initRecaptchaVerifier(
        "recaptcha-container",
        captchaRef.current
      );
    }
  }, [firebaseAuth, emailError.isError]);

  const setEmailCheck = (exists: boolean) => {
    if (exists) {
      setEmailExists(true);
    } else {
      setEmailExists(false);
    }
    setCheckingEmail(false);
  };

  const checkEmail = async (email) => {
    try {
      setCheckingEmail(true);
      debounceEmailCheck(email, setEmailCheck);
    } catch (error) {
      setEmailExists(false);
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        message: "Error occurred while checking if email exists",
        error: error?.message,
      });
    } finally {
      setCheckingEmail(false);
    }
  };

  const loginWithPhone = async () => {
    const phone = getPhoneWithoutCode(profile?.phone as string);
    const verified = await dispatch(reVerifyPhoneRequestOTP(phone));
    // @ts-ignore
    if (verified) {
      const path = "/home/signupReVerify";
      history.push(path, { phone, redirectPath: "/home/agentEmail" });
    }
  };

  const onEmailChange = (event: Event) => {
    const isEmail =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const mail = event.target?.["value"] as string;
    if (!isEmail.test(mail)) return;
    setEmail(mail);
    checkEmail(mail);
  };

  const onNextFunction = async (event?: FormEvent | MouseEvent) => {
    event && event.preventDefault();
    if (checkingEmail || (!agent && emailExists)) return;
    if (isPlatform("capacitor")) Keyboard.hide();
    setShowErrorModal(false);
    try {
      if (agent && agent.email) {
        return history.push(`${nextStagePath}`);
      }
      if (!email) {
        setEmailError({
          isError: true,
          message: "Please enter your email address",
        });
      }
      setUpdatingData(true);

      try {
        const isEmailValid = await verifyEmail(email);
        if (!isEmailValid) {
          setEmailError({
            isError: true,
            message: "Please enter a valid email address.",
          });
          setUpdatingData(false);
          return;
        }
        await firebaseAuth.updateCurrentUserEmail(email);
        const updatedAgent = await updateAgentData({
          stage: ONBOARDING_STAGES.EMAIL,
          email,
        });
        addDataToIdentity(updatedAgent.userId, {
          email: updatedAgent.email,
          phone: updatedAgent.phone,
          type: "AGENT",
        });
        fireOnboardingSegmentEvent(
          ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_EMAIL,
          {
            hcpId: updatedAgent.userId,
            email: updatedAgent.email,
          }
        );
        dispatch(reloadAgentProfile());
        history.push(`${nextStagePath}`);
      } catch (err) {
        if (!err.response || !err.response.text || err.response.status > 500) {
          logOnboardingError(ONBOARDING_STAGES.EMAIL, (err as Error).message);
          setShowErrorModal(true);
          return;
        }
        setEmailError({
          isError: true,
          message: err.response.text,
        });
      }
    } catch (error) {
      setEmailError({
        isError: true,
        message: "You need to reverify your account. Sending OTP...",
      });
      setTimeout(() => {
        loginWithPhone();
      }, 200);
    } finally {
      setUpdatingData(false);
    }
  };

  const onNext = useCallback(
    (event?: FormEvent | MouseEvent) => {
      onNextFunction(event);
    },
    [profile, agent, email, emailExists, checkingEmail]
  );

  const onDismiss = () => {
    setEmailError({ isError: false, message: "" });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentSignupInfo1"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonToast
          isOpen={emailError.isError}
          position="top"
          onDidDismiss={onDismiss}
          message={emailError.message}
          color="danger"
          duration={4000}
        />
        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>What's your email?</h4>
                  </div>

                  <div>
                    <IonLabel className="form-label">Email Address</IonLabel>
                    <IonItem lines="none" className="form-item-wrapper">
                      <IonInput
                        name="email"
                        className="form-input"
                        type="email"
                        placeholder="Email address"
                        onIonChange={onEmailChange}
                        value={email}
                        disabled={Boolean(agent && agent.email) || updatingData}
                        autofocus
                        required
                      />
                      {checkingEmail && <IonSpinner name="crescent" />}
                    </IonItem>
                  </div>

                  {!agent && emailExists && (
                    <IonItem lines="none">
                      <IonNote slot="end" color="danger">
                        This email already exists !
                      </IonNote>
                    </IonItem>
                  )}
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={
                updatingData ||
                checkingEmail ||
                (!agent && emailExists) ||
                !email
              }
              onClick={onNext}
            >
              Continue
              {updatingData && (
                <IonSpinner slot="end" class="ion-margin-start" name="lines" />
              )}
            </IonButton>
            <div ref={captchaRef}></div>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentEmail };
