import {
  CameraPhoto,
  CameraResultType,
  CameraSource,
  Plugins,
} from "@capacitor/core";
import { Chooser } from "@ionic-native/chooser";

const { Camera, Filesystem, Device } = Plugins;

interface CapturedPhotoType {
  /**
   * The base64 encoded string representation of the image.
   */
  file?: string;
  /**
   * The format of the image, ex: jpeg, png, gif.
   */
  type: string;
}

/**
 * Converts file uri to base64 string
 * @param {CameraPhoto} photo
 * @returns {string} base64 string of image
 */
const readAsBase64 = async (photo: CameraPhoto): Promise<string> => {
  const fileContents = await Filesystem.readFile({
    path: photo?.path || "",
  });
  const fileBase64 =
    "data:image/" + photo.format + ";base64," + fileContents?.data;
  return fileBase64;
};

/**
 * Captures image from mobile
 * @param {number} quality defines the image quality for the captured picture
 * @returns
 */
const capturePhoto = async (
  quality?: number,
  cameraSource: CameraSource = CameraSource.Prompt
): Promise<CapturedPhotoType> => {
  const { manufacturer } = await Device.getInfo();

  //Using resultType as data url for xiaomi phones as it leads to crashes when using file uri. Reference issue:- https://github.com/ionic-team/capacitor/issues/2060
  const resultType =
    manufacturer === "Xiaomi" ? CameraResultType.DataUrl : CameraResultType.Uri;
  const fileData = await Camera.getPhoto({
    resultType,
    source: cameraSource,
    ...(quality ? { quality } : {}),
  });
  let file = fileData.dataUrl;
  if (manufacturer !== "Xiaomi") {
    file = await readAsBase64(fileData);
  }
  return { file, type: fileData.format };
};

/**
 * Opens file browser on IOS
 */
const iosFileChooser = async (): Promise<CapturedPhotoType> => {
  const { uri, mediaType: type = "" } = (await Chooser.getFile()) || {};
  const fileContents = await Filesystem.readFile({
    path: uri || "",
  });
  const fileBase64 = "data:" + type + ";base64," + fileContents?.data;
  return { file: fileBase64, type };
};

export { iosFileChooser, capturePhoto };
