import React, { useEffect } from "react";

import { IonPage } from "@ionic/react";
import { LoginRouteState } from "./login.model";
import { loginWithLink } from "../store/session";
import qs from "query-string";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { EmailWaiting } from "./emailWaiting";

const VerifyLogin: React.FC<{}> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { url } = (location.state || {}) as LoginRouteState;
  const emailToVerify = localStorage.getItem("emailToVerify");
  const queryString = qs.parse(location.search);

  useEffect(() => {
    dispatch(loginWithLink(url as string, queryString.email as string));
  }, [url, queryString.email]);

  return (
    <EmailWaiting
      email={
        emailToVerify ||
        (typeof queryString?.email === "string" ? queryString?.email : "")
      }
      opacity={1}
    />
  );
};

export { VerifyLogin };
