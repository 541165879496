import { createSegmentInstance } from "src/lib/analytics";
import { environment } from "@env/environment";
import { firebaseAppAnalytics } from "src/lib/firebase";
import { getRouterPathInfo } from "../routing/constant";
import { GlobalRouterPath } from "../routing/constant/globalRoute";
import { HistoryProps } from "src/lib/interface";
import { isPlatform } from "@ionic/react";
import { Plugins } from "@capacitor/core";
import { useRouteMatch } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../constants/userEvents";

const { CapacitorAppAnalytics } = Plugins;

const AnalyticsRouterTagging: React.FC<HistoryProps> = ({ location }) => {
  const match = useRouteMatch(location.pathname);
  const [prevState, setPrevState] = useState({});
  let segmentData: any = {
    referrer:
      prevState["pathname"] === location.pathname ? "" : prevState["pathname"],
  };
  const isMobileApp = isPlatform("capacitor");
  useEffect(() => {
    if (!window["analytics"]) {
      createSegmentInstance(environment.segment as Record<string, string>);
    }

    const rootPaths = Object.values(GlobalRouterPath);
    const invalidEvent =
      rootPaths.filter((path) => location.pathname.startsWith(path)).length ===
      0;

    if (invalidEvent) {
      return;
    }

    const pathInfo = getRouterPathInfo(match?.path as string);
    const screenName = pathInfo?.name || location.pathname;

    if (process.env.NODE_ENV !== "test") {
      CapacitorAppAnalytics.tagScreenName({ screenName });
    }
    firebaseAppAnalytics.setScreenName(screenName);

    segmentData = {
      ...segmentData,
      path: location.pathname,
      title: document.title,
      search: location.search,
      url: document.URL,
    };

    if (!location.pathname.includes("loading")) {
      setPrevState(location);

      const tabPages = ["/home/myShifts", "/home/openShifts", "/home/ongoing"];

      const myShiftsRegex = /\/home\/myShifts\/\w+/;
      const myShiftsUnverified = "/home/myShifts/unverifiedShifts";

      const myShiftRegex = /\/home\/myShift\/\w+/;
      const myShiftRatingRegex = /\/home\/myShift\/\w+\/rating$/;
      const myShiftSignatureRegex = /\/home\/myShift\/\w+\/signature$/;
      const myOngoingShiftRegex = /\/home\/myShift\/ongoing\/\w+/;

      const openShiftsRegex = /\/home\/openShifts\/\w+/;

      let path = location.pathname;
      if (myShiftsRegex.test(path)) {
        let name = USER_EVENTS.VIEWED_MY_SHIFTS;

        if (path === myShiftsUnverified) {
          name = USER_EVENTS.VIEWED_UNVERIFIED_SHIFTS;
        } else {
          const shiftId = path.substring(path.lastIndexOf("/"));
          segmentData = {
            ...segmentData,
            shiftId,
          };
        }

        logEvent(name, segmentData);
      } else if (myShiftRegex.test(path)) {
        let name = "Viewed My Shift";

        if (myOngoingShiftRegex.test(path)) {
          name = "Viewed Ongoing Shift";
        } else if (myShiftRatingRegex.test(path)) {
          name = "Viewed Shift Rating";
        } else if (myShiftSignatureRegex.test(path)) {
          name = "Viewed Shift Signature";
        }

        const shiftId =
          myShiftRatingRegex.test(path) || myShiftSignatureRegex.test(path)
            ? path.split("/")[3]
            : path.substring(path.lastIndexOf("/") + 1);

        logEvent(name, {
          ...segmentData,
          shiftId,
        });
      } else if (openShiftsRegex.test(path)) {
        const name = USER_EVENTS.SHIFT_OPENED;
        const date = path.substring(path.lastIndexOf("/") + 1);

        logEvent(name, {
          ...segmentData,
          date,
        });
      }
    }
  }, [location]);

  return <Fragment />;
};

export { AnalyticsRouterTagging };
