import request from "superagent";
import { replace, snakeCase } from "lodash";
import { logApiFailureEvent } from "src/lib/analytics";

import { environment } from "../../environments/environment";
import { GET_REQUIREMENTS_STATUS } from "./gql";
import { Requirement } from "../store/documents/model";
import { getAuthHeader } from "../superagent";
import { logFailedApiRetry } from "../utils/api_retry";
import { CheckrApplicationStatus } from "./model";

interface UploadDocumentData {
  requirement: Requirement;
}

const uploadSelectedDocument = async (
  selectedFile,
  data: UploadDocumentData
): Promise<{ url: string; name: string }> => {
  const formData = new FormData();
  formData.append("file", selectedFile.file);
  formData.append("upload_preset", environment.fileUploadPreset);

  const result = await request.post(environment.fileUploadURL).send(formData);
  const {
    body: { url, original_filename: filename },
  } = result;
  const trimmedRequirementName = replace(
    data.requirement.name,
    /[^\w\-\.]/,
    "_"
  );

  const name = filename
    ? `${snakeCase(trimmedRequirementName)}-${filename}`
    : `${snakeCase(trimmedRequirementName)}`;

  return { url, name };
};

const updateCache =
  ({ covidTest, hcpId, selectedRequirement }) =>
  (cache): void => {
    const data = cache.readQuery({
      query: GET_REQUIREMENTS_STATUS,
      variables: { hcpId },
    });
    const updatedData = { ...data.hcpRequirementStatus };
    const { requirement } = selectedRequirement;

    updatedData.pending = [...updatedData.pending, requirement.reqId];

    if (/covid/i.test(requirement.name)) {
      updatedData.mostRecentCovidDoc = { covidTest };
    }

    cache.writeQuery({
      query: GET_REQUIREMENTS_STATUS,
      variables: { hcpId },
      data: { hcpRequirementStatus: updatedData },
    });
  };

const fireDocumentReviewZapierHook = async (
  hcpId: string,
  requirement: { document: Requirement; url: string }
) => {
  return await request
    .post(`${environment.baseUrl}/agentprofile/documents/fireZapierHook`)
    .set(await getAuthHeader())
    .send({ agent: { userId: hcpId }, requirement })
    .then(({ body }) => body);
};

const getCloudinaryDocument = async (_id: string) => {
  return request
    .get(`${environment.baseUrl}/v2/agentprofile/documents`)
    .responseType("blob")
    .retry(1, (err) => {
      logFailedApiRetry(err, `/v2/agentprofile/documents`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ _id })
    .then(({ body }) => body);
};

const getCheckrApplicationStatus = async (): Promise<
  CheckrApplicationStatus | undefined
> => {
  try {
    const { data } = await request
      .get(`${environment.baseUrl}/agentprofile/checkr/application-status`)
      .set(await getAuthHeader())
      .then(({ body }) => body);

    return data;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const resendCheckrInvite = async (): Promise<
  CheckrApplicationStatus | undefined
> => {
  try {
    const { data } = await request
      .get(`${environment.baseUrl}/agentprofile/checkr/create-invitation`)
      .set(await getAuthHeader())
      .then(({ body }) => body);

    return data;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export {
  uploadSelectedDocument,
  updateCache,
  fireDocumentReviewZapierHook,
  getCloudinaryDocument,
  getCheckrApplicationStatus,
  resendCheckrInvite,
};
