import { Plugins } from "@capacitor/core";
import { Diagnostic } from "@ionic-native/diagnostic";
import { LocationAccuracy } from "@ionic-native/location-accuracy";
import { isPlatform } from "@ionic/react";

const { Geolocation } = Plugins;

export enum LocationAlertType {
  CANNOT_REQUEST = "CANNOT_REQUEST",
  USER_DENIED = "USER_DENIED",
  COORDS_NOT_AVAILABLE = "COORDS_NOT_AVAILABLE",
  NOT_CLOSE = "NOT_CLOSE",
  PLUGIN_ERROR = "PLUGIN_ERROR",
}

export enum LocationType {
  LIVE = "LIVE",
}

export enum AppType {
  MOBILE = "MOBILE",
  WEB = "WEB",
}

export enum ConnectionMode {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export const LocationAlertTypeReason = {
  CANNOT_REQUEST: "We cannot request location",
  USER_DENIED: "User denied access to location",
  COORDS_NOT_AVAILABLE: "Location isn't available",
  NOT_CLOSE: "User is not close to facility",
  PLUGIN_ERROR: "Error in the plugin during runtime",
};

// a lot of location-related errors come from third-party libraries with no typing and the source code is difficult to understand
// so we just play it safe and get whatever we can to add to the logs
export const getUnknownErrorString = (error: unknown) => {
  const err = error as Error | string;
  return err?.toString?.();
};

export const isGeoLocationEnabled = async (): Promise<boolean> => {
  const isMobileApp = isPlatform("capacitor");

  try {
    if (isMobileApp) {
      return Diagnostic.isLocationEnabled();
    }
    await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
    return true;
  } catch (error) {
    return false;
  }
};

export const enableGeoLocation = async (): Promise<{
  success: boolean;
  error?: LocationAlertType;
  errorDetails?: string;
}> => {
  const isMobileApp = isPlatform("capacitor");

  try {
    if (isMobileApp) {
      await LocationAccuracy.request(
        LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY
      );
    } else {
      // Web platform
      const perm = await navigator.permissions.query({ name: "geolocation" });
      switch (perm.state) {
        case "granted":
          return { success: true };
        case "denied":
          return { success: false, error: LocationAlertType.USER_DENIED };
        case "prompt":
          await Geolocation.getCurrentPosition({
            enableHighAccuracy: true,
            timeout: 60000,
            maximumAge: 60000,
          });
          break;
        default:
          throw new Error("Unexpected navigator.permissions.query state");
      }
    }
    return { success: true };
  } catch (error) {
    return {
      success: false,
      error: LocationAlertType.PLUGIN_ERROR,
      errorDetails: getUnknownErrorString(error),
    };
  }
};
