import fetch from "cross-fetch";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

import { environment } from "../../environments/environment";
import { firebaseAuth } from "../store/session/init";

const httpLink = createHttpLink({
  uri: environment.documentServiceUrl,
  fetch,
});

const authLink = setContext(async () => {
  let token = await firebaseAuth?.currentUser?.getIdToken();
  return {
    headers: {
      Authorization: token || localStorage.getItem("authToken"),
    },
  };
});

const link = authLink.concat(httpLink);

const GraphQlClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export { GraphQlClient };
