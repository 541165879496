import { Plugins } from "@capacitor/core";
import { logEvent } from "src/lib/analytics";
import { ListLoader } from "src/lib/ionic-components";
import styled from "@emotion/styled";
import {
  IonButton,
  IonContent,
  IonSpinner,
  IonToast,
  useIonViewDidEnter,
  IonIcon,
} from "@ionic/react";
import { PaymentServiceAccountStatus } from "src/lib/interface";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { USER_EVENTS } from "../../constants/userEvents";
import { ShowAPIFailError } from "../404Pages/showAPIFailError";
import { updateShowReferralAlert } from "../account/api";
import { AppPage } from "../layout/page";
import { ShouldBeOnboardedStatuses } from "../onboardingStripe/model";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { updateAgent, updateDeviceFlags } from "../store/session";
import { Store } from "../store/store.model";
import { copySharp } from "ionicons/icons";
import { HowItWorksCard } from "./components/howItWorksCard";
import "./style.scss";
const { Share, Clipboard } = Plugins;
const Container = styled.div``;
const Card = styled.div``;
const DisabledCard = styled.div``;
const ReferralPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { agent, referralRate } = useSelector((state: Store) => state.session);
  const [showMessage, setShowMessage] = useState<
    { message: string; type: string } | undefined
  >();
  const [inviteUrl, setInviteUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { networkStatus } = useSelector((state: Store) => state.session);
  const shouldOnboardToStripe = ShouldBeOnboardedStatuses.includes(
    agent?.paymentAccountInfo?.status as PaymentServiceAccountStatus
  );

  useEffect(() => {
    if (!agent) return;
    if (!agent.showReferralAlert) return;
    updateShowReferralAlert(agent.userId, { showReferralAlert: false }).then(
      () => {
        updateAgent({ showReferralAlert: false });
        dispatch(updateDeviceFlags("referralAlert", false));
      }
    );
  }, [agent, dispatch]);

  useIonViewDidEnter(() => {
    logEvent(USER_EVENTS.VIEWED_REFER_A_CO_WORKER, {
      hcpID: agent?.userId?.toString() || "",
    });
  });

  const navigateReferralCoWorkerPage = useCallback(() => {
    history.push(TabRouterPath.CO_WORKER_REFERRAL);
  }, [history]);

  const onShare = async () => {
    if (!agent) return;
    try {
      await Share.share({
        dialogTitle: "Invite to join Clipboard Health",
        title: `Download the Clipboard Health app for a ${referralRate} bonus!`,
        text: `Hey! I work through Clipboard Health, and I love it! Use my referral code ${agent.referralCode} after signing up. When you work your first shift, we’ll BOTH get a $${referralRate} bonus. 😊`,
      });
      logEvent(USER_EVENTS.TAPPED_LINK_TO_REFER_A_CO_WORKER, {
        inviteUrl: inviteUrl,
      });
    } catch (error) {
      setShowMessage({ message: "Could not share invitation", type: "danger" });
      logEvent(USER_EVENTS.REFERRAL_LINK_ERROR, { inviteUrl: inviteUrl });
    }
  };

  const onCopyToClipboard = useCallback(async () => {
    try {
      await Clipboard.write({
        string: agent?.referralCode,
      });
      setShowMessage({
        message: "Referral code copied to clipboard!",
        type: "success",
      });
    } catch (err) {
      setShowMessage({
        message: "Sorry, we couldn't copy the code to clipboard",
        type: "danger",
      });
    }
  }, [agent]);

  if (!agent) {
    return <IonSpinner color="light" />;
  }
  return (
    <AppPage title="Refer a Co-Worker" defaultHref={"/home/account"}>
      {!networkStatus?.connected ? (
        <ShowAPIFailError networkStatus={networkStatus?.connected} />
      ) : (
        <>
          <IonToast
            data-testid="toast-message"
            isOpen={!!showMessage}
            message={showMessage && showMessage.message}
            onDidDismiss={() => setShowMessage(undefined)}
            color={showMessage && showMessage.type}
            position="top"
            duration={2000}
          />
          {isLoading && <ListLoader />}
          {!isLoading && (
            <IonContent>
              <Container className="referral-container">
                <HowItWorksCard
                  referralRate={referralRate || ""}
                ></HowItWorksCard>

                {!shouldOnboardToStripe ? (
                  <>
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "black",
                        marginBottom: -12,
                      }}
                    >
                      Your referral code:
                    </span>
                    <Card className="card">
                      <span
                        data-testid="agent-referral-code"
                        style={{
                          fontSize: 24,
                          fontWeight: "bold",
                          color: "black",
                          marginRight: 8,
                          fontFamily: "Courier",
                        }}
                      >
                        {agent.referralCode}
                      </span>
                      <IonIcon
                        data-testid="copy-to-clipboard-icon"
                        onClick={onCopyToClipboard}
                        icon={copySharp}
                        color="primary"
                        size="small"
                      />
                    </Card>
                  </>
                ) : (
                  <IonButton
                    expand="block"
                    onClick={() => {
                      history.push(TabRouterPath.PAYMENT_SERVICE_ONBOARDING, {
                        showBackButton: true,
                      });
                    }}
                    className="spaced-button"
                  >
                    Sign up with Stripe to view and
                    <br />
                    share your referral code!
                  </IonButton>
                )}

                <>
                  <IonButton
                    onClick={onShare}
                    disabled={shouldOnboardToStripe}
                    className="disabled-button-variant"
                  >
                    Share your code with colleagues
                  </IonButton>
                </>
                <IonButton
                  onClick={navigateReferralCoWorkerPage}
                  color="primary"
                >
                  {shouldOnboardToStripe ? "Enter" : "Or enter"} a code
                </IonButton>
              </Container>
            </IonContent>
          )}
        </>
      )}
    </AppPage>
  );
};

export { ReferralPage };
