import { logEvent } from "src/lib/analytics";
import { Facility } from "src/lib/interface";
import {
  IonContent,
  IonLoading,
  IonModal,
  IonToast,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import { get, reduce } from "lodash";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { FacilityShiftsProcessType } from ".";
import {
  USER_EVENTS,
  DISTANCE_PREFERENCE_MAX_VALUE,
  SHIFT_PREFERENCE,
  SEARCH_MODE,
} from "src/constants";
import { ShowAPIFailError } from "../404Pages/showAPIFailError";
import { ShiftFilters } from "src/app/components/shiftFilters";
import { FacilityDetails } from "../dayView/facilityDetails";
import { showToast } from "../layout/toast";
import { Store } from "../store/store.model";
import {
  fetchFacilityOpenShiftCount,
  fetchQualifiedfacilitiesEmptyShiftsCount,
} from "./api";
import { RequestOpenShiftOptionsMap } from "./model";
import { GoogleMapShifts } from "./map";
import { computeShiftsCountMap } from "src/utils/openShifts";

interface OpenShiftMapInterface {
  useIonViewWillLeave: Function;
  segmentView: string;
}

const OpenShiftMap: React.FC<OpenShiftMapInterface> = ({
  useIonViewWillLeave,
  segmentView,
}) => {
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const { networkStatus } = useSelector((state: Store) => state.session);
  const [facilityDetails, setFacilitydetails] = useState<Facility | null>(null);
  const [facilityShifts, setFacilityShifts] = useState<Array<Facility>>([]);
  const [facilityShiftsProcess, setFacilityShiftsProcess] =
    useState<FacilityShiftsProcessType>({
      isLoading: true,
      errMessage: "",
    });
  const [didFetchComplete, setDidFetchComplete] = useState(false);

  const distancePreference = useMemo(() => {
    return get(agent, "preference.distance", DISTANCE_PREFERENCE_MAX_VALUE);
  }, [agent?.preference?.distance]);

  const minPayHourlyPreference = useMemo(() => {
    return get(agent, "preference.minPayHourly", SHIFT_PREFERENCE.PAY_HOUR);
  }, [agent?.preference?.minPayHourly]);

  const minPayShiftPreference = useMemo(() => {
    return get(agent, "preference.minPayShift", SHIFT_PREFERENCE.PAY_SHIFT);
  }, [agent?.preference?.minPayShift]);

  const baseRate = useMemo(() => {
    return get(agent, "baseRate", SHIFT_PREFERENCE.BASE_RATE);
  }, [agent?.baseRate]);

  const openMapFacilityDetails = (facility: Facility) => {
    logEvent(USER_EVENTS.TAP_FACILITY_PIN, {
      shifts: facility.shiftsCount || 0,
    });
    setFacilitydetails(facility);
  };

  const closeFacilityDetails = () => {
    setFacilitydetails(null);
  };

  const getQualifiedfaciliesShiftsCount = useCallback(async () => {
    try {
      setFacilityShiftsProcess({
        isLoading: true,
        errMessage: "",
      });
      const dateRange = {
        start: moment().add(1, "minutes").format("YYYY-MM-DD"),
        end: moment().add(30, "days").format("YYYY-MM-DD"),
      };
      const options: RequestOpenShiftOptionsMap = {
        range: dateRange,
        coordinates: get(agent, "geoLocation.coordinates", []),
        distance: distancePreference,
        minPayHourly: minPayHourlyPreference,
        minPayShift: minPayShiftPreference,
        qualification: agent.qualification,
        specialities: get(agent, "specialities", {}),
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const facilityOpenShiftsCount = await fetchFacilityOpenShiftCount(
        options
      );
      if (!facilityOpenShiftsCount) {
        setFacilityShiftsProcess({
          isLoading: false,
          errMessage: "Failed to fetch map openShifts data, please try again",
        });
        return;
      }
      setFacilityShifts(facilityOpenShiftsCount);
      setFacilityShiftsProcess({
        isLoading: false,
        errMessage: "",
      });
    } catch (err) {
      showToast(`${err}`, 3000, "bottom", "danger");
      setFacilityShiftsProcess({
        isLoading: false,
        errMessage: `${err}`,
      });
    }
  }, [agent.userId]);

  useEffect(() => {
    if (segmentView === "map" && !didFetchComplete) {
      getQualifiedfaciliesShiftsCount();
      setDidFetchComplete(true);
    }
  }, [
    segmentView,
    distancePreference,
    minPayHourlyPreference,
    minPayShiftPreference,
  ]);

  useIonViewDidLeave(() => setDidFetchComplete(false));

  return (
    <Fragment>
      {!networkStatus?.connected ? (
        <ShowAPIFailError networkStatus={networkStatus?.connected} />
      ) : (
        <>
          <IonContent>
            <IonToast
              isOpen={!!facilityShiftsProcess.errMessage}
              onDidDismiss={() => {
                setFacilityShiftsProcess({
                  isLoading: false,
                  errMessage: "",
                });
              }}
              message={facilityShiftsProcess.errMessage}
              color="danger"
              position="top"
              duration={2000}
            />
            <IonLoading
              isOpen={facilityShiftsProcess.isLoading && segmentView === "map"}
              backdropDismiss={true}
            />
            <ShiftFilters
              openShiftsEmpty={false}
              totalShifts={computeShiftsCountMap(facilityShifts)}
              hiddenShifts={computeShiftsCountMap(facilityShifts, "hidden")}
              baseRate={baseRate}
              distancePreference={distancePreference}
              minPayHourlyPreference={minPayHourlyPreference}
              minPayShiftPreference={minPayShiftPreference}
              searchMode={SEARCH_MODE.MAP}
              updateOpenShifts={() => {
                setDidFetchComplete(false);
              }}
            />
            <GoogleMapShifts
              useIonViewWillLeave={useIonViewWillLeave}
              segmentView={segmentView}
              onHCFMarkerClick={openMapFacilityDetails}
              facilityShiftsProcess={facilityShiftsProcess}
              facilityShifts={facilityShifts}
            />
          </IonContent>
        </>
      )}
      <IonModal isOpen={!!facilityDetails} onDidDismiss={closeFacilityDetails}>
        <FacilityDetails
          facility={facilityDetails as Facility}
          onClose={closeFacilityDetails}
          shift={{}}
        />
      </IonModal>
    </Fragment>
  );
};

export { OpenShiftMap };
