import { Shift, Specialities } from "src/lib/interface";
import { DateRange } from "src/lib/utils";

interface ShiftStore {
  myShiftDateRange?: DateRange;
  openShiftDateRange?: DateRange;
  isLocationAvailable: boolean;
  undoShiftActions: UndoInterst;
}

interface UndoInterst {
  [index: string]: UndoInterstItem;
}

interface UndoInterstItem {
  isCountinue: boolean;
  isUndo: boolean;
  isCanceledShift?: boolean;
  isRemovedInterest?: boolean;
  reasonType?: string;
  reasonDescription?: string;
}

interface ShiftDateMap {
  [date: string]: Shift[];
}

interface DateDetails {
  timeSlot: string;
  numSpots: number;
}

const ActionType = {
  CLEAR_SHIFTS: "CLEAR_SHIFTS",
  UPDATE_MY_SHIFT_DATE_RANGE: "UPDATE_MY_SHIFT_DATE_RANGE",
  UPDATE_OPEN_SHIFT_DATE_RANGE: "UPDATE_OPEN_SHIFT_DATE_RANGE",
  UPDATE_LOCATION_AVAILABILITY: "UPDATE_LOCATION_AVAILABILITY",
  UNDO_INTEREST: "UNDO_INTEREST",
};

interface ShiftActionData extends ShiftStore {
  updatedShift: Shift;
}

interface ShiftAction {
  type: string;
  data?: Partial<ShiftActionData>;
}

interface RequestOpenShiftOptions {
  range: DateRange;
  coordinates: number[];
  distance: number;
  qualification: string;
  specialities?: Specialities;
  isPlain?: boolean;
}

export {
  ShiftStore,
  ActionType,
  ShiftAction,
  ShiftDateMap,
  RequestOpenShiftOptions,
  UndoInterst,
};
