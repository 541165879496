import { Shift, VerificationPreferences } from "src/lib/interface";

/**
 * returns true, if second argument shift start and end date
 * does not conflict with shifts passed.
 */
const isNonConflictingShift = (shifts: Shift[], shift: Shift): boolean => {
  const shiftStart = shift.start as string;
  const shiftEnd = shift.end as string;
  return shifts.every((myShift) => {
    const myShiftStart = myShift.start as string;
    const myShiftEnd = myShift.end as string;
    return (
      (shiftStart <= myShiftStart && shiftEnd <= myShiftStart) ||
      (shiftStart >= myShiftEnd && shiftEnd >= myShiftEnd)
    );
  });
};

/**
 *
 * @param openShifts removed conflicting shifts from this shift.
 * @param myShifts already assigned shifts.
 */
const removeConflictingShifts = (
  openShifts: Shift[],
  myShifts: Shift[]
): Shift[] =>
  openShifts.filter((shift) => isNonConflictingShift(myShifts, shift));

const addVerificationPreferencesToShiftFacility = (
  shifts: Shift[],
  facilitiesVerificationPreferences: VerificationPreferences[] | undefined = []
) => {
  const facilitiesById = facilitiesVerificationPreferences.reduce(
    (acc, current) => ({ ...acc, [current.mongodbId]: current }),
    {}
  );
  return shifts.map((shift) => ({
    ...shift,
    ...(shift.facility?.userId
      ? {
          facility: {
            ...shift.facility,
            verificationPreference: facilitiesById[shift.facility.userId],
          },
        }
      : {}),
  }));
};

const checkRolloutMsa = (shift: Shift, rolloutMsas: string[]): boolean => {
  return rolloutMsas.includes(
    shift.facility?.fullAddress?.metropolitanStatisticalArea as string
  );
};

export {
  isNonConflictingShift,
  removeConflictingShifts,
  checkRolloutMsa,
  addVerificationPreferencesToShiftFacility,
};
