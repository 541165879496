import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Store } from "../store/store.model";
import { VerifyOTP } from "./verify";
import {
  clearLoginError,
  loginWithOTP,
  verifyPhoneRequestOTP,
  reVerifyPhoneRequestOTP,
} from "../store/session";
import { Landing } from "./landing";

const LandingPageState = (state: Store) => ({
  isLogging: state.session.isLogging,
  verification: state.session.verification,
  loginError: state.session.loginError,
  loginErrorType: state.session.loginErrorType,
  firebaseAuth: state.session.firebaseAuth,
});

const LandingPageActions = (dispatch) =>
  bindActionCreators(
    {
      verifyPhone: verifyPhoneRequestOTP,
      clearLoginError,
    },
    dispatch
  );

const LandingPage = connect(LandingPageState, LandingPageActions)(Landing);

const verifyPageState = (state: Store) => ({
  isVerifying: state.session.isVerifying,
  loginError: state.session.loginError,
});

const verifyPageActions = (dispatch) =>
  bindActionCreators(
    {
      loginWithCode: loginWithOTP,
      clearLoginError,
      reVerifyPhone: reVerifyPhoneRequestOTP,
    },
    dispatch
  );

const VerifyPage = connect(verifyPageState, verifyPageActions)(VerifyOTP);

export { LandingPage, VerifyPage };
