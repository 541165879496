import React, { Fragment, useEffect, useState } from "react";
import { environment } from "@env/environment";
import { IonCardSubtitle, IonIcon, IonRow } from "@ionic/react";
import { Shift } from "src/lib/interface";
import { useAppSelector } from "@store/index";
import { formatRate } from "../helper";
import { chevronDown, chevronForward, helpCircleOutline } from "ionicons/icons";
import moment from "moment-timezone";
import { SupportText } from "./support";
import { VerificationPreferenceDetails } from "@app/components/shift/verificationPreferenceDetails";

const ShiftInstructions: React.VFC<{
  shift: Shift;
  hasMissingDocs: boolean;
  hasMissedClockIn: boolean;
  isTimeSheetRequired: boolean;
  timeSheetExists: boolean;
}> = ({
  shift,
  hasMissingDocs,
  hasMissedClockIn,
  isTimeSheetRequired,
  timeSheetExists,
}) => {
  const [showCheckInInstructions, setShowCheckInInstructions] = useState(false);
  const [renderInstructions, setRenderInstructions] = useState(false);
  const [isMinimumTimePassed, setIsMinimumTimePassed] = useState(false);
  const [canUploadTimeSheet, setCanUploadTimeSheet] = useState(false);
  const [isShiftStarted, setIsShiftStarted] = useState(false);
  const [isPreShiftStarted, setIsPreShiftStarted] = useState(false);
  const [isPostShiftStarted, setIsPostShiftStarted] = useState(false);
  const [isShiftEnded, setIsShiftEnded] = useState(false);
  const [showShiftInstructions, setShowShiftInstructions] = useState(
    !Boolean(shift.clockInOut)
  );
  const disableShiftInstructionsAccordion = !Boolean(shift.clockInOut);
  const { env, userId } = useAppSelector((state) => state.session);
  const isShiftOngoing = moment().isAfter(shift.start);
  const isShiftToday = moment().endOf("day").isAfter(shift.start);
  const isAssigned = shift.agentId === userId;

  const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

  useEffect(() => {
    const shouldShowInstructions =
      (shift.agent || (!shift.agent && !hasMissingDocs)) && !hasMissedClockIn;

    setRenderInstructions(shouldShowInstructions);
  }, [shift, hasMissingDocs, hasMissedClockIn]);

  useEffect(() => {
    const isPreShiftStartedDiff = moment(shift.start)
      .subtract(env?.minBeforeShiftStart, "minutes")
      .diff(moment());
    setIsPreShiftStarted(isPreShiftStartedDiff <= 0);

    if (isPreShiftStartedDiff > ONE_DAY_IN_MILLIS) return;

    const timeout = setTimeout(() => {
      setIsPreShiftStarted(true);
    }, isPreShiftStartedDiff);

    return () => clearTimeout(timeout);
  }, [env, shift]);

  useEffect(() => {
    const isShiftStartedDiff = moment(shift.start).diff(moment());
    setIsShiftStarted(isShiftStartedDiff <= 0);

    if (isShiftStartedDiff > ONE_DAY_IN_MILLIS) return;

    const timeout = setTimeout(() => {
      setIsShiftStarted(true);
    }, isShiftStartedDiff);

    return () => clearTimeout(timeout);
  }, [env, shift]);

  useEffect(() => {
    const isPostShiftStartedDiff = moment(shift.start)
      .add(env?.minToAllowTimeCardUpload, "minutes")
      .diff(moment());
    setIsPostShiftStarted(isPostShiftStartedDiff <= 0);

    if (isPostShiftStartedDiff > ONE_DAY_IN_MILLIS) return;

    const timeout = setTimeout(() => {
      setIsPostShiftStarted(true);
    }, isPostShiftStartedDiff);

    return () => clearTimeout(timeout);
  }, [env, shift]);

  useEffect(() => {
    const isShiftEndedDiff = moment(shift.end)
      .add(env?.shiftEndGracePeriod, "minutes")
      .diff(moment());
    setIsShiftEnded(isShiftEndedDiff <= 0);

    if (isShiftEndedDiff > ONE_DAY_IN_MILLIS) return;

    const timeout = setTimeout(() => {
      setIsShiftEnded(true);
    }, isShiftEndedDiff);

    return () => clearTimeout(timeout);
  }, [env, shift]);

  useEffect(() => {
    if (!renderInstructions) return;

    const timePassed = moment().isAfter(
      moment(shift.start).subtract(env?.minBeforeShiftStart, "minutes")
    );
    setIsMinimumTimePassed(timePassed);

    const hasShiftPassedMinTimeAllowed =
      moment().diff(shift.start, "minutes") > env!.minToAllowTimeCardUpload;

    setCanUploadTimeSheet(hasShiftPassedMinTimeAllowed);

    const showInstructions =
      (!hasShiftPassedMinTimeAllowed && !shift.isInstantPay) ||
      (isShiftOngoing && shift.isInstantPay && !shift.clockInOut) ||
      !isShiftOngoing;

    setShowCheckInInstructions(
      showInstructions && !shift?.isChangedToNonInstantPay
    );
  }, [
    shift,
    env,
    isShiftOngoing,
    renderInstructions,
    isMinimumTimePassed,
    canUploadTimeSheet,
  ]);

  if (!renderInstructions) {
    return null;
  }

  const onInstantPayInformation = () => {
    window.open(environment.instantPayInformationUrl, "_blank");
  };

  const { instantPayDetails: { totalAmount = 0, instantPayRate = 0 } = {} } =
    shift;
  const instantPayAmount = totalAmount;
  const remainingAmount = 0;

  const isInstantPayShift = shift?.isInstantPay;

  const todayShiftGreetMessage = isShiftToday
    ? "We hope you have a great shift today!"
    : "Steps for success!";

  const noNeedToClockMessage = `No need to clock in or clock out for your shift,
  you'll be able to upload a photo of your Timesheet after your shift for verification.`;

  const clockInMessage = `Clock In at the start of the shift and upload your timesheet
  at the end to get InstantPay!`;

  const futureShiftInstruction = {
    title: "Steps for success!",
    message:
      "No need to Clock In or Clock Out for your shift, you’ll just need to upload a photo of your timesheet after your shift for verification! ",
  };

  const timeSheetUploadedDate = shift?.timecard?.files?.length
    ? moment(shift?.timecard?.createdAt).format("dddd, MMM DD")
    : "";

  return (
    <>
      {isInstantPayShift && (
        <>
          <div className="hr"> </div>

          <IonCardSubtitle
            className="title no-text-transform ion-margin-top"
            data-testid="shift-instantpay-instructions-toggle"
            onClick={() =>
              setShowShiftInstructions(
                disableShiftInstructionsAccordion
                  ? true
                  : !showShiftInstructions
              )
            }
          >
            InstantPay
            <IonIcon
              className="instant-pay-icon"
              onClick={onInstantPayInformation}
              icon={helpCircleOutline}
            />
            <IonIcon
              className="instant-pay-icon"
              icon={showShiftInstructions ? chevronDown : chevronForward}
              hidden={disableShiftInstructionsAccordion}
            />
          </IonCardSubtitle>

          {showShiftInstructions ? (
            <>
              {shift.instantPayDetails?.is100InstantPayEnabled ? (
                <>
                  <p>This is an InstantPay shift.</p>
                  <p>
                    Clock In at the start of your shift, record your break
                    times, and Clock Out at the end.
                  </p>
                  <p>
                    You’ll receive{" "}
                    <span className="payment-text-color-instantpay">
                      ${formatRate(totalAmount)}
                    </span>{" "}
                    for this shift as soon as you’re done!
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Clock In within {env?.minBeforeShiftStart || 5} minutes of
                    the start of your shift, record your break times, and Clock
                    Out at the end. You'll receive{" "}
                    <span className="payment-text-color-instantpay">
                      ${formatRate(instantPayAmount)}{" "}
                    </span>
                    for this shift as soon as you're done! Make sure you upload
                    your timesheet after so your shift can get verified quickly.
                  </p>
                  <p>
                    <span
                      className={
                        instantPayRate === 1 ? "payment-text-color" : ""
                      }
                    >
                      You'll receive the remaining{" "}
                      <span className="payment-text-color">
                        ${formatRate(remainingAmount)}{" "}
                      </span>
                      once the timesheet is reviewed and the shift is verified.
                    </span>
                  </p>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <div className="hr"> </div>
        </>
      )}
      {shift.facility?.checkInInstructions &&
        showCheckInInstructions &&
        !isPostShiftStarted && (
          <>
            <IonCardSubtitle className="title no-text-transform ion-margin-top">
              Check-in Instructions
            </IonCardSubtitle>
            <p>{shift.facility.checkInInstructions}</p>
          </>
        )}
      {isAssigned &&
        !isInstantPayShift &&
        isShiftToday &&
        !isTimeSheetRequired && (
          <>
            {!shift.isChangedToNonInstantPay && (
              <>
                <IonCardSubtitle className="title no-text-transform ion-margin-top">
                  {todayShiftGreetMessage}
                </IonCardSubtitle>
                <p>
                  <VerificationPreferenceDetails
                    verificationPreference={
                      shift.facility?.verificationPreference
                    }
                    timeSheetExists={timeSheetExists}
                  />
                </p>
                {isShiftToday && <SupportText />}
              </>
            )}
          </>
        )}
      {isAssigned &&
        !isInstantPayShift &&
        isShiftToday &&
        isPreShiftStarted &&
        !isShiftOngoing &&
        isTimeSheetRequired &&
        !shift?.isChangedToNonInstantPay && (
          <>
            <IonCardSubtitle className="title no-text-transform ion-margin-top">
              {todayShiftGreetMessage}
            </IonCardSubtitle>
            <p>
              {noNeedToClockMessage}
              <VerificationPreferenceDetails
                verificationPreference={shift?.facility?.verificationPreference}
                timeSheetExists={timeSheetExists}
              />
            </p>
            {isShiftToday && <SupportText />}
          </>
        )}
      {isAssigned &&
        !isInstantPayShift &&
        isShiftStarted &&
        !isPostShiftStarted &&
        !canUploadTimeSheet &&
        isTimeSheetRequired &&
        !shift?.isChangedToNonInstantPay && (
          <>
            <IonCardSubtitle className="title no-text-transform ion-margin-top">
              {todayShiftGreetMessage}
            </IonCardSubtitle>
            <p>{noNeedToClockMessage}</p>
            <VerificationPreferenceDetails
              verificationPreference={shift?.facility?.verificationPreference}
              timeSheetExists={timeSheetExists}
            />
            <SupportText />
          </>
        )}
      {!isShiftEnded &&
        isAssigned &&
        !isInstantPayShift &&
        isShiftOngoing &&
        isPostShiftStarted &&
        isTimeSheetRequired && (
          <>
            {!shift.isChangedToNonInstantPay && (
              <>
                <IonCardSubtitle className="title no-text-transform ion-margin-top">
                  {todayShiftGreetMessage}
                </IonCardSubtitle>
                <p>
                  <VerificationPreferenceDetails
                    verificationPreference={
                      shift?.facility?.verificationPreference
                    }
                    timeSheetExists={timeSheetExists}
                  />
                </p>
              </>
            )}
          </>
        )}
      {isAssigned && isInstantPayShift && isShiftToday && !isShiftOngoing && (
        <>
          <IonCardSubtitle className="title no-text-transform ion-margin-top">
            {todayShiftGreetMessage}
          </IonCardSubtitle>
          <p>{clockInMessage}</p>
        </>
      )}
      {!isPreShiftStarted && !isShiftOngoing && !isInstantPayShift && (
        <>
          <IonCardSubtitle className="title no-text-transform ion-margin-top">
            {futureShiftInstruction.title}
          </IonCardSubtitle>
          <p>
            {futureShiftInstruction.message}
            <VerificationPreferenceDetails
              verificationPreference={shift?.facility?.verificationPreference}
              timeSheetExists={timeSheetExists}
            />
          </p>
          {<SupportText />}
        </>
      )}
      {timeSheetExists && timeSheetUploadedDate ? (
        <IonRow>
          <p>Timesheet added {timeSheetUploadedDate}</p>
        </IonRow>
      ) : null}
    </>
  );
};

export { ShiftInstructions };
