import { UploadFileModal } from "@app/components/uploadFile";
import {
  IonContent,
  IonImg,
  IonButton,
  IonPopover,
  IonItemGroup,
  IonText,
  IonItem,
  IonToast,
} from "@ionic/react";
import { render } from "@testing-library/react";
import React, { useState } from "react";
import { SelectedFile } from "@app/shiftSignature/timecard/model";
import { FileExplorer } from "@app/components/uploadFile/fileExplorer";
import { FilePreviewAndUpload } from "@app/components/uploadFile/previewAndUpload";
import {
  enterAnimation,
  leaveAnimation,
} from "../../rating/reviewPrompt/popoverAnimation";
import "./style.scss";
import { isImage } from "@app/utils/isImage";

const TimeSheetSubmission = ({ shift, OnSubmit, isShiftTimeEdit }) => {
  const [showUploadTimesheetModal, setShowUploadTimesheetModal] =
    useState<boolean>(false);
  const [canShowUploadButton, setCanShowUploadButton] = useState<boolean>(true);
  const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");

  const onUpload = (SelectedFile) => {
    if (isImage(SelectedFile.type)) {
      setSelectedFile(SelectedFile);
      setShowUploadTimesheetModal(false);
    } else {
      setUploadErrorMessage("File format not supported");
    }
  };

  return (
    <IonContent>
      <IonToast
        isOpen={!!uploadErrorMessage}
        message={uploadErrorMessage}
        onDidDismiss={() => {
          setUploadErrorMessage("");
        }}
        color="danger"
        position="top"
        duration={5000}
      />
      <div>
        <h6 className="timesheet-summary-text">
          Confirm that your times match your timesheet, or edit them if needed
        </h6>
      </div>
      {selectedFile ? (
        <IonImg className="timesheet-image" src={selectedFile["file"]} />
      ) : (
        <div
          className="empty-timesheet-image"
          style={{
            height: 230,
          }}
        ></div>
      )}
      {showUploadTimesheetModal && shift && (
        <IonPopover
          isOpen
          showBackdrop
          backdropDismiss={false}
          enterAnimation={enterAnimation}
          leaveAnimation={leaveAnimation}
          cssClass="upload-prompt"
        >
          <div id="overlay-section" className="timesheet-upload">
            <IonItemGroup className="upload-time-group">
              <FileExplorer onFileSelected={onUpload} />
              <div className="ion-text-center ion-margin file-upload-cancel-btn">
                <IonText onClick={() => setShowUploadTimesheetModal(false)}>
                  Cancel
                </IonText>
              </div>
            </IonItemGroup>
          </div>
        </IonPopover>
      )}
      <IonItem lines={"none"} className="timesheet-submit-buttons">
        <IonButton
          size="large"
          expand="block"
          shape="round"
          className="ion-margin timesheet-upload-button"
          onClick={() => setShowUploadTimesheetModal(true)}
        >
          {selectedFile != null ? "Retake Picture" : "Take Picture"}
        </IonButton>
        <IonButton
          size="large"
          expand="block"
          shape="round"
          color="primary"
          className="ion-margin timesheet-submit-button"
          onClick={() => OnSubmit(selectedFile)}
          disabled={selectedFile == null || isShiftTimeEdit}
        >
          Submit
        </IonButton>
      </IonItem>
    </IonContent>
  );
};

export { TimeSheetSubmission };
