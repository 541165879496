import { IonAlert } from "@ionic/react";
import { useSelector } from "react-redux";
import { CallNumber } from "@ionic-native/call-number";
import { useLocation } from "react-router-dom";
import { logEvent } from "src/lib/analytics";
import { Plugins } from "@capacitor/core";
import { Store } from "../../store/store.model";
import OnboardingErrorEvent from "../../../types/onboarding/OnboardingErrorEvent";
import AlertProps from "../../../types/components/Alert/AlertProps";
import { environment } from "../../../environments/environment";
import { USER_EVENTS } from "../../../constants/userEvents";
import "../../popups/style.scss";
import { logErrors } from "@app/errorBoundary/api";
const { Device } = Plugins;

const RESOLUTION = Object.freeze({
  TRY_AGAIN: "Try again",
  CALL_SUPPPORT: "Call Support",
});

export default function Alert(props: AlertProps) {
  const { isOpen, closeAlert } = props;
  const { pathname } = useLocation();
  const agentId = useSelector((state: Store) => state.session.agent?.userId);

  function getEventPayload(resolution: string): OnboardingErrorEvent {
    const payload: OnboardingErrorEvent = {
      page: pathname,
      resolution,
    };

    if (agentId) {
      payload["agentId"] = agentId;
    }
    return payload;
  }

  async function handleCallSupport() {
    closeAlert();

    try {
      const { platform } = await Device.getInfo();

      if (platform == "android" || platform == "ios") {
        try {
          await CallNumber.callNumber(`${environment.support.phone}`, false);
        } catch (e) {
          logErrors({
            message: JSON.stringify((e as Error).stack),
            app: "hcpMobile",
          });
        }
      } else {
        window.open(`tel:${environment.support.phone}`);
      }
    } catch (e) {
      logErrors({
        message: JSON.stringify((e as Error).stack),
        app: "hcpMobile",
      });
    }

    logEvent(
      USER_EVENTS.ONBOARDING_ERROR,
      getEventPayload(RESOLUTION.CALL_SUPPPORT)
    );
  }

  function handleTryAgain() {
    closeAlert();
    logEvent(
      USER_EVENTS.ONBOARDING_ERROR,
      getEventPayload(RESOLUTION.TRY_AGAIN)
    );
  }

  return (
    <IonAlert
      cssClass={"alert-popup-container"}
      mode="ios"
      isOpen={isOpen}
      backdropDismiss={false}
      header="Oops!"
      subHeader="Something went wrong."
      message="Try again or call our 24/7 Support Team at (408) 837-0116."
      buttons={[
        {
          text: RESOLUTION.TRY_AGAIN,
          handler: handleTryAgain,
          cssClass: "positive-action",
        },
        {
          text: RESOLUTION.CALL_SUPPPORT,
          handler: handleCallSupport,
          cssClass: "neutral-action",
        },
      ]}
    />
  );
}
