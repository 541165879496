import { ActionType } from "./model";
import {
  Agent,
  AuthorizedSignatory,
  ShiftStages,
  TimeRange,
} from "src/lib/interface";
import { ConnectionMode } from "@app/common/location";
import { Dispatch } from "redux";
import { GetState } from "../store.model";
import { logEvent } from "src/lib/analytics";
import moment from "moment-timezone";
import { Plugins } from "@capacitor/core";
import { ActionType as sessionActionType } from "../../store/session";
import { USER_EVENTS } from "../../../constants/userEvents";
import {
  addRating,
  createAccountLog,
  fetchAgentShifts,
  recordDisputedTime,
  recordShiftTime,
  requestAuthorizedSignature,
  setShiftStage,
  signWithSignature,
  updateShiftUnit,
  updateWorkerSignature,
  uploadTimecard,
} from "./api";
import {
  addRating as addRatingV2,
  fetchAgentShifts as fetchAgentShiftsV2,
} from "./apiV2";
import { FetchAgentShiftRequest } from "./model";
import { isEmpty } from "lodash";
import { getShiftTime, getShiftTimeKey } from "./signatureHelper";
import {
  recordShiftTimeV2,
  uploadTimecardV2,
  uploadSubmittedTimeSheet,
} from "@store/ongoingShifts/apiV2";
import { Shift, Timecard, TimecardV2 } from "src/lib/interface";
import { promiseInSequence } from "../../../utils/promises";
import { api } from "@app/api";

const { Network, Storage } = Plugins;

const ONGOING_SHIFT_STORE_KEY = "ongoingShiftStore";

let networkHandler;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const networkListener =
  (dispatch: Dispatch, getState: GetState) => (status) => {
    if (status.connected) {
      offlineSync(dispatch, getState);
    }
  };

const init =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    if (!networkHandler) {
      networkHandler = Network.addListener(
        "networkStatusChange",
        networkListener(dispatch, getState)
      );
    }

    fetchStateFromLocal(dispatch);
    const agent = getState().session.agent;
    const env = getState().session.env;
    if (env && !env["enableNewFlow"] && agent && !agent["enableNewFlow"]) {
      const shiftList = await fetchShiftList(dispatch);
      fetchShiftInfoList(dispatch, shiftList);
    }
  };

const offlineSync = async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  const { actionList } = getState().ongoingShiftStore;

  await hitSetShiftStage(dispatch, actionList["SET_SHIFT_STAGE"]);
  await hitRecordShiftTime(dispatch, actionList["RECORD_SHIFT_TIME"]);
  await hitRecordShiftTimeFailure(
    dispatch,
    actionList["RECORD_SHIFT_TIME_FAILURE"]
  );
  await hitRecordDisputedTime(dispatch, actionList["RECORD_DISPUTED_TIME"]);
  await hitUpdateShiftUnit(dispatch, actionList["UPDATE_SHIFT_UNIT"]);
  await hitUploadTimeCard(dispatch, actionList["UPLOAD_TIMECARD"]);
  await hitUpdateWorkerSignature(
    dispatch,
    actionList["UPDATE_WORKER_SIGNATURE"]
  );
  await hitUAddRating(dispatch, actionList["ADD_RATING"]);
  await hitRequestAuthorizedSignature(
    dispatch,
    actionList["REQUEST_AUTHORIZED_SIGNATURE"]
  );
  await hitSignWithSignature(dispatch, actionList["SIGN_WITH_SIGNATURE"]);
};

const getKeyInObject = (object, keyToFind): boolean => {
  let foundKey = false;
  if (!object || !keyToFind) return foundKey;

  Object.keys(object).forEach(function (key, index) {
    if (key === keyToFind) foundKey = true;
  });

  return foundKey;
};

const fetchStateFromLocal = async (dispatch: Dispatch): Promise<void> => {
  const ret = await Storage.get({ key: ONGOING_SHIFT_STORE_KEY });

  if (!ret.value) return;

  let ongoingShiftsStore = JSON.parse(ret.value);

  if (isEmpty(ongoingShiftsStore)) ongoingShiftsStore = {};

  ongoingShiftsStore.isLoading = false;

  if (ongoingShiftsStore.actionList) {
    const defaultActionList = {
      RECORD_SHIFT_TIME: [],
      RECORD_SHIFT_TIME_FAILURE: [],
      RECORD_DISPUTED_TIME: [],
      UPDATE_SHIFT_UNIT: [],
      UPDATE_WORKER_SIGNATURE: [],
      UPLOAD_TIMECARD: [],
      REQUEST_AUTHORIZED_SIGNATURE: [],
      SIGN_WITH_SIGNATURE: [],
      ADD_RATING: [],
      SET_SHIFT_STAGE: [],
    };

    Object.keys(defaultActionList).forEach(function (key, index) {
      const value = defaultActionList[key];
      const foundKey = getKeyInObject(ongoingShiftsStore.actionList, key);
      if (!foundKey) ongoingShiftsStore.actionList[key] = value;
    });
  }

  dispatch({
    type: ActionType.SET_STATE_FROM_LOCAL,
    data: ongoingShiftsStore,
  });
};

const fetchShiftList = async (
  dispatch: Dispatch
): Promise<Shift[] | undefined> => {
  const status = await Network.getStatus();
  if (!status.connected) return;
  const query: FetchAgentShiftRequest = {
    shiftDate: undefined,
    upcoming: true,
  };

  const shiftDetails = await fetchAgentShifts(query);
  if (isEmpty(shiftDetails)) return;

  dispatch({
    type: ActionType.SET_SHIFT_LIST,
    data: shiftDetails,
  });

  return shiftDetails;
};

const fetchShiftInfoList = async (
  dispatch: Dispatch,
  shiftList
): Promise<void> => {
  const status = await Network.getStatus();
  if (!status.connected) return;

  const shiftInfoList: Shift[] = [];

  if (isEmpty(shiftList)) return;

  for (const shift of shiftList) {
    const shiftInfo = await api.shift.fetchShiftInfo(shift._id);
    if (!isEmpty(shiftInfo)) shiftInfoList.push(shiftInfo);
  }

  dispatch({
    type: ActionType.SET_SHIFT_INFO_LIST,
    data: shiftInfoList,
  });
};

const hitRecordShiftTime = (dispatch: Dispatch, paramsList): Promise<void> =>
  promiseInSequence("hitRecordShiftTime", async (): Promise<void> => {
    if (isEmpty(paramsList)) return;

    const paramsExecuted: any[] = [];
    for (const query of paramsList) {
      const updatedShift = await recordShiftTime(
        query.shiftId,
        query.stage,
        query.location,
        query.timecard,
        query.locationType,
        query.appType,
        query.connectivityMode
      );
      if (isEmpty(updatedShift)) return;

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: query.shiftId,
          updatedShift,
        },
      });
      paramsExecuted.push(query);
    }
    filterParams(dispatch, paramsExecuted, ActionType.FILTER_RECORD_SHIFT_TIME);
  });

const hitRecordShiftTimeFailure = async (
  dispatch: Dispatch,
  paramsList
): Promise<void> => {
  if (isEmpty(paramsList)) return;

  const paramsExecuted: any[] = [];
  for (const query of paramsList) {
    await createAccountLog(query.failureReason, query.agentId, query.shiftId);

    paramsExecuted.push(query);
  }

  filterParams(
    dispatch,
    paramsExecuted,
    ActionType.FILTER_RECORD_SHIFT_TIME_FAILURE
  );
};

const hitRecordDisputedTime = (dispatch: Dispatch, paramsList): Promise<void> =>
  promiseInSequence("hitRecordDisputedTime", async () => {
    if (isEmpty(paramsList)) return;

    const paramsExecuted: any[] = [];
    for (const query of paramsList) {
      const updatedShift = await recordDisputedTime(
        query.shiftId,
        query.stage,
        query.location
      );
      if (isEmpty(updatedShift)) return;

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: query.shiftId,
          updatedShift,
        },
      });
      paramsExecuted.push(query);
    }
    filterParams(
      dispatch,
      paramsExecuted,
      ActionType.FILTER_RECORD_DISPUTED_TIME
    );
  });

const hitUpdateShiftUnit = (dispatch: Dispatch, paramsList): Promise<void> =>
  promiseInSequence("hitUpdateShiftUnit", async (): Promise<void> => {
    if (isEmpty(paramsList)) return;
    const paramsExecuted: any[] = [];
    for (const query of paramsList) {
      const updatedShift = await updateShiftUnit(query.shiftId, query.unit);
      if (isEmpty(updatedShift)) return;

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: query.shiftId,
          updatedShift,
        },
      });
      paramsExecuted.push(query);
    }
    filterParams(dispatch, paramsExecuted, ActionType.FILTER_UPDATE_SHIFT_UNIT);
  });

const hitUploadTimeCard = (dispatch: Dispatch, paramsList): Promise<void> =>
  promiseInSequence("hitUploadTimeCard", async () => {
    if (isEmpty(paramsList)) return;
    const paramsExecuted: any[] = [];
    for (const query of paramsList) {
      const updatedShift = await uploadTimecard(
        query.selectedFile,
        query.shiftId,
        query.locationType,
        query.appType,
        query.connectivityMode
      );
      if (isEmpty(updatedShift)) return;

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: query.shiftId,
          updatedShift,
        },
      });
      paramsExecuted.push(query);
    }
    filterParams(dispatch, paramsExecuted, ActionType.FILTER_UPLOAD_TIMECARD);
  });

const hitUpdateWorkerSignature = (
  dispatch: Dispatch,
  paramsList
): Promise<void> =>
  promiseInSequence("hitUpdateWorkerSignature", async () => {
    if (isEmpty(paramsList)) return;

    const paramsExecuted: any[] = [];

    for (const query of paramsList) {
      const updatedShift = await updateWorkerSignature(
        query.shiftId,
        query.signature
      );
      if (isEmpty(updatedShift)) return;

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: query.shiftId,
          updatedShift,
        },
      });
      paramsExecuted.push(query);
    }
    filterParams(
      dispatch,
      paramsExecuted,
      ActionType.FILTER_UPDATE_WORKER_SIGNATURE
    );
  });

const hitUAddRating = async (dispatch: Dispatch, paramsList): Promise<void> => {
  if (isEmpty(paramsList)) return;

  const paramsExecuted: any[] = [];

  for (const query of paramsList) {
    const result = await addRating(query.request);
    if (isEmpty(result)) return;
    paramsExecuted.push(query);
  }
  filterParams(dispatch, paramsExecuted, ActionType.FILTER_ADD_RATING);
};

const hitRequestAuthorizedSignature = (
  dispatch: Dispatch,
  paramsList
): Promise<void> =>
  promiseInSequence("hitRequestAuthorizedSignature", async () => {
    if (isEmpty(paramsList)) return;

    const paramsExecuted: any[] = [];

    for (const query of paramsList) {
      const updatedShift = await requestAuthorizedSignature(
        query.shiftId,
        query.signatoryInfo
      );
      if (isEmpty(updatedShift)) return;

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: query.shiftId,
          updatedShift,
        },
      });
      paramsExecuted.push(query);
    }
    filterParams(
      dispatch,
      paramsExecuted,
      ActionType.FILTER_REQUEST_AUTHORIZED_SIGNATURE
    );
  });

const hitSignWithSignature = (dispatch: Dispatch, paramsList): Promise<void> =>
  promiseInSequence("hitSignWithSignature", async () => {
    if (isEmpty(paramsList)) return;

    const paramsExecuted: any[] = [];

    for (const query of paramsList) {
      const updatedShift = await signWithSignature(
        query.shiftId,
        query.signatoryInfo,
        query.signature
      );
      if (isEmpty(updatedShift)) return;

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: query.shiftId,
          updatedShift,
        },
      });
      paramsExecuted.push(query);
    }
    filterParams(
      dispatch,
      paramsExecuted,
      ActionType.FILTER_SIGN_WITH_SIGNATURE
    );
  });

const hitSetShiftStage = async (
  dispatch: Dispatch,
  paramsList
): Promise<void> => {
  if (isEmpty(paramsList)) return;

  const paramsExecuted: any[] = [];

  for (const query of paramsList) {
    const updatedShift = await setShiftStage(
      query.shiftId,
      query.clockInOut,
      query.lunchInOut
    );
    if (isEmpty(updatedShift)) return;

    dispatch({
      type: ActionType.UPDATE_SHIFT,
      data: {
        shiftId: query.shiftId,
        updatedShift: query.shift,
      },
    });
    paramsExecuted.push(query);
  }
  filterParams(dispatch, paramsExecuted, ActionType.FILTER_SET_SHIFT_STAGE);
};

const actionRecordShiftTimeFailure =
  (failureReason: string, agent: Agent, shiftId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const status = await Network.getStatus();

    if (!status.connected) {
      dispatch({
        type: ActionType.PUSH_RECORD_SHIFT_TIME_FAILURE,
        data: {
          failureReason,
          agentId: agent.userId?.toString(),
          shiftId: shiftId,
          timestamp: moment().format(),
        },
      });
    } else {
      await createAccountLog(
        failureReason,
        agent.userId?.toString() as string,
        shiftId
      );
    }
  };

const actionRecordShiftTime =
  (
    shiftId: string,
    stage: ShiftStages,
    location: number[],
    shift: Shift,
    timecard: string,
    locationType: string,
    appType: string
  ) =>
  (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence("actionRecordShiftTime", async () => {
      const status = await Network.getStatus();
      const connectivityMode = status.connected
        ? ConnectionMode.ONLINE
        : ConnectionMode.OFFLINE;

      if (!status.connected) {
        const updateShift = {
          ...shift,
          ...getShiftTimeKey({
            stage,
            location,
            shift,
            timecard,
            locationType,
            appType,
            connectivityMode,
          }),
        };

        if (ShiftStages.CLOCK_OUT) {
          updateShift.time = getShiftTime(
            updateShift.clockInOut,
            updateShift.lunchInOut
          );
        }

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });

        dispatch({
          type: ActionType.PUSH_RECORD_SHIFT_TIME,
          data: {
            shiftId,
            stage,
            location,
            timestamp: moment().format(),
            timecard,
            locationType,
            appType,
            connectivityMode: connectivityMode,
          },
        });
        return true;
      }

      const updatedShift = await recordShiftTime(
        shiftId,
        stage,
        location,
        timecard,
        locationType,
        appType,
        connectivityMode
      );
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });
      return true;
    });

const actionRecordShiftTimeV2 =
  (
    shiftId: string,
    stage: ShiftStages,
    location: number[],
    shift: Shift,
    timecard: string,
    locationType,
    appType,
    connectivityMode,
    onModalClose?: Function
  ) =>
  (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence("actionRecordShiftTimeV2", () =>
      updateShiftAndDispatchAction(
        dispatch,
        shiftId,
        stage,
        location,
        shift,
        timecard,
        locationType,
        appType,
        connectivityMode,
        onModalClose
      )
    );

const updateShiftAndDispatchAction = async (
  dispatch: Dispatch,
  shiftId: string,
  stage: ShiftStages,
  location: number[],
  shift: Shift,
  timecard: string,
  locationType,
  appType,
  connectivityMode,
  onModalClose?: Function
): Promise<boolean> =>
  promiseInSequence(
    "updateShiftAndDispatchAction",
    async (): Promise<boolean> => {
      const status = await Network.getStatus();

      if (!status.connected) {
        const updateShift = {
          ...shift,
          ...getShiftTimeKey({
            stage,
            location,
            shift,
            timecard,
            locationType,
            appType,
            connectivityMode,
          }),
        };

        if (ShiftStages.CLOCK_OUT) {
          updateShift.time = getShiftTime(
            updateShift.clockInOut,
            updateShift.lunchInOut
          );
        }

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_RECORD_SHIFT_TIME,
          data: {
            shiftId,
            stage,
            location,
            timestamp: moment().format(),
            timecard,
            locationType,
            appType,
            connectivityMode,
          },
        });
        return true;
      }

      const updatedShift = await recordShiftTimeV2(
        shiftId,
        stage,
        location,
        timecard,
        locationType,
        appType,
        connectivityMode,
        onModalClose
      );
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });

      return true;
    }
  );

const actionRecordDisputedTime =
  (shiftId: string, stage: ShiftStages, location: number[], shift: Shift) =>
  (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence(
      "actionRecordDisputedTime",
      async (): Promise<boolean> => {
        const status = await Network.getStatus();

        if (!status.connected) {
          const now = moment().toDate();
          const geoLocation = {
            type: "Point",
            coordinates: location,
          };

          const disputedTimeout = {
            stamp: now,
            location: geoLocation,
            stage: stage,
          };
          const updateShift = { ...shift, disputedTimeout: disputedTimeout };

          if (stage === ShiftStages.CLOCK_OUT) {
            updateShift.time = getShiftTime(
              updateShift.clockInOut,
              updateShift.lunchInOut
            );
          }
          dispatch({
            type: ActionType.UPDATE_SHIFT,
            data: {
              shiftId: shiftId,
              updatedShift: updateShift,
            },
          });
          dispatch({
            type: ActionType.PUSH_RECORD_DISPUTED_TIME,
            data: {
              shiftId,
              stage,
              location,
              timestamp: moment().format(),
            },
          });
          return true;
        }

        const updatedShift = await recordDisputedTime(shiftId, stage, location);

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift,
          },
        });
        return true;
      }
    );

const actionUpdateTimecard =
  (shift: Shift) =>
  (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence("actionUpdateTimecard", async () => {
      const status = await Network.getStatus();

      if (!status.connected) {
        const updateShift = { ...shift };

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shift._id,
            updatedShift: updateShift,
          },
        });

        dispatch({
          type: ActionType.PUSH_SET_SHIFT_STAGE,
          data: {
            shiftId: shift._id,
            shift: shift,
            clockInOut: shift.clockInOut,
            lunchInOut: shift.lunchInOut,
            timestamp: moment().format(),
          },
        });
        return true;
      }

      await setShiftStage(
        shift._id as string,
        shift.clockInOut as TimeRange,
        shift.lunchInOut as TimeRange
      );

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shift._id,
          updatedShift: shift,
        },
      });

      return true;
    });

const actionUpdateShiftUnit =
  (shiftId: string, unit: string) =>
  (dispatch: Dispatch): Promise<Shift> =>
    promiseInSequence("actionUpdateShiftUnit", async () => {
      const status = await Network.getStatus();

      if (!status.connected) {
        const updateShift = { _id: shiftId, unit: unit };
        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_UPDATE_SHIFT_UNIT,
          data: {
            shiftId,
            unit,
            timestamp: moment().format(),
          },
        });
        return updateShift;
      }
      const updatedShift = await updateShiftUnit(shiftId, unit);

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });
      return updatedShift;
    });

const actionUploadTimeCard =
  (
    selectedFile: string,
    shiftId: string,
    locationType: string,
    appType: string
  ) =>
  (dispatch: Dispatch, getState: GetState): Promise<Timecard | {}> =>
    promiseInSequence("actionUploadTimeCard", async () => {
      const status = await Network.getStatus();
      const connectivityMode = status.connected
        ? ConnectionMode.ONLINE
        : ConnectionMode.OFFLINE;
      if (!status.connected) {
        const updateShift = {
          files: [{ _id: shiftId, url: selectedFile }],
          _id: shiftId,
          status: "UNVERIFIED",
        };
        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_UPLOAD_TIMECARD,
          data: {
            shiftId,
            selectedFile,
            timestamp: moment().format(),
            locationType,
            appType,
            connectivityMode,
          },
        });
        return updateShift;
      }

      const uploadTimecardResponse = await uploadTimecard(
        selectedFile,
        shiftId,
        locationType,
        appType,
        connectivityMode
      );

      // Loose equality since shift type already has a 'status' property defined as a string that we don't want to break.
      if (uploadTimecardResponse?.status === 200) {
        logEvent(USER_EVENTS.SUBMITTED_TIMECARD_PHOTO), { instant_pay: false };
      }
      const {
        body: {
          response: { timecard },
        },
      } = uploadTimecardResponse;
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift: timecard,
        },
      });
      return timecard;
    });

const actionUploadTimeCardV2 =
  (selectedFile: string, shiftId: string) =>
  (dispatch: Dispatch, getState: GetState): Promise<TimecardV2 | {}> =>
    promiseInSequence("actionUploadTimeCardV2", async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        const updateShift = {
          files: [{ _id: shiftId, url: selectedFile }],
          _id: shiftId,
          status: "UNVERIFIED",
        };
        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_UPLOAD_TIMECARD,
          data: {
            shiftId,
            selectedFile,
            timestamp: moment().format(),
          },
        });
        return updateShift;
      }
      const updatedShift = await uploadTimecardV2(selectedFile, shiftId);
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });
      return updatedShift;
    });

const submitShiftTimeCard =
  (
    selectedFile: string,
    submitClockInOut: TimeRange,
    submitLunchInOut: TimeRange,
    shiftId: string
  ) =>
  (dispatch: Dispatch, getState: GetState): Promise<TimecardV2 | {}> =>
    promiseInSequence("submitUploadTimeSheet", async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        const updateShift = {
          files: [{ _id: shiftId, url: selectedFile }],
          _id: shiftId,
          status: "UNVERIFIED",
        };
        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_UPLOAD_TIMECARD,
          data: {
            shiftId,
            selectedFile,
            submitClockInOut,
            submitLunchInOut,
            timestamp: moment().format(),
          },
        });
        return updateShift;
      }
      const updatedShift = await uploadSubmittedTimeSheet(
        selectedFile,
        submitClockInOut,
        submitLunchInOut,
        shiftId
      );
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });
      return updatedShift;
    });

const actionUpdateWorkerSignature =
  (shiftId: string, signature: string, shift: Shift) =>
  (dispatch: Dispatch): Promise<Shift> =>
    promiseInSequence("actionUpdateWorkerSignature", async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        const updateShift = { _id: shiftId, time: shift.time };

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_UPDATE_WORKER_SIGNATURE,
          data: {
            shiftId,
            signature,
            timestamp: moment().format(),
          },
        });
        return updateShift;
      }

      const updatedShift = await updateWorkerSignature(shiftId, signature);

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });
      return updatedShift;
    });

const actionAddRating =
  (request) =>
  async (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence("actionAddRating", async () => {
      const status = await Network.getStatus();
      const doesFacilityReviewExists = request.shift && request.shift.rating;
      if (!status.connected) {
        let updateShift;
        if (doesFacilityReviewExists) {
          updateShift = {
            rating: {
              ...request.shift.rating,
              AGENT: request.rating,
            },
          };
        } else {
          updateShift = {
            rating: {
              FACILITY: request.rating,
            },
          };
        }

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: request.shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_ADD_RATING,
          data: {
            request,
            timestamp: moment().format(),
          },
        });
        return true;
      }
      const updatedShift = await addRating(request);

      if (updatedShift.status === 200 && !doesFacilityReviewExists) {
        logEvent(USER_EVENTS.SUBMITTED_FACILITY_REVIEW);
      }

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: request.shiftId,
          updatedShift,
        },
      });
      if (updatedShift["appRatingStatus"]) {
        dispatch({
          type: sessionActionType.UPDATE_AGENT,
          data: {
            agent: { appRatingStatus: updatedShift["appRatingStatus"] },
          },
        });
      }

      return true;
    });

const actionAddRatingV2 =
  (request) =>
  async (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence("actionAddRatingV2", async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        let updateShift;
        if (request.shift && request.shift.rating) {
          updateShift = {
            rating: {
              ...request.shift.rating,
              AGENT: request.rating,
            },
          };
        } else {
          updateShift = {
            rating: {
              FACILITY: request.rating,
            },
          };
        }

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: request.shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_ADD_RATING,
          data: {
            request,
            timestamp: moment().format(),
          },
        });
        return true;
      }
      const updatedShift = await addRatingV2(request);
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: request.shiftId,
          updatedShift,
        },
      });
      return true;
    });

const actionRequestAuthorizedSignature =
  (shiftId: string, signatoryInfo, shift: Shift) =>
  async (dispatch: Dispatch): Promise<Shift> =>
    promiseInSequence("actionRequestAuthorizedSignature", async () => {
      const status = await Network.getStatus();

      if (!status.connected) {
        const updateShift = {
          _id: shiftId,
          signatory: {},
          requestedSignatories: [
            {
              ...signatoryInfo,
              signatoryId: signatoryInfo._id,
              status: "REQUESTED",
            },
          ],
          signed: false,
          time: shift.time,
        };

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });

        dispatch({
          type: ActionType.PUSH_REQUEST_AUTHORIZED_SIGNATURE,
          data: {
            shiftId,
            signatoryInfo,
            timestamp: moment().format(),
          },
        });

        return updateShift;
      }

      const updatedShift = await requestAuthorizedSignature(
        shiftId,
        signatoryInfo
      );

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });
      return updatedShift;
    });

const actionSignWithSignature =
  (
    shiftId: string,
    signatoryInfo: AuthorizedSignatory,
    signature: string,
    shift: Shift
  ) =>
  async (dispatch: Dispatch): Promise<Shift> =>
    promiseInSequence("actionSignWithSignature", async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        const updateShift = {
          _id: shiftId,
          time: shift.time,
          signed: true,
          signatory: {
            ...signatoryInfo,
            method: "MANUAL_SIGNATORY",
            signature: signature,
            signedAt: moment().format(),
          },
        };

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_SIGN_WITH_SIGNATURE,
          data: {
            shiftId,
            signatoryInfo,
            signature,
            timestamp: moment().format(),
          },
        });
        return updateShift;
      }
      const updatedShift = await signWithSignature(
        shiftId,
        signatoryInfo,
        signature
      );

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });
      return updatedShift;
    });

const actionFetchShiftInfo =
  (shiftId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const status = await Network.getStatus();
    if (!status.connected) return;
    const shiftInfo = await api.shift.fetchShiftInfo(shiftId);
    if (isEmpty(shiftInfo)) return;

    dispatch({
      type: ActionType.UPDATE_SHIFT,
      data: {
        shiftId: shiftId,
        updatedShift: shiftInfo,
      },
    });
  };

const actionFetchAgentShifts =
  (query: FetchAgentShiftRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    const status = await Network.getStatus();
    if (!status.connected) return;
    const shiftDetails = await fetchAgentShifts(query);
    if (isEmpty(shiftDetails)) return;

    dispatch({
      type: ActionType.SET_SHIFT_LIST,
      data: shiftDetails,
    });
    fetchShiftInfoList(dispatch, shiftDetails);
  };

const actionFetchAgentShiftsV2 =
  (query: FetchAgentShiftRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    const status = await Network.getStatus();
    if (!status.connected) return;
    const shiftDetails = await fetchAgentShiftsV2(query);
    if (isEmpty(shiftDetails)) return;

    dispatch({
      type: ActionType.SET_SHIFT_LIST,
      data: shiftDetails.response,
    });
    fetchShiftInfoList(dispatch, shiftDetails.response);
  };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const filterParams = (dispatch, paramsExecuted, type) => {
  paramsExecuted.map((param) => {
    return dispatch({
      type: type,
      data: {
        timestamp: param.timestamp,
      },
    });
  });
};

export {
  init,
  actionRecordShiftTime,
  actionRecordShiftTimeFailure,
  actionRecordDisputedTime,
  actionUpdateShiftUnit,
  actionUploadTimeCard,
  actionUpdateWorkerSignature,
  actionAddRating,
  actionRequestAuthorizedSignature,
  actionSignWithSignature,
  actionFetchShiftInfo,
  actionFetchAgentShifts,
  ONGOING_SHIFT_STORE_KEY,
  actionUpdateTimecard,
  actionUploadTimeCardV2,
  actionFetchAgentShiftsV2,
  actionAddRatingV2,
  actionRecordShiftTimeV2,
  submitShiftTimeCard,
};
