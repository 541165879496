import "firebase/analytics";

import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { isPlatform } from "@ionic/core";
import firebase from "firebase/app";
import { forEach } from "lodash";

class FirebaseAppAnalytics {
  private analytics!: firebase.analytics.Analytics;
  init(firebaseConfig: any) {
    if (!isPlatform("capacitor")) {
      let FirebaseApp: firebase.app.App;
      if (!firebase.apps.length) {
        FirebaseApp = firebase.initializeApp(firebaseConfig);
      } else {
        FirebaseApp = firebase.app();
      }
      this.analytics = FirebaseApp.analytics();
    } else {
      FirebaseAnalytics.setEnabled(true);
    }
  }

  setUserProperties(options: any) {
    if (isPlatform("capacitor")) {
      FirebaseAnalytics.setUserProperty("name", String(options.name));

      forEach(options.userInfo, (value, key) => {
        FirebaseAnalytics.setUserProperty(String(key), String(value));
      });
    } else {
      this.analytics.setUserId(options.userId);
      this.analytics.setUserProperties({
        name: options.name,
        ...options.userInfo,
      });
    }
  }

  setScreenName(screenName: string) {
    if (isPlatform("capacitor")) {
      FirebaseAnalytics.setCurrentScreen(screenName);
    } else {
      this.analytics && this.analytics.setCurrentScreen(screenName);
    }
  }

  logEvent(eventName: string, options?: any) {
    if (isPlatform("capacitor")) {
      FirebaseAnalytics.logEvent(eventName, options);
    } else {
      this.analytics?.logEvent(eventName, options);
    }
  }
}

const firebaseAppAnalytics = new FirebaseAppAnalytics();

export { firebaseAppAnalytics };
