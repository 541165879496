import { Shift } from "src/lib/interface";
import { ActionType, ChatChannel, ChatStore } from "./model";
import moment from "moment-timezone";

const initialState: ChatStore = {
  channels: [],
  upcomingShifts: {},
};

const sortChannels = (list: ChatChannel[]) => {
  return list.sort((a: ChatChannel, b: ChatChannel) => {
    const a1 = a.lastMessage ? a.lastMessage.createdAt : a.createdAt;
    const b1 = b.lastMessage ? b.lastMessage.createdAt : b.createdAt;
    if (a.unreadMessageCount && b.unreadMessageCount) return b1 - a1;

    if (a.unreadMessageCount) return -1;
    if (b.unreadMessageCount) return 1;

    if (a.shift && b.shift) return moment(a.shift.start).diff(b.shift.start);
    if (a.shift) return -1;
    if (b.shift) return 1;

    return b1 - a1;
  });
};

const setMetadata = (list: ChatChannel[]) => {
  for (const item of list) {
    const { facilityName, hcpName, lastBooked } = item.getCachedMetaData() as {
      facilityName?: string;
      hcpName?: string;
      lastBooked?: string;
    };
    item.metadata = {
      facilityName,
      hcpName,
      lastBooked,
    };
  }
};

export const chatStore = (state = initialState, action): ChatStore => {
  switch (action.type) {
    case ActionType.SET_CHANNELS:
      const newChannels = action.data.channels;
      setMetadata(newChannels);

      const oldChannels = state.channels;
      return {
        ...state,
        channels: sortChannels(
          newChannels.map((channel) => {
            const oldChannel = oldChannels.find(
              (oldChannel) => oldChannel.url === channel.url
            );
            if (oldChannel) return { ...channel, shift: oldChannel.shift };

            return channel;
          })
        ),
      };
    case ActionType.UPDATE_CHANNEL:
      const updatedChannel = action.data.channel;
      const foundChannel = state.channels.find(
        (item) => item.url === updatedChannel.url
      );
      if (foundChannel) {
        return {
          ...state,
          channels: sortChannels(
            state.channels.map((item) =>
              item.url === updatedChannel.url
                ? { ...item, ...updatedChannel }
                : item
            )
          ),
        };
      } else {
        return {
          ...state,
          channels: sortChannels([...state.channels, updatedChannel]),
        };
      }

    case ActionType.SET_UPCOMING_SHIFTS:
      const shiftsByFacility: { [key: string]: Shift } =
        action.data.shiftsByFacility;

      return {
        upcomingShifts: shiftsByFacility,
        channels: sortChannels(
          state.channels.map((channel) => {
            const shift = shiftsByFacility[channel.url.split("_")[0]];
            if (shift) return { ...channel, shift };

            return channel;
          })
        ),
      };
    default:
      return state;
  }
};
