import { IonCardSubtitle } from "@ionic/react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "@store/index";
import React, { Fragment, useMemo } from "react";
import { formatRate } from "../helper";
import { getShiftTitleString } from "../helper";
import { Shift, InstantPayShiftDetails } from "src/lib/interface";
import { Agent, Facility } from "src/lib/interface";
import {
  getOffsetFormattedTextBetweenTimezones,
  FULL_ZONE_NAMES,
} from "src/lib/utils";

const ShiftTitle: React.VFC<{
  shift: Shift | null;
  onShiftClick: () => void;
  hasMissingDocs?: boolean;
  showMissingDocs?: boolean;
}> = ({
  shift,
  onShiftClick,
  hasMissingDocs = false,
  showMissingDocs = false,
}) => {
  moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();
    return FULL_ZONE_NAMES[abbr] || abbr;
  };

  const history = useHistory();

  const payVersion = useAppSelector((state) => state.session.payVersion);

  const shiftDetails = useMemo(() => {
    if (!shift) {
      return {};
    }
    const { tmz: facilityTimezone } = shift.facility as Facility;
    const { tmz: agentTimezone } = shift.agent || ({} as Agent);
    const shiftStart = moment(shift.start).tz(facilityTimezone),
      shiftEnd = moment(shift.end).tz(facilityTimezone),
      formattedDate = shiftStart.format("dddd, MMMM DD"),
      duration = moment
        .duration(shiftEnd.diff(shiftStart))
        .subtract(30, "minutes")
        .asHours(),
      hours = Math.trunc(duration),
      minutes = Math.trunc((duration - hours) * 60),
      hoursString = `${hours} hrs${minutes ? ` ${minutes} mins` : ""}`,
      formattedDuration = `${shiftStart.format("hh:mm A")} - ${shiftEnd.format(
        "hh:mm A"
      )} (${hoursString})`,
      isInstantPay = shift?.isInstantPay;
    let timezoneInfoText = "";
    if (
      agentTimezone &&
      facilityTimezone &&
      moment(shift.start).tz(agentTimezone).utcOffset() !==
        moment(shift.start).tz(facilityTimezone).utcOffset()
    ) {
      timezoneInfoText = `${moment()
        .tz(facilityTimezone)
        .zoneName()} (${getOffsetFormattedTextBetweenTimezones(
        shift.start as string,
        agentTimezone as string,
        facilityTimezone as string
      )})`;
    }

    return {
      shiftStart,
      isInstantPay,
      duration,
      formattedDate,
      formattedDuration,
      timezoneInfoText,
    };
  }, [shift]);

  // TODO refactor this and payment status into components
  const shiftTitle = useMemo(() => {
    let title = getShiftTitleString({
      shiftStart: shiftDetails.shiftStart,
      shift,
    });
    if (title) {
      const shiftTag = (
        <span className="shift-title shift-title-color">{title}</span>
      );
      const shiftDateTag = (
        <span style={{ fontSize: "18px" }} className="">
          {shiftDetails.formattedDate}
        </span>
      );
      return moment().isAfter(shiftDetails.shiftStart) ? (
        shiftTag
      ) : (
        <span>
          {shiftTag} {shiftDateTag}
        </span>
      );
    }

    return <span className="shift-title">{shiftDetails.formattedDate}</span>;
  }, [shift, shiftDetails]);

  if (!shift) {
    return <Fragment />;
  }

  const instantPayDetails = shiftDetails.isInstantPay
    ? shift.instantPayDetails
    : ({} as InstantPayShiftDetails);

  let instantBookPayText = "";
  if (history.location.pathname.includes("myShift")) {
    instantBookPayText = `$${formatRate(
      instantPayDetails ? instantPayDetails.totalAmount : 0
    )} total`;
  } else {
    instantBookPayText =
      payVersion === "b"
        ? `$${formatRate(shift.finalPay)}/hr total`
        : `$${formatRate(
            instantPayDetails ? instantPayDetails.totalAmount : 0
          )} total`;
  }

  const instantPayPartialAmount = instantPayDetails
    ? instantPayDetails.totalAmount * instantPayDetails.instantPayRate
    : 0;

  const isShiftOngoing = shift && moment().isAfter(shift.start);

  return (
    <div onClick={onShiftClick}>
      <IonCardSubtitle className="no-text-transform">
        {shiftTitle}
      </IonCardSubtitle>
      <IonCardSubtitle className="title no-text-transform">
        {shiftDetails.formattedDuration}
      </IonCardSubtitle>
      {shiftDetails.timezoneInfoText && shiftDetails.timezoneInfoText.length && (
        <IonCardSubtitle
          className="title no-text-transform"
          style={{
            background: "#D88C33",
            borderRadius: "4px",
            width: "fit-content",
            padding: "1px 4px",
            color: "#FFFFFF",
            fontWeight: 600,
          }}
        >
          {shiftDetails.timezoneInfoText}
        </IonCardSubtitle>
      )}
      {shiftDetails.isInstantPay ? (
        <IonCardSubtitle className="title no-text-transform">
          <span className="payment-text-color">{instantBookPayText}</span> |{" "}
          <span className="payment-text-color-instantpay">
            {!shift.instantPayDetails?.is100InstantPayEnabled ? (
              <>${formatRate(instantPayPartialAmount)} </>
            ) : null}
            InstantPay
          </span>
        </IonCardSubtitle>
      ) : (
        <IonCardSubtitle className="title no-text-transform">
          <span className="payment-text-color">
            ${formatRate(shift.finalPay! * shift.time!)} for this shift
          </span>
        </IonCardSubtitle>
      )}
      {showMissingDocs && hasMissingDocs && !isShiftOngoing && (
        <IonCardSubtitle className="title no-text-transform">
          <span className="missing-docs-color">Missing Documents</span>
        </IonCardSubtitle>
      )}
    </div>
  );
};

export { ShiftTitle };
