import React, { useState, FormEvent } from "react";
import {
  IonButton,
  IonContent,
  IonLabel,
  IonInput,
  IonToast,
  IonAlert,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/react";
import { isPlatform } from "@ionic/core";
import { useSelector } from "react-redux";
import { Plugins } from "@capacitor/core";
import { get } from "lodash";

import { Store } from "../../store/store.model";
import { ProfileUpdateStepProps } from "./interface";

import "./style.scss";

const { Keyboard } = Plugins;

const EmailVerification: React.FC<ProfileUpdateStepProps> = ({
  stepFinished,
}) => {
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [verificationError, setVerificationError] = useState<boolean>(false);

  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const [email, setEmail] = useState("");

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();

    try {
      if (isPlatform("capacitor")) Keyboard.hide();
      if (email !== agent?.email) {
        setVerificationError(true);
        return;
      }
      stepFinished();
    } catch (error) {
      setPageError({
        isError: true,
        message: "Unexpected error occurred while verifying ssn",
      });
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const onEmailChange = (event: Event) => {
    const isEmail =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const mail = event.target?.["value"] as string;
    if (!isEmail.test(mail)) return;
    setEmail(mail);
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (event.key === "Enter") {
      onNext(event);
    }
  };
  return (
    <IonContent>
      <IonToast
        isOpen={pageError.isError}
        onDidDismiss={onDismiss}
        message={pageError.message}
        color="danger"
        duration={2000}
        position="top"
      />

      <IonAlert
        mode="ios"
        isOpen={verificationError}
        backdropDismiss={false}
        header="Your email doesn't match"
        message="The email you typed doesn't match our current records. Do you want to try typing it again? Make sure to input the same one you use for your account today."
        buttons={[
          {
            text: "Cancel",
            handler: () => setVerificationError(false),
          },
          {
            text: "Try Again",
            handler: () => setVerificationError(false),
          },
        ]}
      />

      <div className="signup-content content-layout adjust-form-height">
        <div className="form-container">
          <form onSubmit={onNext}>
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>What is your email?</h4>
                </div>

                <div>
                  <IonLabel className="form-label hcp-self-label">
                    Email Address
                  </IonLabel>
                  <IonInput
                    name="email"
                    className="form-input no-border"
                    type="email"
                    placeholder="Email address"
                    onIonChange={onEmailChange}
                    value={email}
                    autofocus
                    required
                    no-border
                  />
                </div>
                <IonText className="profile-update-helper-text ion-text-start ion-margin-top">
                  We are asking you for your email to prevent fraudlent attempts
                  of controlling your account.
                </IonText>
              </IonCol>
            </IonRow>
          </form>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            disabled={!email}
            onClick={onNext}
          >
            Continue
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
};

export { EmailVerification };
