import { formatPhone } from "src/lib/utils";
import { IonAlert } from "@ionic/react";
import React from "react";

import { environment } from "@env/environment";
import { MissingDocumentsItemProps } from "../model";
import { useMissingDocuments } from "../custom-hooks/useMissingDocuments";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "@constants/userEvent";

const MissingDocumentsAlert = ({
  shift,
  isOpen,
  onDidDismiss,
  onConfirm,
}: MissingDocumentsItemProps) => {
  const { loading, missingDocs } = useMissingDocuments(shift);

  const documents = missingDocs;

  if (loading || !documents) return null;

  // Build a documents array which truncates more than 6 documents.
  const truncatedDocuments: string[] = [];
  let i = 0;
  for (const name of documents) {
    if (i >= 6) {
      break;
    }
    truncatedDocuments.push(name.name);
    i++;
  }
  const isPlural = documents.size > 1;
  const hasMoreDocuments = documents.size > 6;

  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      cssClass="shift-item-alert"
      message={`
        <h1><img alt='alert-circle' src='assets/icons/add-circle.svg'/> You're Missing <span class='missing-doc-count'>${
          documents.size
        }</span> Document${isPlural ? "s" : ""}</h1>
        <p>To complete your booking, the following ${
          isPlural ? "documents" : "document"
        } must be uploaded and approved:</p>
        <div class='information'>
          ${
            truncatedDocuments
              .map((name) => `<p>${name}</p>`)
              .join(
                ""
              ) /* As it's not react-jsx and doesn't maintain virtual-dom, no need of key prop. */
          }
        </div>
        ${
          hasMoreDocuments
            ? "<p>Click to upload your documents to view the remaining missing requirements </p>"
            : ""
        }
      `} /** No need wrap above HTML inside a div */
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
          handler: () =>
            logEvent(USER_EVENTS.TAPPED_CANCEL_MISSING_DOCS_POPUP_BUTTON),
        },
        { text: isPlural ? "Upload Docs" : "Upload Doc", handler: onConfirm },
      ]}
    />
  );
};

export { MissingDocumentsAlert };
