import "./style.scss";

import { LogoutButton } from "src/lib/ionic-components";
import { formatPhone } from "src/lib/utils";
import { CallNumber } from "@ionic-native/call-number";
import {
  EmailComposer,
  EmailComposerOptions,
} from "@ionic-native/email-composer";
import { SMS } from "@ionic-native/sms";
import { isPlatform } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { call, chatbubbles, mail } from "ionicons/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { environment } from "../../environments/environment";
import { GlobalRouterPath } from "../routing/constant/globalRoute";
import { logout, setIsSignup } from "../store/session";
import { Store } from "../store/store.model";

const NoUser: React.FC<RouteComponentProps> = ({ history }) => {
  const { isAuthorized, userId } = useSelector((state: Store) => state.session);
  const dispatch = useDispatch();

  if (!isAuthorized) {
    return <Redirect to="/" />;
  }

  if (userId) {
    return <Redirect to={GlobalRouterPath.HOME} />;
  }

  const initSignup = () => {
    setIsSignup(dispatch);
    history.push(GlobalRouterPath.HOME);
  };

  const { support, mobileCode } = environment;

  const callSupport = () => {
    CallNumber.callNumber(`${mobileCode}${support.phone}`, true);
  };

  const textSupport = () => {
    SMS.send(`${mobileCode}${support.phone}`, "", {
      android: { intent: "INTENT" },
    });
  };

  const emailSupport = async () => {
    const email: EmailComposerOptions = {
      to: support.email,
    };
    EmailComposer.open(email);
  };

  const isCapacitor = isPlatform(window, "capacitor");

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonTitle>No Details Found</IonTitle>
          <IonButtons slot="end">
            <LogoutButton color="light" mode="ios" logout={logout} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="not-found-content ion-padding ion-text-center">
        <IonRow>
          <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
            <div className="text-container text-with-points">
              <h4>We couldn't find user details!</h4>
              <ul>
                <li>
                  If you have initiated Signup Process, please{" "}
                  <IonButton type="button" onClick={initSignup}>
                    Go to Signup Page
                  </IonButton>
                </li>
                <li>
                  If you are an existing user, try log out and login again
                </li>
                <li>
                  If issue still persists, reach out to Clipboard Support Team
                </li>
              </ul>
            </div>
          </IonCol>
        </IonRow>
        <IonList className="list-items-custom">
          <IonItem>
            <IonLabel>
              <h4>{support.email}</h4>
              <p>Email</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h4>{formatPhone(support.phone)}</h4>
              <p>Phone</p>
            </IonLabel>
          </IonItem>
        </IonList>
        {isCapacitor && (
          <div className="ion-text-center">
            <IonButton color="primary" onClick={callSupport}>
              <IonIcon icon={call} mode="ios" />
            </IonButton>
            <IonButton color="primary" onClick={textSupport}>
              <IonIcon icon={chatbubbles} />
            </IonButton>
            <IonButton color="primary" onClick={emailSupport}>
              <IonIcon icon={mail} />
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export { NoUser };
