import { IonInput } from "@ionic/react";
import React, { useEffect, useRef } from "react";

import {
  getLocationFromPlace,
  parseAddressComponents,
} from "../helpers/googleMaps";

const PlacesAutoComplete = ({
  defaultValue,
  onChange,
  googleApiKey,
  className = "",
}) => {
  const searchInput = useRef<HTMLIonInputElement>(null);

  let autoComplete: google.maps.places.Autocomplete;

  const onPlaceChanged = () => {
    const place = autoComplete.getPlace();

    if (!place.geometry) {
      return;
    }

    const location = getLocationFromPlace(place);
    const address = parseAddressComponents(place.address_components);
    address.formatted = place.formatted_address;
    onChange({ location, address });
  };

  useEffect(() => {
    async function addressInit() {
      const mapApi = window.google.maps;
      const input = searchInput.current;
      const inpEle = await input?.getInputElement();
      autoComplete = new mapApi.places.Autocomplete(inpEle as HTMLInputElement);
      autoComplete.addListener("place_changed", onPlaceChanged);
    }
    addressInit();
  }, []);

  return (
    <IonInput
      placeholder="Search for area, street name..."
      debounce={1000}
      ref={searchInput}
      clearInput={true}
      autofocus={!defaultValue}
      value={defaultValue}
      className={className}
    />
  );
};

export { PlacesAutoComplete };
