import { Types } from "mongoose";

const { ObjectId } = Types;

/**
 * Takes string or mongoose object id and checks if both of them are equal.
 *
 * @param {string | ObjectId} sourceObj Source object which is used as the matcher
 */
const isObjectIdEqual = (sourceObj) => {
  const objectId = new ObjectId(sourceObj);
  return (newObj) => objectId.equals(newObj);
};

const isValidObjectId = (objectId): boolean => ObjectId.isValid(objectId);

export { isObjectIdEqual, isValidObjectId };
