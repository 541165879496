import { useMutation, useQuery } from "@apollo/client";
import { TabRouterPath } from "@app/routing/constant/tabRoute";
import { CameraSource } from "@capacitor/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { SelectedFile } from "../shiftSignature/timecard/model";
import { setSelectedRequirement } from "../store/documents";
import { Store } from "../store/store.model";
import { urlQueryString } from "../utils/urlQueryHelper";
import {
  fireDocumentReviewZapierHook,
  updateCache,
  uploadSelectedDocument,
} from "./api";
import {
  ExpiryType,
  HcpRequirementResponse,
  Requirement,
} from "@store/documents/model";
import { keyBy } from "lodash";
import { isImage } from "../utils/isImage";
import { constructPDFFromMultipleImages } from "../utils/constructPDFFromMultipleImages";
import { DocumentPreview } from "./documentPreview";
import { DocumentViewFooter } from "./documentViewFooter";
import {
  CREATE_HCP_DOCUMENT,
  GET_FILTERED_DOCUMENTS_URL,
  GET_HCP_REQUIREMENTS,
  GET_REJECTED_DOCUMENT,
  GET_REQUIREMENTS_STATUS,
} from "./gql";
import { RequirementStatusData, RequirementTypes } from "./model";
import { CustomContainer } from "./style";
import { ActionType } from "@store/session";
import { chevronBackOutline, cloudUpload } from "ionicons/icons";
import { DocumentStatus, HcpDocument } from "@store/documents/model";
import moment from "moment-timezone";
import { URLTextRenderer } from "./URLTextRenderer";
import { getParsedURLText } from "src/lib/utils";
import { hcpAppLogger } from "../remoteLoggers";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "@constants/userEvent";
import { capturePhoto, iosFileChooser } from "@app/utils/mediaUpload";
import { FeatureFlag } from "../../constants/FEATURE_FLAGS";

const PREVIEW_SHOWING_DOCUMENT_TYPES = [
  RequirementTypes.EXPIRED,
  RequirementTypes.REJECTED,
  RequirementTypes.OPTIONAL,
  RequirementTypes.PENDING,
  RequirementTypes.COMPLETED,
];

const DocumentView: React.FC<RouteComponentProps> = () => {
  /*
   * Covid documents expire based on HCF requirements.
   * So, will send parameter if a HCP to HCF mapping requires a new Covid Document Upload.
   **/
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const {
    requirement,
    requirementStatus,
    isDocReplacement,
    showRejectionAlert,
    docUploadDate,
    updateProfile,
  } = urlQueryString(search);
  const [createHcpDocument] = useMutation(CREATE_HCP_DOCUMENT);
  const { userId: hcpId, profile } = useSelector(
    (state: Store) => state.session
  );
  const { selectedRequirement } = useSelector(
    (state: Store) => state.documentStore
  );

  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [covidTestDate, setCovidTestDate] = useState("");
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });

  const ldFlags = useFlags();
  const cameraImageQuality = ldFlags[FeatureFlag.CAMERA_IMAGE_QUALITY];
  const canUploadMultiplePages = isImage(selectedFiles[0]?.type);
  const selectedFilesExist = selectedFiles.length > 0;

  const { data: hcpDocument } = useQuery(GET_REJECTED_DOCUMENT, {
    variables: {
      hcpId,
      requirementId: selectedRequirement?.requirement?.reqId,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: hcpRequirements, loading: hcpRequirementLoading } = useQuery(
    GET_REQUIREMENTS_STATUS,
    {
      variables: { hcpId },
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: fulfilledRequirements, loading: fullFilledRequirementLoading } =
    useQuery(GET_HCP_REQUIREMENTS, {
      variables: {
        hcpId,
        filter: { requirementId: selectedRequirement?.requirement?.reqId },
      },
      fetchPolicy: "cache-and-network",
    });

  let requirementExpiryType, requirementExpiryDate;
  if (
    !fullFilledRequirementLoading &&
    fulfilledRequirements?.hcpRequirementList?.length
  ) {
    const fulfilledRequirement = fulfilledRequirements.hcpRequirementList[0];
    requirementExpiryDate = fulfilledRequirement?.expiry;
    requirementExpiryType = fulfilledRequirement?.baseRequirement?.expiryType;
  }

  const { data: { documentList } = { documentList: [] } } = useQuery<{
    documentList: HcpDocument[];
  }>(GET_FILTERED_DOCUMENTS_URL, {
    variables: {
      hcpId,
      filter: {
        fulfilledRequirementId: selectedRequirement?.requirement?.reqId,
        ...(requirementStatus === RequirementTypes.COMPLETED && {
          status: DocumentStatus.APPROVED,
        }),
      },
      limit: 1,
      sort: { createdAt: -1 },
    },
    fetchPolicy: "cache-and-network",
  });

  const { rejectedDocument } = hcpDocument || {};

  useEffect(() => {
    if (hcpRequirementLoading || !requirement || !requirementStatus) return;
    const { hcpRequirementStatus: { requirements } = { requirements: [] } } =
      hcpRequirements || {};
    const requirementsById = keyBy(requirements, "reqId");
    if (requirementsById[requirement]) {
      dispatch(
        setSelectedRequirement(requirementsById[requirement], requirementStatus)
      );
    }
  }, [requirement, hcpRequirementLoading, requirementStatus]);

  useEffect(() => {
    if (!selectedRequirement || !rejectedDocument) return;
    const isRequirementCompletedOrExpired =
      requirementStatus === RequirementTypes.COMPLETED ||
      requirementStatus === RequirementTypes.EXPIRED;
    if (
      isRequirementCompletedOrExpired &&
      showRejectionAlert &&
      docUploadDate
    ) {
      dispatch({
        type: ActionType.SHOW_REPLACEMENT_DOCUMENT_REJECTED_POPUP,
        data: {
          documentName: selectedRequirement?.requirement?.name,
          note: rejectedDocument?.notification?.note,
          docUploadDate,
        },
      });
    }
  }, [
    requirementStatus,
    showRejectionAlert,
    docUploadDate,
    selectedRequirement,
    rejectedDocument,
  ]);

  useEffect(() => {
    setSelectedFiles([]);
    setUploaded(false);
  }, [requirementStatus]);

  const handleFileUpload = async () => {
    setUploading(true);
    try {
      let documentFile;
      if (canUploadMultiplePages && selectedFiles.length > 1) {
        const base64EncodedPDF = await constructPDFFromMultipleImages(
          selectedFiles
        );
        documentFile = { file: base64EncodedPDF, type: "PDF" };
      } else {
        documentFile = selectedFiles[0];
      }

      if (!documentFile) return;

      const { name, url } = await uploadSelectedDocument(documentFile, {
        ...selectedRequirement,
        requirement: selectedRequirement?.requirement as Requirement,
      });
      const covidTest =
        new Date(covidTestDate).toString() === "Invalid Date"
          ? null
          : covidTestDate;
      await createHcpDocument({
        variables: {
          name,
          hcpId,
          fileUrl: url,
          uploadedFor: selectedRequirement?.requirement?.reqId,
          covidTest,
        },
        update: updateCache({ covidTest, hcpId, selectedRequirement }),
      });
      fireDocumentReviewZapierHook(hcpId as string, {
        document: selectedRequirement?.requirement as Requirement,
        url,
      });
      const { pathname = "", search = "" } = history.location || {};
      history.replace(`${pathname}${search}`);
      setUploaded(true);
      if (updateProfile === "true") {
        logEvent(USER_EVENTS.PROFILE_CHANGE_COMPLETED, {
          type: "LICENSE",
        });
      }
      dispatch(
        setSelectedRequirement(
          selectedRequirement?.requirement as unknown as HcpRequirementResponse,
          RequirementTypes.PENDING
        )
      );
      logEvent(USER_EVENTS.SUCCESSFULLY_UPLOADED_DOCUMENT);
    } catch (error) {
      setPageError({
        isError: true,
        message: `Could not upload file. ${error.message}`,
      });
      hcpAppLogger({
        logType: "ERROR",
        title: `[handleFileUpload] Could not upload document file for hcp id: ${hcpId}, requirement: ${selectedRequirement?.requirement?.name}, id: ${selectedRequirement?.requirement?._id}, reqId: ${selectedRequirement?.requirement?.reqId}`,
        logArea: "SERVER",
        featureName: "SIGNUP",
        hcpEmail: profile?.email,
        details: {
          error: error?.message,
          errorStack: error?.stack,
        },
      });
      logEvent(USER_EVENTS.DOCUMENT_UPLOAD_FAILED);
    } finally {
      setUploading(false);
    }
  };

  const openGallery = async (source: CameraSource) => {
    const { file, type } = await capturePhoto(cameraImageQuality || 85, source);
    setSelectedFiles([...selectedFiles, { file, type }]);
  };

  const getFileFromSystem = (event) => {
    event.preventDefault();
    if (!event.target.files) return;

    const reader = new FileReader();
    const {
      target: {
        files: [file],
      },
    } = event;
    const [, type] = file.type.split("/");

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSelectedFiles([...selectedFiles, { file: reader.result, type }]);
    };
    event.target.value = null;
  };

  const handleSelectDocumentClick = async () => {
    if (isPlatform("ios")) {
      const { file, type } = await iosFileChooser();
      setSelectedFiles([...selectedFiles, { file, type }]);
      return;
    }
    inputFileRef?.current?.click();
  };

  const handleTakePhotoClick = (source: CameraSource) => {
    const isNative = isPlatform("capacitor");
    return isNative ? openGallery(source) : inputFileRef?.current?.click();
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  if (!selectedRequirement || !selectedRequirement.requirement) {
    return (
      <IonPage>
        <IonHeader no-border>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                text=""
                defaultHref="/home/account/documents"
                mode="ios"
              />
            </IonButtons>
            <IonTitle>No Document Selected</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonButtons>
              <IonBackButton
                color="primary"
                text="Go Back"
                defaultHref="/home/account/documents"
                mode="md"
              />
            </IonButtons>
          </IonItem>
        </IonContent>
      </IonPage>
    );
  }

  const isEdit = [
    RequirementTypes.EXPIRED,
    RequirementTypes.MISSING,
    RequirementTypes.REJECTED,
    RequirementTypes.MISCELLANEOUS,
    RequirementTypes.OPTIONAL,
    RequirementTypes.REQUIRED_BY_FACILITIES,
  ].includes(selectedRequirement?.requirementType as string);

  const handleReplacementLink = () => {
    try {
      const params = new URLSearchParams();
      // @ts-ignore
      params.set("requirement", selectedRequirement?.requirement?.reqId);
      params.set("requirementStatus", RequirementTypes.MISSING);
      params.set("isDocReplacement", "true");
      history.push(`${TabRouterPath.DOCUMENT_VIEW}?${params.toString()}`);
    } catch (error) {
      setPageError({
        isError: true,
        message: "Something went wrong, please try again",
      });
    }
  };

  const onBackButtonPressed = () => {
    if (selectedFilesExist && isDocReplacement && uploaded) {
      history.go(-2);
    } else {
      history.goBack();
    }
  };

  const onDeleteDocument = () => {
    dispatch({
      type: ActionType.SHOW_DELETE_DOCUMENT_POPUP,
      data: { documentId: documentList[0]?._id },
    });
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              style={{ "--padding-start": 0, "--padding-end": 0 }}
              onClick={onBackButtonPressed}
            >
              <IonIcon mode="ios" icon={chevronBackOutline} size="large" />
            </IonButton>
          </IonButtons>
          <IonTitle>
            {isEdit ? "Upload New Document" : "View Document"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
          position="top"
        />
        <IonList>
          <IonItem>
            <IonLabel className="ion-text-wrap">
              <h4>Document Name</h4>
              <p>{selectedRequirement.requirement.name}</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-wrap">
              <h4>
                Status :{" "}
                {
                  RequirementStatusData[
                    selectedRequirement.requirementType as string
                  ].status
                }
              </h4>
              <p>
                {
                  RequirementStatusData[
                    selectedRequirement.requirementType as string
                  ].description
                }
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-wrap">
              <h4>Description</h4>
              <p>
                {selectedRequirement.requirement.description ? (
                  <URLTextRenderer
                    URLTextComponents={getParsedURLText(
                      selectedRequirement.requirement.description
                    )}
                  />
                ) : (
                  "No Description given"
                )}
              </p>
            </IonLabel>
          </IonItem>
          {isEdit && (
            <IonItem>
              <IonLabel className="ion-text-wrap">
                <h4>Instructions</h4>
                <p>
                  {selectedRequirement.requirement.instructions ? (
                    <URLTextRenderer
                      URLTextComponents={getParsedURLText(
                        selectedRequirement.requirement.instructions
                      )}
                    />
                  ) : (
                    `Please take or upload a picture of your ${selectedRequirement.requirement.name}`
                  )}
                </p>
              </IonLabel>
            </IonItem>
          )}
          {[RequirementTypes.EXPIRED, RequirementTypes.COMPLETED].includes(
            requirementStatus
          ) &&
            requirementExpiryDate &&
            ExpiryType[requirementExpiryType] !==
              ExpiryType.DOES_NOT_EXPIRE && (
              <IonItem>
                <IonLabel className="ion-text-wrap">
                  <IonText
                    color={
                      moment(requirementExpiryDate).isSameOrBefore(moment())
                        ? "danger"
                        : ""
                    }
                  >
                    <h4>Expiration Date</h4>
                  </IonText>
                  <IonText
                    color={
                      moment(requirementExpiryDate).isSameOrBefore(moment())
                        ? "danger"
                        : ""
                    }
                  >
                    <p>{moment(requirementExpiryDate).format("MM/DD/YYYY")}</p>
                  </IonText>
                </IonLabel>
              </IonItem>
            )}
          {selectedRequirement?.requirementType === RequirementTypes.REJECTED &&
            rejectedDocument?.notification?.note && (
              <IonItem>
                <IonLabel className="ion-text-wrap">
                  <IonText color="danger">
                    <h4>This document was rejected</h4>
                    <p>{rejectedDocument?.notification?.note}</p>
                  </IonText>
                </IonLabel>
              </IonItem>
            )}
        </IonList>

        {selectedFilesExist && !canUploadMultiplePages && (
          <IonCard style={{ boxShadow: "none", padding: "0 10px" }}>
            <CustomContainer>Document</CustomContainer>
          </IonCard>
        )}

        {selectedFilesExist && canUploadMultiplePages && (
          <Fragment>
            {selectedFiles.map((selectedFile) => {
              return (
                <IonCard>
                  {isImage(selectedFile.type) ? (
                    <img alt="timecard" src={selectedFile.file} />
                  ) : (
                    <IonText> Image could not be displayed </IonText>
                  )}
                </IonCard>
              );
            })}
          </Fragment>
        )}

        {selectedRequirement &&
          !selectedFilesExist &&
          !isDocReplacement &&
          PREVIEW_SHOWING_DOCUMENT_TYPES.includes(
            selectedRequirement.requirementType as string
          ) && (
            <DocumentPreview
              document={documentList[0]}
              setPageError={setPageError}
            />
          )}
      </IonContent>
      {isEdit && !uploaded && (
        <DocumentViewFooter
          selectedFiles={selectedFiles}
          allowMultipleUpload={canUploadMultiplePages}
          uploading={uploading}
          handleFileUpload={handleFileUpload}
          handleTakePhotoClick={handleTakePhotoClick}
          handleSelectDocumentClick={handleSelectDocumentClick}
          disableUpload={false}
        />
      )}
      {selectedRequirement?.requirementType === RequirementTypes.COMPLETED && (
        <IonButton
          expand="block"
          color="primary"
          onClick={handleReplacementLink}
        >
          <IonIcon slot="start" icon={cloudUpload} mode="ios" />
          Upload a replacement
        </IonButton>
      )}
      {selectedRequirement?.requirementType === RequirementTypes.PENDING && (
        <IonButton color="light" mode="ios" onClick={onDeleteDocument}>
          <IonText>
            <h5 style={{ color: "#727272", borderBottom: "1px solid #727272" }}>
              Delete
            </h5>
          </IonText>
        </IonButton>
      )}
      <input
        type="file"
        style={{ position: "absolute", top: "200%", left: "200%" }}
        onChange={getFileFromSystem}
        ref={inputFileRef}
      />
    </IonPage>
  );
};

export { DocumentView };
