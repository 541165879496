import { useState, useEffect } from "react";
import { IonButton, IonRow } from "@ionic/react";
import {
  USER_EVENTS,
  SEARCH_MODE,
  HIDDEN_SHIFT_MODAL_TTL,
} from "src/constants";
import { ShiftFiltersInterface } from "./interface";
import { DistancePreference } from "./distancePreference";
import { MinPayPreference } from "./minPayPreference";
import { HiddenShiftsModal } from "./hiddenShiftsModal";
import { logEvent } from "src/lib/analytics";
import { get } from "lodash";
import { Store } from "src/app/store/store.model";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";

const hiddenShiftsModalShownLocalStorage = (key = "hiddenShiftsModalShown") => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return false;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return false;
  }
  return true;
};

export const ShiftFilters = ({
  openShiftsEmpty,
  totalShifts,
  hiddenShifts,
  baseRate,
  distancePreference,
  minPayHourlyPreference,
  minPayShiftPreference,
  searchMode,
  updateOpenShifts,
}: ShiftFiltersInterface) => {
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};

  const [distancePreferenceModal, setDistancePreferenceModal] =
    useState<boolean>(false);
  const [minPayPreferenceModal, setMinPayPreferenceModal] =
    useState<boolean>(false);
  const [hiddenShiftModal, setHiddenShiftModal] = useState<boolean>(false);

  const ldClient = useLDClient();
  const primitivesRollout = ldClient?.variation(
    "open-shifts-primitives-status"
  );

  useEffect(() => {
    if (searchMode !== SEARCH_MODE.HCF_PROFILE && totalShifts && hiddenShifts) {
      const hiddenShiftsModalShown = hiddenShiftsModalShownLocalStorage();
      if (!hiddenShiftsModalShown && totalShifts < 5 && hiddenShifts > 5) {
        setHiddenShiftModal(true);
        const item = {
          value: true,
          expiry: new Date().getTime() + HIDDEN_SHIFT_MODAL_TTL,
        };
        localStorage.setItem("hiddenShiftsModalShown", JSON.stringify(item));
      }
    }
  }, [totalShifts, hiddenShifts]);

  const openDistancePreferenceModal = () => {
    setDistancePreferenceModal(true);
    logEvent(USER_EVENTS.OPEN_DISTANCE_PREFERENCE_MODAL, {
      distance: agent?.preference?.distance,
    });
  };

  const openMinPayPreferenceModal = () => {
    setMinPayPreferenceModal(true);
    setHiddenShiftModal(false);
    logEvent(USER_EVENTS.OPEN_MIN_PAY_PREFERENCE_MODAL, {
      minPayHourly: agent?.preference?.minPayHourly,
      minPayShift: agent?.preference?.minPayShift,
    });
  };

  return (
    <>
      {([SEARCH_MODE.CALENDAR, SEARCH_MODE.MAP].includes(searchMode) ||
        [2, 3].includes(primitivesRollout)) && (
        <IonRow
          className="ion-justify-content-center ion-align-items-center"
          style={{ marginTop: 5, cursor: "pointer" }}
        >
          <div
            style={{
              background: "white",
              width: "100%",
              color: "#032E68",
              textAlign: "center",
              padding: "4px 0px",
              marginTop: "-4px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {openShiftsEmpty
              ? `There are no open shifts within `
              : searchMode == SEARCH_MODE.MAP
              ? `Shifts for the next 30 days `
              : searchMode == SEARCH_MODE.HCF_PROFILE
              ? `Shifts that pay `
              : `Shifts within `}
            {![SEARCH_MODE.HCF_PROFILE, SEARCH_MODE.MAP].includes(
              searchMode
            ) && (
              <IonButton
                size="small"
                fill="outline"
                style={{
                  "--padding-start": "2px",
                  "--padding-end": "2px",
                  marginTop: "0px",
                  marginBottom: "0px",
                  "--border-color": "#5DA0FB",
                  "--color": "#5DA0FB",
                  "--border-radius": "6px",
                }}
                onClick={openDistancePreferenceModal}
              >
                {distancePreference} {distancePreference > 1 ? "miles" : "mile"}
              </IonButton>
            )}
            {[2, 3].includes(primitivesRollout) && (
              <IonButton
                size="small"
                fill={
                  minPayHourlyPreference == 0 && minPayShiftPreference == 0
                    ? "outline"
                    : "solid"
                }
                style={{
                  "--padding-start": "2px",
                  "--padding-end": "2px",
                  marginTop: "0px",
                  marginBottom: "0px",
                  "--background":
                    minPayHourlyPreference == 0 && minPayShiftPreference == 0
                      ? "transparent"
                      : "#008000",
                  "--border-color": "#008000",
                  "--color":
                    minPayHourlyPreference == 0 && minPayShiftPreference == 0
                      ? "#008000"
                      : "#FFFFFF",
                  "--border-radius": "6px",
                }}
                onClick={openMinPayPreferenceModal}
              >
                {"$"}
                {minPayHourlyPreference == 0 && minPayShiftPreference == 0
                  ? "minimum"
                  : ""}
                {minPayHourlyPreference > 0
                  ? `${minPayHourlyPreference}/hr`
                  : ""}
                {minPayHourlyPreference > 0 && minPayShiftPreference > 0
                  ? ", "
                  : ""}
                {minPayShiftPreference > 0 ? minPayShiftPreference : ""}
              </IonButton>
            )}
          </div>
        </IonRow>
      )}
      {searchMode === SEARCH_MODE.HCF_PROFILE && <div className="h-line"></div>}
      {distancePreferenceModal && (
        <DistancePreference
          isOpen={distancePreferenceModal}
          distancePreference={distancePreference}
          closeModal={() => setDistancePreferenceModal(false)}
          updateOpenShifts={updateOpenShifts}
        />
      )}
      {minPayPreferenceModal && (
        <MinPayPreference
          isOpen={minPayPreferenceModal}
          minPayHourlyPreference={minPayHourlyPreference}
          minPayShiftPreference={minPayShiftPreference}
          baseRate={baseRate}
          closeModal={() => setMinPayPreferenceModal(false)}
          updateOpenShifts={updateOpenShifts}
        />
      )}
      {hiddenShiftModal &&
        searchMode !== SEARCH_MODE.HCF_PROFILE &&
        totalShifts &&
        hiddenShifts && (
          <HiddenShiftsModal
            isOpen={hiddenShiftModal}
            hiddenShifts={hiddenShifts}
            closeModal={() => setHiddenShiftModal(false)}
            openMinPayPreferenceModal={openMinPayPreferenceModal}
          />
        )}
    </>
  );
};
