import { AppPage } from "../../../layout/page";
import bunnyMoney from "../../../../assets/images/cph-bunny-money.svg";

import { ShowAPIFailError } from "../../../404Pages/showAPIFailError";
import { Store } from "../../../store/store.model";
import { useSelector } from "react-redux";
import "./style.scss";
import {
  BonusAmount,
  BonusInfo,
  BonusStatus,
  Card,
  Container,
  Divider,
  Header,
  ReferralCard,
  ReferralListContainer,
  ReferrerCode,
  ReferrerInfo,
  ReferrerName,
  Title,
  ValidationContainer,
} from "./styles";
import { checkmarkCircle, closeCircle, personAddSharp } from "ionicons/icons";
import {
  IonAlert,
  IonContent,
  IonIcon,
  IonInput,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import { listReferrals, Referral, submitReferralCode } from "../../api";
import React, { useCallback, useEffect, useState } from "react";

const CoWorkerReferralPage = () => {
  const [isValidReferralCode, setIsValidReferralCode] = useState(true);
  const [codeSubmited, setCodeSubmited] = useState(false);
  const { agent, referralRate } = useSelector((state: Store) => state.session);
  const [referralCode, setReferralCode] = useState("");
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [overrideModalOpen, setOverrideModalOpen] = useState(false);
  const [referrals, setReferrals] = useState<Referral[] | undefined>([]);
  const [validationMessage, setValidationMessage] = useState("");
  const [referralCodeApplied, setReferralCodeApplied] = useState("");
  const [showMessage, setShowMessage] = useState<
    { message: string; type: string } | undefined
  >();
  const { networkStatus } = useSelector((state: Store) => state.session);

  const loadReferrals = useCallback(async () => {
    const referrals = await listReferrals();
    setReferrals(referrals);
    setReferralCodeApplied(
      referrals && referrals[0] ? referrals[0].referrerCode : ""
    );
  }, []);

  useEffect(() => {
    loadReferrals();
  }, [loadReferrals]);

  const handleSubmitReferralCode = useCallback(
    async (code) => {
      try {
        setLoadingValidate(true);
        const response = await submitReferralCode(code);
        setCodeSubmited(true);

        if (!response) {
          setValidationMessage("Something went wrong");
          setIsValidReferralCode(false);
          setLoadingValidate(false);
          return;
        }

        setValidationMessage(response.message);
        setIsValidReferralCode(response.referralCodeValid);
        setLoadingValidate(false);

        if (response.referralCodeValid) loadReferrals();
      } catch (err) {
        setValidationMessage("Something went wrong");
        setIsValidReferralCode(false);
        setLoadingValidate(false);
      }
    },
    [referralCode]
  );

  const validateReferralCode = useCallback(
    async (code) => {
      if (
        referralCodeApplied &&
        referralCodeApplied !== code &&
        !agent?.referralCodeUsed?.referredBonusId
      ) {
        setOverrideModalOpen(true);
        setCodeSubmited(false);
        return;
      }

      await handleSubmitReferralCode(code);
    },
    [referralCodeApplied]
  );

  const onChangeReferralCode = useCallback(
    async (e) => {
      setCodeSubmited(false);
      const { value } = e.detail;

      if (loadingValidate || value.length > 8) return;
      setReferralCode(value);
      if (value.length === 8) {
        await validateReferralCode(value);
        return;
      }
    },
    [validateReferralCode, loadingValidate]
  );

  if (!agent) {
    return <IonSpinner color="light" />;
  }
  return (
    <AppPage title="Refer a Co-Worker" defaultHref={"/home/account/referral"}>
      {!networkStatus?.connected ? (
        <ShowAPIFailError networkStatus={networkStatus?.connected} />
      ) : (
        <>
          <IonToast
            isOpen={!!showMessage}
            message={showMessage && showMessage.message}
            onDidDismiss={() => setShowMessage(undefined)}
            color={showMessage && showMessage.type}
            position="top"
            duration={2000}
          />
          <Header className="coworker-referal-header">
            <img src={bunnyMoney} alt="Bunny with Money" />
          </Header>
          <IonContent>
            <Container className="coworker-referal-container">
              <Card className="card">
                <Title className="title" style={{ color: "black" }}>
                  Enter a referral code
                </Title>
                <Divider className="divider"></Divider>
                <IonInput
                  clearInput
                  value={referralCode}
                  debounce={100}
                  disabled={loadingValidate}
                  onIonChange={onChangeReferralCode}
                  maxlength={8}
                  style={{
                    width: "95%",
                    margin: "15px 10px",
                    border: `2px solid ${
                      !codeSubmited
                        ? "black"
                        : isValidReferralCode
                        ? "#18DC68"
                        : "#DC3028"
                    }`,
                    borderRadius: "5px",
                  }}
                >
                  {loadingValidate && (
                    <IonSpinner
                      slot="start"
                      name="lines"
                      style={{ width: 12, height: 12, marginRight: 10 }}
                    />
                  )}
                </IonInput>

                {codeSubmited && (
                  <ValidationContainer
                    isValid={isValidReferralCode}
                    className={
                      isValidReferralCode
                        ? "validationContainer isValid"
                        : "validationContainer"
                    }
                  >
                    <IonIcon
                      icon={isValidReferralCode ? checkmarkCircle : closeCircle}
                    ></IonIcon>
                    <span>{validationMessage}</span>
                  </ValidationContainer>
                )}
              </Card>
              <ReferralListContainer className="referralListContainer">
                {referrals?.map((referral) => (
                  <ReferralCard
                    className={
                      referral.bonusPaid
                        ? "referralCard wasPaid"
                        : "referralCard"
                    }
                    wasPaid={referral.bonusPaid}
                    key={referral.referrerCode}
                  >
                    <IonIcon icon={personAddSharp}></IonIcon>
                    <ReferrerInfo className="referrerInfo">
                      <ReferrerName className="referrerName">
                        Referral | {referral.referrerName}
                      </ReferrerName>
                      <ReferrerCode className="referrerCode">
                        {referral.referrerCode}
                      </ReferrerCode>
                    </ReferrerInfo>
                    <BonusInfo className="bonusInfo">
                      <BonusAmount className="bonusAmount">
                        ${referral.bonusValue || referralRate}
                      </BonusAmount>
                      <BonusStatus className="bonusStatus">
                        {referral.bonusPaid
                          ? "Paid!"
                          : "After your first shift"}
                      </BonusStatus>
                    </BonusInfo>
                  </ReferralCard>
                ))}
              </ReferralListContainer>
            </Container>
          </IonContent>

          <IonAlert
            isOpen={overrideModalOpen}
            onDidDismiss={() => setOverrideModalOpen(false)}
            header={"Only one referral code can be applied."}
            message={"Update to apply new code, or keep existing code?"}
            backdropDismiss={true}
            buttons={[
              {
                text: "Update",
                handler: () => handleSubmitReferralCode(referralCode),
              },
              {
                text: "Keep",
                role: "cancel",
                handler: () => setReferralCode(""),
              },
            ]}
          />
        </>
      )}
    </AppPage>
  );
};

export { CoWorkerReferralPage };
