import { Plugins } from "@capacitor/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkIfRouteValid } from "../utils/routeUtils";

const { App: CapApp } = Plugins;

const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();
  useEffect(() => {
    CapApp.addListener("appUrlOpen", (data: { url: string }) => {
      if (!data.url.includes("https://cbh.care")) return;
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = data.url.split(".care").pop();

      // Exception for auto-login link
      if (slug?.includes("/welcome/login/emailVerify")) {
        return;
      }

      if (slug && checkIfRouteValid(slug)) {
        history.push(slug);
      }
    });
  }, []);

  return null;
};

export { AppUrlListener };
