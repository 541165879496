import _ from "lodash";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { ChatProvider, WrapSendbird } from "@app/chat/chatProvider";
import { OnBoardingRoutes } from "../../onboarding";
import { OnboardToStripe } from "../../onboardingStripe";
import { SignupBonusClaim } from "../../signupBonusClaim";
import { AppTabs } from "../../tabs";
import { GlobalRouterPath } from "../constant/globalRoute";
import { HomeRouteProps } from "./model";
import { TabRouterPath } from "../constant/tabRoute";
import { ActionType } from "@store/session";
import { useDispatch } from "react-redux";

const isAgentInSignupProcess = (agent) =>
  !agent.active &&
  _.get(agent, "onboardingFlags.isSignupInitiated") &&
  !_.get(agent, "onboardingFlags.isSignupCompleted");

const HomeRouter: React.FC<HomeRouteProps> = ({
  agent,
  userId,
  isSignup,
  history,
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      agent &&
      agent.onboardingFlags?.isSignupCompleted &&
      agent.shouldSignLatestAgreement
    ) {
      dispatch({
        type: ActionType.SHOW_IC_AGREEMENT_POPUP,
        data: { showMessageOnly: true },
      });
    } else {
      dispatch({ type: ActionType.HIDE_IC_AGREEMENT_POPUP });
    }
  }, [agent]);

  if (!userId || !agent) {
    if (isSignup) {
      return <OnBoardingRoutes {...props} agent={agent} />;
    } else {
      return <Redirect to={{ pathname: GlobalRouterPath.NO_USER_PAGE }} />;
    }
  }

  if (isAgentInSignupProcess(agent)) {
    return <OnBoardingRoutes {...props} agent={agent} />;
  }

  if (
    agent.canBeOnboardedInStripe &&
    (!agent.paymentAccountInfo || agent.shouldSignLatestAgreement)
  ) {
    return (
      <OnboardToStripe
        nextStagePath={TabRouterPath.PAYMENT_SERVICE_ONBOARDING}
        disableBackButton={true}
      />
    );
  }
  if (
    !agent.hasClaimedBonus &&
    agent.applicantId &&
    agent.signupExperimentId &&
    agent.signupConfig &&
    agent.signupConfig.signingBonusAmount1 &&
    agent.signupConfig.signingBonusAmount2
  ) {
    return <SignupBonusClaim />;
  }

  return (
    <WrapSendbird userId={userId} agent={agent}>
      <ChatProvider>
        <AppTabs {...props} />
      </ChatProvider>
    </WrapSendbird>
  );
};

export { HomeRouter };
