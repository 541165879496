import {
  ActionType,
  Session,
  SessionAction,
  SessionEnv,
  PendingActions,
} from "./session.model";
import { get } from "lodash";

const initialState: Session = {
  hasSession: false,
  /** there is a hole in the way
   * types where defined for Session
   * trying to add null as a type to Session will break
   * other part of the codebase where Session is been used
   * best is to put it in any mode
   **/
  firebaseAuth: null as any,
  isLogging: false,
  isLoggingOut: false,
  isVerifying: false,
  isAuthorized: false,
  verification: null,
  loginError: null,
  deviceFlags: {},
  networkStatus: null,
  pendingActions: {
    pending: false,
    action: {},
  },
  isSignup: false,
  showAlert: true,
  isNewTimeCardFeatureEnabled: false,
  payVersion: "",
  env: null,
  bookingRolloutMsas: [],
  popups: {},
  referralRate: "",
};

const session = (state = initialState, action: SessionAction): Session => {
  switch (action.type) {
    case ActionType.FIREBASE_INIT:
      return {
        ...state,
        firebaseAuth: action.data?.firebaseAuth as Session["firebaseAuth"],
      };

    case ActionType.LOGIN_REQUESTED:
      return {
        ...state,
        isLogging: true,
        isSignup: Boolean(action.data && action.data.isSignup),
      };

    case ActionType.VERIFICATION_ID_RECEIVED:
      return {
        ...state,
        isLogging: false,
        verification: {
          phone: action.data?.["phone"],
          email: action.data?.["email"],
        },
      };

    case ActionType.VERIFICATION_REQUESTED:
      return {
        ...state,
        isVerifying: true,
      };

    case ActionType.VERIFICATION_COMPLETED:
      return {
        ...state,
        isVerifying: false,
      };

    case ActionType.LOGIN_ERROR:
      return {
        ...state,
        isLogging: false,
        isVerifying: false,
        loginError: action.data?.["error"],
        loginErrorType: action.data?.["errorType"],
      };

    case ActionType.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        loginError: null,
        loginErrorType: null,
      };

    case ActionType.LOGGED_IN:
      return {
        ...state,
        hasSession: true,
        isLogging: false,
        isVerifying: false,
        verification: null,
        isAuthorized: true,
        userId: action.data?.userId,
        profile: action.data?.profile,
        sendBirdAccessToken: action.data?.sendBirdAccessToken,
      };

    case ActionType.CHECKING_AUTHORIZATION:
      return {
        ...state,
        hasSession: action.data?.hasSession as boolean,
      };

    case ActionType.AGENT_PROFILE:
      return {
        ...state,
        agent: action.data?.agent,
        isNewTimeCardFeatureEnabled: get(
          action.data?.agent,
          "enabledFeatures.timecardFeature",
          false
        ),
      };

    case ActionType.UPDATE_AGENT:
      return {
        ...state,
        agent: {
          ...state.agent,
          ...action.data?.agent,
        },
      };

    case ActionType.LOGOUT_REQUESTED:
      return {
        ...state,
        isLoggingOut: true,
      };

    case ActionType.LOGGED_OUT:
      return {
        ...state,
        hasSession: true,
        isLoggingOut: false,
        isAuthorized: false,
        isSignup: false,
        env: null,
      };

    case ActionType.UPDATE_DEVICE_FLAGS:
      return {
        ...state,
        deviceFlags: action.data?.deviceFlags,
      };

    case ActionType.UPDATE_NETWORK_STATUS:
      return {
        ...state,
        networkStatus: action.data?.networkStatus,
      };

    case ActionType.UPDATE_PENDING_ACTIONS:
      return {
        ...state,
        pendingActions: action.data?.pendingActions as PendingActions,
      };

    case ActionType.SET_IS_SIGNUP:
      return {
        ...state,
        isSignup: true,
      };

    case ActionType.SET_SHOW_ALERT:
      return {
        ...state,
        showAlert: false,
      };

    case ActionType.SET_PAY_VERSION:
      return {
        ...state,
        payVersion: action.data?.payVersion,
      };

    case ActionType.SET_ENV_VARIABLES:
      const env = Object.assign({}, action.data) as SessionEnv;
      return {
        ...state,
        env,
      };

    case ActionType.SET_BOOKING_ROLLOUT_MSAS:
      return {
        ...state,
        bookingRolloutMsas: action.data?.bookingRolloutMsas,
      };
    case ActionType.SHOW_IC_AGREEMENT_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          ICAgreement: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_IC_AGREEMENT_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          ICAgreement: { visible: false, ...action.data },
        },
      };
    case ActionType.SHOW_DELETE_DOCUMENT_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          deleteDocumentPopup: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_DELETE_DOCUMENT_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          deleteDocumentPopup: { visible: false, ...action.data },
        },
      };
    case ActionType.SHOW_REPLACEMENT_DOCUMENT_REJECTED_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          replacementRejectedPopup: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_REPLACEMENT_DOCUMENT_REJECTED_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          replacementRejectedPopup: { visible: false, ...action.data },
        },
      };
    case ActionType.SHOW_COVID_TESTING_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          covidTestingPopup: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_COVID_TESTING_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          covidTestingPopup: { visible: false, ...action.data },
        },
      };
    case ActionType.UPDATE_REFERRAL_RATE:
      return {
        ...state,
        referralRate: action.data?.referralRate,
      };
    case ActionType.SHOW_PROFILE_UPDATE_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          profileUpdatePopup: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_PROFILE_UPDATE_POPUP:
      return {
        ...state,
        popups: { ...state.popups, profileUpdatePopup: { visible: false } },
      };

    default:
      return state;
  }
};

export { session };
