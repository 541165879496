import React from "react";
// import {
//   IonSelect,
//   IonSelectOption
// } from '@ionic/react'
import { Select } from "antd";

import { workerSpecialities } from "src/lib/constants";

const { Option } = Select;

const SpecialityDropDown = ({
  defaultSelected,
  onChange,
  multiple = false,
  slot = "end",
}) => {
  return (
    <>
      {/* <IonSelect slot={slot} multiple={multiple} onIonChange={onChange} placeholder="Select Specialities">
        {workerSpecialities.map(speciality => (
          <IonSelectOption selected={isSelected(speciality)} key={speciality}>{speciality}</IonSelectOption>
        ))}
      </IonSelect> */}
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        defaultValue={defaultSelected}
        onChange={onChange}
      >
        {workerSpecialities.map((speciality) => (
          <Option key={speciality} value={speciality}>
            {speciality}
          </Option>
        ))}
      </Select>
    </>
  );
};

export { SpecialityDropDown };
