import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import { PushMessage } from "./model";
import { logFailedApiRetry } from "../utils/api_retry";

const getMessageRecord = async (
  messageId: string,
  channel: string
): Promise<PushMessage> => {
  return await request
    .get(`${environment.baseUrl}/notificationInteraction/getRecord`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/notificationInteraction/getRecord`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ messageId, channel })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { getMessageRecord };
