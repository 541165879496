import { Modal } from "antd";
import { GenericModal } from "src/lib/interface";

/**
 * @param type Type of modal to show,like can be error, success, warning
 * @param content message to display
 * @param title Title of the modal (Optional)
 **/
const showModal: GenericModal = (
  type: string,
  content: string,
  title?: string,
  onOk?: Function,
  onCancel?: Function
) => {
  Modal[type](
    title ? { title, content, onOk, onCancel } : { content, onOk, onCancel }
  );
};

export { showModal };
