import { gql } from "@apollo/client";

const CREATE_HCP_DOCUMENT = gql`
  mutation CREATE_HCP_DOCUMENT(
    $fileUrl: String!
    $hcpId: ObjectId
    $name: String!
    $uploadedFor: ObjectId!
    $covidTest: DateTime
  ) {
    createHcpDocument(
      fileUrl: $fileUrl
      hcpId: $hcpId
      name: $name
      uploadedFor: $uploadedFor
      covidTest: $covidTest
    ) {
      _id
      name
      covidTest
    }
  }
`;

const GET_REQUIREMENTS_STATUS = gql`
  query GET_REQUIREMENTS_STATUS($hcpId: ObjectId!) {
    hcpRequirementStatus(hcpId: $hcpId) {
      miscellaneous {
        _id
        name
        instructions
        description
      }
      requirements {
        _id
        reqId
        name
        level
        hcfType
        requiredBy
        instructions
        description
        visibleToHCP
        expiry
      }
      completed
      pending
      expired
      missing
      optionalRequirements {
        _id
        reqId
        name
        level
        hcfType
        requiredBy
        instructions
        description
        visibleToHCP
      }
      validOptionalRequirements
      expiredOptionalRequirements
      missingOptionalRequirements
      rejected
      mostRecentCovidDoc {
        covidTest
      }
    }
  }
`;
const GET_REJECTED_DOCUMENT = gql`
  query GET_REJECTED_DOCUMENT($hcpId: ObjectId!, $requirementId: ObjectId!) {
    rejectedDocument(hcpId: $hcpId, requirementId: $requirementId) {
      notification {
        reason
        pushNotification
        note
      }
    }
  }
`;
const GET_FILTERED_DOCUMENTS_URL = gql`
  query GET_FILTERED_DOCUMENTS(
    $hcpId: ObjectId
    $limit: Int
    $sort: Sort
    $filter: DocumentFilter
  ) {
    documentList(hcpId: $hcpId, limit: $limit, sort: $sort, filter: $filter) {
      _id
      createdAt
    }
  }
`;

const DELETE_HCP_DOCUMENT = gql`
  mutation DELETE_HCP_DOCUMENT($_id: ObjectId!) {
    deleteHcpDocument(_id: $_id) {
      _id
      name
      displayName
      expiry
      visible
      status
      fulfilledRequirement {
        _id
        name
      }
    }
  }
`;

const GET_HCP_REQUIREMENTS = gql`
  query GET_HCP_REQUIREMENTS($hcpId: ObjectId!, $filter: RequirementsFilter) {
    hcpRequirementList(hcpId: $hcpId, filter: $filter) {
      expiry
      baseRequirement {
        expiryType
      }
    }
  }
`;

export {
  CREATE_HCP_DOCUMENT,
  GET_REQUIREMENTS_STATUS,
  GET_REJECTED_DOCUMENT,
  GET_FILTERED_DOCUMENTS_URL,
  DELETE_HCP_DOCUMENT,
  GET_HCP_REQUIREMENTS,
};
