import { IonChip, IonLabel } from "@ionic/react";
import React from "react";

import { DistanceChipProps } from "./model";

const DistanceChip: React.FC<DistanceChipProps> = ({ distance }) => {
  const options = {
    text: "",
    color: "",
  };

  if (distance < 10) {
    options.text = "Very Close";
    options.color = "tertiary";
  } else if (distance < 20) {
    options.text = "Nearby";
    options.color = "primary";
  } else if (distance < 30) {
    options.text = "Close";
    options.color = "cyan";
  } else {
    return null;
  }

  return (
    <IonChip color={options.color} className="small-chip">
      <IonLabel>{options.text}</IonLabel>
    </IonChip>
  );
};

export { DistanceChip };
