import { logAppCrashedEvent } from "src/lib/analytics";
import React, { ErrorInfo, ReactNode } from "react";
import { logErrors } from "./api";
import { Fallback } from "./fallback";

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
  hasError: boolean;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
  oldErrorHandler: typeof window.onerror;

  constructor(props) {
    super(props);
    this.oldErrorHandler = window.onerror;
    this.state = {
      error: null,
      hasError: false,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidMount() {
    window.onerror = this.logError;
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logAppCrashedEvent(error, errorInfo);
    this.logError(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  logError(...args) {
    try {
      logErrors({
        app: "Mobile",
        message: JSON.stringify(args),
      });
      // @ts-ignore
      this.oldErrorHandler?.(...args);
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    return this.state.hasError ? (
      <Fallback error={this.state.error} errorInfo={this.state.errorInfo} />
    ) : (
      this.props.children
    );
  }
}
