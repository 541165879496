import mixpanel from "mixpanel-browser";
import { ObjectId } from "mongodb";

const initLogging = (token: string) => {
  mixpanel.init(token);
};

const identifyAndSetUser = (
  userId: string | ObjectId,
  properties: Record<string, string | number | Date>
) => {
  const { name, email, ...profile } = properties;
  mixpanel.identify(userId);
  mixpanel.people.set({
    userId,
    $name: name,
    $email: email,
    ...profile,
  });
};

export { mixpanel, initLogging, identifyAndSetUser };
