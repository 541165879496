import moment from "moment-timezone";
import { Shift } from "src/lib/interface";
import { formatDuration, formatRateWithZeroes } from "../app/hcpShifts/helper";

const getDiffMinutes = (start?: string, end?: string): number =>
  moment(end).diff(start, "minutes");

export const formatShiftFinishSuccessMessage = (shift: Shift) => {
  const formattedPaidAmount = formatRateWithZeroes(
    shift.instantPayDetails?.paidAmount
  );
  // this is gross to look at, but IonAlert.message doesn't support JSX so we're stuck with this.
  const htmlPaidAmount = `<span class="payment-text-color-instantpay">$${formattedPaidAmount}</span>`;

  const minutesLunchBreak = getDiffMinutes(
    shift.instantPayDetails?.hcpLunchOut,
    shift.instantPayDetails?.hcpLunchIn
  );

  const minutesPostedDuration =
    getDiffMinutes(shift.start, shift.end) - minutesLunchBreak;
  const minutesActualWorkDuration =
    getDiffMinutes(
      shift.instantPayDetails?.hcpClockIn,
      shift.instantPayDetails?.hcpClockOut
    ) - minutesLunchBreak;

  const diffActualWorkDuration =
    minutesActualWorkDuration - minutesPostedDuration;

  if (diffActualWorkDuration > 5) {
    return `You have been paid ${htmlPaidAmount}. You also clocked in more than the expected shift time. If we verify that on your timesheet, we will send a second payment!`;
  }

  const formattedActualWorkDuration =
    minutesActualWorkDuration >= 60
      ? `${formatDuration(minutesActualWorkDuration / 60)} hours`
      : `${minutesActualWorkDuration} minutes`;
  return `For your ${formattedActualWorkDuration} of work, you have been paid ${htmlPaidAmount}.`;
};
