import "./style.scss";
import React, { useCallback } from "react";
import { IonItem, IonIcon, IonInput } from "@ionic/react";
import { call } from "ionicons/icons";

import { commandKeys, modifierKeys } from "./constants";

interface KeyboardEventPostOmit<T extends EventTarget>
  extends Omit<Event, "target"> {
  target: T;
}

const PhoneInput = ({ onEnterNumber, onChange, label, value }) => {
  const onPhoneChange = (value: string) => {
    onChange(value);
  };

  const isNumericInput = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    const key = event.keyCode;
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  const isModifierKey = useCallback(
    (event: React.KeyboardEvent<HTMLIonInputElement>) => {
      const key = event.keyCode;
      return (
        event.shiftKey === true ||
        !!modifierKeys.find(({ key: modifierKey }) => modifierKey === key) ||
        // Allow Ctrl/Command + A,C,V,X,Z
        ((event.ctrlKey === true || event.metaKey === true) &&
          !!commandKeys.find(({ key: commandKey }) => commandKey === key))
      );
    },
    []
  );

  const enforceFormat = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault();
    }
  };

  // Function to format input field to phone number format (xxx) xxx-xxxx
  const formatNumber = useCallback((phoneNumber: string) => {
    const input = phoneNumber?.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    if (!input) {
      return "";
    }
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    let modifiedPhoneNumber;
    if (input.length > 6) {
      modifiedPhoneNumber = `(${areaCode}) ${middle}-${last}`;
    } else if (input.length > 3) {
      modifiedPhoneNumber = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      modifiedPhoneNumber = `(${areaCode}`;
    }
    return modifiedPhoneNumber || phoneNumber;
  }, []);

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLIonInputElement>) => {
      let phoneNumber = (event.target as HTMLInputElement).value;
      if (event.key === "Enter") {
        event.preventDefault();
        onEnterNumber(event, phoneNumber);
        return;
      }
      phoneNumber = formatNumber(phoneNumber);
      onChange(phoneNumber);
    },
    []
  );

  return (
    <>
      <div className="form-heading">
        <h4>{label}</h4>
      </div>
      <IonItem class="form-item-wrapper" lines="none">
        <IonIcon icon={call} mode="md" slot="start" className="form-icon" />
        <IonInput
          id="phoneEdit"
          className="form-input"
          placeholder="Phone number"
          maxlength={14}
          onIonChange={(e) => onPhoneChange(e.detail.value!)}
          onKeyDown={enforceFormat}
          value={value}
          onKeyUp={onKeyUp}
          required
          // @ts-ignore
          autocomplete="tel-national"
          type="tel"
        />
      </IonItem>
    </>
  );
};

export default PhoneInput;
