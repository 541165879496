import { Plugins } from "@capacitor/core";
import {
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonSpinner,
  IonToast,
  IonBackButton,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import React, { useState, FormEvent, useEffect } from "react";
import { states } from "src/lib/utils";
import { LogoutButton, openBrowser } from "src/lib/ionic-components";
import { Select } from "antd";
import { useSelector } from "react-redux";
import { isPlatform } from "@ionic/core";

import { logout } from "../../store/session";
import { Store } from "../../store/store.model";
import { OnBoardingComponentProps, LicenseType } from "./model";
import { updateAgentData } from "./api";
import { StyledIonPage } from "./style";
import { licenseTypes } from "../licenseTypes";
import Alert from "./Alert";
import { logOnboardingError } from "../util/logging";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";

const { Keyboard } = Plugins;
const { Option } = Select;

const AgentLicense: React.FC<OnBoardingComponentProps> = ({
  agent,
  nextStagePath,
}) => {
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [number, setNumber] = useState<string | undefined>("");
  const [expires, setExpires] = useState<string | undefined>("");
  const [state, setState] = useState<string | undefined>("");
  const [licenseType, setLicenseType] = useState<LicenseType>({});
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const { profile } = useSelector((state: Store) => state.session);

  const history = useHistory();

  useEffect(() => {
    if (!agent) {
      return;
    }

    if (agent?.qualification) {
      if (agent?.otherQualification) {
        setLicenseType({ type: "other", name: agent.otherQualification });
      } else {
        licenseTypes.forEach((item: LicenseType) => {
          if (item.value === agent.qualification) setLicenseType(item);
        });
      }
    } else {
      history.push(`/home/agentQualification`);
    }

    if (agent?.license) {
      const { number: num, expires, state } = agent.license;
      setNumber(num);
      setExpires(expires);
      setState(state);
    }
  }, [agent]);

  const onNumberChange = (event: Event) => {
    setNumber(event.target?.["value"]);
  };

  const onExpiresChange = (event: Event) => {
    setExpires(event.target?.["value"]);
  };

  const onStateChange = (value: string) => {
    setState(value);
  };

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event && event.preventDefault();
    if (isPlatform("capacitor")) Keyboard.hide();
    setShowErrorModal(false);
    try {
      setUpdatingData(true);
      history.push(`${nextStagePath}`);
    } catch (error) {
      if (
        !error.response ||
        !error.response.text ||
        error.response.status > 500
      ) {
        logOnboardingError(
          ONBOARDING_STAGES.LICENSE,
          (error as Error).message,
          agent?.userId
        );
        setShowErrorModal(true);
        return;
      }
      setPageError({
        isError: true,
        message: error.response.text,
      });
    } finally {
      setUpdatingData(false);
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentQualification"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
          position="top"
        />

        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>Add License Details</h4>
                    <p>
                      Please provide details to your current medical license so
                      we can verify your eligibility to work with facilities.
                    </p>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <div className="agentQType">
                    <p className="licenseType">{licenseType.type}</p>
                    <p className="licenseName">{licenseType.name}</p>
                  </div>
                  <div className="form-control">
                    <IonLabel className="form-label">License Number</IonLabel>
                    <IonInput
                      className="form-input"
                      name="number"
                      type="text"
                      placeholder="123456"
                      onIonChange={onNumberChange}
                      value={number}
                      required
                    />
                  </div>
                  <div className="form-control">
                    <IonLabel className="form-label">
                      Date of Expiration
                    </IonLabel>
                    <IonInput
                      className="form-input"
                      name="expires"
                      type="date"
                      placeholder="Expiry Date"
                      onIonChange={onExpiresChange}
                      value={expires}
                    />
                  </div>
                  <div className="form-control">
                    <IonLabel className="form-label">State</IonLabel>
                    <Select
                      showSearch
                      value={state}
                      onChange={onStateChange}
                      className="form-input"
                      style={{
                        width: "100%",
                        border: "none",
                        marginTop: "15px",
                      }}
                    >
                      {states.map((state) => (
                        <Option key={state.key} value={state.name}>
                          {state.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={updatingData || !number || !expires || !state}
              onClick={onNext}
            >
              Continue
              {updatingData && (
                <IonSpinner slot="end" class="ion-margin-start" name="lines" />
              )}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentLicense };
