import { logEvent } from "src/lib/analytics";
import {
  Facility,
  Shift,
  TimeSystems,
  ClockInMethods,
  SHIFT_MARKED_NON_IP_REASONS,
} from "src/lib/interface";
import { isValidObjectId } from "src/lib/utils";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAlert,
  isPlatform,
} from "@ionic/react";
import { SessionEnv } from "@store/session";
import moment from "moment-timezone";
import { ShiftLoader } from "src/lib/ionic-components";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "@store/index";
import { USER_EVENTS } from "../../../constants/userEvents";
import { useMissingDocuments } from "../../dayView/custom-hooks/useMissingDocuments";
import { FacilityDetails as DetailedFacility } from "../../dayView/facilityDetails";
import { ShiftActionFooter } from "./shiftActionFooter";
import { ShiftTimeline } from "./shiftTimeline";
import { NoTimeSheetOptions } from "@app/components/shift/noTimeSheetOptions";
import { RatingPrompt } from "../../rating";
import { FacilityDetails } from "../components/facilityDetails";
import { RecordTimeButton } from "../components/recordTime";
import { ShiftTitle } from "../components/shiftTitle";
import { getShiftTitleString } from "../helper";
import { MissingDocsDetails } from "./missingDocs";
import { RatingSuccess } from "./ratingSuccess";
import { ShiftInstructions } from "./shiftInstructions";
import { UploadFileModal } from "../../components/uploadFile";
import { useAlertsForShiftDetails } from "./alerts";
import { TimeSheetSummary } from "../components/timesheetSummary";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { api } from "@app/api";
import { NonIPConversionText } from "@app/components/shift/nonIPConversionMessage";

const MyShiftDetailsPage = () => {
  const [showUploadTimesheetModal, setShowUploadTimesheetModal] =
    useState<boolean>(false);
  const [showFileExplorer, setShowFileExplorer] = useState<boolean>(false);
  const [shift, setShift] = useState<Shift | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [title, setTitle] = useState("My Shift");
  const [openNoTimeSheetOptions, setOpenNoTimeSheetOptions] =
    useState<boolean>(false);
  const [openRating, setOpenRating] = useState<boolean>(false);
  const [hasMissedClockIn, setHasMissedClockIn] = useState<boolean>(false);
  const [isRatingSuccess, setIsRatingSuccess] = useState<boolean>(false);
  const [canShowUploadButton, setCanShowUploadButton] = useState<boolean>(true);
  const [isTimeSheetRequired, setIsTimeSheetRequired] = useState<boolean>(true);
  const { shiftId } = useParams<{ shiftId: string }>();
  const [facilityDetails, setFacilitydetails] = useState<Facility | null>(null);
  const [shiftDetails, setShiftDetails] = useState<Shift | null>(null);
  const isMobileApp = isPlatform("capacitor");
  const ldClient = useLDClient();
  const isNewTimeSheetEnabled = ldClient?.variation(
    "display-time-sheet-summary",
    false
  );
  const showTimeSheetSummaryModel = isTimeSheetSummaryModelEnabled();
  const env = useAppSelector(
    (state) => state?.session?.env ?? ({} as SessionEnv)
  );
  const shiftList = useAppSelector(
    (state) => state?.ongoingShiftStore?.shiftList ?? []
  );

  const openTimesheetModel = async (isFromAlert?: boolean) => {
    if (showTimeSheetSummaryModel && isMobileApp) {
      await ScreenOrientation.lock(
        ScreenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY
      );
    }
    if (isFromAlert) {
      setShowFileExplorer(true);
    }
    setShowUploadTimesheetModal(true);
  };

  const isSignatureSubmission =
    shift?.facility?.timeSystem == TimeSystems.IPAD ||
    shift?.facility?.timeSystem == TimeSystems.NFC;

  const closeTimesheetModel = async () => {
    if (showTimeSheetSummaryModel && isMobileApp) {
      await ScreenOrientation.unlock();
      await ScreenOrientation.lock(
        ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
      );
    }
    setShowUploadTimesheetModal(false);
  };

  const history = useHistory();
  const { userId } = useAppSelector((state) => state.session);

  const { cbh, state, msa, county, hcf } = useMissingDocuments(shift);

  const [displayExtraTimePayCard, setDisplayExtraTimePayCard] = useState(false);

  const shiftDetailsAlerts = useAlertsForShiftDetails();

  useEffect(() => {
    if (!isValidObjectId(shiftId)) return;
    (async function () {
      await loadShiftDetails(shiftId);
    })();
  }, [shiftId]);

  const loadShiftDetails = async (shiftId: string): Promise<Shift> => {
    setLoading(true);
    const res = await api.shift.fetchShiftDetails({ shiftId });
    const facility = res.facility as Facility;
    facility.verification = res.facility?.verificationPreference;
    if (res?.facility?.userId) {
      const { data } = await api.facility.fetchFacilityDetails(
        res.facility.userId
      );
      res.facility.verificationPreference = data;
    }
    setShift(res);
    const shiftTitle = getShiftTitleString({
      shiftStart: res.start,
      shift: res,
    });
    setTitle(shiftTitle);
    setLoading(false);
    return res; // if someone needs it before state updates
  };

  useEffect(() => {
    const updatedShift = shiftList?.find((item) => item?._id === shiftId);
    if (!shift || !updatedShift) return;
    setShift({
      ...shift,
      ...updatedShift,
      facility: { ...shift?.facility, ...updatedShift.facility }, // updated shifts returns fewer object keys on refresh
    });
  }, [shiftList, shiftId]);

  const triggerMissedClockIn = useCallback(async () => {
    if (shift?.isInstantPay && !shift.clockInOut?.start) {
      logEvent(USER_EVENTS.MISSED_INSTANTPAY_CLOCK_IN, {
        shift,
      });
      await api.shift.markShiftAsNonInstantPay(
        shift?._id as string,
        SHIFT_MARKED_NON_IP_REASONS.MOBILE_FORGOT_TO_CLOCK_IN
      );
      loadShiftDetails(shift?._id as string);
    }
    if (shift?.isChangedToNonInstantPay) setHasMissedClockIn(true);
  }, [shift]);

  useEffect(() => {
    if (!shift) {
      return () => {};
    }

    const clockInTimeDiff = moment(shift?.start)
      .add(env?.minToAllowTimeCardUpload, "minutes")
      .diff(moment());

    let timeout: NodeJS.Timeout | null = null;
    if (clockInTimeDiff < 0) {
      triggerMissedClockIn();
    } else {
      timeout = setTimeout(() => {
        triggerMissedClockIn();
      }, clockInTimeDiff);
    }

    if (shift?.timecardNotAvailable?.reason || shift?.agentId !== userId) {
      setCanShowUploadButton(false);
    }

    if (
      !(
        shift.facility?.verificationPreference?.usesTimesheets ||
        shift.facility?.requireTimecardPhoto
      )
    ) {
      setIsTimeSheetRequired(false);
    }

    if (
      !(
        shift.facility?.verificationPreference?.usesTimesheets ||
        shift.facility?.requireTimecardPhoto
      )
    )
      setIsTimeSheetRequired(false);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shift, env, triggerMissedClockIn, userId]);

  const formattedDate = shift && moment(shift?.start).format("ddd, MMMM DD");

  const uploadTimeSheet = (isFromAlert?: boolean) =>
    openTimesheetModel(isFromAlert);

  const cancelNoTimeSheet = () => setOpenNoTimeSheetOptions(false);

  const handleClose = () => {
    setIsRatingSuccess(false);
    history.push("/home/openShifts");
  };

  const onClickOnItemFacilityDetails = (
    shift: Shift,
    facility: Facility,
    displayExtraTimePayCard = false
  ) => {
    if (shift?.isInstantBook) {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "instant book",
      });
    } else {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "standard",
      });
    }
    setDisplayExtraTimePayCard(displayExtraTimePayCard);
    setFacilitydetails(facility ?? null);
    setShiftDetails(shift);
  };

  const closeFacilityDetails = () => {
    setFacilitydetails(null);
    setShiftDetails(null);
  };

  const hasMissingDocs = !!(cbh || state || msa || county || hcf);
  const timeSheetExists =
    (shift?.timecard?.files?.length as number) > 0 ||
    !!shift?.timecardNotAvailable?.reason;

  const { alert } = shiftDetailsAlerts;

  const onSuccessfulUpload = async () => {
    closeTimesheetModel();
    setCanShowUploadButton(false);
    // we can't just wait for React's setState updates, so we get it directly
    const updatedShift = await loadShiftDetails(shiftId);
    if (
      updatedShift.instantPayDetails?.is100InstantPayEnabled &&
      updatedShift.isInstantPay
    ) {
      shiftDetailsAlerts.alertShiftFinishedSuccessfully({
        shift: updatedShift,
        continueBtnHandler: () => {
          setOpenRating(true);
        },
      });
    } else {
      setOpenRating(true);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/myShifts" />
          </IonButtons>
          <IonTitle>{title || formattedDate}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <ShiftActionFooter
        isShiftLoading={isLoading}
        shift={shift}
        hasMissingDocs={hasMissingDocs}
      />
      <IonContent>
        <ShiftLoader loading={isLoading} count={1} />
        <IonCard className="shift-details-card no-text-transform new-flow shifts ongoing">
          {!isLoading && (
            <>
              <ShiftTitle
                shift={shift}
                hasMissingDocs={hasMissingDocs}
                showMissingDocs={!shift?.agent}
                onShiftClick={() => void 0}
              />
              <FacilityDetails
                onFacilityDetailsClick={onClickOnItemFacilityDetails}
                shift={shift}
                showCheckInInstructions={false}
                onCardClick={() => void 0}
                showMinimalDetails={Boolean(shift?.clockInOut)}
              />
              <ShiftInstructions
                hasMissingDocs={hasMissingDocs}
                hasMissedClockIn={hasMissedClockIn}
                shift={shift!}
                isTimeSheetRequired={isTimeSheetRequired}
                timeSheetExists={timeSheetExists}
              />
              <MissingDocsDetails
                agent={shift?.agent}
                hasMissingDocs={hasMissingDocs}
              />
              {shift?.isChangedToNonInstantPay && (
                <NonIPConversionText
                  hasMissedClockIn={hasMissedClockIn}
                  isAutoClockedOut={!!shift.autoClockedOut}
                />
              )}
            </>
          )}
        </IonCard>
        <NoTimeSheetOptions
          visible={openNoTimeSheetOptions}
          setVisible={setOpenNoTimeSheetOptions}
          cancel={cancelNoTimeSheet}
          shift={shift as Shift}
          setCanShowUploadButton={setCanShowUploadButton}
        />
      </IonContent>
      {!isLoading &&
        shift &&
        shift?.isInstantPay &&
        (shift?.clockInOut || !hasMissedClockIn) && (
          <ShiftTimeline shift={shift} />
        )}
      {isRatingSuccess && (
        <RatingSuccess isOpen={isRatingSuccess} onClose={handleClose} />
      )}
      {openRating && (
        <RatingPrompt
          shift={shift as Shift | undefined}
          onDidDismiss={() => void 0}
          setIsRatingSuccess={setIsRatingSuccess}
        />
      )}
      {canShowUploadButton && (
        <RecordTimeButton
          shift={shift}
          uploadTimeSheet={uploadTimeSheet}
          setOpenNoTimeSheetOptions={setOpenNoTimeSheetOptions}
          hasMissedClockIn={hasMissedClockIn}
          timeSheetExists={timeSheetExists}
          isTimeSheetRequired={isTimeSheetRequired}
          shiftDetailsAlerts={shiftDetailsAlerts}
          loadShiftDetails={loadShiftDetails}
          isNewTimeSheetEnabled={isNewTimeSheetEnabled}
          isSignatureSubmission={isSignatureSubmission}
        />
      )}
      {showUploadTimesheetModal &&
        shift &&
        (showTimeSheetSummaryModel ? (
          <TimeSheetSummary
            shift={shift}
            isSignatureSubmission={isSignatureSubmission}
            showFileExplorer={showFileExplorer}
            onCloseOrCancel={closeTimesheetModel}
            onSuccessfulUpload={onSuccessfulUpload}
          />
        ) : (
          <UploadFileModal
            shift={shift}
            onCloseOrCancel={() => setShowUploadTimesheetModal(false)}
            onSuccessfulUpload={onSuccessfulUpload}
          />
        ))}
      <IonModal isOpen={!!facilityDetails} onDidDismiss={closeFacilityDetails}>
        {facilityDetails && (
          <DetailedFacility
            onClose={closeFacilityDetails}
            facility={facilityDetails}
            shift={shiftDetails as Shift | undefined}
            displayExtraTimePayCard={displayExtraTimePayCard}
          />
        )}
      </IonModal>
      <IonAlert
        header={alert?.header}
        message={alert?.message}
        isOpen={!!alert}
        // onDidDismiss will override alert to null if we try to change the alert inside a modal button handler
        onWillDismiss={shiftDetailsAlerts.dismissAlert}
        buttons={alert?.buttons}
        mode="ios"
      />
    </IonPage>
  );

  function isTimeSheetSummaryModelEnabled() {
    return (
      isNewTimeSheetEnabled &&
      shift?.isInstantPay &&
      ((shift?.facility?.timeSystem === TimeSystems.PAPER &&
        shift?.clockInMethod !== ClockInMethods.PRIMARY) ||
        shift?.facility?.timeSystem === TimeSystems.NFC ||
        shift?.facility?.timeSystem === TimeSystems.IPAD)
    );
  }
};

export { MyShiftDetailsPage };
