import "./style.scss";
import { BrowserLinkButton } from "src/lib/ionic-components";
import { Plugins } from "@capacitor/core";
import { call, mail } from "ionicons/icons";

import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonSpinner,
  IonToast,
  IonAlert,
  useIonViewDidEnter,
  CreateAnimation,
} from "@ionic/react";
import { isPlatform } from "@ionic/core";
import React, {
  FormEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import PhoneInput from "@app/components/phoneInput";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../constants/userEvents";
import { LoginPageProps, LoginProps } from "./login.model";
import useQuery from "../../hooks/useQuery";

import { AlertStrings } from "../utils/alertUtils";
import { LoginErrorType } from "../../constants/loginErrorConstants";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginError } from "../store/session";
import {
  DISMISSED_STRIPE_POPUP,
  WAS_APP_PAUSED,
} from "../onboardingStripe/constants";
const { Keyboard } = Plugins;

const LoginForm: React.FC<LoginProps> = ({
  verifyPhone,
  verifyEmail,
  isLogging,
  history,
  firebaseAuth,
  loginError,
}) => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const search = useLocation().search;
  const dispatch = useDispatch();

  const captchaRef: React.Ref<HTMLDivElement | null> = useRef(null);
  const query = useQuery();

  useIonViewDidEnter(() => {
    if (firebaseAuth) {
      firebaseAuth.initRecaptchaVerifier(
        "recaptcha-container",
        captchaRef.current
      );
    }
  }, [firebaseAuth]);

  useEffect(() => {
    if (loginError && firebaseAuth) {
      firebaseAuth.initRecaptchaVerifier(
        "recaptcha-container",
        captchaRef.current
      );
    }
  }, [loginError, firebaseAuth]);

  useEffect(() => {
    const phone = query.get("phone");
    if (phone) {
      setPhone(phone);
      onLogin(undefined, phone);
    }
  }, [query.get("phone"), setPhone]);

  useEffect(() => {
    // To display error message when login link is invalid/expired or failed because of some other reason.
    const failed = new URLSearchParams(search).get("failed");
    const email = new URLSearchParams(search).get("email");
    setEmail(email || "");
    if (failed === "true") {
      setLoginError(
        dispatch,
        "Link verification failed. Please try again or contact Support if the issue persists."
      );
    }
  }, []);

  const onPhoneChange = (value) => {
    setPhone(value);
    setEmail("");
  };

  const onEmailChange = (event: Event) => {
    setEmail(event.target?.["value"]);
    setPhone("");
  };

  const loginWithPhone = async (unformattedPhone) => {
    const verified = await verifyPhone(unformattedPhone, false);
    const path = "/welcome/login/verify";
    // @ts-ignore
    if (verified) {
      if (query.get("phone")) {
        history.replace(path, { phone: unformattedPhone });
      } else {
        history.push(path, { phone: unformattedPhone });
      }
    }
  };

  const loginWithEmail = async () => {
    const verified = await verifyEmail(email);
    // @ts-ignore
    if (verified) {
      localStorage.setItem("emailToVerify", email);
      history.push("/welcome/login/emailVerify/sent", { email });
    }
  };

  const onLogin = async (
    event?: FormEvent | MouseEvent,
    passedInPhone?: string
  ) => {
    event?.preventDefault();
    const unformattedPhone = passedInPhone
      ? passedInPhone?.replace(/\D/g, "")
      : phone?.replace(/\D/g, "");
    if (email || phone)
      logEvent(USER_EVENTS.ENTERED_SIGN_IN_CREDENTIAL, {
        email: email,
        phone: unformattedPhone,
      });
    if (isPlatform("capacitor")) Keyboard.hide();

    // Used to track if user is coming from login page for stripe popup
    localStorage.setItem(WAS_APP_PAUSED, "true");
    localStorage.removeItem(DISMISSED_STRIPE_POPUP);

    if (email) {
      loginWithEmail();
    } else {
      loginWithPhone(unformattedPhone);
    }
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (event.key === "Enter") {
      onLogin(event);
    }
  };

  return (
    <form onSubmit={onLogin} className="login-form">
      <PhoneInput
        onEnterNumber={onLogin}
        onChange={onPhoneChange}
        label="Log in using your phone number:"
        value={phone}
      />
      <div ref={captchaRef as RefObject<HTMLDivElement>}></div>
      <div className="or-email-text-divider">
        <h4 className="ion-margin-bottom">Or enter your email:</h4>
      </div>

      <IonItem lines="none">
        <IonIcon icon={mail} slot="start" color="medium" />
        <IonInput
          className="ion-text-left"
          type="email"
          placeholder="Email"
          onIonInput={onEmailChange}
          value={email}
          onKeyUp={onKeyUp}
        />
      </IonItem>

      <IonButton
        expand="block"
        size="large"
        className="ion-margin-top ion-margin-bottom login-button"
        onClick={onLogin}
        disabled={isLogging}
        id="login-button continue-button"
      >
        {isLogging && (
          <IonSpinner slot="end" className="ion-margin-start" name="lines" />
        )}
        Log In
      </IonButton>

      <div className="login-link cbh-link-wrapper">
        <span>Don't have an account?</span>
        <IonButton
          className="cbh-link"
          fill="clear"
          color="#1C69D1"
          onClick={(event) => {
            event.preventDefault();
            history.push("/welcome");
          }}
        >
          Sign Up
        </IonButton>
      </div>
    </form>
  );
};

const Login: React.FC<LoginPageProps> = ({
  match,
  loginError,
  loginErrorType,
  ...props
}) => {
  const animationRef = useRef<CreateAnimation>(null);

  const logLearnMoreClicked = () => {
    logEvent(USER_EVENTS.TAPPED_LEARN_MORE);
  };

  useEffect(() => {
    if (isPlatform("capacitor")) {
      Keyboard.addListener("keyboardWillShow", () =>
        animationRef.current!.animation.play()
      );
      Keyboard.addListener("keyboardDidHide", () =>
        animationRef.current!.animation.stop()
      );
    }
    return () => {
      if (isPlatform("capacitor")) {
        Keyboard.removeAllListeners();
      }
    };
  }, []);

  return (
    <IonPage>
      <IonContent className="login-content ion-padding ion-text-center">
        <div className="content-layout">
          <IonRow className="ion-align-items-center">
            <IonCol
              align-self-center
              sizeMd="8"
              offsetMd="2"
              offsetLg="4"
              sizeLg="4"
            >
              <CreateAnimation
                ref={animationRef}
                duration={300}
                keyframes={[
                  {
                    offset: 0,
                    transform: "scale(1)",
                    opacity: "1",
                    marginBottom: "40px",
                  },
                  {
                    offset: 0.5,
                    transform: "scale(0.9)",
                    opacity: "0.75",
                    marginBottom: "20px",
                  },
                  {
                    offset: 1,
                    transform: "scale(0.75)",
                    opacity: "0.5",
                    marginBottom: "0px",
                  },
                ]}
              >
                <div className="login-top-container">
                  <h1>Great rates.</h1>
                  <h1>Instant payouts.</h1>
                  <h1>Lots of shifts.</h1>
                  <IonImg src="/assets/logo/cbh-logo.png" className="logo" />
                </div>
              </CreateAnimation>
              <LoginForm {...props} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div onClick={logLearnMoreClicked}>
                <BrowserLinkButton
                  url="https://clipboardhealth.com"
                  color="dark"
                  className="learn-more-link"
                >
                  Learn more
                </BrowserLinkButton>
              </div>
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
      <IonAlert
        isOpen={
          !!loginError && !!loginErrorType && !!AlertStrings[loginErrorType]
        }
        cssClass="ion-login-alert-popup"
        onDidDismiss={props.clearLoginError}
        header={loginErrorType && AlertStrings[loginErrorType]?.header}
        message={loginErrorType && AlertStrings[loginErrorType]?.message}
        backdropDismiss={true}
        buttons={[
          {
            text: "Sign Up",
            handler: () => {
              props.history.push("/welcome");
            },
          },
          {
            cssClass: "ion-login-alert-ok-button",
            text: `${
              (loginErrorType && AlertStrings[loginErrorType]?.buttonText) ||
              "OK"
            }`,
            handler: props.clearLoginError,
          },
        ]}
      />
      <IonToast
        isOpen={
          !!loginError &&
          !!loginErrorType &&
          loginErrorType === LoginErrorType.INVALID_PHONE_NUMBRER
        }
        onDidDismiss={props.clearLoginError}
        message={loginError as string | undefined}
        cssClass={"error-text-black"}
        color={"medium"}
        position="top"
        duration={3000}
      />
    </IonPage>
  );
};

export { Login };
