import { ShiftName } from "src/lib/interface";
import { JSX } from "@ionic/core";
import { IonChip, IonLabel } from "@ionic/react";
import React from "react";

import { HasShift, ShiftChipProps } from "./model";
import { getHasShiftColor } from "./utils";

const ShiftChip: React.FC<ShiftChipProps & JSX.IonChip> = ({
  name,
  verified,
  hasShift,
  includeCustom,
  hide,
  ...props
}) => {
  if (!includeCustom && name === ShiftName.CUSTOM) return null;

  let className = "small-chip";

  if (hide) {
    className += " hidden";
  }

  return (
    <IonChip
      color={getHasShiftColor(
        hasShift as HasShift,
        name,
        verified,
        props.outline
      )}
      className={className}
      {...props}
    >
      <IonLabel>{name ? name.toUpperCase() : ""}</IonLabel>
    </IonChip>
  );
};

export { ShiftChip, getHasShiftColor };
