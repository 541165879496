import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  clearLoginError,
  loginWithOTP,
  verifyEmail,
  verifyPhoneRequestOTP,
  reVerifyPhoneRequestOTP,
} from "../store/session";
import { Store } from "../store/store.model";
import { Login } from "./login";
import { VerifyComponent } from "./verify";

const LoginPageState = (state: Store) => ({
  isLogging: state.session.isLogging,
  verification: state.session.verification,
  loginError: state.session.loginError as Exclude<
    typeof state.session.loginError,
    null
  >,
  firebaseAuth: state.session.firebaseAuth,
  loginErrorType: state.session.loginErrorType,
});

const loginPageActions = (dispatch) =>
  bindActionCreators(
    {
      verifyPhone: verifyPhoneRequestOTP,
      verifyEmail,
      clearLoginError,
    },
    dispatch
  );

const LoginPage = connect(LoginPageState, loginPageActions)(Login);

const verifyPageState = (state: Store) => ({
  isVerifying: state.session.isVerifying,
  loginError: state.session.loginError as Exclude<
    typeof state.session.loginError,
    null
  >,
});

const verifyPageActions = (dispatch) =>
  bindActionCreators(
    {
      loginWithCode: loginWithOTP,
      clearLoginError,
      reVerifyPhone: reVerifyPhoneRequestOTP,
    },
    dispatch
  );

const VerifyPage = connect(verifyPageState, verifyPageActions)(VerifyComponent);

export { LoginPage, VerifyPage };
