import { environment } from "../../../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import request from "superagent";
import { getAuthHeader } from "../../../superagent";

const uploadProfileImage = async ({
  croppedImageFile,
}): Promise<string | undefined> => {
  const { fileUploadURL, fileUploadPreset } = environment;
  const formData = new FormData();
  formData.append("file", croppedImageFile);
  formData.append("upload_preset", fileUploadPreset);

  try {
    const result = await request.post(fileUploadURL).send(formData);
    const { url } = result.body;

    await request
      .put(`${environment.baseUrl}/agentprofile/profilePic`)
      .set(await getAuthHeader())
      .send({ url });
    return url;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { uploadProfileImage };
