import React from "react";
import { useLocation } from "react-router-dom";

import { LoginRouteState } from "./login.model";
import { EmailWaiting } from "./emailWaiting";

const EmailVerifySent: React.FC<{}> = () => {
  const location = useLocation();
  const { email } = (location.state || {}) as LoginRouteState;

  return <EmailWaiting email={email} opacity={0.5} />;
};

export { EmailVerifySent };
