import React, { useEffect, useState, useRef } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  isPlatform,
  getPlatforms,
  IonCol,
  IonGrid,
  IonInput,
  IonLabel,
  IonRow,
  IonItem,
  IonToast,
} from "@ionic/react";
import { TimeSheetSubmission } from "./timesheetSubmission";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "@store/store.model";
import { get } from "lodash";
import { useAlertsForShiftDetails } from "../shiftDetails/alerts";
import {
  Shift,
  TimeSystems,
  ClockInMethods,
  TimeRange,
} from "src/lib/interface";
import { ShiftTimeSummary } from "./shiftTimeSummary";
import "./style.scss";
import {
  submitShiftTimeCard,
  actionUploadTimeCardV2,
} from "@store/ongoingShifts";
import { SelectedFile } from "@app/shiftSignature/timecard/model";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "@constants/userEvent";
import { MakeUploadTimeLog } from "@app/components/uploadFile/previewAndUpload";
import * as Sentry from "@sentry/react";
import { wait } from "@testing-library/user-event/dist/utils";
import { SignatureConfirmation } from "./signatureConfirmation";

const TimeSheetSummary: React.VFC<{
  shift: Shift;
  onCloseOrCancel: () => void;
  onSuccessfulUpload: () => void;
  isSignatureSubmission: boolean;
  showFileExplorer: boolean;
}> = ({
  shift,
  onCloseOrCancel,
  onSuccessfulUpload,
  isSignatureSubmission,
  showFileExplorer,
}) => {
  const [showModal, setShowModal] = useState(true);
  const isMobileApp = isPlatform("capacitor");
  const [timeElapsed, setTimeElapsed] = useState<boolean>(false);
  const shiftDetailsAlerts = useAlertsForShiftDetails();
  const env = useSelector((state: Store) => get(state, "session.env", {}));
  const [uploading, setUploading] = useState<boolean>(false);
  let timeoutRef = useRef<NodeJS.Timeout>();
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [modalToolbarHeight, setModalToolbarHeight] = useState<number>(0);
  const [isShiftTimeEdit, setIsShiftTimeEdited] = useState<boolean>(false);

  const FIVE_MINUTES_IN_MS = 300000;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shift) return;
    const diff = moment(shift?.start)
      .add(env?.minToAllowTimeCardUpload, "minutes")
      .diff(moment());
    setTimeElapsed(diff <= 0);
    const timeout = setTimeout(() => {
      setTimeElapsed(true);
    }, diff);

    return () => clearTimeout(timeout);
  }, [env, shift, timeElapsed]);

  const [submitClockInOut, setSubmitClockInOut] = useState<TimeRange>({
    start: shift?.clockInOut?.start,
    end: shift?.clockInOut?.end,
  });
  const [submitLunchInOut, setSubmitLunchInOut] = useState<TimeRange>({
    start: shift?.lunchInOut?.start,
    end: shift?.lunchInOut?.end,
  });

  const onShiftTimeSave = (
    submitClockIn,
    submitClockOut,
    submitLunchOut,
    submitLunchIn
  ) => {
    setSubmitClockInOut({
      start: submitClockIn,
      end: submitClockOut,
    });
    setSubmitLunchInOut({
      start: submitLunchOut,
      end: submitLunchIn,
    });
    setIsShiftTimeEdited(false);
  };

  const SubmitTimesheet = (submittedFile: SelectedFile) => {
    //UploadFile
    uploadFile(submittedFile);
    //Submit Edited time - Saving it to Db (Submit_ clock_in and out,lunch in and out)
  };

  // Edit Timesheet button click / cancel
  const onShiftTimeEdit = (val: boolean) => {
    setIsShiftTimeEdited(val);
  };

  const maxTimeout = () => {
    return new Promise((_, reject) => {
      timeoutRef.current = setTimeout(() => {
        reject();
      }, FIVE_MINUTES_IN_MS);
    });
  };

  const uploadFile = async (submittedFile: SelectedFile) => {
    const { file, type } = submittedFile;
    const uploadTimeStart = moment();
    try {
      setUploading(true);
      logEvent(
        USER_EVENTS.UPLOAD_TIMESHEET_STARTED,
        MakeUploadTimeLog(shift, file, uploadTimeStart, false, null)
      );
      await Promise.race([
        maxTimeout(),
        dispatch(
          submitShiftTimeCard(
            file,
            submitClockInOut,
            submitLunchInOut,
            shift._id as string
          )
        ),
      ]);
      logEvent(
        shift.isInstantPay
          ? USER_EVENTS.SUBMITTED_INSTANTPAY_TIMECARD_PHOTO
          : USER_EVENTS.SUBMITTED_TIMECARD_PHOTO,
        {
          instant_pay: shift?.isInstantPay,
        }
      );
      setUploading(false);
      logEvent(USER_EVENTS.UPLOAD_TIMESHEET_SUCCESS, {
        ...MakeUploadTimeLog(shift, file, uploadTimeStart, true, null),
      });
      onSuccessfulUpload();
    } catch (err) {
      const error = err as Error | string;
      Sentry.captureException(error);
      logEvent(
        USER_EVENTS.UPLOAD_TIMESHEET_FAILED,
        MakeUploadTimeLog(shift, file, uploadTimeStart, true, error)
      );
      if (error?.toString().includes("Request has been terminated")) {
        setUploadErrorMessage("Fail while uploading, please try again");
      } else {
        setUploadErrorMessage(error?.["message"] || error);
      }
      setUploading(false);
    }
  };

  useEffect(() => {
    if (
      modalToolbarHeight === 0 &&
      document.getElementsByClassName("toolbar-title-default")
    ) {
      setModalToolbarHeight(
        document.getElementsByClassName("toolbar-title-default")[0].clientHeight
      );
    }
  });

  return (
    <IonContent>
      <IonModal
        isOpen={showModal}
        cssClass="my-custom-class"
        swipeToClose={true}
        onDidDismiss={onCloseOrCancel}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>Timesheet Summary</IonTitle>
            <IonButtons slot="end">
              <IonButton routerDirection="back" onClick={onCloseOrCancel}>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonToast
            isOpen={!!uploadErrorMessage}
            message={uploadErrorMessage}
            onDidDismiss={() => {
              setUploadErrorMessage("");
            }}
            color="danger"
            position="top"
            duration={5000}
          />
          <IonGrid style={{ padding: 0 }}>
            <IonRow
              style={{
                height:
                  (window.innerHeight > window.innerWidth
                    ? window.innerWidth
                    : window.innerHeight) - modalToolbarHeight,
              }}
            >
              <IonCol size="3" className="shift-event-log-content">
                <ShiftTimeSummary
                  shift={shift}
                  submitClockInOut={submitClockInOut}
                  submitLunchInOut={submitLunchInOut}
                  onShiftTimeSave={onShiftTimeSave}
                  onShiftTimeEdit={onShiftTimeEdit}
                />
              </IonCol>
              <IonCol>
                {shift && !isSignatureSubmission ? (
                  <TimeSheetSubmission
                    shift={shift}
                    OnSubmit={SubmitTimesheet}
                    isShiftTimeEdit={isShiftTimeEdit}
                  />
                ) : (
                  <SignatureConfirmation
                    onSubmit={SubmitTimesheet}
                    isUploadingImage={uploading}
                    isShiftTimeEdit={isShiftTimeEdit}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};

export { TimeSheetSummary };
