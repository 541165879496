import React, {
  useRef,
  useState,
  FormEvent,
  useEffect,
  useCallback,
} from "react";
import {
  IonButtons,
  IonButton,
  IonBackButton,
  IonContent,
  IonHeader,
  IonLabel,
  IonToolbar,
  IonToast,
  IonSpinner,
  IonAlert,
  IonRow,
  IonCol,
  IonDatetime,
  IonItem,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { openBrowser } from "src/lib/ionic-components";
import moment from "moment-timezone";

import { reloadAgentProfile } from "../../store/session";
import { Store } from "../../store/store.model";

import { StyledIonPage } from "./style";
import { showSSNPage, updateAgentData, updateUnder18 } from "./api";
import { OnBoardingComponentProps } from "./model";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { OnboardingRouterPath } from "../../routing/constant/onboardingRoute";
import "../style.scss";
import Alert from "./Alert";
import { logOnboardingError } from "../util/logging";

const MIN_VALID_AGE = 18;

const AgentDoB: React.FC<OnBoardingComponentProps> = ({ nextStagePath }) => {
  const dobRef = useRef<HTMLIonDatetimeElement | null>(null);
  const dispatch = useDispatch();
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showNotValidAgeModal, setShowNotValidAgeModal] =
    useState<boolean>(false);
  const [showNotContinueModal, setShowNotContinueModal] =
    useState<boolean>(false);

  const closeNotValidAgeModal = () => {
    setShowNotValidAgeModal(false);
  };
  const closeNotContinueModal = () => {
    setShowNotContinueModal(false);
  };

  const { agent, profile } = useSelector((state: Store) => state.session);

  const maxDoB = moment().tz(agent?.tmz || "America/Los_Angeles");

  const validateDoB = (dob) => {
    if (!dob) return false;

    const newDoB = moment(dob).tz(agent?.tmz || "America/Los_Angeles");

    const diffInYears = maxDoB.diff(newDoB, "years");

    return diffInYears >= MIN_VALID_AGE;
  };

  const [dob, setDOB] = useState<string>(agent?.dob || maxDoB || "");

  const [isValidDoB, setIsValidDoB] = useState<boolean>(validateDoB(dob));

  const history = useHistory();

  const focusInput = async () => {
    await dobRef.current?.open();
  };

  useEffect(() => {
    const timeout = setTimeout(focusInput, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onNextFunction = async (
    event?: FormEvent | MouseEvent
  ): Promise<void> => {
    event && event.preventDefault();
    setShowErrorModal(false);
    try {
      setUpdatingData(true);
      await updateAgentData({
        stage: ONBOARDING_STAGES.DOB,
        dob,
      });
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_DOB, {
        hcpId: agent?.userId as string,
      });
      dispatch(reloadAgentProfile());
      const { showSSNFlag } = await showSSNPage();
      if (showSSNFlag) {
        history.push(`${OnboardingRouterPath.ONBOARDING_SSN}`);
        return;
      }
      history.push(`${nextStagePath}`);
    } catch (error) {
      logOnboardingError(
        ONBOARDING_STAGES.DOB,
        (error as Error).message,
        agent?.userId
      );
      setShowErrorModal(true);
      setPageError({
        isError: true,
        message: "Unexpected error occurred while saving dob",
      });
    } finally {
      setUpdatingData(false);
    }
  };

  const onNext = useCallback(
    (event?: FormEvent | MouseEvent) => {
      onNextFunction(event);
    },
    [profile, agent, dob]
  );

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleConfirmNotValidAge = async () => {
    closeNotValidAgeModal();
    setShowNotContinueModal(true);
    await updateUnder18({
      dob,
      underage: true,
    });
  };

  const handleChangeNotValidAge = async () => {
    closeNotValidAgeModal();
    await updateUnder18({
      dob,
      underage: false,
    });
  };

  const handleOnChange = (event: CustomEvent) => {
    const dob = event?.detail?.value;
    if (dob) {
      setDOB(dob.substring(0, 10));

      if (validateDoB(dob)) {
        setIsValidDoB(true);
      } else {
        setIsValidDoB(false);
        setShowNotValidAgeModal(true);
      }
    }
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentAddress"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
        />

        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <IonAlert
          cssClass={"alert-popup-container"}
          mode="ios"
          isOpen={showNotValidAgeModal}
          backdropDismiss={false}
          header="Are you under 18?"
          subHeader="You’ve selected a date of birth indicating that you are under 18 years old."
          message="Is this correct?"
          buttons={[
            {
              text: "I am under 18",
              handler: handleConfirmNotValidAge,
              cssClass: "positive-action",
            },
            {
              text: "Change my Date of Birth",
              handler: handleChangeNotValidAge,
              cssClass: "neutral-action",
            },
          ]}
        />
        <IonAlert
          cssClass={"alert-popup-container"}
          mode="ios"
          isOpen={showNotContinueModal}
          backdropDismiss={false}
          header="Sorry, you can’t continue."
          message="Clipboard does not allow individuals under 18 years of age to pick up work on its marketplace."
          buttons={[
            {
              text: "OK",
              handler: closeNotContinueModal,
              cssClass: "positive-action",
            },
          ]}
        />
        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>What is your date of birth?</h4>
                  </div>
                  <div>
                    <IonLabel className="form-label">Date of Birth</IonLabel>
                    <IonItem className="form-item-wrapper">
                      <IonDatetime
                        className="date-time-picker-dob"
                        ref={dobRef}
                        displayFormat="MMMM DD, YYYY"
                        max={maxDoB.format("YYYY-MM-DD")}
                        name="dob"
                        placeholder="mm/dd/yyyy"
                        value={dob}
                        onIonChange={handleOnChange}
                      />
                    </IonItem>
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>
          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={updatingData || !dob || !isValidDoB}
              onClick={onNext}
            >
              Continue
              {updatingData && (
                <IonSpinner slot="end" class="ion-margin-start" name="lines" />
              )}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentDoB };
