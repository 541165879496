import { Plugins } from "@capacitor/core";
import { logEvent } from "src/lib/analytics";
import { Events } from "src/lib/constants";
import { getHumanReadableTag } from "src/lib/utils";
import { isPlatform } from "@ionic/core";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { USER_EVENTS } from "../../constants/userEvents";
import { segmentInstance } from "../../utils/cbhSegementHelper";
import { updateAgentProfileState } from "../onboardingStripe/actions";
import { recordImpression } from "../pushNotifications/api";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { WelcomeRouterPath } from "../routing/constant/welcomeRoute";
import { Store } from "../store/store.model";
import { getMessageRecord } from "./api";
import { checkIfRouteValid } from "../utils/routeUtils";

const { CapacitorFirebaseDynamicLinks } = Plugins;

const DynamicLinkHandler: React.FC<{}> = () => {
  const { userId } = useSelector((state: Store) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  // const [agentId, setAgentId] = useState('')
  const [url, setUrl] = useState("");
  /**
   * this will push log the specfic message to the segment before pushing it to the view
   */
  const pushToLink = async () => {
    if (!userId || !url) return;
    const urlParsed = new URL(url);
    const methodValue = urlParsed.searchParams.get("method");
    const messageId = urlParsed.searchParams.get("messageId") as string;

    recordImpression(messageId, "sms");

    const userPhone = urlParsed.searchParams.get("phoneNumber");
    const preferenceType = urlParsed.searchParams.get("preferenceType");
    try {
      const response = await getMessageRecord(messageId, "sms");
      if (response.code === 0) {
        segmentInstance.track(userId.toString(), Events.messageOpened, {
          by: "Worker",
          message_name: getHumanReadableTag(methodValue),
          message: response.data?.message || "",
          method: methodValue,
          channel: "sms",
          channelType: preferenceType,
          notifiyId: messageId,
          userPhone,
        } as any);
      }
    } catch (ex) {
      // Ignore
    }
  };
  useEffect(() => {
    if (!isPlatform("capacitor")) return;
    CapacitorFirebaseDynamicLinks.addListener(
      "deepLinkOpen",
      (data: { url: string }) => {
        const { pathname } = new URL(data.url);
        setUrl(data.url);
        logEvent(USER_EVENTS.DEEP_LINK_OPENED, { url: data.url });
        if (pathname === "/__/auth/action") {
          if (data.url?.includes("linkVerify")) {
            history.push(WelcomeRouterPath.LOGIN_VERIFY_LINK, {
              url: data.url,
            });
          } else {
            history.push(WelcomeRouterPath.LOGIN_EMAIL_VERIFY, {
              url: data.url,
            });
          }
        } else if (pathname === `${TabRouterPath.SUCCESS_ONBOARDING}`) {
          dispatch(updateAgentProfileState({ alreadyOnboarded: true }));
          localStorage.setItem("lastOnboarded", JSON.stringify(moment()));
          logEvent(USER_EVENTS.COMPLETED_STRIPE_FLOW, {
            hcpID: userId?.toString() || "",
          });
          history.replace(`${TabRouterPath.SUCCESS_ONBOARDING}`, {
            url: data.url,
          });
        } else if (checkIfRouteValid(pathname)) {
          pushToLink();
          history.push(pathname);
        }
      }
    );
  }, [history]);

  useEffect(() => {
    pushToLink();
  }, [userId, url]);

  return <Fragment />;
};

export { DynamicLinkHandler };
