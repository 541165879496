import "./style.scss";
import { IonAlert, IonItem, IonLabel, IonToggle } from "@ionic/react";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import React, { useState, useEffect } from "react";
import { PreferenceItemProps } from "./model";
import { PermissionType, Plugins } from "@capacitor/core";
const { Permissions } = Plugins;

const PreferenceItem: React.FC<PreferenceItemProps> = ({
  name,
  preferenceKey,
  isBusy,
  initialValue,
  onUpdate,
  confirmAlertProps,
}) => {
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [
    showPushSettingsConfirmationAlert,
    setShowPushSettingsConfirmationAlert,
  ] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean | undefined>(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Updates toggle state based on change of prop initialValue
   */
  useEffect(() => {
    setIsChecked(initialValue);
  }, [initialValue]);

  /**
   * Handles toggle change event
   */
  const onToggleChange = async () => {
    if (isBusy || initialValue === !isChecked) return;

    setIsLoading(true);

    if (!isChecked) {
      setShowConfirmationAlert(true);
    } else {
      await onUpdate?.({
        key: preferenceKey as string,
        value: !isChecked,
      });
      setIsLoading(false);
    }
  };

  /**
   * Handles confirmation alert dismiss event when option is selected
   * @param isYesClicked of type boolean to know if user clicked Yes option
   */
  const onCloseAlert = (isYesClicked: boolean) => async () => {
    setShowConfirmationAlert(false);

    if (isYesClicked) {
      if (preferenceKey === "push") {
        const { state } = await Permissions.query({
          name: PermissionType.Notifications,
        });
        if (state === "denied") {
          setShowPushSettingsConfirmationAlert(true);
        } else {
          await onUpdate?.({
            key: preferenceKey,
            value: true,
          });
        }
      } else {
        await onUpdate?.({
          key: preferenceKey as string,
          value: true,
        });
      }
    }

    setIsLoading(false);
  };

  /**
   * Handles push settings confirmation alert dismiss event when option is selected
   * @param isYesClicked of type boolean to know if user clicked Yes option
   */
  const onClosePushSettingsAlert = (isYesClicked: boolean) => async () => {
    setShowPushSettingsConfirmationAlert(false);

    if (isYesClicked) {
      OpenNativeSettings.open("settings");
    }
  };

  return (
    <>
      <IonItem lines="full">
        <IonLabel>{name}</IonLabel>
        <IonToggle
          checked={isChecked}
          value={name}
          disabled={isBusy || isLoading}
          onIonChange={onToggleChange}
        />
        <IonAlert
          header={confirmAlertProps?.header}
          subHeader={confirmAlertProps?.subHeader}
          isOpen={showConfirmationAlert}
          buttons={[
            {
              text: confirmAlertProps?.buttonProps?.negativeName as string,
              role: "cancel",
              handler: onCloseAlert(false),
            },
            {
              text: confirmAlertProps?.buttonProps?.positiveName as string,
              handler: onCloseAlert(true),
            },
          ]}
        />
        {preferenceKey === "push" && (
          <IonAlert
            header={"Sign up for Push Notifications"}
            subHeader={`Please go to settings to allow Clipboard Health to send you push notifications`}
            isOpen={showPushSettingsConfirmationAlert}
            buttons={[
              {
                text: "No Thanks",
                role: "cancel",
                handler: onClosePushSettingsAlert(false),
              },
              {
                text: "Go to settings",
                handler: onClosePushSettingsAlert(true),
              },
            ]}
          />
        )}
      </IonItem>
    </>
  );
};

export { PreferenceItem };
