import { IonCard, IonText, IonSpinner } from "@ionic/react";
import React, { useState, useEffect, useCallback } from "react";
import { isPlatform } from "@ionic/core";
import { DocumentPreviewProps } from "./model";
import { last } from "lodash";
import { getCloudinaryDocument } from "./api";

const getExtension = (type: string | undefined): string => {
  if (!type) {
    return "";
  }
  const urlArray = type.toLowerCase().split("/");
  const extension = last(urlArray);
  return extension ? extension : "";
};

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  document,
  setPageError,
}) => {
  if (!document) {
    return null;
  }

  let { _id } = document;
  const [file, setFile] = useState<string>("");
  const [fileExtension, setExtension] = useState<string>("");

  const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);
  const isPDF = fileExtension === "pdf";
  const embedPDF = isPlatform("desktop");
  const [updatingData, setUpdatingData] = useState<boolean>(false);

  const fetchFile = useCallback(async () => {
    setUpdatingData(true);
    try {
      if (!_id) return;
      let fileDataUrl = await getCloudinaryDocument(_id);
      setExtension(getExtension(fileDataUrl?.type));
      setFile(URL.createObjectURL(fileDataUrl));
    } catch (error) {
      setPageError({
        isError: true,
        message:
          "The file could not be shown at the moment, Please try again later.",
      });
    } finally {
      setUpdatingData(false);
    }
  }, [_id]);

  useEffect(() => {
    fetchFile();
  }, [_id]);

  return (
    <IonCard target="_blank">
      {updatingData ? (
        <IonSpinner class="ion-margin" name="lines" />
      ) : (
        <>
          {isImage && <img alt="Document Preview" src={file} />}
          {embedPDF && isPDF && (
            <embed
              src={file}
              type="application/pdf"
              width="100%"
              height="500px"
            />
          )}
          {(!embedPDF || !isPDF) && !isImage && (
            <IonText>
              {" "}
              <h6 style={{ padding: ".5rem", margin: 0 }}>Document</h6>
            </IonText>
          )}
        </>
      )}
    </IonCard>
  );
};

export { DocumentPreview };
