import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import { Agent } from "src/lib/interface";
import {
  requestFacilityOpenShiftCountOptions,
  RequestOpenShiftOptions,
  ShiftCountDateMap,
} from "./model";
import { logFailedApiRetry } from "../utils/api_retry";

const sendCalendarOpenActivity = async (): Promise<void> => {
  try {
    await request
      .post(`${environment.baseUrl}/calendar/open`)
      .set(await getAuthHeader())
      .send({ isNative: true });
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const sendCalendarDayOpen = async (day: string): Promise<void> => {
  try {
    await request
      .post(`${environment.baseUrl}/calendar/dayOpen`)
      .set(await getAuthHeader())
      .send({ day, isNative: true });
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const fetchOpenShifts = async (
  options: RequestOpenShiftOptions
): Promise<ShiftCountDateMap> => {
  return await request
    .get(`${environment.baseUrl}/calendar/agentOpenShiftCount`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/calendar/agentOpenShiftCount`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ ...options, isAgent: true })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const updateDistancePreference = async (distance: number): Promise<Agent> => {
  return await request
    .post(`${environment.baseUrl}/agentProfile/preference`)
    .set(await getAuthHeader())
    .send({ key: "distance", value: distance })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const removeRecommendedDistance = async (): Promise<Agent> => {
  return await request
    .delete(`${environment.baseUrl}/agentProfile/recommendedDistance`)
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const fetchQualifiedfacilitiesEmptyShiftsCount = async (args: {
  agentUserId: string;
}): Promise<any> => {
  const { agentUserId } = args;
  return await request
    .get(`${environment.baseUrl}/v1/shifts/qualifiedFacilitiesEmptyShiftsCount`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/v1/shifts/qualifiedFacilitiesEmptyShiftsCount`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ agentUserId })
    .then(({ body }) => body.response)
    .catch((error) => {
      logApiFailureEvent(error);
      return error.response;
    });
};

const fetchFacilityOpenShiftCount = async (
  options: requestFacilityOpenShiftCountOptions
): Promise<any> => {
  return await request
    .get(`${environment.baseUrl}/calendar/facilityOpenShiftCount`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/calendar/facilityOpenShiftCount`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ ...options, isAgent: true })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const updatePreference = async (
  preference: string,
  value: number
): Promise<Agent> => {
  return await request
    .post(`${environment.baseUrl}/agentProfile/preference`)
    .set(await getAuthHeader())
    .send({ key: preference, value: value })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export {
  sendCalendarOpenActivity,
  sendCalendarDayOpen,
  fetchOpenShifts,
  updateDistancePreference,
  removeRecommendedDistance,
  fetchQualifiedfacilitiesEmptyShiftsCount,
  fetchFacilityOpenShiftCount,
  updatePreference,
};
