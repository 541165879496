import { findLastKey } from "lodash";

import { GlobalRouterPath, GlobalRouterPathInfo } from "./globalRoute";
import { RoutePathInfo } from "./interface";
import {
  OnboardingRouterPath,
  OnboardingRouterPathInfo,
} from "./onboardingRoute";
import { TabRouterPath, TabRouterPathInfo } from "./tabRoute";
import { WelcomeRouterPath, WelcomeRouterPathInfo } from "./welcomeRoute";

const RouterPath = {
  ...GlobalRouterPath,
  ...WelcomeRouterPath,
  ...OnboardingRouterPath,
  ...TabRouterPath,
};

const RouterPathInfo = {
  ...GlobalRouterPathInfo,
  ...WelcomeRouterPathInfo,
  ...OnboardingRouterPathInfo,
  ...TabRouterPathInfo,
};

const getRouterPathInfo = (path: string): RoutePathInfo | undefined => {
  const routerKey = findLastKey(RouterPath, (route) => route === path);
  if (!routerKey) return undefined;
  return RouterPathInfo[routerKey];
};

export { RouterPath, RouterPathInfo, getRouterPathInfo };
