import { CovidTestingAvailabilityText } from "@app/components/shift/CovidTestingAvailabilityText";
import { logEvent } from "src/lib/analytics";
import { geoDistance, formatPhone } from "src/lib/utils";
import { ScrollDetail } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { Collapse } from "antd";
import { closeOutline, home, stopwatchOutline } from "ionicons/icons";
import { get, has } from "lodash";
import moment from "moment-timezone";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLDClient } from "launchdarkly-react-client-sdk";

import CheckedInInstruction from "../../../assets/images/facilityDetails/check-in-instructions.svg";
import Checked from "../../../assets/images/facilityDetails/checked-status.svg";
import ChevronDown from "../../../assets/images/facilityDetails/chevron-compact-down.svg";
import Collapsed from "../../../assets/images/facilityDetails/collapse.svg";
import ExclamationMark from "../../../assets/images/facilityDetails/exclamation-mark.svg";
import Expand from "../../../assets/images/facilityDetails/expand.svg";
import MapDirections from "../../../assets/images/facilityDetails/map-directional.svg";
import { USER_EVENTS } from "../../../constants";
import { NOTES_CATEGORY } from "../../../constants/facility";
import { MessageString } from "../../../constants/strings";
import { environment } from "../../../environments/environment";
import { VerificationPreferenceDetails } from "@app/components/shift/verificationPreferenceDetails";
import {
  getNotesByCategory,
  getCheckInInstructionsObj,
} from "../../../utils/facility";
import { Store } from "../../store/store.model";
import { fetchRatings } from "../api";
import { ResponseRatings } from "../model";
import { CovidStatusCard } from "./covidStatusCard";
import { ExtraTimePayCard } from "./extraTimePayCard";
import { FacilityOpenShifts } from "./facilityOpenShifts";
import { FacilityReviews } from "./facilityReviews";
import { FacilityDetailsProps } from "./model";
import { StarRating } from "./starRating";
import "./style.scss";

enum COLLAPASABLE_PANELS {
  CHECK_IN_INSTRUCTIONS = "checkInInstructions",
  COVID_STATUS = "covidStatus",
  EXTRA_TIME_PAY = "extraTimePay",
}

const FacilityDetails: React.FC<FacilityDetailsProps> = ({
  facility,
  onClose,
  shift,
  showHeader = true,
  displayExtraTimePayCard = false,
}) => {
  const { Panel } = Collapse;
  const facilityOpenShiftsRef = useRef<HTMLDivElement>(null);
  const { agent = {} } = useSelector((state: Store) => state.session);
  const [overlay, setOverlay] = useState<string>("");
  const [distance, setDistance] = useState<{
    distance: number;
    isApproxDistance: boolean;
  }>({ distance: 0, isApproxDistance: false });
  const [facilitySreetImgUrl, setFacilitySreetImgUrl] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [isFacilityOpenShiftsShown, setIsFacilityOpenShiftsShown] =
    useState<boolean>(false);
  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const [extraPayRef, setExtraPayRef] = useState<any>(null);
  const facilityDescriptionObj = getNotesByCategory(
    facility.facilityNotes,
    NOTES_CATEGORY.PROF_DESC
  );
  const checkInInstructionsObj = getCheckInInstructionsObj(facility);
  const [facilityRatings, setFacilityRatings] = useState<ResponseRatings>({
    avg: 0,
    total: 0,
  });
  const ratings = {
    avg: facilityRatings?.avg || 0,
    total: facilityRatings?.total || 0,
  };
  const showRatings = ratings.avg < 3 && ratings.total < 5 ? 0 : ratings.avg;
  const date = moment().format("YYYY-MM-DD");
  const isCovidTestAvailable =
    shift?.facility?.covidData?.testingAvailabilityOnsite;
  const ldClient = useLDClient();

  const extraTimePayEnabled =
    facility?.isETPFlagEnabled && facility?.tbioOptions?.enabled;
  const showingETPIcon =
    extraTimePayEnabled && ldClient?.variation("display-extra-time-pay", false);
  const etpEventOptions = {
    extra_time_pay_feature_flag_enabled: facility?.isETPFlagEnabled
      ? "yes"
      : "no",
    extra_time_pay_enabled: extraTimePayEnabled ? "yes" : "no",
    showing_extra_time_pay_icon: showingETPIcon ? "yes" : "no",
  };

  const goToOpenShiftsButton = () => {
    const { offsetTop } = document.getElementById("shifts-list") as HTMLElement;
    const content = document.querySelector(
      "#profile-container"
    ) as HTMLIonContentElement;

    content.scrollToPoint(0, offsetTop);
  };

  const getFacilityRatings = useCallback(async () => {
    const facilityRatings = await fetchRatings({
      facilityId: facility?.userId,
      reviewFor: "FACILITY",
    });
    setFacilityRatings(facilityRatings);
  }, [fetchRatings, facility?.userId]);

  const getFacilityDetails = useCallback(async () => {
    const getFacilityGeoDistance = () => {
      const facilityCoordinates: number[] | undefined =
        shift?.facility?.geoLocation?.coordinates ||
        facility?.geoLocation?.coordinates;
      const agentCoordinates: number[] = get(
        agent,
        "geoLocation.coordinates",
        []
      );

      let distanceInMiles: { distance: number; isApproxDistance: boolean } = {
        distance: 0,
        isApproxDistance: false,
      };
      if (facility.distance) {
        distanceInMiles.distance = parseFloat(facility.distance.toFixed(2));
        distanceInMiles.isApproxDistance = facility.isApproxDistance ?? false;
      } else {
        distanceInMiles.distance = geoDistance(
          agentCoordinates,
          facilityCoordinates as number[]
        );
        distanceInMiles.isApproxDistance = true;
      }
      if (distanceInMiles.isApproxDistance) {
        distanceInMiles.distance = Math.round(2 * distanceInMiles.distance) / 2;
      }

      setDistance(distanceInMiles);
    };
    const getHcfPhoto = () => {
      const facilityCoordinates: number[] = get(
        facility,
        "geoLocation.coordinates",
        []
      );
      const streetImgUrl = `${environment.googleStreetMapViewApiUrl}?size=500x500&location=${facilityCoordinates[1]},${facilityCoordinates[0]}&key=${environment.googleMapApi}`;
      setFacilitySreetImgUrl(streetImgUrl);
    };

    try {
      setLoader(true);
      getFacilityGeoDistance();
      getHcfPhoto();
      setLoader(false);
    } catch (ex) {
      setLoader(false);
    }
  }, [shift, agent, facility]);

  // On load, send event with etp options
  useEffect(() => {
    logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, etpEventOptions);
  }, []);

  useEffect(() => {
    getFacilityDetails();
  }, [facilitySreetImgUrl, getFacilityDetails]);

  useEffect(() => {
    getFacilityRatings();
  }, [getFacilityRatings]);

  useLayoutEffect(() => {
    if (extraPayRef && displayExtraTimePayCard) {
      setTimeout(() => {
        extraPayRef.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 1000);
    }
  });

  const checkInHeader = (
    <IonItem lines="none" class="card-item-height">
      <img alt="check-in" src={CheckedInInstruction} />
      <IonLabel color="text" className="card-header-label">
        {" "}
        Check in instructions
      </IonLabel>
    </IonItem>
  );
  const importantInfoHeader = (
    <IonItem lines="none" class="card-item-height">
      <img alt="covid-19-status" src={ExclamationMark} />
      <IonLabel color="text" className="card-header-label">
        {" "}
        Important information
      </IonLabel>
    </IonItem>
  );
  const covidStatusHeader = (
    <IonItem lines="none" class="card-item-height">
      <img
        alt="covid-19-status"
        src={
          has(facility, "covidData") && facility?.covidData?.covidActive
            ? ExclamationMark
            : Checked
        }
      />
      <IonLabel color="text" className="card-header-label">
        {" "}
        COVID-19 Status
      </IonLabel>
    </IonItem>
  );
  const extraTimePayHeader = (
    <IonItem lines="none" class="card-item-height">
      <IonIcon
        icon={stopwatchOutline}
        className="extra-time-pay-panel-header-icon"
      />
      <IonLabel color="text" className="card-header-label">
        {" "}
        Extra Time Pay
      </IonLabel>
    </IonItem>
  );

  const groupStyle = {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  };

  const onCheckInInfoPanelClick = (key: string | string[]) => {
    if (key.includes(COLLAPASABLE_PANELS.CHECK_IN_INSTRUCTIONS)) {
      logEvent(USER_EVENTS.VIEWED_CHECK_IN_INSTRUCTIONS);
    }
  };

  const onCovidStatusPanelClick = (key: string | string[]) => {
    if (key.includes(COLLAPASABLE_PANELS.COVID_STATUS)) {
      logEvent(USER_EVENTS.VIEWED_COVID_STATUS);
    }
  };
  const onExtraTimePayPanelClick = (key: string | string[]) => {
    if (key.includes(COLLAPASABLE_PANELS.EXTRA_TIME_PAY)) {
      logEvent(USER_EVENTS.VIEWED_EXTRA_TIME_PAY_PANEL, etpEventOptions);
    }
  };

  const onMapsClick = (overlay: string) => () => {
    logEvent(USER_EVENTS.TAPPED_OPEN_MAPS);
    if (isPlatform("ios")) {
      setOverlay(overlay);
    } else {
      openGooleMaps();
    }
  };

  const handleCancel = () => {
    setOverlay("");
  };

  const openGooleMaps = () => {
    const facilityCoordinates: number[] = get(
      facility,
      "geoLocation.coordinates",
      []
    );
    logEvent(USER_EVENTS.TAPPED_GOOGLE_MAPS);
    window.open(
      ` ${environment.googleMapSearchUrl}&query=${facilityCoordinates[1]},${facilityCoordinates[0]}`,
      "_blank"
    );
  };

  const openAppleMaps = () => {
    const facilityCoordinates: number[] = get(
      facility,
      "geoLocation.coordinates",
      []
    );
    logEvent(USER_EVENTS.TAPPED_APPLE_MAPS);
    window.open(
      `${environment.appleMapSearchUrl}/?q=${facility?.fullAddress?.formatted}&ll=${facilityCoordinates[1]},${facilityCoordinates[0]}`,
      "_blank"
    );
  };

  const onIonScroll = (e: CustomEvent<ScrollDetail> | any) => {
    if (e.target.clientHeight && e.detail?.currentY) {
      onScrollToShifts(e.target.clientHeight, e.detail.currentY);
    }
  };

  const onScrollToShifts = (currentY: number, offset: number) => {
    if (
      currentY + offset >=
        (facilityOpenShiftsRef?.current?.offsetTop as number) &&
      !isFacilityOpenShiftsShown
    ) {
      setIsFacilityOpenShiftsShown(true);
    }
  };

  const onClickFacilityStars = () => {
    if (showRatings) {
      setShowReviewsModal(true);
    }
  };

  const openMapsOverlay = () => (
    <div
      id="overlay-section"
      style={{
        zIndex: 300,
        position: "fixed",
        bottom: "1rem",
        left: 0,
        right: 0,
        top: "5rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IonItemGroup style={groupStyle}>
        <div className="ion-text-center ion-margin open-maps-container">
          <IonText onClick={openAppleMaps}>Open in maps</IonText>
          <hr />
          <IonText onClick={openGooleMaps}>Open in google maps</IonText>
        </div>
        <div className="ion-text-center ion-margin open-maps-cancel-btn">
          <IonText onClick={handleCancel}>Cancel</IonText>
        </div>
      </IonItemGroup>
    </div>
  );

  const stripVisibleToHCPSuffix = (instruction) => {
    instruction.category = instruction.category.trim();
    instruction.category = instruction.category.endsWith(" (Visible to HCP)")
      ? instruction.category.replace(" (Visible to HCP)", "")
      : instruction.category;
    return instruction;
  };

  const getPresentCheckInInstructions = (checkInInstructionsObj) => {
    const checkInInstructionsArr = [
      checkInInstructionsObj.CHECK_IN_INST_PARKING,
      checkInInstructionsObj.CHECK_IN_INST_ENTRANCE,
      checkInInstructionsObj.CHECK_IN_INST_ORIENTATION,
      checkInInstructionsObj.CHECK_IN_INST_EARLYCHECKIN,
      checkInInstructionsObj.CHECK_IN_INST_FIRSTSHIFT,
      checkInInstructionsObj.CHECK_IN_INST_TIMECARD,
      checkInInstructionsObj.CHECK_IN_INST_DRESS,
    ];
    return checkInInstructionsArr
      .filter(
        (instruction) =>
          instruction &&
          instruction.note &&
          instruction.note.trim() &&
          instruction.category &&
          instruction.category.trim()
      )
      .map((instruction) => stripVisibleToHCPSuffix(instruction));
  };

  const hasCheckInInstructionsPresent = (checkInInstructionsObj) => {
    return getPresentCheckInInstructions(checkInInstructionsObj).length > 0;
  };

  const shouldShowCheckInInstructions = (checkInInstructionsObj) => {
    return (
      checkInInstructionsObj &&
      (checkInInstructionsObj.CHECK_IN_INST ||
        hasCheckInInstructionsPresent(checkInInstructionsObj))
    );
  };

  const getCheckInInstructionsMap = (checkInInstructionsObj) => {
    const checkInInstructionsArr = {};
    if (
      !hasCheckInInstructionsPresent(checkInInstructionsObj) &&
      checkInInstructionsObj.CHECK_IN_INST
    ) {
      checkInInstructionsArr[
        stripVisibleToHCPSuffix(checkInInstructionsObj.CHECK_IN_INST).category
      ] = checkInInstructionsObj.CHECK_IN_INST.note;
    } else {
      getPresentCheckInInstructions(checkInInstructionsObj).forEach(
        (instruction) => {
          checkInInstructionsArr[instruction.category] = instruction.note;
        }
      );
    }
    return checkInInstructionsArr;
  };

  const getCheckInInstructionsMessage = (checkInInstructionsObj) => {
    if (shouldShowCheckInInstructions(checkInInstructionsObj)) {
      return getCheckInInstructionsMap(checkInInstructionsObj);
    }
    return null;
  };

  const checkInInstructionsMessage = getCheckInInstructionsMessage(
    checkInInstructionsObj
  );

  return (
    <Fragment>
      {showHeader && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                routerDirection="back"
                fill="clear"
                size="small"
                color="light"
                onClick={onClose}
              >
                <IonIcon icon={closeOutline} mode="ios" />
              </IonButton>
            </IonButtons>
            <IonTitle>{facility.name || "Facility Details"}</IonTitle>
          </IonToolbar>
        </IonHeader>
      )}
      {showRatings && facility?.userId ? (
        <IonModal
          cssClass="facilityReviews-modal"
          isOpen={showReviewsModal}
          onDidDismiss={() => setShowReviewsModal(false)}
        >
          <FacilityReviews
            avgRating={ratings.avg}
            facilityId={facility.userId}
            totalRating={ratings.total}
          />
          <IonButton
            strong
            expand="full"
            fill="clear"
            onClick={() => setShowReviewsModal(false)}
          >
            Ok
          </IonButton>
        </IonModal>
      ) : null}
      {loader ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <IonSpinner slot="start" name="lines" />
          <IonLabel color="text">
            <h2 className="facility-srteet-map-view">
              {" "}
              Loading Facility Details{" "}
            </h2>
          </IonLabel>
        </div>
      ) : (
        <IonContent
          id="profile-container"
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={onIonScroll}
          onIonScrollEnd={() => {}}
        >
          <div className="facility-photo" id="facility-street-img">
            <img
              alt="facility-srteet-map-view"
              className="facility-srteet-map-view"
              src={facilitySreetImgUrl}
            />
          </div>

          <div
            onClick={onClickFacilityStars}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: 12,
              cursor: showRatings ? "pointer" : "not-allowed",
            }}
          >
            <StarRating
              value={showRatings}
              color={showRatings ? "#032E68" : "#BDBDBD"}
              size={30}
            />
            <span style={{ color: showRatings ? "#032E68" : "#BDBDBD" }}>
              {showRatings
                ? `${ratings.total} rating${ratings.total > 1 ? "s" : ""}`
                : "not enough ratings yet"}
            </span>
          </div>
          <IonList lines="none" className="facility-profile">
            <IonItem lines="none" class="card-item-height">
              <IonLabel color="text">
                <h2 className="facility-name-title">{facility.name}</h2>
                <p className="ion-text-wrap">
                  {facility?.fullAddress?.formatted}
                </p>
                <p>
                  {formatPhone(
                    facility?.phone as string,
                    environment.mobileCode
                  )}
                </p>
                <IonIcon icon={home} />
                <span>
                  {` ${distance.isApproxDistance ? "~" : ""}${
                    distance.distance
                  } miles away`}
                </span>
              </IonLabel>
              <IonButton
                style={{ fontSize: "16px", height: "80px" }}
                fill="clear"
                size="small"
                onClick={onMapsClick("overlay")}
              >
                <img alt="check-in-collapse" src={MapDirections} />
              </IonButton>
            </IonItem>
            <div className="verification-details">
              <VerificationPreferenceDetails facilityId={facility.userId} />
            </div>
          </IonList>

          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton
                fill="outline"
                shape="round"
                strong={true}
                className="shift-ion-button ion-button"
                onClick={() => goToOpenShiftsButton()}
              >
                <div>
                  Open Shifts
                  <br />
                  <img alt="check-in-collapse" src={ChevronDown} />
                </div>
              </IonButton>
            </IonCol>
          </IonRow>

          {facilityDescriptionObj && facilityDescriptionObj.note && (
            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? (
                  <img alt="check-in-collapse" src={Expand} />
                ) : (
                  <img alt="check-in-collapse" src={Collapsed} />
                )
              }
              bordered={false}
              className="site-collapse-header"
              expandIconPosition={"right"}
              defaultActiveKey={["1"]}
            >
              <Panel
                header={importantInfoHeader}
                key="1"
                className="site-collapse-header-panel important-info-disabled"
                showArrow={false}
              >
                <div className="site-collapse-panel-inner-text">
                  <IonText>
                    <p className="card-section-body">
                      {" "}
                      {`As of ${moment(facilityDescriptionObj.updatedAt).format(
                        "MMM DD, YYYY"
                      )} `}{" "}
                    </p>
                  </IonText>
                  <IonText>
                    <p className="card-section-body">
                      {facilityDescriptionObj.note}
                      <br />
                      <br />
                      <i>
                        {
                          MessageString.importantInformationFromHCFDisclaimerText
                        }
                      </i>
                    </p>
                  </IonText>
                </div>
              </Panel>
            </Collapse>
          )}

          {checkInInstructionsMessage && (
            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? (
                  <img alt="check-in-collapse" src={Expand} />
                ) : (
                  <img alt="check-in-collapse" src={Collapsed} />
                )
              }
              bordered={false}
              className="site-collapse-header"
              expandIconPosition={"right"}
              onChange={onCheckInInfoPanelClick}
            >
              <Panel
                header={checkInHeader}
                key={COLLAPASABLE_PANELS.CHECK_IN_INSTRUCTIONS}
                className="site-collapse-header-panel"
              >
                <div className="site-collapse-panel-inner-text">
                  {isCovidTestAvailable && (
                    <CovidTestingAvailabilityText
                      message="This facility performs an on-site rapid COVID test before each shift."
                      customClass="covid-testing-availability-text"
                    />
                  )}
                  <p className="card-section-body">
                    <ul>
                      {Object.keys(checkInInstructionsMessage).map(
                        (name, index) => (
                          <li key={index}>
                            <IonText>
                              <span className="check-in-instruction-category-name">
                                {name}
                              </span>
                              :{" "}
                              <span className="check-in-instruction-category-description">
                                {checkInInstructionsMessage[name]}
                              </span>
                            </IonText>
                          </li>
                        )
                      )}
                    </ul>
                  </p>
                </div>
              </Panel>
            </Collapse>
          )}

          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <img alt="check-in-collapse" src={Expand} />
              ) : (
                <img alt="check-in-collapse" src={Collapsed} />
              )
            }
            bordered={false}
            className="site-collapse-header"
            expandIconPosition={"right"}
            onChange={onCovidStatusPanelClick}
          >
            <Panel
              header={covidStatusHeader}
              key={COLLAPASABLE_PANELS.COVID_STATUS}
              className="site-collapse-header-panel"
            >
              <div className="site-collapse-panel-inner-text">
                {facility?.covidData && facility?.covidData?.covidActive ? (
                  <CovidStatusCard facility={facility} shift={shift} />
                ) : (
                  <Fragment>
                    <IonItem lines="none" class="covid-item-background">
                      <span className="covid-input-title">
                        {" "}
                        {`As of ${moment(date).format("MMM DD, YYYY")} `}{" "}
                      </span>
                    </IonItem>
                    <IonItem lines="none" class="covid-item-background">
                      <span className="covid-input-title">
                        <b>
                          {` There are no reported cases of COVID-19 at ${facility.name}`}{" "}
                        </b>
                      </span>
                    </IonItem>
                  </Fragment>
                )}
              </div>
            </Panel>
          </Collapse>

          {facility.isETPFlagEnabled && (
            <div
              className={`extra-pay-widget${
                displayExtraTimePayCard ? " padding-top" : ""
              }`}
              ref={setExtraPayRef}
            >
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <img alt="check-in-collapse" src={Expand} />
                  ) : (
                    <img alt="check-in-collapse" src={Collapsed} />
                  )
                }
                bordered={false}
                className="site-collapse-header"
                expandIconPosition={"right"}
                onChange={onExtraTimePayPanelClick}
                defaultActiveKey={
                  displayExtraTimePayCard
                    ? COLLAPASABLE_PANELS.EXTRA_TIME_PAY
                    : undefined
                }
              >
                <Panel
                  header={extraTimePayHeader}
                  key={COLLAPASABLE_PANELS.EXTRA_TIME_PAY}
                  className="site-collapse-header-panel"
                >
                  <div className="site-collapse-panel-inner-text">
                    <ExtraTimePayCard facility={facility} shift={shift} />
                  </div>
                </Panel>
              </Collapse>
            </div>
          )}

          {overlay.length > 0 && openMapsOverlay()}
          <div id="shifts-list">
            {isFacilityOpenShiftsShown ? (
              <FacilityOpenShifts facility={facility} onClose={onClose} />
            ) : (
              // for default min-height after scroll
              <div className="facility-openshifts"></div>
            )}
            <div ref={facilityOpenShiftsRef}></div>
          </div>
        </IonContent>
      )}
    </Fragment>
  );
};

export { FacilityDetails };
