import { connect } from "react-redux";
import { Store } from "../store/store.model";
import { AppLoading } from "./appLoading";
import { NoUser as NoUserPage } from "./noUser";

const loadingProps = (state: Store) => ({
  hasSession: state.session.hasSession,
});

const AppLoadingPage = connect(loadingProps)(AppLoading);

export { AppLoadingPage, NoUserPage };
