import { IonAlert } from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Store } from "@store/store.model";

import { ActionType, Popups } from "@store/session";
import { logEvent } from "src/lib/analytics";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { USER_EVENTS } from "../../constants";
import "./style.scss";

const SignICAgreementModal = () => {
  const { popups } = useSelector((state: Store) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    ICAgreement: { visible, showMessageOnly } = {
      visible: false,
      showMessageOnly: false,
    },
  } = popups as Popups;

  const onShowICAgreementConfirm = () => {
    logEvent(USER_EVENTS.SUBMITTED_FACILITY_REVIEW);
    history.replace(TabRouterPath.UPDATED_CONTRACTOR_AGREEMENT, {
      redirectTo: history.location.pathname,
    });
    dispatch({ type: ActionType.HIDE_IC_AGREEMENT_POPUP });
  };
  const onCancelShowICAgreementPopup = () => {
    logEvent(USER_EVENTS.REVIEW_AND_SIGN_CANCEL);
    dispatch({ type: ActionType.HIDE_IC_AGREEMENT_POPUP });
  };

  return (
    <>
      {visible && (
        <IonAlert
          isOpen={true}
          header="Review New Agreement"
          message="We have updated our Independent Contractor Agreement. Please review and sign to continue booking shifts."
          cssClass="sign-ic-agreement-popup"
          mode="ios"
          backdropDismiss={!showMessageOnly}
          onDidDismiss={() =>
            dispatch({ type: ActionType.HIDE_IC_AGREEMENT_POPUP })
          }
          buttons={
            !showMessageOnly
              ? [
                  {
                    text: "Review and Sign",
                    cssClass: "review-and-sign",
                    handler: onShowICAgreementConfirm,
                  },
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "review-and-sign-cancel",
                    handler: onCancelShowICAgreementPopup,
                  },
                ]
              : [
                  {
                    text: "Review and Sign",
                    cssClass: "review-and-sign",
                  },
                ]
          }
        />
      )}
    </>
  );
};

export { SignICAgreementModal };
