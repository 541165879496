import { NetworkStatus } from "@capacitor/core";
import { InfoCircleOutlined } from "@ant-design/icons";
import { InlineLoader, ShiftCalendar } from "src/lib/ionic-components";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidLeave,
} from "@ionic/react";

import { get, isEmpty, reduce } from "lodash";
import moment, { Moment } from "moment-timezone";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logEvent } from "src/lib/analytics";
import { calendarVisibleDateRange, DateRange } from "src/lib/utils";
import {
  USER_EVENTS,
  DISTANCE_PREFERENCE_MAX_VALUE,
  SHIFT_PREFERENCE,
  SEARCH_MODE,
} from "src/constants";
import { ResponseCodes } from "../../utils/response";
import { ShowAPIFailError } from "../404Pages/showAPIFailError";
import { fetchCurrentTimezone, fetchYourShifts } from "../dayView/api";
import { fetchLastMinuteShifts } from "../lastMinuteShifts/api";
import { LastMinuteShiftsCard } from "../lastMinuteShifts/lastMinuteShiftsCard";
import { FeatureName, hcpAppLogger, LogArea, LogType } from "../remoteLoggers";
import { ActionType } from "../store/shift/model";
import { updateAgent } from "../store/session";
import { Store } from "../store/store.model";
import { fetchOpenShifts, sendCalendarDayOpen } from "./api";
import {
  RequestOpenShiftOptions,
  ShiftCountDateMap,
  ShiftDateMap,
} from "./model";
import { ShiftFilters } from "src/app/components/shiftFilters";
import { markAgentActive } from "../onboarding/components/api";
import { computeShiftsCountCalendar } from "src/utils/openShifts";
import { AgentStages } from "src/lib/interface";
import useFetchHcpBonuses from "@app/hcpBonuses/useFetchHcpBonuses";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ShiftCalendarDate } from "./calendar/shiftCalendarDate";
import { ShiftCalendarDateChips } from "./calendar/shiftCalendarDateChips";

interface OpenShiftCalendarInterface {
  networkStatus: NetworkStatus;
  showLastMinuteShiftsCard: boolean;
  setShowLastMinuteShiftsCard: React.Dispatch<React.SetStateAction<boolean>>;
  segmentView: string;
}

const OpenShiftCalendar: React.FC<OpenShiftCalendarInterface> = ({
  networkStatus,
  showLastMinuteShiftsCard,
  setShowLastMinuteShiftsCard,
  segmentView,
}) => {
  const selectedDate = useRef(moment());
  const calendarRef = useRef<HTMLIonContentElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [myShifts, setMyShifts] = useState<ShiftDateMap | undefined>();
  const [openShifts, setOpenShifts] = useState<ShiftCountDateMap>();
  const [isCurrentMonthSelected, setCurrentMonth] = useState(
    selectedDate.current.month()
  );
  const [requestFailedError, setRequestFailedError] = useState(null);
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const [didFetchComplete, setDidFetchComplete] = useState(false);

  const ldFlags = useFlags();
  const isHcpBonusesEnabled = ldFlags["hcp-bonuses"] as boolean;
  const { bonuses } = useFetchHcpBonuses(agent.tmz);

  const onDateSelect = (date: Moment) => {
    if (isCurrentMonthSelected === date.month()) {
      logEvent(USER_EVENTS.TAPPED_CALENDAR_DATE);
      const day = date.format("YYYY-MM-DD");
      sendCalendarDayOpen(day);
      history.push(`/home/openShifts/${day}`);
    }
  };

  const distancePreference = useMemo(() => {
    return get(agent, "preference.distance", DISTANCE_PREFERENCE_MAX_VALUE);
  }, [agent?.preference?.distance]);

  const minPayHourlyPreference = useMemo(() => {
    return get(agent, "preference.minPayHourly", SHIFT_PREFERENCE.PAY_HOUR);
  }, [agent?.preference?.minPayHourly]);

  const minPayShiftPreference = useMemo(() => {
    return get(agent, "preference.minPayShift", SHIFT_PREFERENCE.PAY_SHIFT);
  }, [agent?.preference?.minPayShift]);

  const baseRate = useMemo(() => {
    return get(agent, "baseRate", SHIFT_PREFERENCE.BASE_RATE);
  }, [agent.baseRate]);

  const updateDateRange = useCallback(
    async (date?: Moment) => {
      const tmz = await fetchCurrentTimezone();
      const currentDate = date ? date : selectedDate.current;
      setCurrentMonth(currentDate.month());
      setRequestFailedError(null);
      if (
        !agent._id ||
        (date && date.month() === selectedDate.current.month())
      ) {
        return;
      }

      if (isEmpty(agent.geoLocation)) {
        return dispatch({
          type: ActionType.UPDATE_LOCATION_AVAILABILITY,
        });
      }

      setLoading(true);
      let range: DateRange | null = null;

      if (date) {
        range = calendarVisibleDateRange(date);
        selectedDate.current = date;
      } else {
        range = calendarVisibleDateRange(selectedDate.current);
      }

      const options: RequestOpenShiftOptions = {
        dateFilter: range,
        qualification: agent.qualification,
        coordinates: agent.geoLocation.coordinates,
        specialities: agent.specialities,
        distance: distancePreference,
        minPayHourly: minPayHourlyPreference,
        minPayShift: minPayShiftPreference,
        tmz: tmz,
      };

      try {
        fetchYourShifts(range).then(setMyShifts);
        const openShifts = await fetchOpenShifts(options);
        setOpenShifts(openShifts);
        setLoading(false);
      } catch (error) {
        setRequestFailedError(error);
        setLoading(false);
        const { status, statusText, text } = error.response || {};
        hcpAppLogger({
          logType: LogType.ERROR,
          title: "Open shifts API failed to return response",
          logArea: LogArea.SERVER,
          featureName: FeatureName.OPEN_SHIFTS_API,
          hcpMobile: agent.phone,
          hcpEmail: agent.email,
          details: {
            status: status || error.status,
            statusText,
            text,
            data: options,
          },
        });
      }
    },
    [agent, dispatch]
  );

  const getLastMinuteShiftsCount = async () => {
    try {
      const response = await fetchLastMinuteShifts({
        getCountOnly: true,
      });

      if (response.code === ResponseCodes.Success) {
        const { lastMinuteShifts, notificationPreferences } = response.data;

        if (
          lastMinuteShifts > 0 &&
          !notificationPreferences.last_minute_notifications.isSnoozed &&
          notificationPreferences.last_minute_notifications.isOn
        )
          setShowLastMinuteShiftsCard(true);
        else setShowLastMinuteShiftsCard(false);
      }
    } catch (err) {
      const { status, statusText, text } = err.response || {};
      hcpAppLogger({
        logType: LogType.ERROR,
        title: "getLastMinuteShiftsCount failed to return response",
        logArea: LogArea.SERVER,
        featureName: "GET_LAST_MINUTE_SHIFTS_COUNT",
        hcpMobile: agent.phone,
        hcpEmail: agent.email,
        details: {
          status: status || err.status,
          statusText,
          text,
          data: {
            qualification: agent.qualification,
            userId: agent.userId,
          },
        },
      });
    }
  };

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    await updateDateRange();
    await getLastMinuteShiftsCount();
    event.detail.complete();
  };

  useEffect(() => {
    if (segmentView === "calendar" && !didFetchComplete) {
      updateDateRange();
      getLastMinuteShiftsCount();
      setDidFetchComplete(true);
    }
  }, [
    segmentView,
    distancePreference,
    minPayHourlyPreference,
    minPayShiftPreference,
  ]);

  useEffect(() => {
    if (!agent.active) {
      markAgentActive(agent.userId);
      dispatch(updateAgent({ active: true }));
    }
  }, []);

  useIonViewDidLeave(() => setDidFetchComplete(false));

  useEffect(() => {
    const isAfter15th = moment().isAfter(moment("14", "DD"));
    if (!isAfter15th) return;
    if (networkStatus.connected) {
      const position = calendarRef?.current?.offsetTop as number;
      calendarRef?.current?.scrollToBottom?.(position + 100);
    }
  }, []);

  let stageWarning = useMemo(() => {
    if (
      [AgentStages.Suspended, AgentStages.Deactivated].includes(agent?.stage)
    ) {
      const [text, bannerClass] =
        agent.stage === AgentStages.Suspended
          ? ["Your account is suspended", "warning-banner"]
          : ["Your account has been deactivated", "danger-banner"];

      const link =
        "http://help.clipboard.health/en/articles/5905389-account-suspensions-deactivation";

      return (
        <div className={bannerClass}>
          {" "}
          <InfoCircleOutlined /> {text}. For more info click{" "}
          <a href={link} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </div>
      );
    }

    return undefined;
  }, [agent?.stage]);

  return (
    <Fragment>
      {!networkStatus.connected ? (
        <ShowAPIFailError networkStatus={networkStatus.connected} />
      ) : (
        <>
          <IonContent ref={calendarRef}>
            {loading && !refreshing && (
              <div style={{ textAlign: "center" }}>
                <InlineLoader loading={true} />
              </div>
            )}
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            {!requestFailedError ? (
              <div className="d-flex flex-column">
                {showLastMinuteShiftsCard && (
                  <LastMinuteShiftsCard
                    agentName={agent.name}
                    agentUserId={agent.userId}
                    onClose={() => setShowLastMinuteShiftsCard(false)}
                  />
                )}
                {stageWarning}
                {!stageWarning && agent?.preference?.distance && (
                  <ShiftFilters
                    openShiftsEmpty={isEmpty(openShifts)}
                    totalShifts={computeShiftsCountCalendar(openShifts)}
                    hiddenShifts={computeShiftsCountCalendar(
                      openShifts,
                      "hidden"
                    )}
                    baseRate={baseRate}
                    distancePreference={distancePreference}
                    minPayHourlyPreference={minPayHourlyPreference}
                    minPayShiftPreference={minPayShiftPreference}
                    searchMode={SEARCH_MODE.CALENDAR}
                    updateOpenShifts={() => {
                      setDidFetchComplete(false);
                    }}
                  />
                )}
                <div className="d-block">
                  <ShiftCalendar
                    dateFullCellRender={(date) => {
                      return (
                        <ShiftCalendarDate
                          date={date}
                          renderShiftChip={() => {
                            return (
                              <ShiftCalendarDateChips
                                date={date}
                                openShiftMap={openShifts}
                                myShiftMap={myShifts}
                                qualification={agent.qualification}
                              />
                            );
                          }}
                          openShiftMap={openShifts}
                          isHcpBonusesEnabled={isHcpBonusesEnabled}
                          bonuses={bonuses}
                        />
                      );
                    }}
                    defaultValue={selectedDate.current}
                    onPanelChange={updateDateRange}
                    onSelect={onDateSelect}
                  />
                </div>
              </div>
            ) : (
              <ShowAPIFailError
                networkStatus={networkStatus.connected}
                date={selectedDate.current}
                onRefresh={updateDateRange}
              />
            )}
          </IonContent>
        </>
      )}
    </Fragment>
  );
};

export { OpenShiftCalendar };
