import { IonCardSubtitle } from "@ionic/react";
import { environment } from "@env/environment";
import React from "react";
import { formatPhone } from "src/lib/utils";
import {
  clockInReminderText,
  clockOutReminderText,
  forgotClockIn,
  forgotClockOut,
  instruction,
  notInstantPay,
  supportText,
} from "../../../constants/ShiftInstructionMessages";
const NonIPConversionText: React.VFC<{
  hide?: boolean;
  hasMissedClockIn: boolean;
  isAutoClockedOut: boolean;
}> = ({ hide, hasMissedClockIn, isAutoClockedOut }) => {
  if (hide) {
    return <></>;
  }
  return (
    <div>
      {isAutoClockedOut ? (
        <div>
          <IonCardSubtitle className="title no-text-transform ion-margin-top">
            {forgotClockOut}
          </IonCardSubtitle>
          <p>
            {notInstantPay}
            <br />
            {instruction}
            <br />
            {supportText}
            {formatPhone(environment.support.phone)}
          </p>
          <p>{clockOutReminderText}</p>
        </div>
      ) : hasMissedClockIn ? (
        <div>
          <IonCardSubtitle className="title no-text-transform ion-margin-top">
            {forgotClockIn}
          </IonCardSubtitle>
          <p>
            {notInstantPay}
            <br />
            {instruction}
            <br />
            {supportText}
            {formatPhone(environment.support.phone)}
          </p>
          <p>{clockInReminderText}</p>
        </div>
      ) : (
        <div>
          <p>
            {notInstantPay}
            <br />
            {instruction}
            <br />
            {supportText}
            {formatPhone(environment.support.phone)}
          </p>
        </div>
      )}
    </div>
  );
};

export { NonIPConversionText };
