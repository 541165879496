import { connect } from "react-redux";

import { Store } from "../store/store.model";
import { AppRouter } from "./appRouter";
import { bindActionCreators } from "redux";
import { initSession } from "../store/session";

const mapStateToProps = (state: Store) => ({
  hasSession: state.session.hasSession,
  isAuthorized: state.session.isAuthorized,
  isVerifying: state.session.isVerifying,
  isLoggingOut: state.session.isLoggingOut,
  agent: state.session.agent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      initSession,
    },
    dispatch
  );

const AppRoutes = connect(mapStateToProps, mapDispatchToProps)(AppRouter);

export { AppRoutes };
