import React, { useState, FormEvent } from "react";
import {
  IonButton,
  IonContent,
  IonLabel,
  IonInput,
  IonToast,
  IonSpinner,
  IonAlert,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/react";
import { isPlatform } from "@ionic/core";
import { useSelector } from "react-redux";
import { Plugins } from "@capacitor/core";
import { get } from "lodash";

import { Store } from "../../store/store.model";
import { ProfileUpdateStepProps } from "./interface";
import "./style.scss";

const { Keyboard } = Plugins;

const SSNVerification: React.FC<ProfileUpdateStepProps> = ({
  stepFinished,
}) => {
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [verificationError, setVerificationError] = useState<boolean>(false);

  const [ssn, setSSN] = useState<string | null>(null);
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();

    try {
      if (isPlatform("capacitor")) Keyboard.hide();

      if (!ssn?.endsWith(agent?.socialSecurityNumber)) {
        setVerificationError(true);
        return;
      }

      stepFinished();
    } catch (error) {
      setPageError({
        isError: true,
        message: "Unexpected error occurred while verifying ssn",
      });
    } finally {
      setUpdatingData(false);
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  //Function to format input field to SSN
  const formatToSSN = (value: string) => {
    return value
      .replace(/[^0-9x]/g, "")
      .replace(/^(\w{3})/, "$1-")
      .replace(/-(\w{2})/, "-$1-")
      .replace(/(\w)-(\w{4}).*/, "$1-$2");
  };

  const handleOnChange = (event: Event) => {
    const target = event.target as EventTarget;
    target["value"] = target["value"].replace(/[^0-9x-]/g, "");
    setSSN(target["value"]);
  };

  //To format the input only when the key pressed is not backspace and delete
  const handleKeyUp = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    let ssNo = event.target["value"] as string;
    if (event.key !== "Backspace" && event.key !== "Delete") {
      ssNo = formatToSSN(ssNo);
    }
    setSSN(ssNo);
  };

  //To prevent inputs which are not number
  const handleKeyPress = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  };

  return (
    <>
      <IonToast
        isOpen={pageError.isError}
        onDidDismiss={onDismiss}
        message={pageError.message}
        color="danger"
        duration={2000}
        position="top"
      />

      <IonAlert
        mode="ios"
        isOpen={verificationError}
        backdropDismiss={false}
        header="Your SSN doesn't match"
        message="The Social Security Number you typed doesn't match our current records. Do you want to try typing it again? Make sure to type it according to your document"
        buttons={[
          {
            text: "Cancel",
            handler: () => setVerificationError(false),
          },
          {
            text: "Try Again",
            handler: () => setVerificationError(false),
          },
        ]}
      />

      <div className="signup-content content-layout adjust-form-height">
        <div className="form-container">
          <form onSubmit={onNext}>
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>What&#39;s your Social Security Number?</h4>
                </div>

                <div>
                  <IonLabel className="form-label hcp-self-label">
                    Social Security Number
                  </IonLabel>
                  <IonInput
                    name="ssn"
                    className="form-input hcp-self-input"
                    type="text"
                    placeholder="999-99-9999"
                    onIonChange={handleOnChange}
                    onKeyUp={handleKeyUp}
                    onKeyPress={handleKeyPress}
                    value={ssn}
                    maxlength={11}
                    disabled={updatingData}
                    autofocus
                    no-border
                    required
                  />
                </div>
                <IonText className="profile-update-helper-text ion-text-start ion-margin-top">
                  We are asking you for your Social Security Number to prevent
                  fraudlent attempts of controlling your account.
                </IonText>
              </IonCol>
            </IonRow>
          </form>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            disabled={updatingData || ssn?.length !== 11}
            onClick={onNext}
          >
            Continue
            {updatingData && (
              <IonSpinner slot="end" class="ion-margin-start" name="lines" />
            )}
          </IonButton>
        </div>
      </div>
    </>
  );
};

export { SSNVerification };
