/* eslint-disable prefer-destructuring */
import "./style.scss";
import { useSelector } from "react-redux";
import { get, sortBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { Shift, ShiftName } from "src/lib/interface";

import { HCP_ACTIONS } from "../constants";
import { IonSpinner } from "@ionic/react";
import { ShiftItem } from "../shiftItem";
import { ShiftListGroupState } from "../model";
import { showToast } from "../../layout/toast";
import { Store } from "../../store/store.model";
import { addAgentActivityLog, fetchInstantBookShifts } from "../api";
import {
  isNonConflictingShift,
  removeConflictingShifts,
} from "../../utils/shiftHelper";

type FacilityShiftItemProps = {
  day: string;
  shift: Shift;
  shifts: ShiftListGroupState;
  goToDocumentsPage: (queryParameters?: string) => void;
  getFacilityShifts: Function;
  setClickedInterestInShift: Function;
  isHcpInstantPayEnabled?: boolean;
  payVersion?: string;
  pushNotification: { [key in string]: boolean };
  handleShiftFlagFound: (type: "holiday" | "claim") => void;
};
const FacilityShiftItem: React.FC<FacilityShiftItemProps> = ({
  day,
  shift,
  shifts,
  goToDocumentsPage,
  getFacilityShifts,
  setClickedInterestInShift,
  isHcpInstantPayEnabled,
  payVersion,
  pushNotification,
  handleShiftFlagFound,
}) => {
  const agent = useSelector((state: Store) => get(state, "session.agent", {}));
  const [shiftName, setShiftName] = useState<ShiftName | undefined | null>(
    null
  );

  useEffect(() => {
    setShiftName(shift.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shiftValidatorBeforeSubmission = (shift: Shift) => {
    // Already assigned shifts doesn't appear on same shift type. So only checking on open shifts.
    if (
      isNonConflictingShift(
        [
          ...shifts.assigned,
          ...shifts.open.filter(
            ({ agentId, interested }) =>
              agentId === agent.userId || interested?.includes(agent.userId)
          ),
        ],
        shift
      )
    )
      return true;
    showToast(
      `Unable to book shift, it looks like you're already working at that time! If there's a mistake, please contact support for help`,
      5000,
      "bottom",
      "danger"
    );
    return false;
  };

  // Instant Book check
  const [isLoadingCheckInstantBook, setIsLoadingCheckInstantBook] =
    useState<boolean>(true);
  const [instantBookShifts, setInstantBookShifts] = useState<Shift[]>([]);
  const [isShiftLoaded, setIsShiftLoaded] = useState<boolean>(false);
  const [sortedShifts, setSortedShifts] = useState<Shift[]>([]);

  const getInstantBookShifts = useCallback(async () => {
    if (day && shiftName) {
      setIsLoadingCheckInstantBook(true);
      const instantbookShiftsLoaded = await fetchInstantBookShifts(day);

      const instantbookShifts = (instantbookShiftsLoaded || []).filter(
        (shift) => shiftName === shift.name || shift.name === "custom"
      );
      setInstantBookShifts(instantbookShifts);
      setIsLoadingCheckInstantBook(false);
      setIsShiftLoaded(true);
    }
  }, [day, shiftName]);

  useEffect(() => {
    getInstantBookShifts();
  }, [getInstantBookShifts]);

  // Check if shifts loaded
  useEffect(() => {
    setSortedShifts(
      sortBy(removeConflictingShifts(shifts.open, shifts.assigned), [
        "facility.distance",
        "start",
      ])
    );
  }, [shifts.open, shifts.assigned]);

  // Logger
  const logAgentActivity = useCallback(
    (shiftsToLog: any[], action: string, shiftId = null) => {
      if (
        agent.userId &&
        day &&
        shiftName &&
        !isLoadingCheckInstantBook &&
        isShiftLoaded
      ) {
        const agentActivityLog = {
          day: day,
          shiftId,
          timeSlot: shiftName.toUpperCase(),
          agentId: agent.userId,
          shifts: shiftsToLog,
          action,
        };
        addAgentActivityLog(agentActivityLog);
      }
    },
    [agent.userId, day, shiftName, isLoadingCheckInstantBook, isShiftLoaded]
  );

  useEffect(() => {
    if (!isShiftLoaded && isLoadingCheckInstantBook) return;
    let currentIndex = 1;
    const shiftsToLog = [...shifts.open, ...shifts.assigned].reduce(
      (filtered: Shift[], option) => {
        if (option != null) {
          const shift = {
            ...option,
            position: currentIndex,
            isInstantBook:
              instantBookShifts.findIndex(
                (a: Shift) => a.shiftId === option._id
              ) > -1,
          };
          filtered.push(shift);
          currentIndex = currentIndex + 1;
        }
        return filtered;
      },
      []
    );
    logAgentActivity(shiftsToLog, HCP_ACTIONS.TIME_SLOT_CLICK);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShiftLoaded, isLoadingCheckInstantBook]);

  const filterShiftsToLog = (shiftId = null) => {
    //Getting Mark Interest Shifts and filtering the null items
    const markInterestShifts = sortedShifts.filter((el) => {
      return (
        el != null &&
        instantBookShifts.findIndex((k: Shift) => k.shiftId === el._id) < 0
      );
    });
    // Combining mark and self claim shifts
    const combinedShifts = instantBookShifts.concat(markInterestShifts);
    //Using to set the position of the shift
    let currentIndex = 1;
    //filtering the mark interest shifts and is instant check
    const shiftsToLog = combinedShifts.reduce((filtered: Shift[], option) => {
      if (
        option.interested!.indexOf(agent?.userId) < 0 &&
        !option["isClaimed"] &&
        shiftId !== option.shiftId
      ) {
        const isInstantBook =
          instantBookShifts.findIndex(
            (instantShift) => instantShift.shiftId === option.shiftId
          ) > -1;
        const shift = {
          ...option,
          type: isInstantBook
            ? HCP_ACTIONS.SELF_CLAIM
            : HCP_ACTIONS.MARK_INTEREST,
          position: currentIndex,
          isInstantBook,
        };
        filtered.push(shift);
      }
      currentIndex = currentIndex + 1;
      return filtered;
    }, []);

    return shiftsToLog;
  };
  const shiftActivityLog = (shiftId) => {
    const shiftsToLog = filterShiftsToLog(shiftId);
    return { shifts: shiftsToLog };
  };
  const handleShiftActivityLog = (shiftId, action) => {
    const shiftsToLog = filterShiftsToLog(shiftId);
    //Recording Activity
    logAgentActivity(shiftsToLog, action, shiftId);
  };

  if (!isLoadingCheckInstantBook && isShiftLoaded) {
    return (
      <ShiftItem
        shift={shift}
        isInstantBookingShift={
          !!instantBookShifts.find(
            (instantShift) => instantShift.shiftId === shift.shiftId
          )
        }
        shiftName={shiftName as string}
        goToDocumentsPage={goToDocumentsPage}
        handleShiftFlagFound={handleShiftFlagFound}
        updateShiftList={getFacilityShifts}
        setClickedInterestInShift={setClickedInterestInShift}
        isHcpInstantPayEnabled={isHcpInstantPayEnabled}
        payVersion={payVersion}
        handleShiftActivityLog={handleShiftActivityLog}
        validator={shiftValidatorBeforeSubmission}
        pushNotification={pushNotification}
        shiftActivityLog={shiftActivityLog}
        showFacilityDetails={false}
        loggerSearchMode={"facility"}
      />
    );
  }
  return (
    <div
      style={{
        textAlign: "center",
        padding: "4em 4em",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <IonSpinner />
    </div>
  );
};
export { FacilityShiftItem };
