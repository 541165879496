import { logEvent } from "src/lib/analytics";
import { useQuery } from "@apollo/client";
import { difference, filter, keyBy, uniq, groupBy, isEqual } from "lodash";
import moment from "moment-timezone";
import {
  IonBackButton,
  IonBadge,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DocumentsList } from "./documentList";
import { GET_FILTERED_DOCUMENTS_URL, GET_REQUIREMENTS_STATUS } from "./gql";
import {
  HcpRequirementStatus,
  HcpRequirementResponse,
  HcpRequirementStatusResponse,
  DocumentStatus,
  HcpDocument,
} from "../store/documents/model";
import { RequirementTypes } from "./model";
import { setSelectedRequirement } from "../store/documents";
import { ShowAPIFailError } from "../404Pages/showAPIFailError";
import { Store } from "../store/store.model";
import { USER_EVENTS } from "../../constants/userEvents";
import { TabRouterPath } from "@app/routing/constant/tabRoute";
import { useHistory } from "react-router";
import { MappingLevel } from "src/lib/constants";
import "./styles/index.scss";
import usePrevious from "../../hooks/usePrevious";
import { getCheckrApplicationStatus } from "@app/documents/api";
import { setCheckrApplicationStatus } from "@store/documents";
import { CHECKR_REQUIREMENT_ID } from "@constants/userEvent";
import updateStatusForCheckrRequirement from "@app/utils/updateStatusForCheckrRequirement";
import { FeatureFlag } from "../../constants/FEATURE_FLAGS";
import { api } from "@app/api";

const DocumentsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { networkStatus, userId: hcpId } = useSelector(
    (state: Store) => state.session
  );

  const { checkrApplicationStatus } = useSelector(
    (state: Store) => state.documentStore
  );

  const history = useHistory<{ forceReload: boolean }>();
  const { forceReload } = history?.location?.state || {};

  const { data, loading } = useQuery(GET_REQUIREMENTS_STATUS, {
    variables: { hcpId },
    fetchPolicy: forceReload ? "network-only" : "cache-and-network",
  });

  const [potentialNewShiftsCount, setPotentialNewShiftsCount] = useState(0);

  const ldFlags = useFlags();
  const isCheckrPillStatusEnabled =
    ldFlags[FeatureFlag.ENABLE_CHECKR_PILL_STATUS];

  const hcpRequirementStatus: HcpRequirementStatusResponse =
    data?.hcpRequirementStatus || {
      requirements: [],
      completed: [],
      pending: [],
      expired: [],
      missing: [],
      optionalRequirements: [],
      validOptionalRequirements: [],
      expiredOptionalRequirements: [],
      missingOptionalRequirements: [],
      rejected: [],
      miscellaneous: {},
    };

  let status: HcpRequirementStatus = {
    ...hcpRequirementStatus,
    requirements: keyBy(
      [
        ...hcpRequirementStatus?.requirements,
        ...hcpRequirementStatus?.optionalRequirements,
        {
          ...hcpRequirementStatus?.miscellaneous,
          reqId: hcpRequirementStatus.miscellaneous?._id,
        },
      ],
      "reqId"
    ),
    missing: difference(hcpRequirementStatus.missing, [
      ...hcpRequirementStatus.pending,
      ...hcpRequirementStatus.rejected,
    ]),
    optional: difference(hcpRequirementStatus.missingOptionalRequirements, [
      ...hcpRequirementStatus.rejected,
      ...hcpRequirementStatus.pending,
    ]),
    expired: difference(
      [
        ...hcpRequirementStatus.expired,
        ...hcpRequirementStatus.expiredOptionalRequirements,
      ],
      hcpRequirementStatus.pending
    ),
    completed: [
      ...hcpRequirementStatus.completed,
      ...hcpRequirementStatus.validOptionalRequirements,
    ],
    rejected: difference(hcpRequirementStatus.rejected, [
      ...hcpRequirementStatus.pending,
      ...hcpRequirementStatus.expired,
      ...hcpRequirementStatus.completed,
    ]),
    miscellaneous: [hcpRequirementStatus.miscellaneous?._id],
    missingHCFLevel: [],
  };

  // Needed to handle checkr rejected state.
  const { data: { documentList } = { documentList: [] } } = useQuery<{
    documentList: HcpDocument[];
  }>(GET_FILTERED_DOCUMENTS_URL, {
    variables: {
      hcpId,
      filter: {
        fulfilledRequirementId: CHECKR_REQUIREMENT_ID,
        status: DocumentStatus.REJECTED,
        workerUploaded: false,
      },
      limit: 1,
      sort: { createdAt: -1 },
    },
    fetchPolicy: "cache-and-network",
    skip: !(
      isCheckrPillStatusEnabled &&
      (status.rejected?.includes(CHECKR_REQUIREMENT_ID) ||
        status.expired?.includes(CHECKR_REQUIREMENT_ID))
    ),
  });

  // Fetches checkr application status to update the checkr requirement
  const fetchCheckrApplicationStatus = async () => {
    if (Object.keys(status?.requirements)?.includes(CHECKR_REQUIREMENT_ID)) {
      let { invitationStatus = "", reportStatus = "" } =
        (await getCheckrApplicationStatus()) || {};
      dispatch(setCheckrApplicationStatus({ invitationStatus, reportStatus }));
    }
  };

  useEffect(() => {
    if (isCheckrPillStatusEnabled) {
      fetchCheckrApplicationStatus();
    }
  }, [hcpRequirementStatus, hcpId, isCheckrPillStatusEnabled]);

  /*
   * Update the status if checkr is in the applicable requirements.
   * For reference https://clipboardhealth.atlassian.net/browse/CH-5797
   */
  if (
    isCheckrPillStatusEnabled &&
    Object.keys(status?.requirements)?.find(
      (reqId) => reqId === CHECKR_REQUIREMENT_ID
    )
  ) {
    status = updateStatusForCheckrRequirement(
      status,
      checkrApplicationStatus,
      documentList
    );
  }

  const isVisibleToHCP = (reqId) =>
    status?.requirements[reqId].visibleToHCP || false;

  status = {
    ...status,
    completed: uniq(status.completed),
    pending: uniq(status.pending),
    missing: uniq(filter(status.missing, isVisibleToHCP)),
    expired: filter(status.expired, isVisibleToHCP),
    optional: filter(status.optional, isVisibleToHCP),
  };

  const { length: total } = uniq([
    ...status.completed,
    ...status.pending,
    ...status.expired,
    ...status.rejected,
    ...status.missing,
    ...status.optional,
  ]);

  const groupedRequirementMappings: {
    [key: string]: HcpRequirementResponse[];
  } = groupBy(hcpRequirementStatus?.requirements, "reqId");
  const missingFacilityIds: string[] = [];
  const missingHCFLevel: string[] = [];

  status?.missing.forEach((reqId) => {
    const mappingsForRequirement = groupedRequirementMappings[reqId] || [];
    let mappedFacilityIds: string[] = [];
    for (const mapping of mappingsForRequirement) {
      if (mapping?.level !== MappingLevel.HCF) return;
      mappedFacilityIds.push(...(mapping.requiredBy || []));
    }
    missingHCFLevel.push(reqId);
    missingFacilityIds.push(...mappedFacilityIds);
  });

  const missingHighLevel = difference(status?.missing, missingHCFLevel);

  status = {
    ...status,
    missing: missingHighLevel,
    missingHCFLevel,
  };

  const { length: submitted } = uniq([...status.completed, ...status.pending]);
  const progress = Number(Number(submitted / total).toFixed(3));
  const documentsLeft = total - submitted;

  useEffect(() => {
    logEvent(USER_EVENTS.VIEWED_DOCUMENTS, {
      status: `${documentsLeft ? "completed" : "not completed"}`,
    });
  }, [documentsLeft]);

  const previousMissingFacilityIds = usePrevious(missingFacilityIds);

  useEffect(() => {
    if (
      !hcpId ||
      !missingFacilityIds?.length ||
      isEqual(previousMissingFacilityIds, missingFacilityIds)
    )
      return;

    const fetchPotentialNewShifts = async (agentId, missingFacilityIds) => {
      if (!agentId || !missingFacilityIds) return;
      const start = moment().format("YYYY-MM-DD");
      const end = moment().add(1, "month").format("YYYY-MM-DD");
      const { success, totalPotentialShiftsCount } =
        await api.shift.fetchPotentialShiftsForQualifiedFacilities(
          agentId,
          missingFacilityIds,
          { start, end }
        );
      if (success) {
        setPotentialNewShiftsCount(totalPotentialShiftsCount || 0);
      }
    };
    fetchPotentialNewShifts(hcpId, missingFacilityIds);
  }, [hcpId, missingFacilityIds]);

  const requirementClickHandler =
    (requirement: HcpRequirementResponse, requirementType: string) => () => {
      dispatch(setSelectedRequirement(requirement, requirementType));
      const params = new URLSearchParams();
      params.set("requirement", requirement?.reqId as string);
      params.set("requirementStatus", requirementType);
      history.push(`${TabRouterPath.DOCUMENT_VIEW}?${params.toString()}`);
    };

  const requiresAttention = status.missing.length + status.expired.length;

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Documents</IonTitle>
        </IonToolbar>
      </IonHeader>
      {!networkStatus?.connected ? (
        <ShowAPIFailError networkStatus={networkStatus?.connected} />
      ) : (
        <IonContent>
          <IonItem lines="none">
            <IonLabel className="ion-text-wrap ion-text-center">
              {loading ? (
                <IonSkeletonText animated style={{ width: "70%" }} />
              ) : (
                <div className="document-progress-title">
                  {requiresAttention && (
                    <IonBadge className="total" color="danger" mode="ios">
                      {requiresAttention}
                    </IonBadge>
                  )}
                  <span className="label">
                    {submitted} out of {total} documents submitted
                  </span>
                </div>
              )}
            </IonLabel>
          </IonItem>
          <IonItem className="document-progress-bar" lines="none">
            <IonProgressBar
              type={loading ? "indeterminate" : "determinate"}
              value={progress}
            />
          </IonItem>
          {Object.values(RequirementTypes).map((type) => {
            return (
              <DocumentsList
                key={type}
                status={status}
                loading={loading}
                type={type}
                onClick={requirementClickHandler}
                extraData={{ potentialNewShiftsCount }}
                isCheckrPillStatusEnabled={isCheckrPillStatusEnabled}
              />
            );
          })}
        </IonContent>
      )}
    </IonPage>
  );
};

export { DocumentsPage };
