import request from "superagent";
import { getAuthHeader } from "../../superagent";
import { environment } from "@env/environment";
import {
  FetchAgentShiftRequest,
  RatingRequest,
  NoTimeSheetAvailableReq,
} from "../../store/ongoingShifts/model";
import {
  Shift,
  ShiftStages,
  Shift_NEW,
  TimecardV2,
  TimeRange,
  UnverifiedShift,
} from "src/lib/interface";
import isEmpty from "lodash/isEmpty";
import { showModal } from "../../shared/modal";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants/userEvents";
import { logFailedApiRetry } from "../../utils/api_retry";
import moment from "moment";

const { baseUrl, fileUploadPreset, fileUploadURL } = environment;

async function recordTime(
  shiftId: string,
  stage: ShiftStages,
  location: number[],
  timecard,
  locationType: string,
  appType: string,
  connectivityMode: string,
  onModalClose?: Function
) {
  return request
    .post(`${baseUrl}/v1/shifts/recordTime/${shiftId}`)
    .set(await getAuthHeader())
    .send({
      stage,
      location,
      timecard,
      locationType,
      appType,
      connectivityMode,
    })
    .then(({ body }) => body.response)
    .catch((err) => {
      if (err.response?.status === 400 && err.response?.body?.error) {
        return showModal(
          "error",
          err.response.body.error,
          "Error Clocking Time",
          onModalClose,
          onModalClose
        );
      }
      return showModal(
        "error",
        err.response.body.message,
        "Error Clocking Time",
        onModalClose,
        onModalClose
      );
    });
}

async function uploadFile(timecard) {
  const formData = new FormData();
  formData.append("file", timecard);
  formData.append("upload_preset", fileUploadPreset);
  formData.append("folder", "timecard");

  return await request.post(fileUploadURL).send(formData);
}

async function recordSignatureTime(
  shiftId: string,
  stage: ShiftStages,
  location: number[],
  url: string
) {
  return request
    .post(`${baseUrl}/shift/agentSignature/recordTime`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      stage,
      location,
      timecard: url,
    })
    .then(({ body }) => body)
    .catch((err) => {
      if (err.response?.status === 400 && err.response?.body?.error) {
        return showModal(
          "error",
          err.response.body.error,
          "Error Clocking Time"
        );
      }
      return showModal(
        "error",
        err.response.body.message,
        "Error Clocking Time"
      );
    });
}

const fetchAgentShifts = async (
  query: FetchAgentShiftRequest
): Promise<Shift_NEW> => {
  return request
    .get(`${baseUrl}/v1/shifts/agentShifts`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/v1/shifts/agentShifts`);
      return true;
    })
    .set(await getAuthHeader())
    .query(query)
    .then(({ body }) => body);
};

const uploadSubmittedTimeSheet = async (
  selectedFile,
  submitClockInOut: TimeRange,
  submitLunchInOut: TimeRange,
  _id: string
): Promise<TimecardV2> => {
  const formData = new FormData();
  formData.append("file", selectedFile);
  formData.append("upload_preset", environment.fileUploadPreset);
  formData.append("folder", "timecard");

  const result = await request.post(environment.fileUploadURL).send(formData);
  const { url, original_filename: filename } = result.body;
  const fileUploadResult = [{ url, filename: "abc.png" }];
  logEvent(USER_EVENTS.UPLOAD_CLOUDINARY_SUCCESS, {
    shiftId: _id,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  const {
    body: {
      response: { timecard },
    },
  } = await request
    .put(`${baseUrl}/v2/shifts/timecard/${_id}`)
    .set(await getAuthHeader())
    .send({
      timecard: fileUploadResult,
      location: ["12", "34"],
      submitClockInOut: submitClockInOut,
      submitLunchInOut: submitLunchInOut,
    });
  logEvent(USER_EVENTS.UPLOAD_API_SUCCESS, {
    shiftId: _id,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });

  return timecard;
};

const uploadTimecardV2 = async (
  selectedFile,
  _id: string
): Promise<TimecardV2> => {
  const formData = new FormData();
  formData.append("file", selectedFile);
  formData.append("upload_preset", environment.fileUploadPreset);
  formData.append("folder", "timecard");

  const result = await request.post(environment.fileUploadURL).send(formData);
  const { url, original_filename: filename } = result.body;
  const fileUploadResult = [{ url, filename: "abc.png" }];
  logEvent(USER_EVENTS.UPLOAD_CLOUDINARY_SUCCESS, {
    shiftId: _id,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  const {
    body: {
      response: { timecard },
    },
  } = await request
    .put(`${baseUrl}/v1/shifts/timecard/${_id}`)
    .set(await getAuthHeader())
    .send({ timecard: fileUploadResult, location: ["12", "34"] });
  logEvent(USER_EVENTS.UPLOAD_API_SUCCESS, {
    shiftId: _id,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });

  return timecard;
};

const addRating = async (req: RatingRequest): Promise<Shift> => {
  const { body } = await request
    .post(`${baseUrl}/v1/rating`)
    .set(await getAuthHeader())
    .send({ reviewFor: "FACILITY", ...req });

  return body;
};

const recordShiftTimeV2 = async (
  shiftId: string,
  stage: ShiftStages,
  location: number[],
  timecard,
  locationType: string,
  appType: string,
  connectivityMode: string,
  onModalClose?: Function
): Promise<Shift_NEW | undefined> => {
  if (isEmpty(timecard)) {
    try {
      return await recordTime(
        shiftId,
        stage,
        location,
        timecard,
        locationType,
        appType,
        connectivityMode,
        onModalClose
      );
    } catch (err) {
      showModal("error", "Error Recording Shift Time", "Time Record Error");
    }
  } else {
    let result;
    try {
      result = await uploadFile(timecard);
    } catch (err) {
      showModal("error", "Error Uploading Time Card", "Upload Error");
    }
    try {
      const { url } = result.body;
      return await recordSignatureTime(shiftId, stage, location, url);
    } catch (err) {
      showModal("error", "Error Recording Shift Time", "Time Record Error");
    }
  }
};
const fetchAgentUnverifiedShifts = async (): Promise<UnverifiedShift[]> => {
  return request
    .get(`${baseUrl}/v1/shifts/getUnverifiedShifts`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/v1/shifts/getUnverifiedShifts`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body.response);
};

const setTimeSheetAvailability = async (
  query: NoTimeSheetAvailableReq
): Promise<Shift_NEW> => {
  return request
    .post(`${baseUrl}/v1/shifts/setTimeSheetAvailability`)
    .set(await getAuthHeader())
    .send(query)
    .then(({ body }) => body.response);
};

export const getUpcomingShiftsGrouped = async (): Promise<{
  [key: string]: Shift;
}> => {
  return request
    .get(`${baseUrl}/v1/shifts/upcomingShiftsGrouped`)
    .set(await getAuthHeader())
    .retry(1, (err) => {
      if (!err) return false;
      logFailedApiRetry(err, `/v1/shifts/upcomingShiftsGrouped`);
      return true;
    })
    .then(({ body }) => body.response);
};

export {
  fetchAgentShifts,
  uploadTimecardV2,
  addRating,
  recordShiftTimeV2,
  fetchAgentUnverifiedShifts,
  setTimeSheetAvailability,
  uploadSubmittedTimeSheet,
};
