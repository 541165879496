import { Plugins } from "@capacitor/core";
import { isPlatform } from "@ionic/react";
import { Diagnostic } from "@ionic-native/diagnostic";
import { sendLocationData } from "../locationService/api";
import { logErrors } from "../errorBoundary/api";
import { BackgroundMode } from "@ionic-native/background-mode";
const { Geolocation, App, BackgroundTask } = Plugins;

export const sendLocation = async (userId, locationServiceUrl) => {
  try {
    const locationAvailable = await Diagnostic.isLocationAvailable();

    if (!locationAvailable) {
      return;
    }

    const location = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
    });

    if (!location) {
      logErrors({
        message: `Unable to get location for user: ${userId}`,
        app: "hcpMobile",
      });
      return;
    }

    const { coords } = location;

    await sendLocationData(locationServiceUrl, [
      {
        latitude: coords.latitude,
        longitude: coords.longitude,
        createdAt: location.timestamp,
        userId: userId || "",
      },
    ]);
  } catch (err) {
    logErrors({
      message: `Unable to send location for user: ${userId}`,
      app: "hcpMobile",
    });
  }
};

export const handleSilentNotifications = async (userId, locationServiceUrl) => {
  const { isActive } = await App.getState();

  if (isActive) {
    await sendLocation(userId, locationServiceUrl);
    return;
  }

  if (isPlatform("android")) {
    try {
      BackgroundMode.setDefaults({
        title: "You have an upcoming shift",
        text: "Using your location",
      });

      BackgroundMode.enable();
      BackgroundMode.disableBatteryOptimizations();
      BackgroundMode.disableWebViewOptimizations();
      await sendLocation(userId, locationServiceUrl);
    } catch (err) {
      logErrors({
        message: `Unable to send location for user: ${userId}`,
        app: "hcpMobile",
      });
    } finally {
      BackgroundMode.disable();
    }

    return;
  }

  /*
   * We're using background task in order to send the location while the app is in the background.
   * We need to explicitly terminate the task, otherwise we risk our app being terminated or flagged as impacting battery life.
   * Library itself does not support android platform anymore
   */
  const taskId = BackgroundTask.beforeExit(async () => {
    await sendLocation(userId, locationServiceUrl);
    BackgroundTask.finish({
      taskId,
    });
  });
};
