import "./style.scss";

import { IonCol, IonContent, IonImg, IonPage, IonRow } from "@ionic/react";
import React from "react";

const NotFoundContainer: React.FC<{}> = ({ children }) => {
  return (
    <IonPage>
      <IonContent class="not-found-content ion-padding ion-text-center">
        <IonRow class="ion-align-items-center full-height-row">
          <IonCol
            align-self-center
            sizeMd="8"
            offsetMd="2"
            offsetLg="4"
            sizeLg="4"
          >
            <IonImg src="/assets/logo/cbh-logo.png" class="logo" />
            <div className="text-container">{children}</div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export { NotFoundContainer };
