import { logApiFailureEvent } from "src/lib/analytics";
import { Agent, APIResponse, Shift } from "src/lib/interface";
import { DateRange } from "src/lib/utils";
import { reduce } from "lodash";
import moment from "moment-timezone";
import request from "superagent";
import { environment } from "../../environments/environment";
import { getAuthHeader } from "../superagent";
import {
  RequestDetailedRatings,
  RequestOpenShiftOptions,
  RequestRatings,
  RequestSlotClaim,
  RequestSlotDetails,
  ResponseDetailedRatings,
  ResponseRatings,
  ResponseSlotDetails,
  ShiftDateMap,
} from "./model";
import { logFailedApiRetry } from "../utils/api_retry";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const reduceToDate = (acc: ShiftDateMap, shift: Shift): ShiftDateMap => {
  const day = moment(shift.start).format("YYYY-MM-DD");

  return {
    ...acc,
    [day]: [...(acc[day] || []), shift],
  };
};

const fetchYourShifts = async (
  dateFilter: DateRange
): Promise<ShiftDateMap> => {
  return request
    .get(`${environment.baseUrl}/calendar/agent`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/calendar/agent`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ dateFilter, groupByDate: false })
    .then(({ body }) => {
      return reduce(body.agentShifts, reduceToDate, {});
    })
    .catch((error) => {
      logApiFailureEvent(error);
      throw error;
    });
};

const fetchOpenShifts = async (
  options: RequestOpenShiftOptions
): Promise<Shift[]> => {
  return await request
    .get(`${environment.baseUrl}/calendar/openShifts`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/calendar/openShifts`);
      return true;
    })
    .set(await getAuthHeader())
    .query(options)
    .then(({ body }) => body.openAgentShifts)
    .catch(logApiFailureEvent);
};

const markShiftInterest = async (
  shiftId: string,
  activityLog: any
): Promise<Shift | any> => {
  return await request
    .post(`${environment.baseUrl}/shift/interested`)
    .set(await getAuthHeader())
    .send({ shift: shiftId, ...activityLog, isNative: true, docVer: "v2" })
    .then(({ body }) => body)
    .catch((error) => {
      logApiFailureEvent(error);
      const { response } = error;
      return { error: response.body.error || response.error.text };
    });
};

const claimAgentShift = async (
  shiftId: string,
  hasLastMinuteGrabCheck: boolean,
  longitude?: string,
  latitude?: string
): Promise<Shift> => {
  return await request
    .post(`${environment.baseUrl}/instantBookShifts/claim`)
    .set(await getAuthHeader())
    .send({ shiftId, hasLastMinuteGrabCheck, longitude, latitude })
    .then(({ body }) => body);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchInstantBookShifts = async (date: string) => {
  return await request
    .get(`${environment.baseUrl}/instantBookShifts/${date}`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/instantBookShifts/${date}`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

/**
 *
 * @returns Is InstantPay allowed for current agent (user)?
 */
const instantPayStatus = async (): Promise<
  | {
      _id: string;
      isHCPInstantPayProhibited: boolean;
      employmentStatus: string;
    }
  | {
      instantPayStatus: boolean;
    }
  | undefined
> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/agentprofile/instant-pay-status`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/agentprofile/instant-pay-status`);
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    return undefined;
  }
};

// Line below can be removed after TypeScript 4.1
type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

const isHCPInstantPayAllowed = (
  status: Awaited<ReturnType<typeof instantPayStatus>>
): boolean =>
  status
    ? "instantPayStatus" in status
      ? Boolean(status.instantPayStatus)
      : !status.isHCPInstantPayProhibited
    : false;

const fetchAgentProfile = async (): Promise<Agent | undefined> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/agentProfile`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/agentProfile`);
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const addAgentActivityLog = async (data: object): Promise<void> => {
  try {
    await request
      .post(`${environment.baseUrl}/agentActivityLog/record`)
      .set(await getAuthHeader())
      .send(data);
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const fetchCurrentTimezone = async (): Promise<string> => {
  return await request
    .get(`${environment.baseUrl}/user/timezone`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/user/timezone`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => {
      moment.tz.setDefault(body.tmz);
      return body.tmz;
    })
    .catch(logApiFailureEvent);
};

const createCase = async (shiftId: string): Promise<ResponseSlotDetails> => {
  return await request
    .post(`${environment.baseUrl}/shift/createCase/${shiftId}`)
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const fetchDetailedRatingsList = async (
  options: RequestDetailedRatings
): Promise<ResponseDetailedRatings[]> => {
  return await request
    .get(`${environment.baseUrl}/rating/list`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/rating/list`);
      return true;
    })
    .set(await getAuthHeader())
    .query(options)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const fetchRatings = async (
  options: RequestRatings
): Promise<ResponseRatings> => {
  return await request
    .get(`${environment.baseUrl}/rating/avgRatingById`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/rating/avgRatingById`);
      return true;
    })
    .set(await getAuthHeader())
    .query(options)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export {
  fetchYourShifts,
  fetchOpenShifts,
  markShiftInterest,
  claimAgentShift,
  fetchInstantBookShifts,
  instantPayStatus,
  isHCPInstantPayAllowed,
  addAgentActivityLog,
  fetchAgentProfile,
  fetchCurrentTimezone,
  createCase,
  fetchDetailedRatingsList,
  fetchRatings,
};
