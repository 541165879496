import { ShowAPIFailError } from "@app/404Pages/showAPIFailError";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Store } from "@store/store.model";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import useFetchBonuses from "./useFetchHcpBonuses";
import "./style.scss";

function HcpBonusesPage() {
  const tmz = useSelector((state: Store) => state.session.agent?.tmz);
  const { bonuses } = useFetchBonuses(tmz);

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>My Bonuses</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {bonuses
            .filter((bonus) => bonus.progress > 0)
            .map((bonus) => (
              <IonItem className="bonus-row" key={bonus.id}>
                <details>
                  <summary>
                    <h3>
                      {bonus.title}
                      <IonText color="success"> ${bonus.amount}</IonText>
                    </h3>
                  </summary>
                  <p>{bonus.description}</p>
                </details>
              </IonItem>
            ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export { HcpBonusesPage };
