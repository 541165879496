import { ShiftName } from "src/lib/interface";

import { HasShift } from "./model";

const getShiftColor = (shiftName: string, verified?: boolean): string => {
  if (verified) {
    return "gold";
  }

  switch (shiftName) {
    case ShiftName.AM:
      return "shift-am";
    case ShiftName.PM:
      return "shift-pm";
    case ShiftName.NOC:
      return "shift-noc";
    default:
      return "shift-custom";
  }
};

const getHasShiftColor = (
  hasShift: HasShift,
  shiftName: string,
  verified?: boolean,
  outline?: boolean
): string => {
  if (!hasShift) {
    return getShiftColor(shiftName, verified);
  }

  if (hasShift.hasStaffedShift) {
    return "success";
  }

  if (hasShift.hasOpenShift) {
    return getShiftColor(shiftName);
  }

  return outline ? "medium" : "light";
};

export { getHasShiftColor, getShiftColor };
