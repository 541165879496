import request from "superagent";
import { logApiFailureEvent } from "src/lib/analytics";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";

const sendLocationData = async (url, locationData): Promise<void> => {
  await request
    .post(url)
    .set(await getAuthHeader())
    .send(locationData)
    .catch(logApiFailureEvent);
};

export { sendLocationData };
