import { IonButton, IonFooter } from "@ionic/react";
import { Shift } from "src/lib/interface";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "@store/index";
import { CancelShiftModal } from "@app/components/shift/cancelShiftModal";
import { api } from "@app/api";

const ShiftActionFooter: React.VFC<{
  shift: Shift | null;
  hasMissingDocs?: boolean;
  isShiftLoading: boolean;
}> = ({ shift, hasMissingDocs = false, isShiftLoading }) => {
  const [isLoading, setLoading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [removeInterestButtonText, setRemoveInterestButtonText] =
    useState("Cancel this shift");
  const { undoShiftActions } = useAppSelector((state) => state.shiftStore);
  const userId = useAppSelector((state) => state?.session?.agent?.userId);
  const history = useHistory();

  const redirectToDocsPage = () => {
    history.push("/home/account/documents");
  };

  useEffect(() => {
    if (!shift) return;

    const isInterested = !!shift.interested?.find(
      (agentId) => agentId === userId
    );
    const isAssigned = shift.agentId === userId;
    setIsCancelled(!(isInterested || isAssigned));
  }, [shift, userId]);

  const undoShiftActionsRef = useRef(undoShiftActions);
  undoShiftActionsRef.current = undoShiftActions;

  const onRemoveInterest = async () => {
    setLoading(true);
    setRemoveInterestButtonText("Cancelling Shift...");
    await api.shift.unmarkShiftInterest(shift!._id!);
    setRemoveInterestButtonText("Shift Cancelled");
    setLoading(false);
    setIsCancelled(true);
    history.push("/home/openShifts");
  };

  if (!shift) {
    return <Fragment />;
  }

  const isShiftOngoing = moment().isAfter(shift.start);
  const onlyShowCancelButton =
    isShiftOngoing && shift.isInstantPay && !shift.clockInOut;

  return (
    <Fragment>
      {!isCancelled || onlyShowCancelButton || hasMissingDocs ? (
        <IonFooter
          className="ion-padding footer-shadow"
          style={{ paddingBottom: "0" }}
        >
          {!shift.agent && (
            <IonButton
              color="dark"
              expand="block"
              fill="clear"
              onClick={onRemoveInterest}
              disabled={isLoading}
              className="cancel-button"
            >
              {removeInterestButtonText}
            </IonButton>
          )}
          {shift.agent && (
            <CancelShiftModal isShiftLoading={isShiftLoading} shift={shift} />
          )}
          {hasMissingDocs && !shift.agent && !isShiftOngoing && (
            <IonButton
              color="secondarybtn"
              shape="round"
              onClick={redirectToDocsPage}
              expand="block"
            >
              Upload Your Documents
            </IonButton>
          )}
        </IonFooter>
      ) : (
        <Fragment></Fragment>
      )}
    </Fragment>
  );
};

export { ShiftActionFooter };
