import { range } from "lodash";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonItem,
  IonLabel,
  IonSkeletonText,
} from "@ionic/react";
import React, { Fragment } from "react";

import { ShiftLoaderProps } from "./model";

const ShiftLoader: React.FC<ShiftLoaderProps> = ({ loading, count = 3 }) => {
  const cardCount = loading ? count : 0;

  return (
    <Fragment>
      {range(cardCount).map((index) => (
        <IonCard mode="ios" key={index}>
          <IonCardHeader>
            <IonCardSubtitle>
              <IonSkeletonText animated />
              <IonSkeletonText animated style={{ width: "70%" }} />
            </IonCardSubtitle>
          </IonCardHeader>
          <IonItem lines="none">
            <IonLabel>
              <h3>
                <IonSkeletonText animated />
              </h3>
              <p>
                <IonSkeletonText animated style={{ width: "70%" }} />
              </p>
              <p>
                <IonSkeletonText animated style={{ width: "50%" }} />
              </p>
            </IonLabel>
          </IonItem>
        </IonCard>
      ))}
    </Fragment>
  );
};

export { ShiftLoader };
