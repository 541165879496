import request from "superagent";
import { getAuthHeader } from "src/app/superagent";
import { environment } from "@env/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import { RequestOpenShiftOptions, ShiftDateMap } from "./calendar.model";

const fetchOpenShifts = async (
  options: RequestOpenShiftOptions
): Promise<ShiftDateMap> => {
  return await request
    .get(`${environment.baseUrl}/calendar/agentOpenShiftCount`)
    .set(await getAuthHeader())
    .query({ ...options, isAgent: true })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { fetchOpenShifts };
