import styled from "@emotion/styled";
import { IonSpinner } from "@ionic/react";
import React from "react";

const SpinnerContainer = styled.div`
  text-align: center;
  padding-top: 10px;
`;

interface InlineLoaderProps {
  loading: boolean;
}

const InlineLoader: React.FC<InlineLoaderProps> = ({ loading }) => (
  <SpinnerContainer hidden={!loading}>
    <IonSpinner />
  </SpinnerContainer>
);

export { InlineLoader };
