import { AppPage } from "../layout/page";
import { AuthorizedSignatoryContainer } from "./authorized";
import { FacilityRating } from "../facilityRating";
import { getReview } from "../rating/api";
import { InfoTitleItem } from "./infoTitleItem";
import { logEvent } from "src/lib/analytics";
import { ManualSignatory } from "./manual";
import { ManualSignature } from "./manual/manualSignature";
import { ManualSignSuccess } from "./manual/manualSignSuccess";
import { Plugins } from "@capacitor/core";
import { RejectedSignatories } from "./authorized/rejectedSignatories";
import { RequestedContainer } from "./authorized/requestedContainer";
import { RequestSignatoryConfirmAlert } from "./authorized/requestConfirmAlert";
import { ShiftTimeConfirmAlert } from "./shiftTimeConfirmAlert";
import { ShiftViewList } from "./shiftView";
import { SignButton } from "./signButton";
import { SignModeContainer } from "./signModeContainer";
import { Store } from "../store/store.model";
import { SwitchManualSignatory } from "./switchManualSignatory";
import { USER_EVENTS } from "../../constants/userEvents";
import { WorkerSignature } from "./workerSignature";
import {
  actionAddRating,
  actionRequestAuthorizedSignature,
  actionSignWithSignature,
  actionUpdateWorkerSignature,
} from "../store/ongoingShifts";
import {
  AuthorizedSignatory,
  RequestedSignatory,
  Shift,
  SignatoryStatus,
} from "src/lib/interface";
import { filter, isEmpty, get } from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ShiftSignatureProps, ShiftSignMode, ReviewData } from "./model";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { api } from "@app/api";
const { Network } = Plugins;

const ShiftSignature: React.FC<ShiftSignatureProps> = () => {
  const [shiftInfo, setShiftInfo] = useState<Shift>({});
  const [signMode, setSignMode] = useState<ShiftSignMode>(ShiftSignMode.WORKER);
  const [signature, setSignature] = useState<string | null>(null);
  const [signatoryInfo, setSignatoryInfo] = useState<AuthorizedSignatory>({});
  const [isConfirmTime, setIsConfirmTime] = useState<boolean>(false);
  const [isConfirmRequest, setIsConfirmRequest] = useState<boolean>(false);
  const [isSignSuccess, setIsSignSuccess] = useState<boolean>(false);
  const [reviewData, setReviewData] = useState<ReviewData>({} as ReviewData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { agent } = useSelector((state: Store) => state.session);

  const history = useHistory();
  const { state = {} } = useLocation();
  // const { shift } = state || {}
  const shift = get(state, "shift");
  useEffect(() => {
    if (!shift) {
      setShiftInfo({});
      return;
    }

    const requestedSignatories = filter(shift.requestedSignatories, {
      status: SignatoryStatus.REQUESTED,
    });

    if (requestedSignatories.length > 0) {
      setSignMode(ShiftSignMode.REQUESTED);
    } else {
      setSignMode(ShiftSignMode.WORKER);
    }
    (async function () {
      if (shift.facility?.userId) {
        const { data: verificationPreference } =
          await api.facility.fetchFacilityDetails(shift.facility.userId);
        setShiftInfo({
          ...shift,
          facility: { ...shift.facility, verificationPreference },
        });
      } else {
        setShiftInfo(shift);
      }
    })();
  }, [shift]);

  const updateShift = useCallback(
    (updatedInfo: Shift) =>
      setShiftInfo((state) => ({ ...state, ...updatedInfo })),
    []
  );

  const onCancel = () => {
    history.goBack();
  };

  const addReview = async () => {
    setIsLoading(true);
    const { rating, reasons, otherReason, review } = reviewData;
    await dispatch(
      actionAddRating({
        rating,
        reasons,
        otherReason,
        review,
        reviewFor: "AGENT",
        shiftId: shift._id,
        shift: shift,
      })
    );
    setIsLoading(false);
  };

  const checkForExistingReview = async () => {
    setIsLoading(true);
    const review = await getReview({ reviewFor: "AGENT", shiftId: shift._id });
    setIsLoading(false);
    if (isEmpty(review)) return false;
    return true;
  };

  const onSignClick = async () => {
    const status = await Network.getStatus();
    if (signMode === ShiftSignMode.WORKER) {
      setIsConfirmTime(true);
      return;
    }

    if (signMode === ShiftSignMode.AUTHORIZED) {
      setIsConfirmRequest(true);
      return;
    }

    if (signMode === ShiftSignMode.REQUESTED) {
      onCancel();
      return;
    }

    if (signMode === ShiftSignMode.MANUAL) {
      if (status.connected) {
        const doesReviewAlreadyExists = await checkForExistingReview();
        if (doesReviewAlreadyExists) {
          setSignMode(ShiftSignMode.MANUAL_SIGNATURE);
        } else {
          setSignMode(ShiftSignMode.RATING);
        }
        return;
      }
      setSignMode(ShiftSignMode.RATING);
      return;
    }

    if (signMode === ShiftSignMode.RATING) {
      addReview();
      setSignMode(ShiftSignMode.MANUAL_SIGNATURE);
      return;
    }

    if (signMode === ShiftSignMode.MANUAL_SIGNATURE) {
      signManually();
      logEvent(USER_EVENTS.SHIFT_SIGNED_OFF);
      return;
    }
  };

  const signManually = async () => {
    setIsLoading(true);
    const updatedInfo = await dispatch<any>(
      actionSignWithSignature(
        shiftInfo._id as string,
        signatoryInfo,
        signature as string,
        shift
      )
    );
    updateShift(updatedInfo);
    setSignature(null);
    setIsLoading(false);
    setIsSignSuccess(true);
  };

  const closeTimeConfirmModal = (isSuccess: boolean) => async () => {
    setIsConfirmTime(false);

    if (!isSuccess) return;

    setIsLoading(true);

    const updatedInfo = await dispatch<any>(
      actionUpdateWorkerSignature(
        shiftInfo._id as string,
        signature as string,
        shift
      )
    );

    updateShift(updatedInfo);
    setSignature(null);
    setIsLoading(false);
    setSignMode(ShiftSignMode.AUTHORIZED);
  };

  const closeSignatoryConfirmModal = (isSuccess: boolean) => async () => {
    setIsConfirmRequest(false);
    if (!isSuccess) return;

    setIsLoading(true);
    const updatedInfo = await dispatch<any>(
      actionRequestAuthorizedSignature(
        shiftInfo._id as string,
        signatoryInfo,
        shift
      )
    );
    updateShift(updatedInfo);
    logEvent(USER_EVENTS.REQUESTED_SIGNATURE_FROM_HCF);
    setIsLoading(false);
    setSignMode(ShiftSignMode.REQUESTED);
  };

  const closeSignSuccessModal = () => {
    setIsSignSuccess(false);
    onCancel();
  };

  const switchToManual = () => {
    setSignatoryInfo({ name: "", phone: "", role: "" });
    setSignMode(ShiftSignMode.MANUAL);
  };

  return (
    <Fragment>
      <AppPage title="Shift Signature">
        <SignModeContainer
          mode={signMode}
          requiredMode={ShiftSignMode.AUTHORIZED}
        >
          <RejectedSignatories
            requestedSignatories={shiftInfo.requestedSignatories!}
          />
        </SignModeContainer>

        <ShiftViewList
          shift={shiftInfo}
          isEditMode={signMode === ShiftSignMode.WORKER}
          updateShift={updateShift}
        />

        <SignModeContainer mode={signMode} requiredMode={ShiftSignMode.WORKER}>
          <WorkerSignature shift={shiftInfo} onChange={setSignature} />
        </SignModeContainer>

        <SignModeContainer
          mode={signMode}
          requiredMode={ShiftSignMode.AUTHORIZED}
        >
          <AuthorizedSignatoryContainer
            shiftInfo={shiftInfo}
            onChange={setSignatoryInfo}
          />
          <SwitchManualSignatory
            facility={shiftInfo.facility!}
            setSignMode={switchToManual}
          />
        </SignModeContainer>

        <SignModeContainer
          mode={signMode}
          requiredMode={ShiftSignMode.REQUESTED}
        >
          <RequestedContainer shiftInfo={shiftInfo} setSignMode={setSignMode} />
        </SignModeContainer>

        <SignModeContainer mode={signMode} requiredMode={ShiftSignMode.MANUAL}>
          <ManualSignatory
            signatoryInfo={signatoryInfo}
            onChange={setSignatoryInfo}
            facility={shiftInfo.facility!}
          />
        </SignModeContainer>

        <SignModeContainer mode={signMode} requiredMode={ShiftSignMode.RATING}>
          <InfoTitleItem>
            How did {agent && agent.name} perform during their shift?
          </InfoTitleItem>
          <FacilityRating
            setReviewData={setReviewData}
            setDisabled={setDisabled}
          />
        </SignModeContainer>

        <SignModeContainer
          mode={signMode}
          requiredMode={ShiftSignMode.MANUAL_SIGNATURE}
        >
          <ManualSignature
            signatoryInfo={signatoryInfo}
            onChange={setSignature}
          />
        </SignModeContainer>

        <SignButton
          signMode={signMode}
          loading={isLoading}
          isDisabled={isDisabled}
          shift={shiftInfo}
          signatory={signatoryInfo}
          signature={signature!}
          onClick={onSignClick}
        />
      </AppPage>

      <ShiftTimeConfirmAlert
        isOpen={isConfirmTime}
        time={shiftInfo.time!}
        onClose={closeTimeConfirmModal}
      />
      <RequestSignatoryConfirmAlert
        isOpen={isConfirmRequest}
        signatory={signatoryInfo}
        onClose={closeSignatoryConfirmModal}
      />
      <ManualSignSuccess
        isOpen={isSignSuccess}
        onClose={closeSignSuccessModal}
      />
    </Fragment>
  );
};

export { ShiftSignature };
