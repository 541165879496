import { LoginErrorType } from "../../constants/loginErrorConstants";

const AlertStrings = {
  [LoginErrorType.EMAIL_NOT_FOUND]: {
    header: "We can’t find that email",
    message:
      'Sign up with this email, or try logging in with a different one.<br><br>  If you think this is an error, then please contact our 24/7 Support Team by phone at <a href="tel:4088370116">(408) 837-0116</a>, or via email at <a href= "mailto:support@clipboardhealth.com">support@clipboardhealth.com</a>',
    buttonText: "Try a different email",
  },
  [LoginErrorType.AGENT_NOT_FOUND]: {
    header: "We can’t find that number",
    message:
      'Sign up with this phone number, or try loggin in with a different one.<br><br> If you think this is an error, then please contact our 24/7 Support Team by phone at <a  href="tel:4088370116">(408) 837-0116</a>, or via email at <a href= "mailto:support@clipboardhealth.com">support@clipboardhealth.com</a>',
    buttonText: "Try a different phone number",
  },
};

export { AlertStrings };
