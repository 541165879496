export const enum UPDATE_PROFILE_WORKFLOW {
  UPDATE_EMAIL = "UPDATE_EMAIL",
  UPDATE_PHONE = "UPDATE_PHONE",
}

export const enum UPDATE_EMAIL_STEPS {
  SSN_VERIFICATION = 0,
  PHONE_VERIFICATION = 1,
  OTP_VERIFICATION = 2,
  UPDATE_EMAIL = 3,
}

export const enum UPDATE_PHONE_STEPS {
  SSN_VERIFICATION = 0,
  EMAIL_VERIFICATION = 1,
  ENTER_PHONE = 2,
  UPDATE_PHONE = 3,
}

export const enum PROFILE_UPDATE_LOCAL_STORAGE_ITEMS {
  UPDATE_PROFILE_PHONE_NUMBER_CHANGED = "UPDATE_PROFILE_PHONE_NUMBER_CHANGED",
  UPDATE_PROFILE_EMAIL_CHANGED = "UPDATE_PROFILE_EMAIL_CHANGED",
}
