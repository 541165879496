import { environment } from "../../../environments/environment";
import { getAuthHeader } from "../../superagent";
import { logFailedApiRetry } from "../../utils/api_retry";

import { logApiFailureEvent } from "src/lib/analytics";
import request from "superagent";

import { AddressBody, AddressResponse } from "src/lib/interface";

const getAgreement = async (
  sendToEmail = false
): Promise<string | undefined> => {
  try {
    const { text } = await request
      .get(`${environment.baseUrl}/agentprofile/agreement`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/agentprofile/agreement`);
        return true;
      })
      .query({ sendToEmail })
      .set(await getAuthHeader());
    return text;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const verifySSN = async (ssn): Promise<any> => {
  try {
    const { verified } = await request
      .post(`${environment.baseUrl}/agentProfile/verifySSN`)
      .set(await getAuthHeader())
      .send({ ssn })
      .then(({ body }) => body);
    return verified;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const updateProfile = async (profile): Promise<any> => {
  try {
    const { verified } = await request
      .put(`${environment.baseUrl}/agentProfile/updateProfile`)
      .set(await getAuthHeader())
      .send({ profile })
      .then(({ body }) => body);
    return verified;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const requestOTP = async (phone): Promise<any> => {
  try {
    const { body } = await request
      .post(`${environment.baseUrl}/agentProfile/requestOTPNew`)
      .set(await getAuthHeader())
      .send({ phone });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { getAgreement, verifySSN, updateProfile, requestOTP };
