import request from "superagent";
import { logApiFailureEvent } from "src/lib/analytics";
import { getAuthHeader } from "@app/superagent";
import { environment } from "@env/environment";
import { Shift, ShiftStages } from "src/lib/interface";
import { logFailedApiRetry } from "@app/utils/api_retry";

const { baseUrl } = environment;

export const unmarkShiftInterest = async (shiftId: string): Promise<Shift> => {
  return await request
    .post(`${baseUrl}/v1/shifts/undoInterest`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      isNative: true,
    })
    .then(({ body }) => body.result)
    .catch(logApiFailureEvent);
};

export const fetchPotentialShiftsForQualifiedFacilities = async (
  agentId: string,
  facilityIds: string[],
  dateFilter: { start: string; end: string }
): Promise<{
  success: boolean;
  error?: string;
  totalPotentialShiftsCount?: number;
}> => {
  return await request
    .post(`${baseUrl}/calendar/potentialShiftsForQualifiedFacilities`)
    .set(await getAuthHeader())
    .send({ agentId, facilityIds, dateFilter })
    .then(({ body }) => body);
};

export interface CancelShiftPayload {
  shiftId: string;
  reasonType: string;
  reasonDescription: string;
  coordinates?: [Longitude: number, Latitude: number];
}

export const selfCancelShift = async (
  payload: CancelShiftPayload
): Promise<Shift> => {
  return await request
    .post(`${baseUrl}/v1/shifts/selfCancel`)
    .set(await getAuthHeader())
    .send({
      ...payload,
      isNative: true,
    })
    .then(({ body }) => body.result)
    .catch((error) => {
      logApiFailureEvent(error);
      return error.response;
    });
};

export const fetchShiftInfo = async (shiftId: string): Promise<Shift> => {
  return await request
    .get(`${baseUrl}/shift/agentSignature/${shiftId}`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/agentSignature/${shiftId}`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const simpĺeRecordShiftTime = async (
  shiftId: string,
  stage: ShiftStages,
  location: number[]
): Promise<Shift> => {
  return await request
    .post(`${baseUrl}/shift/agentSignature/recordTime`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      stage,
      location,
    })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const markShiftAsNonInstantPay = async (
  shiftId: string,
  reason: string
): Promise<Shift> => {
  return await request
    .post(`${baseUrl}/v1/shifts/markNonInstant`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      reason,
    })
    .then(({ body }) => body.response);
};

export const fetchShiftDetails = async ({ shiftId }): Promise<Shift> => {
  return await request
    .get(`${baseUrl}/v1/shifts/myShifts/details/${shiftId}`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/v1/shifts/myShifts/details/${shiftId}`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body.response);
};

export const fetchNextTwoDaysShifts = async (tmz: string): Promise<any> => {
  return await request
    .get(`${baseUrl}/v1/shifts/myShifts/nextTwoDays`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/v1/shifts/myShifts/nextTwoDays`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ tmz })
    .then(({ body }) => body.response);
};

export const fetchFutureShifts = async (tmz: string): Promise<any> => {
  return await request
    .get(`${baseUrl}/v1/shifts/myShifts/future`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/v1/shifts/myShifts/future`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ tmz })
    .then(({ body }) => body.response);
};

export const fetchMaxEarnings = async (): Promise<{
  amount: number;
  date?: Date;
}> => {
  return await request
    .get(`${baseUrl}/calendar/myShifts/maxEarnings`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/calendar/myShifts/maxEarnings`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const checkAttendanceConfirmation = async (
  urgentShiftsServiceUrl: string,
  payload
) => {
  return request
    .get(`${urgentShiftsServiceUrl}/api/attendance/check`)
    .query({ ...payload })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const confirmAttendance = async (
  urgentShiftsServiceUrl: string,
  payload
) => {
  return request
    .post(`${urgentShiftsServiceUrl}/api/attendance/confirm`)
    .set(await getAuthHeader())
    .send({ ...payload })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};
