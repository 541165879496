import { createAnimation } from "@ionic/react";
import { RouterPath } from "../routing/constant";
import { matchPath } from "react-router";

const checkIfRouteValid = (route) => {
  const paths = Object.values(RouterPath);

  for (const path of paths) {
    if (
      matchPath(route, {
        path,
        exact: true,
        strict: false,
      })
    ) {
      return true;
    }
  }
  return false;
};

const animationBuilder = (baseEl, opts) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("transform", "translateX(100px)", "translateX(0px)")
    .fromTo("opacity", 0, 1)
    .duration(300);

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translateX(0px)", "translateX(100px)")
    .fromTo("opacity", 1, 0)
    .duration(300);

  const animation = createAnimation()
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);

  return animation;
};

export { checkIfRouteValid, animationBuilder };
