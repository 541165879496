// This Context should be on the most top level of the APP to prevent re-render the google API
import { useLoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import React, { createContext } from "react";
import { environment } from "../../environments/environment";

const { googleMapApi } = environment;

const initialContext = {
  isMapLoaded: false,
  mapLoadError: "",
};

export const GoogleMapContext = createContext(initialContext);

const libraries: Libraries = ["places"];

export const GoogleMapProvider = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: googleMapApi,
    preventGoogleFontsLoading: false,
    language: "en",
    region: "US",
    version: "3.48",
    libraries,
  });
  const value = {
    isMapLoaded: isLoaded,
    mapLoadError: loadError ? `${loadError}` : "",
  };
  return (
    <GoogleMapContext.Provider value={value}>
      {props.children}
    </GoogleMapContext.Provider>
  );
};
