import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonToolbar,
  IonIcon,
  IonImg,
  IonHeader,
} from "@ionic/react";
import { mailUnread } from "ionicons/icons";
import React from "react";
import { EmailWaitingProps } from "./login.model";

const EmailWaiting: React.FC<EmailWaitingProps> = ({ email, opacity }) => {
  return (
    <IonPage>
      <IonContent class="login-content ion-padding ion-text-center">
        <IonHeader no-border class="verify-sent-header">
          <IonToolbar color="transparent">
            <IonButtons slot="start">
              <IonBackButton
                defaultHref="/welcome/login"
                mode="ios"
                text="Log in"
                color="light"
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonRow
          style={{ height: "100%", padding: "5%" }}
          class="ion-align-items-center"
        >
          <IonCol
            style={{ height: "100%" }}
            align-self-center
            sizeMd="8"
            offsetMd="2"
            offsetLg="4"
            sizeLg="4"
          >
            <div className="verify-sent-container">
              <div
                className="login-top-container verify-sent-top-container"
                style={{ opacity }}
              >
                <h1>Great rates.</h1>
                <h1>Instant payouts.</h1>
                <h1>Lots of shifts.</h1>
                <IonImg src="/assets/logo/cbh-logo.png" className="logo" />
              </div>
              <div>
                <IonIcon
                  size="large"
                  style={{ color: "white" }}
                  icon={mailUnread}
                  mode="md"
                />
                <p>We sent a login link to {email || "your email address"}.</p>
                <p>Check your inbox to finish logging in.</p>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export { EmailWaiting };
