import "../style.scss";
import { Facility } from "src/lib/interface";
import { logEvent } from "src/lib/analytics";
import { IonItem, IonToast } from "@ionic/react";
import React, { useEffect, useMemo } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { ShiftItemAlerts } from "./ShiftItemAlerts";
import { ShiftItemView } from "./shiftItemView";
import { USER_EVENTS } from "../../../constants";
import { useShiftItemHook } from "../custom-hooks/useShiftItemHook";
import { ShiftItemCategory, ShiftItemProps } from "../model";
import { createCase } from "../api";

const ShiftItem: React.FC<ShiftItemProps> = ({
  shift,
  isInstantBookingShift,
  updateShiftList,
  goToDocumentsPage,
  handleShiftFlagFound,
  onFacilityDetailsClick,
  isHcpInstantPayEnabled,
  payVersion,
  isDisabled,
  handleShiftActivityLog,
  shiftActivityLog,
  isLastMinuteShift,
  validator,
  className = "",
  pushNotification,
  showFacilityDetails = true,
  loggerSearchMode,
  onItemClick,
  isUrgentShift,
}) => {
  const {
    loadAlertDialog,
    category,
    alertOpen,
    onCancel,
    onConfirm,
    interested,
    toast,
    onToastDidDismiss,
    pushNotificationOpen,
    closePushNotification,
    submitting,
    submitted,
    isLoading,
    workApproved,
  } = useShiftItemHook({
    shift,
    goToDocumentsPage,
    isInstantBookingShift,
    handleShiftActivityLog,
    updateShiftList,
    isLastMinuteShift: !!isLastMinuteShift,
    validator,
    pushNotification,
    shiftActivityLog,
    isUrgentShift,
  });

  const ldClient = useLDClient();

  const { tbioOptions, isETPFlagEnabled } = shift.facility as Facility;
  const extraTimePayEnabled = isETPFlagEnabled && tbioOptions?.enabled;
  const showingETPIcon =
    extraTimePayEnabled && ldClient?.variation("display-extra-time-pay", false);
  const etpEventOptions = {
    extra_time_pay_enabled: extraTimePayEnabled ? "yes" : "no",
    showing_extra_time_pay_icon: showingETPIcon ? "yes" : "no",
  };

  useEffect(() => {
    if (isInstantBookingShift) {
      logEvent(USER_EVENTS.DISCOVERED_AN_INSTANT_BOOK_SHIFT);
    }
  }, [isInstantBookingShift]);

  useEffect(() => {
    if (alertOpen && !isLastMinuteShift) {
      if (category === ShiftItemCategory.BOOKING_WARNING) {
        if (isInstantBookingShift) {
          logEvent(USER_EVENTS.TAPPED_INSTANTBOOK_SHIFT, {
            search_mode: loggerSearchMode ? loggerSearchMode : "date",
            instant_book: true,
            instant_pay: shift?.isInstantPay,
            last_minute: isLastMinuteShift,
            ...etpEventOptions,
          });
        } else {
          logEvent(USER_EVENTS.TAPPED_BOOK_SHIFT, {
            search_mode: loggerSearchMode ? loggerSearchMode : "date",
            instant_pay: shift?.isInstantPay,
            last_minute: isLastMinuteShift,
            ...etpEventOptions,
          });
        }
      } else if (category === ShiftItemCategory.MISSING_CBH_DOCUMENTS) {
        logEvent(USER_EVENTS.VIEWED_CBH_MISSING_DOCS_POPUP);
      } else if (category === ShiftItemCategory.WELCOME_TO_INSTANTPAY) {
        logEvent(USER_EVENTS.VIEWED_WELCOME_TO_INSTANTPAY);
      }
    }

    if (
      [ShiftItemCategory.MISSING_CBH_DOCUMENTS].indexOf(category) !== -1 &&
      alertOpen
    ) {
      createCase(shift?._id as string);
    }
  }, [alertOpen, category, isInstantBookingShift, isLastMinuteShift]);

  useEffect(() => {
    if (submitted && !isLastMinuteShift) {
      logEvent(USER_EVENTS.BOOKED_A_SHIFT, {
        instant_book: false,
        last_minute: false,
        instant_pay: false,
      });
    }
  }, [isLastMinuteShift, submitted]);

  if (shift.isHolidayShift) {
    handleShiftFlagFound?.("holiday");
  }

  const isInstantPayAllowed = useMemo(() => {
    const hcfInstantPayStatus =
      "isInstantPay" in shift
        ? shift.isInstantPay
        : shift.isHCFInstantPayProhibited === null
        ? false
        : !shift.isHCFInstantPayProhibited;
    return hcfInstantPayStatus && isHcpInstantPayEnabled;
  }, [isHcpInstantPayEnabled, shift.isHCFInstantPayProhibited]);

  const onClickOnFacilityDetails = (displayExtraTimePayCard = false) => {
    const facility = shift?.facility as Facility;
    facility.isInstantPayEnabled =
      shift.isHCFInstantPayProhibited === null
        ? false
        : !shift.isHCFInstantPayProhibited;
    onFacilityDetailsClick?.(
      shift,
      isInstantBookingShift as boolean,
      displayExtraTimePayCard
    );
  };

  return (
    <IonItem
      className={`shift-item__container ${className}`}
      disabled={isDisabled}
    >
      <ShiftItemView
        isLoading={isLoading}
        submitted={submitted}
        submitting={submitting}
        loadAlertDialog={loadAlertDialog}
        onClickOnFacilityDetails={onClickOnFacilityDetails}
        shift={shift}
        payVersion={payVersion}
        isInstantPayAllowed={isInstantPayAllowed as boolean}
        isInstantBookingShift={isInstantBookingShift}
        category={category}
        interested={interested}
        workApproved={workApproved}
        isLastMinuteShift={isLastMinuteShift}
        showFacilityDetails={showFacilityDetails}
        onItemClick={onItemClick}
        isUrgentShift={isUrgentShift}
      />
      <IonToast {...toast} onDidDismiss={onToastDidDismiss} position="top" />
      <ShiftItemAlerts
        shift={shift}
        category={category}
        isInstantBookingShift={isInstantBookingShift}
        alertOpen={alertOpen}
        onConfirm={onConfirm}
        onCancel={onCancel}
        pushNotificationOpen={pushNotificationOpen}
        closePushNotification={closePushNotification}
        isUrgentShift={isUrgentShift}
      />
    </IonItem>
  );
};

export { ShiftItem };
