import React from "react";
import {
  IonBadge,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonText,
} from "@ionic/react";
import CheckrPill from "../components/checkrPill";

import {
  DocumentsListProps,
  RequirementStatusData,
  RequirementTypes,
} from "./model";
import { CHECKR_REQUIREMENT_ID } from "@constants/userEvent";

const DocumentsList: React.FC<DocumentsListProps> = ({
  status,
  type,
  loading,
  onClick,
  extraData,
  isCheckrPillStatusEnabled,
}) => {
  const { potentialNewShiftsCount = 0 } = extraData || {};
  const showPotentialShiftsText =
    type === RequirementTypes.REQUIRED_BY_FACILITIES &&
    potentialNewShiftsCount > 0;

  if (loading) {
    return (
      <IonList>
        <IonItem>
          <IonLabel>
            <h4>
              <IonSkeletonText animated style={{ width: "100%" }} />
            </h4>
            <p>
              <IonSkeletonText animated style={{ width: "100%" }} />
            </p>
          </IonLabel>
        </IonItem>
      </IonList>
    );
  }
  if (!status[type].length) return null;

  return (
    <IonList className="ion-margin-top">
      <IonListHeader
        className={`document-list-header ${
          showPotentialShiftsText ? "has-subtitle" : ""
        }`}
      >
        <h4 className={showPotentialShiftsText ? "no-margin" : ""}>
          {RequirementStatusData[type].status}
        </h4>
        {showPotentialShiftsText && (
          <span className="document-list-subtitle">
            Unlock{" "}
            {`${potentialNewShiftsCount} ${
              potentialNewShiftsCount > 1 ? "shifts" : "shift"
            }`}{" "}
            by uploading these docs!
          </span>
        )}
      </IonListHeader>
      {isCheckrPillStatusEnabled &&
        status[type].find(
          (reqId: string) => reqId === CHECKR_REQUIREMENT_ID
        ) && (
          <CheckrPill
            requirementStatusType={type}
            expiry={`${status.requirements[CHECKR_REQUIREMENT_ID].expiry}`}
            requirementStatus={status}
          />
        )}

      {status[type].map((reqId, index) => {
        if (
          !status.requirements[reqId] ||
          (isCheckrPillStatusEnabled && reqId === CHECKR_REQUIREMENT_ID)
        )
          return;
        return (
          <IonItem
            key={`${reqId}-${index}`}
            disabled={loading}
            button
            detail
            onClick={onClick(status?.requirements[reqId], type)}
          >
            <IonLabel>
              <h4>{status?.requirements[reqId].name}</h4>
              <p>
                {RequirementStatusData[type].listTitleColor ? (
                  <IonText color={RequirementStatusData[type].listTitleColor}>
                    {RequirementStatusData[type].listTitle}
                  </IonText>
                ) : (
                  <IonText>{RequirementStatusData[type].listTitle}</IonText>
                )}
              </p>
            </IonLabel>
            {RequirementStatusData[type].listIcon && (
              <IonIcon
                icon={RequirementStatusData[type].listIcon}
                color={RequirementStatusData[type].listTitleColor}
                slot="end"
              />
            )}
          </IonItem>
        );
      })}
    </IonList>
  );
};

export { DocumentsList };
