// import { Plugins } from '@capacitor/core'
import React, { Fragment, useEffect } from "react";
import { init, ONGOING_SHIFT_STORE_KEY } from "../store/ongoingShifts";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../store/store.model";
import { get } from "lodash";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

const OngoingShiftSync: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const ongoingShiftStore = useSelector((state: Store) =>
    get(state, ONGOING_SHIFT_STORE_KEY, {})
  );

  useEffect(() => {
    const updateStorageFromStore = async () => {
      const isLoading = get(ongoingShiftStore, "isLoading", {});

      if (!isLoading) {
        const storeData = JSON.stringify(ongoingShiftStore);
        await Storage.set({ key: ONGOING_SHIFT_STORE_KEY, value: storeData });
      }
    };
    updateStorageFromStore();
  }, [ongoingShiftStore]);

  useEffect(() => {
    dispatch(init());
  }, []);

  return <Fragment />;
};

export { OngoingShiftSync };
