import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { mail } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { clearLoginError, loginWithLink } from "../store/session";
import { Store } from "../store/store.model";
import { LoginRouteState } from "./login.model";

const EmailVerify: React.FC<{}> = () => {
  const [email, setEmail] = useState<string>("");

  const { loginError } = useSelector((state: Store) => state.session);
  const location = useLocation();
  const dispatch = useDispatch();
  const emailToVerify = localStorage.getItem("emailToVerify");

  useEffect(() => {
    if (emailToVerify) {
      verify();
    }
  }, [emailToVerify]);

  const onEmailChange = (event: Event) => {
    setEmail(event.target?.["value"]);
  };

  const verify = async () => {
    const { url } = (location.state || {}) as LoginRouteState;
    if (!email && emailToVerify) {
      return dispatch(loginWithLink(url as string, emailToVerify));
    }
    dispatch(loginWithLink(url as string, email));
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (event.key === "Enter") {
      verify();
    }
  };

  const onToastDismiss = () => {
    dispatch(clearLoginError());
  };

  return (
    <IonPage>
      <IonContent class="login-content ion-padding ion-text-center">
        <IonToolbar color="transparent">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/welcome/login"
              mode="ios"
              text="Login"
              color="light"
            />
          </IonButtons>
        </IonToolbar>
        <IonRow class="ion-align-items-center">
          <IonCol
            align-self-center
            sizeMd="8"
            offsetMd="2"
            offsetLg="4"
            sizeLg="4"
          >
            <div style={{ margin: "60px 0" }}>
              <h4>Welcome</h4>
              <h4 className="ion-margin-bottom">
                Please enter your email to verify
              </h4>
            </div>

            <IonItem lines="none">
              <IonIcon icon={mail} slot="start" color="medium" />
              <IonInput
                class="ion-text-left"
                type="email"
                placeholder="Email"
                onIonChange={onEmailChange}
                value={email}
                onKeyUp={onKeyUp}
              />
            </IonItem>
            <IonButton
              color="primary"
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom login-button"
              onClick={verify}
              disabled={!email}
              id="login-button"
            >
              Verify
            </IonButton>
          </IonCol>
        </IonRow>
        <IonToast
          isOpen={!!loginError}
          onDidDismiss={onToastDismiss}
          message={loginError as Exclude<typeof loginError, null>}
          duration={1000}
          color="danger"
          position="top"
        />
      </IonContent>
    </IonPage>
  );
};

export { EmailVerify };
