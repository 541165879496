import { isPlatform } from "@ionic/core";
import { Plugins } from "@capacitor/core";
import { IonInput, IonRow } from "@ionic/react";
import React, { useEffect, useRef } from "react";

const { Keyboard } = Plugins;

const CODE_LENGTH = 6;

const OtpInputs = ({ verify, code, setCode, noBorder = false }) => {
  const inputRef = useRef<HTMLIonInputElement | null>(null);

  useEffect(() => {
    const timeout = setTimeout(focusInput, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const focusInput = async () => {
    await inputRef.current?.setFocus();
    if (isPlatform(window, "capacitor")) {
      Keyboard.show();
    }
  };

  const onCodeChange = (event: Event) => {
    let new_code = event.target?.["value"];
    new_code =
      new_code.trim().length > 6
        ? new_code.trim().substring(0, 6)
        : new_code.trim();
    setCode(new_code);
    if (new_code.length === CODE_LENGTH) {
      verify(new_code);
      return;
    }
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (event.key === "Enter") {
      verify();
      return;
    }
  };

  //Need to monitor key press events to prevent entering more than 6 digits. This is needed as IonInput does not
  //validated max length for input type number.
  //Keypress event is used as it captures event only for characters. Not for keys like backspace, shift, delete etc.
  const onKeyPress = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (code.length === CODE_LENGTH) {
      event.preventDefault();
    }
  };

  return (
    <IonRow>
      <IonInput
        className={`otp-field-border ${noBorder ? "no-border" : ""}`}
        type="number"
        ref={inputRef}
        onIonChange={onCodeChange}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        value={code}
        placeholder="123456"
      />
    </IonRow>
  );
};

export { OtpInputs };
