import { Shift } from "src/lib/interface";
import { logEvent } from "src/lib/analytics";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { get, orderBy, groupBy, map } from "lodash";
import React, { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Response, ResponseCodes } from "../../utils/response";
import { notifications, notificationsOff } from "ionicons/icons";

import { Store } from "../store/store.model";
import { fetchLastMinuteShifts } from "./api";
import { ShiftsList } from "./shiftsList";
import { BackgroundImageLayout } from "./backgroundImageLayout";
import { setNotificationPreference } from "../privacyPolicy/notificationPreference/api";
import { showToast } from "../layout/toast";

import { USER_EVENTS } from "../../constants";
import { RequestLastMinuteShiftsOptions } from "./model";
import { RefresherEventDetail } from "@ionic/core";
import moment from "moment-timezone";

let SetUndoSnoozeTime: ReturnType<typeof setTimeout> | undefined;
const LastMinuteShifts: React.FC = () => {
  const { date } = useParams() as any;
  const history = useHistory();
  const agent = useSelector((state: Store) => get(state, "session.agent", {}));
  const qualification = useSelector((state: Store) =>
    get(state, "session.agent.qualification", "")
  );

  const [isUpdatingSnoozePreference, setIsUpdatingSnoozePreference] =
    useState(false);
  const [isNotificationSnoozed, setIsNotificationSnoozed] = useState(false);
  const [loader, setLoader] = useState(true);
  const [snoozableDays, setSnoozableDays] = useState<number>(7);
  const [lastMinuteShifts, setLastMinuteShifts] = useState<Array<Shift>>([]);

  const sortAndUniqueShiftsByFacilityAndTime = (shifts: Shift[]) => {
    shifts = orderBy(shifts, (shift) => shift.pay, "desc");
    shifts = groupBy(
      shifts,
      (shift) =>
        (shift.facility?.name as string) +
        shift.start +
        shift.end +
        shift.agentId
    ) as ReturnType<typeof groupBy>;

    let updatedShifts: Shift[] = [];
    Object.entries(shifts).forEach(([, val]) => {
      updatedShifts.push(val[0]);
    });
    updatedShifts = orderBy(updatedShifts, (shift) => shift.start, "asc");
    return updatedShifts;
  };

  const getLastMinuteShifts = useCallback(async () => {
    try {
      const options: RequestLastMinuteShiftsOptions = {
        getCountOnly: false,
      };
      const response: Response = await fetchLastMinuteShifts(options);

      if (response.code === ResponseCodes.Success) {
        setLoader(false);

        setLastMinuteShifts(
          sortAndUniqueShiftsByFacilityAndTime(response.data.lastMinuteShifts)
        );

        if (
          response.data.notificationPreferences &&
          response.data.notificationPreferences.last_minute_notifications
        )
          setIsNotificationSnoozed(
            response.data.notificationPreferences.last_minute_notifications
              .isSnoozed
          );
        setSnoozableDays(response.data.snoozableDays);
      }
    } catch (ex) {
      // Ignore
      setLoader(false);
      console.error(ex);
    }
  }, [agent, date, qualification]);

  useIonViewDidEnter(() => {
    logEvent(USER_EVENTS.VIEWED_LAST_MINUTE_SHIFTS, {
      hcpUserId: agent.userId,
      hcpName: agent.name,
      timestamp: moment().format(),
    });
    getLastMinuteShifts();
  }, []);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await getLastMinuteShifts();
    event.detail.complete();
  };

  const goToDocumentsPage = () => {
    history.push("/home/account/documents");
  };
  const goToOpenShifts = () => {
    if (SetUndoSnoozeTime !== null) {
      history.push("/home/openShifts");
    }
  };
  const snoozeNotifications = async () => {
    try {
      setIsUpdatingSnoozePreference(true);
      const response: Response = await setNotificationPreference({
        notificationPreference: {
          key: "last_minute_notifications",
          snooze: true,
        },
      });

      if (response.code === ResponseCodes.Success) {
        setIsNotificationSnoozed(true);
        SetUndoSnoozeTime = setTimeout(() => goToOpenShifts(), 3000);
      } else {
        await showToast(response.message as string);
      }
    } catch (err) {
      console.error(err);
    }
    setIsUpdatingSnoozePreference(false);
  };

  const undoSnoozeNotifications = async () => {
    try {
      SetUndoSnoozeTime = undefined;
      clearTimeout(SetUndoSnoozeTime);
      setIsUpdatingSnoozePreference(true);
      const response: Response = await setNotificationPreference({
        notificationPreference: {
          key: "last_minute_notifications",
          snooze: false,
        },
      });

      if (response.code === ResponseCodes.Success) {
        setIsNotificationSnoozed(false);
      } else {
        await showToast(response.message as string);
      }
    } catch (err) {
      console.error(err);
    }
    setIsUpdatingSnoozePreference(false);
  };

  const onShiftClaim = async (shiftId, isAlreadyGrabbed, startAt) => {
    await getLastMinuteShifts();

    const hasShiftWithSameStart = [
      ...map(
        lastMinuteShifts.filter((shift) => shift._id !== shiftId),
        "start"
      ),
    ].includes(startAt);

    if (hasShiftWithSameStart && isAlreadyGrabbed) {
      logEvent(USER_EVENTS.INSTANT_BOOK_FAILURE_AVAILABLE_VIEWED, {
        shiftId,
        message:
          "User viewed Instant book failed message and similar shifts were available",
      });
    } else if (!hasShiftWithSameStart && isAlreadyGrabbed) {
      logEvent(USER_EVENTS.INSTANT_BOOK_FAILURE_AVAILABLE_NONE, {
        shiftId,
        message:
          "User viewed Instant book failed message and no similar shifts were available",
      });
      history.push("/home/openShifts");
    } else {
      history.push("/home/myShifts");
    }
  };

  return (
    <Fragment>
      <IonPage>
        <IonHeader no-border>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                text=""
                defaultHref="/home/openShifts"
                mode="ios"
              />
            </IonButtons>
            <IonTitle>Last Minute Shifts</IonTitle>
          </IonToolbar>
        </IonHeader>
        {loader ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <img
              width="250"
              height="250"
              src="/assets/gifs/loadingAvailableShifts.gif"
              alt="Loading available shifts"
            />
          </div>
        ) : (
          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <BackgroundImageLayout />
            <div className="last-minute-shifts-content container">
              <IonLabel className="last-minute-shifts-content-heading">
                Check out the last minute shifts that
                <br /> were just posted!
              </IonLabel>

              <IonItem
                className="snooze-message-container"
                disabled={isUpdatingSnoozePreference}
                onClick={
                  !isNotificationSnoozed
                    ? snoozeNotifications
                    : undoSnoozeNotifications
                }
              >
                <IonLabel className="snooze-message-label">
                  <IonIcon
                    icon={
                      !isNotificationSnoozed ? notificationsOff : notifications
                    }
                  />
                  {isNotificationSnoozed
                    ? "Undo snooze"
                    : `Snooze message for ${snoozableDays} days`}
                </IonLabel>
              </IonItem>

              <ShiftsList
                shifts={lastMinuteShifts}
                goToDocumentsPage={goToDocumentsPage}
                isNotificationSnoozed={isNotificationSnoozed}
                onShiftClaim={onShiftClaim}
              />
            </div>
          </IonContent>
        )}
      </IonPage>
    </Fragment>
  );
};

export { LastMinuteShifts };
