import { Plugins } from "@capacitor/core";
import { intercomLogout, logEvent } from "src/lib/analytics";
import { Agent } from "src/lib/interface";
import { isPlatform } from "@ionic/react";
import { User } from "firebase";
import { Dispatch } from "redux";
import { LoginErrorType } from "../../../constants/loginErrorConstants";
import { USER_EVENTS } from "../../../constants/userEvents";
import { environment } from "../../../environments/environment";
import { hcpAppLogger } from "../../remoteLoggers";
import { GetState } from "../store.model";
import {
  getAgentByPhone,
  getReferralRate,
  requestAgentOTP,
  sendAgentLoginLink,
  updateAgentLoggedInTime,
  validateAgentOTP,
} from "./api";
import { onLoggedOut, reloadFirebaseUser } from "./init";
import { ActionType, LocalStorage, SessionAction } from "./session.model";

const { Storage } = Plugins;

const verifyPhone =
  (phone: string, isSignup: boolean) =>
  async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<SessionAction | boolean> => {
    dispatch({
      type: ActionType.LOGIN_REQUESTED,
      data: {
        isSignup: isSignup,
      },
    });
    if (isSignup) {
      localStorage.setItem("isSignup", "true");
    } else {
      localStorage.removeItem("isSignup");
    }

    if (!phone || phone.length !== environment.mobileLength) {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error: `Please enter a valid US phone number to sign ${
            isSignup ? "up" : "in"
          }.`,
          errorType: LoginErrorType.INVALID_PHONE_NUMBRER,
        },
      });

      return false;
    }
    if (!isSignup) {
      const agent = await getAgentByPhone(phone);

      if (!agent) {
        dispatch({
          type: ActionType.LOGIN_ERROR,
          data: {
            error: "No account found. New? Sign up today to join.",
          },
        });

        return false;
      }
    }

    const { firebaseAuth } = getState().session;
    const { error } = await firebaseAuth.signInWithPhoneNumber(
      `${environment.mobileCode}${phone}`
    );

    if (error) {
      hcpAppLogger({
        logType: "ERROR",
        title: "Firebase Authentication Failed",
        logArea: "FIREBASE",
        featureName: isSignup ? "SIGNUP" : "LOGIN",
        hcpMobile: `${environment.mobileCode}${phone}`,
        details: {
          error,
        },
      });
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error,
          errorType: LoginErrorType.FIREBASE_AUTH_ERROR,
          // error: 'Invalid phone number',
        },
      });

      return false;
    }

    if (isSignup) {
      logEvent(USER_EVENTS.SIGNED_UP);
    }

    return dispatch({
      type: ActionType.VERIFICATION_ID_RECEIVED,
      data: {
        verification: { phone },
      },
    });
  };

const reVerifyWithPhone =
  (phone: string) =>
  async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<SessionAction | boolean> => {
    const { firebaseAuth } = getState().session;
    const { error } = await firebaseAuth.reAuthWithPhoneNumber(
      `${environment.mobileCode}${phone}`
    );

    if (error) {
      hcpAppLogger({
        logType: "ERROR",
        title: "Firebase ReVerifying Failed",
        logArea: "FIREBASE",
        featureName: "SIGNUP",
        hcpMobile: `${environment.mobileCode}${phone}`,
        details: {
          error,
        },
      });
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error,
          // error: 'Invalid phone number',
        },
      });

      return false;
    }

    return dispatch({
      type: ActionType.VERIFICATION_ID_RECEIVED,
      data: {
        verification: { phone },
      },
    });
  };

const loginWithCode =
  (code: string, phone: string) =>
  async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<SessionAction | boolean> => {
    dispatch({
      type: ActionType.VERIFICATION_REQUESTED,
    });
    const { firebaseAuth, isSignup } = getState().session;

    const { error } = await firebaseAuth.confirmPhoneCode(code);

    if (error) {
      hcpAppLogger({
        logType: "ERROR",
        title: "Firebase Confirm OTP Failed",
        logArea: "FIREBASE",
        featureName: "LOGIN",
        hcpMobile: `${environment.mobileCode}${phone}`,
        details: {
          error,
          otp: code,
        },
      });
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error,
        },
      });
      logEvent(USER_EVENTS.TWO_FACTOR_CODE_DENIED);
      return false;
    }

    logEvent(USER_EVENTS.TWO_FACTOR_CODE_ACCEPTED);
    // User and Agent does not exist in DB and user is currently in signup flow
    if (!isSignup) {
      updateAgentLoggedInTime(firebaseAuth);
    }
    logEvent(USER_EVENTS.SIGNED_IN);
    return true;
  };

const verifyPhoneRequestOTP =
  (phone: string, isSignup: boolean) =>
  async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<SessionAction | boolean> => {
    dispatch({
      type: ActionType.LOGIN_REQUESTED,
      data: {
        isSignup: isSignup,
      },
    });
    if (isSignup) {
      localStorage.setItem("isSignup", "true");
    } else {
      localStorage.removeItem("isSignup");
    }

    if (!phone || phone.length !== environment.mobileLength) {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error: `Please enter a valid US phone number to sign ${
            isSignup ? "up" : "in"
          }.`,
          errorType: LoginErrorType.INVALID_PHONE_NUMBRER,
        },
      });

      return false;
    }
    const res = await requestAgentOTP(phone, isSignup);
    if (!isSignup) {
      if (!res || res?.error === "AgentNotFound") {
        dispatch({
          type: ActionType.LOGIN_ERROR,
          data: {
            error: "No account found. New? Sign up today to join.",
            errorType: `${res?.error}`,
          },
        });
        return false;
      }
    }
    if (!res || res?.error) {
      hcpAppLogger({
        logType: "ERROR",
        title: res?.error,
        logArea: "FIREBASE",
        featureName: isSignup ? "SIGNUP" : "LOGIN",
        hcpMobile: `${environment.mobileCode}${phone}`,
        details: {
          error: `${res?.message}`,
        },
      });
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error: `${res?.message}`,
          errorType: `${res?.error}`,
        },
      });
      return false;
    }
    if (isSignup) {
      logEvent(USER_EVENTS.SIGNED_UP);
    }
    if (res?.data?.firebaseToken) {
      localStorage.setItem("firebaseToken", res.data.firebaseToken);
    }
    return dispatch({
      type: ActionType.VERIFICATION_ID_RECEIVED,
      data: {
        verification: { phone },
      },
    });
  };

const reVerifyPhoneRequestOTP =
  (phone: string) =>
  async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<SessionAction | boolean> => {
    const { isSignup } = getState().session;

    const res = await requestAgentOTP(phone, isSignup);
    if (!isSignup) {
      if (!res || res?.error === "AgentNotFound") {
        dispatch({
          type: ActionType.LOGIN_ERROR,
          data: {
            error: "No account found. New? Sign up today to join.",
          },
        });
        return false;
      }
    }
    if (res?.error) {
      hcpAppLogger({
        logType: "ERROR",
        title: res.error,
        logArea: "FIREBASE",
        featureName: "SIGNUP",
        hcpMobile: `${environment.mobileCode}${phone}`,
        details: {
          error: `${res.message}`,
        },
      });
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error: `${res.message}`,
        },
      });

      return false;
    }
    if (res?.data?.firebaseToken) {
      localStorage.setItem("firebaseToken", res.data.firebaseToken);
    }
    return dispatch({
      type: ActionType.VERIFICATION_ID_RECEIVED,
      data: {
        verification: { phone },
      },
    });
  };

const loginWithOTP =
  (code: string, phone: string) =>
  async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<SessionAction | boolean> => {
    dispatch({
      type: ActionType.VERIFICATION_REQUESTED,
    });
    const firebaseToken = localStorage.getItem("firebaseToken");
    const res = await validateAgentOTP(phone, code);
    if (res?.error) {
      hcpAppLogger({
        logType: "ERROR",
        title: res.error,
        logArea: "FIREBASE",
        featureName: "LOGIN",
        hcpMobile: `${environment.mobileCode}${phone}`,
        details: {
          error: `${res.message}`,
          otp: code,
        },
      });
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error: `${res.message}`,
        },
      });
      logEvent(USER_EVENTS.TWO_FACTOR_CODE_DENIED);
      return false;
    }
    const { firebaseAuth, isSignup } = getState().session;
    try {
      await firebaseAuth.signInWithCustomToken(firebaseToken as string);
    } catch (error) {
      if (error) {
        hcpAppLogger({
          logType: "ERROR",
          title: "Firebase Authentication Failed",
          logArea: "FIREBASE",
          featureName: "LOGIN",
          hcpMobile: `${environment.mobileCode}${phone}`,
          details: {
            error: `${error}`,
            otp: code,
          },
        });
        dispatch({
          type: ActionType.LOGIN_ERROR,
          data: {
            error: `${error}`,
          },
        });
        logEvent(USER_EVENTS.TWO_FACTOR_CODE_DENIED);
        return false;
      }
    }

    logEvent(USER_EVENTS.TWO_FACTOR_CODE_ACCEPTED);
    // User and Agent does not exist in DB and user is currently in signup flow
    if (!isSignup) {
      updateAgentLoggedInTime(firebaseAuth);
    }
    logEvent(USER_EVENTS.SIGNED_IN);
    localStorage.removeItem("firebaseToken");
    return true;
  };

const getSignInLink = (email): string => {
  const { webAppUrl } = environment;
  const isDev = window.location.hostname === "localhost";
  const appDomain = isPlatform("capacitor")
    ? webAppUrl
    : isDev
    ? `http://localhost:${process.env.PORT || 4200}`
    : `https://${window.location.hostname}`;
  return `${appDomain}/welcome/login/linkVerify?email=${email}`;
};

const verifyEmail =
  (email: string) =>
  async (dispatch: Dispatch): Promise<SessionAction | boolean> => {
    dispatch({
      type: ActionType.LOGIN_REQUESTED,
    });

    if (!email) {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error: "Invalid email",
        },
      });
      return false;
    }

    const res = await sendAgentLoginLink(email, getSignInLink(email));

    if (res?.success) {
      return dispatch({
        type: ActionType.VERIFICATION_ID_RECEIVED,
        data: {
          error: "No account found. New? Sign up today to join.",
          verification: { email },
        },
      });
    } else {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error: "No account found. New? Click Learn More to join.",
          errorType: LoginErrorType.EMAIL_NOT_FOUND,
        },
      });
      return false;
    }
  };

const loginWithLink =
  (link: string, email: string) =>
  async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<SessionAction | boolean> => {
    dispatch({
      type: ActionType.VERIFICATION_REQUESTED,
    });

    const { firebaseAuth } = getState().session;

    try {
      const { error } = await firebaseAuth.signInWithEmailLink(link, email);

      if (error) {
        dispatch({
          type: ActionType.LOGIN_ERROR,
          data: {
            error,
          },
        });

        return false;
      }
    } catch (error) {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        data: {
          error: "Error occurred while signing in, please try again.",
        },
      });
      return false;
    }

    updateAgentLoggedInTime(firebaseAuth);
    logEvent(USER_EVENTS.SIGNED_IN);
    return true;
  };

const logout =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({
      type: ActionType.LOGOUT_REQUESTED,
    });

    const { firebaseAuth } = getState().session;
    logEvent(USER_EVENTS.LOGGED_OUT);
    await firebaseAuth.signOut();
    intercomLogout();
  };

const clearLoginError = (): SessionAction => ({
  type: ActionType.CLEAR_LOGIN_ERROR,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const updateAgent = (agent: Agent) => ({
  type: ActionType.UPDATE_AGENT,
  data: { agent },
});

const updateDeviceFlags =
  (key: string, value: boolean | object = true) =>
  (dispatch: Dispatch, getState: GetState): void => {
    const stateSession = getState().session;
    const deviceFlags = stateSession.deviceFlags as Omit<
      typeof stateSession.deviceFlags,
      "undefined"
    >;

    deviceFlags[key] = value;

    localStorage.setItem(
      LocalStorage.DEVICE_FLAGS,
      JSON.stringify(deviceFlags)
    );

    dispatch({
      type: ActionType.UPDATE_DEVICE_FLAGS,
      data: { deviceFlags },
    });
  };

const reloadAgentAuth =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { firebaseAuth } = getState().session;
    await reloadFirebaseUser(dispatch, firebaseAuth.currentUser as User);
  };

const logOutAgentAuth =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    await onLoggedOut(dispatch);
  };

const reloadAgentProfile =
  (logError = true) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { firebaseAuth } = getState().session;
      const tokenResult = await firebaseAuth.currentUser?.getIdTokenResult(
        true
      );
    } catch (error) {
      if (logError) {
        const err = error as Error;
        logEvent(USER_EVENTS.ONBOARDING_ERROR, {
          message: "Unable to re-load agent profile",
          error: err?.message,
        });
      }
    }
  };

const setIsSignup = (dispatch: Dispatch): void => {
  localStorage.setItem("isSignup", "true");
  dispatch({
    type: ActionType.SET_IS_SIGNUP,
  });
};

const setShowAlert = (dispatch: Dispatch, isOpenAlert: boolean) => {
  dispatch({
    type: ActionType.SET_SHOW_ALERT,
    data: isOpenAlert,
  });
};

const setLoginError = (dispatch: Dispatch, message: string) => {
  dispatch({
    type: ActionType.LOGIN_ERROR,
    data: {
      error: message,
    },
  });
};

const setReferralRate =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const referralRate = await getReferralRate();
    dispatch({
      type: ActionType.UPDATE_REFERRAL_RATE,
      data: { referralRate },
    });
  };

export {
  verifyPhone,
  loginWithCode,
  clearLoginError,
  logout,
  updateAgent,
  updateDeviceFlags,
  reloadAgentAuth,
  loginWithLink,
  verifyEmail,
  reVerifyWithPhone,
  setIsSignup,
  setShowAlert,
  reloadAgentProfile,
  logOutAgentAuth,
  verifyPhoneRequestOTP,
  reVerifyPhoneRequestOTP,
  loginWithOTP,
  setLoginError,
  setReferralRate,
};
