import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { Moment } from "moment-timezone";
import { HeaderRender } from "antd/es/calendar/generateCalendar";
import { arrowBack, arrowForward } from "ionicons/icons";
import React from "react";
import moment from "moment";
import { ArrowButtonProps, RequestOpenShiftOptions } from "./calendar.model";
import { get, isEmpty } from "lodash";
import { calendarVisibleDateRange } from "src/lib/utils";
import { fetchOpenShifts } from "./api";
import { fetchCurrentTimezone } from "src/app/dayView/api";

const ArrowButton: React.FC<ArrowButtonProps> = ({
  onClick,
  icon,
  disabled = false,
}) => {
  return (
    <IonButton
      disabled={disabled}
      color="primary"
      shape="round"
      fill="clear"
      size="small"
      onClick={onClick}
    >
      <IonIcon slot="icon-only" icon={icon} mode="ios" />
    </IonButton>
  );
};

const fetchNextMonthShifts = async (newMonth, agent) => {
  const tmz = await fetchCurrentTimezone();
  const range = calendarVisibleDateRange(newMonth);
  const options: RequestOpenShiftOptions = {
    dateFilter: range,
    qualification: agent.qualification,
    coordinates: agent.geoLocation.coordinates,
    specialities: agent.specialities,
    distance: get(agent, "preference.distance", 100),
    tmz,
  };
  return await fetchOpenShifts(options);
};

const headerRenderer =
  (
    setBackArrowFlag,
    setForwardArrowFlag,
    setMonth,
    agent,
    setAllowedRangeStart,
    setAllowedRangeEnd
  ): HeaderRender<Moment> =>
  ({ value, onChange }) => {
    const changeMonth = (monthToChange: number, type: string) => async () => {
      const newMonth = value.clone().add(monthToChange, "month");
      setMonth(newMonth.format("MMMM"));
      if (type === "back" && moment().month() === value.month()) {
        setForwardArrowFlag(false);
        setBackArrowFlag(true);
      } else if (type === "forward") {
        const nextMonthShifts = await fetchNextMonthShifts(newMonth, agent);
        if (isEmpty(nextMonthShifts)) {
          setForwardArrowFlag(true);
        } else if (
          nextMonthShifts && nextMonthShifts.openAgentShifts
            ? nextMonthShifts.openAgentShifts.length === 0
            : false
        ) {
          setForwardArrowFlag(true);
        } else {
          setAllowedRangeStart(value.clone().add(1, "month").startOf("month"));
          setAllowedRangeEnd(value.clone().add(1, "month").endOf("month"));
          onChange(newMonth);
        }
      } else if (
        (type === "back" && moment().month() < value.month()) ||
        moment().year() < value.year()
      ) {
        setAllowedRangeStart(value.clone().add(-1, "month").startOf("month"));
        setAllowedRangeEnd(value.clone().add(-1, "month").endOf("month"));
        onChange(newMonth);
      }
    };

    return (
      <IonGrid class="ion-text-center">
        <IonRow justify-content-center align-items-center>
          <IonCol size="3">
            <ArrowButton icon={arrowBack} onClick={changeMonth(-1, "back")} />
          </IonCol>
          <IonCol size="6">
            <IonLabel className="calendar-header">
              {value.format("MMMM YYYY")}
            </IonLabel>
          </IonCol>
          <IonCol size="3">
            <ArrowButton
              icon={arrowForward}
              onClick={changeMonth(1, "forward")}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };
export { headerRenderer, ArrowButton };
