import request from "superagent";
import { environment } from "../../environments/environment";
import { getAuthHeader } from "../superagent";

export const logErrors = async (data: { message: string; app: string }) => {
  if (!process.env.REACT_APP_LOG_ERRORS) {
    return;
  }
  await request
    .post(`${environment.baseUrl}/logErrors`)
    .set(await getAuthHeader())
    .send(data);
};
