import { Plugins } from "@capacitor/core";
import { isPlatform } from "@ionic/react";
import { FusedLocationPlugin } from "@jonoj/capacitor-fused-location";
import {
  enableGeoLocation,
  getUnknownErrorString,
  LocationAlertType,
} from "./enable";

const fusedLocation = new FusedLocationPlugin();
const { Geolocation } = Plugins;

type Coordinates = [Longitude: number, Latitude: number];

const getOrWatchCurrentLocation = async (): Promise<{
  location?: Coordinates;
  error?: LocationAlertType;
  errorDetails?: string;
}> => {
  const { success, error, errorDetails } = await enableGeoLocation();
  if (!success) {
    return {
      error,
      errorDetails,
    };
  }

  let positionResponse = await getCurrentPosition();
  let {
    location,
    error: positionError,
    errorDetails: positionErrorDetails,
  } = positionResponse;
  if (
    isPlatform("capacitor") &&
    positionError === LocationAlertType.COORDS_NOT_AVAILABLE
  ) {
    positionResponse = await watchPosition();
    location = positionResponse.location;
    positionError = positionResponse.error;
    positionErrorDetails = positionResponse.errorDetails;
  }

  if (positionError) {
    return {
      error: positionError,
      errorDetails: positionErrorDetails,
    };
  }
  return {
    location,
  };
};

// TODO inline this inside getOrWatchCurrentLocation
// this function isn't used anywhere else
const getCurrentPosition = async (): Promise<{
  location?: Coordinates;
  error?: LocationAlertType;
  errorDetails?: string;
}> => {
  try {
    // For Mobile, 60 sec gives low error
    // For Web and testing purposes, we prefer to have exact coordinates (mostly provided by Chrome dev tools` fake location)
    const maximumAge = isPlatform("capacitor") ? 60000 : 0;
    const position = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout: 60000,
      maximumAge,
    });
    return {
      location: [position.coords.longitude, position.coords.latitude],
    };
  } catch (error) {
    return {
      error: LocationAlertType.COORDS_NOT_AVAILABLE,
      errorDetails: getUnknownErrorString(error),
    };
  }
};

const watchPosition = async (): Promise<{
  location?: Coordinates;
  error?: LocationAlertType;
  errorDetails?: string;
}> => {
  return new Promise((resolve, reject) => {
    try {
      const watchPositionCallback = async (pos, err) => {
        if (err) {
          resolve({
            error: LocationAlertType.COORDS_NOT_AVAILABLE,
            errorDetails: getUnknownErrorString(err),
          });
        }

        const { longitude, latitude } = pos.coords;
        if (latitude && longitude) {
          isPlatform("android")
            ? await fusedLocation.clearWatch({ id: watch })
            : await Geolocation.clearWatch({ id: watch });
          resolve({
            location: [longitude, latitude],
          });
        }
      };

      //TODO: Remove extra library <@jonoj/capacitor-fused-location> when upgrading to new version of capacitor
      // because new version already supports fused location handling.
      const watch = isPlatform("android")
        ? fusedLocation.watchPosition({}, watchPositionCallback)
        : Geolocation.watchPosition({}, watchPositionCallback);
    } catch (error) {
      resolve({
        error: LocationAlertType.COORDS_NOT_AVAILABLE,
        errorDetails: getUnknownErrorString(error),
      });
    }
  });
};

export {
  enableGeoLocation,
  getCurrentPosition,
  watchPosition,
  getOrWatchCurrentLocation,
};
