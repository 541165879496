import "../style.scss";

import React from "react";
import PushNotificationAlert from "../../AppAlerts/PushNotificationAlert";
import {
  MissingDocumentsAlert,
  ShiftBookingWarningAlert,
  WaitingStripeResponseAlert,
  PendingDocumentsAlert,
} from "../Alerts";
import { ShiftItemAlertsProps, ShiftItemCategory } from "../model";
import NoLocationPermissionBookingAlert from "@app/openShifts/urgentShifts/noLocationPermissionBookingAlert";

const ShiftItemAlerts = ({
  shift,
  category,
  isInstantBookingShift,
  alertOpen,
  onCancel,
  onConfirm,
  pushNotificationOpen,
  closePushNotification,
  isUrgentShift,
}: ShiftItemAlertsProps) => {
  return (
    <>
      <ShiftBookingWarningAlert
        isOpen={
          [ShiftItemCategory.BOOKING_WARNING].includes(category) && alertOpen
        }
        onDidDismiss={onCancel}
        onConfirm={onConfirm}
        shift={shift}
        isInstantBookingShift={isInstantBookingShift}
        isUrgentShift={isUrgentShift}
      />
      <WaitingStripeResponseAlert
        isOpen={
          category === ShiftItemCategory.NOT_READY_TO_BOOK_SHIFT && alertOpen
        }
        onDidDismiss={onCancel}
        onConfirm={onConfirm}
        shift={shift}
        isInstantBookingShift={isInstantBookingShift}
      />
      <MissingDocumentsAlert
        isOpen={
          [ShiftItemCategory.MISSING_CBH_DOCUMENTS].indexOf(category) !== -1 &&
          alertOpen
        }
        shift={shift}
        category={category}
        onConfirm={onConfirm}
        onDidDismiss={onCancel}
      />
      <PendingDocumentsAlert
        isOpen={
          [ShiftItemCategory.WAITING_FOR_APPROVE].indexOf(category) !== -1 &&
          alertOpen
        }
        shift={shift}
        category={category}
        onConfirm={onConfirm}
        onDidDismiss={onCancel}
      />
      {pushNotificationOpen && (
        <PushNotificationAlert
          isOpen={pushNotificationOpen}
          onClosed={closePushNotification}
        />
      )}
      {isUrgentShift && (
        <NoLocationPermissionBookingAlert
          onDidDismiss={onCancel}
          isOpen={
            alertOpen && category === ShiftItemCategory.NO_LOCATION_PERMISSION
          }
        />
      )}
    </>
  );
};

export { ShiftItemAlerts };
