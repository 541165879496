import { surgeryWorkerTypes } from "src/lib/constants";
import { Shift } from "src/lib/interface";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { get, groupBy, reduce } from "lodash";
import moment from "moment-timezone";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ShiftFilters } from "src/app/components/shiftFilters";
import { groupByShiftName } from "../store/shift";
import { ShiftDateMap } from "../store/shift/model";
import { Store } from "../store/store.model";
import { fetchCurrentTimezone, fetchOpenShifts, fetchYourShifts } from "./api";
import { GroupItem } from "./groupItem";
import { ShiftListPage } from "./shiftList";
import { ShiftNameLoader } from "./shiftNameLoader";
import { ShiftListGroupState, RequestOpenShiftOptions } from "./model";
import { computeShiftsCountDay } from "src/utils/openShifts";
import {
  DISTANCE_PREFERENCE_MAX_VALUE,
  SHIFT_PREFERENCE,
  SEARCH_MODE,
} from "src/constants";
import useFetchHcpBonuses, {
  dateHasBonus,
} from "@app/hcpBonuses/useFetchHcpBonuses";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ribbon } from "ionicons/icons";
import "./shiftList.scss";

const OpenShiftDayView: React.FC = () => {
  const { date } = useParams() as any;
  const agent = useSelector((state: Store) => get(state, "session.agent", {}));
  const qualification = useSelector((state: Store) =>
    get(state, "session.agent.qualification", "")
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [myShiftMap, setMyShiftMap] = useState<ShiftDateMap>({});
  const [openShiftMap, setOpenShiftMap] = useState<ShiftDateMap>({});
  const [shiftName, setShiftName] = useState<string | null>(null);
  const [shiftList, setShiftList] = useState<ShiftListGroupState>({
    open: [],
    assigned: [],
  });
  const history = useHistory();

  const distancePreference = useMemo(() => {
    return get(agent, "preference.distance", DISTANCE_PREFERENCE_MAX_VALUE);
  }, [agent?.preference?.distance]);

  const minPayHourlyPreference = useMemo(() => {
    return get(agent, "preference.minPayHourly", SHIFT_PREFERENCE.PAY_HOUR);
  }, [agent?.preference?.minPayHourly]);

  const minPayShiftPreference = useMemo(() => {
    return get(agent, "preference.minPayShift", SHIFT_PREFERENCE.PAY_SHIFT);
  }, [agent?.preference?.minPayShift]);

  const baseRate = useMemo(() => {
    return get(agent, "baseRate", SHIFT_PREFERENCE.BASE_RATE);
  }, [agent?.baseRate]);

  const shiftNames =
    qualification && surgeryWorkerTypes.includes(qualification)
      ? ["surg"]
      : ["am", "pm", "noc"];

  const getOpenAndMyShifts = useCallback(async () => {
    if (date && get(agent, "geoLocation.coordinates")) {
      (async function () {
        try {
          setLoading(true);
          const options: RequestOpenShiftOptions = {
            range: { start: date, end: date },
            coordinates: get(agent, "geoLocation.coordinates", []),
            distance: distancePreference,
            minPayHourly: minPayHourlyPreference,
            minPayShift: minPayShiftPreference,
            qualification,
            specialities: get(agent, "specialities", {}),
          };
          const [openShifts, myShifts]: [Shift[], void | ShiftDateMap, string] =
            await Promise.all([
              fetchOpenShifts(options),
              fetchYourShifts({ start: date, end: date }),
              fetchCurrentTimezone(),
            ]);
          if (Array.isArray(myShifts)) return;
          const myTodayShifts = myShifts[date] || [];
          setMyShiftMap(groupBy(myTodayShifts, groupByShiftName));
          setOpenShiftMap(groupBy(openShifts, groupByShiftName));
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      })();
    }
  }, [date, setLoading, setMyShiftMap, setOpenShiftMap, agent]);

  useEffect(() => {
    const isValidDate = moment(date, "YYYY-MM-DD", true);
    if (!isValidDate) {
      return history.goBack();
    }
  }, [date, history]);

  useIonViewDidEnter(() => {
    getOpenAndMyShifts();
  }, []);

  useEffect(() => {
    if (shiftName) {
      setShiftList({
        open: openShiftMap[shiftName] || [],
        assigned: myShiftMap[shiftName] || [],
      });
    } else {
      setShiftList({ open: [], assigned: [] });
    }
  }, [myShiftMap, openShiftMap, shiftName]);

  const closeShiftList = () => {
    setShiftName(null);
  };

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await getOpenAndMyShifts();
    event.detail.complete();
  };

  const goToDocumentsPage = () => {
    setShiftName(null);
    history.push(`/home/account/documents`);
  };
  useIonViewDidLeave(() => {
    setShiftName(null);
  }, []);

  const ldFlags = useFlags();
  const isHcpBonusesEnabled = ldFlags["hcp-bonuses"] as boolean;
  const { bonuses } = useFetchHcpBonuses(agent.tmz);

  const hasDateBonus =
    isHcpBonusesEnabled && dateHasBonus(bonuses, moment(date));

  return (
    <Fragment>
      <IonPage>
        <IonHeader no-border>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                text=""
                defaultHref="/home/openShifts"
                mode="ios"
              />
            </IonButtons>
            <IonTitle>{moment(date).format("dddd, MMMM DD YYYY")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {hasDateBonus && (
            <div className="hcp-bonus-banner">
              <IonIcon icon={ribbon} />
              <span>ongoing promotion available</span>
              <IonIcon icon={ribbon} />
            </div>
          )}
          <ShiftFilters
            openShiftsEmpty={false}
            totalShifts={computeShiftsCountDay(openShiftMap)}
            hiddenShifts={computeShiftsCountDay(openShiftMap, "hidden")}
            baseRate={baseRate}
            distancePreference={distancePreference}
            minPayHourlyPreference={minPayHourlyPreference}
            minPayShiftPreference={minPayShiftPreference}
            searchMode={SEARCH_MODE.DATE}
            updateOpenShifts={getOpenAndMyShifts}
          />
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {loading ? (
            <ShiftNameLoader listLength={shiftNames.length} />
          ) : (
            <IonList>
              {shiftNames.map((name) => (
                <GroupItem
                  shiftName={name}
                  key={name}
                  myShiftMap={myShiftMap}
                  openShiftMap={openShiftMap}
                  onClick={setShiftName}
                />
              ))}
            </IonList>
          )}
        </IonContent>
        <IonModal isOpen={!!shiftName} onDidDismiss={closeShiftList}>
          {shiftName && (
            <ShiftListPage
              onClose={closeShiftList}
              goToDocumentsPage={goToDocumentsPage}
              day={date}
              shiftName={shiftName}
              shifts={shiftList}
              getOpenAndMyShifts={getOpenAndMyShifts}
              loadingShiftList={loading}
            />
          )}
        </IonModal>
      </IonPage>
    </Fragment>
  );
};

export { OpenShiftDayView };
