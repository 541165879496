/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
import { Plugins } from "@capacitor/core";
import { firebaseAppAnalytics } from "src/lib/firebase";
import { environment } from "@env/environment";
import { isPlatform } from "@ionic/react";
import { snakeCase } from "lodash";
import { USER_EVENTS } from "@constants/userEvent";
import * as crypto from "crypto-js";
import { ErrorInfo } from "react";
import { trackAdjustEvent } from "@app/utils/adjust";

const { SegmentCapacitorPlugin } = Plugins as any;

const emitSegmentTrackEvent = ({ eventName, properties }): void => {
  if (SegmentInstance.isInitialized) {
    if (isPlatform("capacitor")) {
      SegmentCapacitorPlugin.track({ eventName, properties });
    } else {
      window["analytics"].track(eventName, { ...properties });
    }
  }
};

const logEvent = (
  eventName: string,
  optionalProps?: Record<string, unknown>
): void => {
  emitSegmentTrackEvent({ eventName: eventName, properties: optionalProps });
  const eventNameSnakeCase = snakeCase(eventName);
  firebaseAppAnalytics.logEvent(eventNameSnakeCase, optionalProps);
  trackAdjustEvent(eventName, optionalProps);
};

const SegmentInstance: {
  isInitialized: boolean;
  createSegmentInstance: (segmentKeys: Record<string, string>) => void;
} = {
  isInitialized: false,
  createSegmentInstance: (segmentKeys) => {
    if (SegmentInstance.isInitialized) return;
    else {
      const {
        iosKey,
        androidKey,
        webKey,
        intercomAppID,
        intercomAndroidAPIKey,
        intercomiOSAPIKey,
        intercomAndroidSecret,
        intercomiOSSecret,
      } = segmentKeys;
      if (isPlatform("capacitor")) {
        (async function () {
          // Do not initialize when already an instance exists.
          const { instanceExist } =
            await SegmentCapacitorPlugin.isInstanceExist();
          if (instanceExist) return;
          const key = isPlatform("ios") ? iosKey : androidKey;
          const intercomAPIKey = isPlatform("ios")
            ? intercomiOSAPIKey
            : intercomAndroidAPIKey;
          const intercomSecret = isPlatform("ios")
            ? intercomiOSSecret
            : intercomAndroidSecret;
          if (SegmentInstance.isInitialized) return;
          SegmentCapacitorPlugin.setUp({
            key,
            trackLifecycle: true,
            intercomAppID,
            intercomAPIKey,
            intercomSecret,
          });
          SegmentInstance.isInitialized = true;
        })();
      } else {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        (function () {
          var analytics = (window["analytics"] = window["analytics"] || []);
          if (SegmentInstance.isInitialized) return;
          if (!analytics.initialize)
            if (analytics.invoked)
              window.console &&
                console.error &&
                console.error("Segment snippet included twice.");
            else {
              analytics.invoked = !0;
              analytics.methods = [
                "trackSubmit",
                "trackClick",
                "trackLink",
                "trackForm",
                "pageview",
                "identify",
                "reset",
                "group",
                "track",
                "ready",
                "alias",
                "debug",
                "page",
                "once",
                "off",
                "on",
                "addSourceMiddleware",
                "addIntegrationMiddleware",
                "setAnonymousId",
                "addDestinationMiddleware",
              ];
              analytics.factory = function (t) {
                return function () {
                  var e = Array.prototype.slice.call(arguments);
                  e.unshift(t);
                  analytics.push(e);
                  return analytics;
                };
              };
              for (var t = 0; t < analytics.methods.length; t++) {
                var e = analytics.methods[t];
                analytics[e] = analytics.factory(e);
              }
              analytics.load = function (t, e) {
                var n = document.createElement("script");
                n.type = "text/javascript";
                n.async = !0;
                n.src =
                  "https://cdn.segment.com/analytics.js/v1/" +
                  t +
                  "/analytics.min.js";
                var a = document.getElementsByTagName("script")[0]!;
                a.parentNode?.insertBefore(n, a);
                analytics._loadOptions = e;
              };
              analytics.SNIPPET_VERSION = "4.1.0";
              analytics.load(webKey);
            }
          SegmentInstance.isInitialized = true;
        })();
      }
    }
  },
};
const identifyUser = (agent): void => {
  if (isPlatform("capacitor")) {
    SegmentCapacitorPlugin.identify({
      userID: agent.userId,
      traits: {},
    });
  } else {
    const hex = crypto
      .HmacSHA256(agent.userId, environment.segment.intercomWebSecret as string)
      .toString(crypto.enc.Hex);
    window["analytics"].identify(
      agent.userId,
      {},
      {
        integrations: {
          Intercom: {
            user_hash: hex,
          },
        },
      }
    );
  }
};

const addDataToIdentity = (userId, payload): void => {
  if (isPlatform("ios") || isPlatform("android")) {
    SegmentCapacitorPlugin.identify({
      userID: userId,
      traits: payload,
    });
  } else {
    const hex = crypto
      .HmacSHA256(userId, environment.segment.intercomWebSecret as string)
      .toString(crypto.enc.Hex);
    window["analytics"].identify(
      userId,
      {
        ...payload,
        traits: payload,
      },
      {
        integrations: {
          Intercom: {
            user_hash: hex,
          },
        },
      }
    );
  }
};

const intercomLogout = (): void => {
  if (isPlatform("ios") || isPlatform("android")) {
    SegmentCapacitorPlugin.logoutIntercom();
  } else {
    window["Intercom"] && window["Intercom"]("shutdown");
  }
};

const logApiFailureEvent = (error): void => {
  const { url, response, message } = error;
  const properties = {
    reason: "Api Failure",
    message,
    requestPath:
      url && url?.indexOf("?") > -1 ? url.substring(0, url.indexOf("?")) : url,
    url,
    responseUrl: response?.req?.url,
    status: response?.status,
  };
  logEvent(USER_EVENTS.API_FAILURE, properties);
};

const logAppCrashedEvent = (error: Error, errorInfo: ErrorInfo): void => {
  logEvent(USER_EVENTS.APPLICATION_CRASHED, {
    reason: "React Boundaries Application Crashed",
    message: error.toString
      ? error.toString()
      : "uncaught error message, possibly blank screen or auto-restart",
    error: error.stack ? error.stack : "",
    errorInfo: errorInfo?.componentStack ? errorInfo.componentStack : "",
  });
};

const { createSegmentInstance } = SegmentInstance;

export {
  logEvent,
  emitSegmentTrackEvent,
  createSegmentInstance,
  identifyUser,
  logApiFailureEvent,
  logAppCrashedEvent,
  intercomLogout,
  addDataToIdentity,
};
