import { Plugins, StatusBarStyle } from "@capacitor/core";
import { IonLoading, IonRouterOutlet, isPlatform } from "@ionic/react";
import React, { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import qs from "query-string";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { AppLoadingPage, NoUserPage } from "../404Pages";
import { AnalyticsRouterTagging } from "../analyticsRouterTagging";
import { DynamicLinkHandler } from "../dynamicLink";
import { WelcomeRoute } from "../welcome/route";
import { GlobalRouterPath } from "./constant/globalRoute";
import { HomeRoutes } from "./home";
import { AppRouterProps } from "./model";
import { PrivateRoute } from "./privateRoute";
import { PublicRoute } from "./publicRoute";
import { AppUrlListener } from "../deepLink";
import {
  initDatadogRUM,
  startDatadogRUMReplayRecording,
} from "../utils/datadogRUM";
import * as braze from "@braze/web-sdk";
import { useFlags } from "launchdarkly-react-client-sdk";

const { SplashScreen, StatusBar } = Plugins;

const AppRouter: React.FC<AppRouterProps> = ({
  initSession,
  isVerifying,
  isLoggingOut,
  hasSession,
  agent,
  ...props
}) => {
  const location = useLocation();
  const queryString = qs.parse(location.search);
  const ldFlags = useFlags();

  useEffect(() => {
    if (!isPlatform("capacitor")) return;

    StatusBar.setStyle({
      style: StatusBarStyle.Dark,
    });
  }, []);

  useEffect(() => {
    if (!hasSession) return;
    setTimeout(() => {
      SplashScreen.hide();
    }, 200);
  }, [hasSession]);

  useEffect(() => {
    const isHcpBonusesEnabled = ldFlags["hcp-bonuses"] as boolean;
    if (agent && isHcpBonusesEnabled) {
      // update braze user whenever the agnet changes
      braze.changeUser(agent._id as string);
    }
  }, [agent]);

  useEffect(() => {
    initDatadogRUM();
    startDatadogRUMReplayRecording();
  }, []);

  useEffect(() => {
    initSession(location.pathname, queryString.email as string);
  }, [initSession]);

  // ugly temporary stopgap to make .identify() be called only once (our plan's usage might run out without it)
  // a better way to do it is move this inside initSession()
  const ldClient = useLDClient();
  useEffect(() => {
    if (!agent?.userId || !ldClient?.getUser()?.anonymous) {
      return;
    }
    ldClient.identify({
      key: agent.userId,
      name: agent.name,
      custom: {
        msa: agent.address?.metropolitanStatisticalArea || "",
      },
    });
    // the redux object gets updated immutably so we have to memo each field separately
  }, [
    ldClient,
    agent?.userId,
    agent?.name,
    agent?.address?.metropolitanStatisticalArea,
  ]);

  return (
    <>
      <Route path="/" component={DynamicLinkHandler} />
      <Route path="/" component={AppUrlListener} />
      <Route path="/" component={AnalyticsRouterTagging} />
      <IonRouterOutlet>
        <Route
          exact
          path={GlobalRouterPath.LOADING_PAGE}
          component={AppLoadingPage}
        />
        <Route
          exact
          path={GlobalRouterPath.NO_USER_PAGE}
          component={NoUserPage}
        />
        <Route
          exact
          path="/"
          render={() => <Redirect to={GlobalRouterPath.HOME} />}
        />
        <Route
          exact
          path={GlobalRouterPath.Link}
          render={() => <Redirect to={GlobalRouterPath.HOME} />}
        />
      </IonRouterOutlet>
      <PrivateRoute
        path={GlobalRouterPath.HOME}
        component={HomeRoutes}
        hasSession={hasSession}
        {...props}
      />
      <PublicRoute
        path={GlobalRouterPath.WELCOME}
        component={WelcomeRoute}
        hasSession={hasSession}
        {...props}
      />
      <IonLoading isOpen={isVerifying} message="Logging in..." />
      <IonLoading isOpen={isLoggingOut} message="Logging out..." />
    </>
  );
};

export { AppRouter };
