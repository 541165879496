import moment, { Moment } from "moment-timezone";

interface DateRange {
  start: string;
  end: string;
}

const FULL_ZONE_NAMES = {
  EST: "Eastern Standard Time",
  EDT: "Eastern Daylight Time",
  CST: "Central Standard Time",
  CDT: "Central Daylight Time",
  MST: "Mountain Standard Time",
  MDT: "Mountain Daylight Time",
  PST: "Pacific Standard Time",
  PDT: "Pacific Daylight Time",
};

const calendarVisibleDateRange = (date: Moment): DateRange => {
  const start = date
    .clone()
    .startOf("month")
    .startOf("week")
    .format("YYYY-MM-DD");

  // 6 weeks are displayed in calendar, most of the times last entire week comes from next month
  const end = date
    .clone()
    .endOf("month")
    .add(1, "week")
    .endOf("week")
    .format("YYYY-MM-DD");

  return {
    start,
    end,
  };
};

const weekBoundsFromDate = (momentDate: Moment, timezone: string) => ({
  start: momentDate.clone().tz(timezone).startOf("week"),
  end: momentDate.clone().tz(timezone).endOf("week"),
});

/**
 * Formats date range to a time string to display on UI.
 */
const timeRangeFormatter = (
  format: string,
  { start, end }: DateRange,
  timezone?: string
) =>
  timezone
    ? `${moment(start).tz(timezone).format(format)} - ${moment(end)
        .tz(timezone)
        .format(format)}`
    : `${moment(start).format(format)} - ${moment(end).format(format)}`;

/**
 * Gets offset of a timezone from a basetimezone and converts to string to display on UI.
 */
const getOffsetFormattedTextBetweenTimezones = (
  date: string,
  baseTimezone: string,
  timezone: string
): string => {
  const baseTimezoneOffset = moment(date).tz(baseTimezone).utcOffset();
  const timezoneOffset = moment(date).tz(timezone).utcOffset();
  const diffInMinutes = Math.abs(timezoneOffset - baseTimezoneOffset);
  if (diffInMinutes === 0) {
    return "";
  }
  const diffInHrs = Math.floor(diffInMinutes / 60);
  const remainingMins = diffInMinutes % 60;
  let offsetText = "";
  if (diffInHrs > 0) {
    offsetText += `${diffInHrs} ${diffInHrs === 1 ? "hr " : "hrs "}`;
  }
  if (remainingMins > 0) {
    offsetText += `${remainingMins} mins `;
  }
  return `${offsetText}${
    timezoneOffset < baseTimezoneOffset ? "earlier" : "later"
  }`;
};

export {
  calendarVisibleDateRange,
  timeRangeFormatter,
  DateRange,
  weekBoundsFromDate,
  FULL_ZONE_NAMES,
  getOffsetFormattedTextBetweenTimezones,
};
