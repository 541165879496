export * from "./userEvents";

export const DISTANCE_PREFERENCE_MAX_VALUE = 150;

export const CHECKR_REQUIREMENT_ID = "5b9327c238aeaa0014c3688d";

export const SHIFT_PREFERENCE = {
  DISTANCE: 150,
  PAY_HOUR: 0,
  PAY_SHIFT: 0,
  BASE_RATE: 17,
};

export const SEARCH_MODE = {
  CALENDAR: "CALENDAR",
  DATE: "DATE",
  MAP: "MAP",
  HCF_PROFILE: "HCF_PROFILE",
};

export const HIDDEN_SHIFT_MODAL_TTL = 1000 * 60 * 60 * 24;
