import { useQuery } from "@apollo/client";
import { logApiFailureEvent, logEvent } from "src/lib/analytics";
import { REFERRAL_RATE, surgeryWorkerTypes } from "src/lib/constants";
import {
  IonAlert,
  IonBadge,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { isPlatform } from "@ionic/core";
import { difference, filter, get, keyBy } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import request from "superagent";
import { Plugins } from "@capacitor/core";
import { useFlags } from "launchdarkly-react-client-sdk";

import { USER_EVENTS } from "../../constants/userEvents";
import { environment } from "../../environments/environment";
import { GET_REQUIREMENTS_STATUS } from "../documents/gql";
import { useShouldBeOnboarded } from "../onboardingStripe/hooks";
import { setNotificationPreference } from "../privacyPolicy/notificationPreference/api";
import { updateAgent, updateDeviceFlags } from "../store/session";
import { ActionType } from "../store/session/session.model";
import { Store } from "../store/store.model";
import { getAuthHeader } from "../superagent";
import { updateShowReferralAlert } from "./api";
import { MyAccountProps } from "./model";
import { Version } from "./version";

const { SegmentCapacitorPlugin } = Plugins as any;

const MyAccount: React.FC<MyAccountProps> = ({ logout, history, match }) => {
  const dispatch = useDispatch();
  const [isLogoutConfirm, setIsLogoutConfirm] = useState<boolean>(false);
  const [isSurgeryWorker, setIsSurgeryWorker] = useState<boolean>(false);
  const [isClosed, setClosed] = useState<boolean>(false);
  const [pnChecked, setPNChecked] = useState(false);
  const [pushFlag, setPushFlag] = useState<boolean>(false);
  const [isNotificationAlertShown, setNotificationAlertShown] =
    useState<boolean>(true);
  const {
    agent,
    userId: hcpId,
    referralRate,
  } = useSelector((state: Store) => state.session);
  const unreadCount = useSelector(
    (state: Store) => state.notificationStore.unreadCount
  );

  const shouldBeOnboardedInPaymentService = useShouldBeOnboarded();

  const env = useSelector((state: Store) => get(state, "session.env", {}));
  const { data } = useQuery(GET_REQUIREMENTS_STATUS, {
    variables: { hcpId },
    fetchPolicy: "cache-and-network",
  });
  const isCapacitor = isPlatform(window, "capacitor");
  const ldFlags = useFlags();
  const isLicenseManagerEnabled = ldFlags[
    "license-manager-activation"
  ] as boolean;
  const isHcpBonusesEnabled = ldFlags["hcp-bonuses"] as boolean;

  const { requirements, expired, missing, pending } =
    data?.hcpRequirementStatus || {};

  const requirementsById = keyBy(requirements, "reqId");
  const isVisibleToHCP = (reqId) => requirementsById[reqId].visibleToHCP;

  const pendingActions =
    filter(difference(missing, pending), isVisibleToHCP).length ||
    filter(difference(expired, pending), isVisibleToHCP).length;

  useEffect(() => {
    if (!env) return;
    setPNChecked(Boolean(env?.notificationPreferences?.push));
    setPushFlag(Boolean(env?.PushNotificationOnAccountTab));
    setClosed(true);
  }, [env]);

  useEffect(() => {
    if (!agent) return;
    if (agent.showReferralAlert) {
      updateShowReferralAlert(agent.userId, { showReferralAlert: false }).then(
        () => {
          dispatch(updateAgent({ showReferralAlert: false }));
          dispatch(updateDeviceFlags("referralAlert", false));
        }
      );
    }
    setNotificationAlertShown(Boolean(agent?.isNotificationAlertShown));
    setIsSurgeryWorker(
      surgeryWorkerTypes.includes(agent.qualification as string)
    );
  }, [agent, dispatch]);

  useIonViewDidEnter(() => {
    logEvent(USER_EVENTS.VIEWED_ACCOUNT);
  });

  const onPayroll = () => history.push(`${match.url}/payroll`);
  const onAddressClick = () => history.push(`${match.url}/address`);

  const onSupportClick = () => {
    history.push(`${match.url}/needhelp`);
  };
  const onSpecialitiesClick = () => history.push(`${match.url}/specialities`);
  const onDocumentsClick = () => history.push(`${match.url}/documents`);
  const onHcpBonusesClick = () => history.push(`${match.url}/hcp-bonuses`);
  const onLicensesClick = () => history.push(`${match.url}`);

  const onProfileClick = () => history.push(`${match.url}/profile`);
  const onReferralClick = () => history.push(`${match.url}/referral`);
  const onPrivacyAndNotificationsClick = () => {
    history.push(`${match.url}/privacy-notification-setting`);
  };

  const closeConfirmModal = (isLogout: boolean) => () => {
    setIsLogoutConfirm(false);
    if (isLogout) logout();
  };

  const openConfirmModal = () => {
    logEvent(USER_EVENTS.TAPPED_LOGOUT);
    setIsLogoutConfirm(true);
  };

  const setNotificationPopUpShown = async () => {
    const { body: agent } = await request
      .post(`${environment.baseUrl}/agentProfile/setNotificationAlertShown`)
      .set(await getAuthHeader());
    dispatch({
      type: ActionType.UPDATE_AGENT,
      data: { agent },
    });
  };

  const dismissAlert = async () => {
    try {
      setClosed(false);
      setNotificationPopUpShown();
      await setNotificationPreference({
        notificationPreference: { key: "push", value: true },
      });
    } catch (error) {
      logApiFailureEvent(error);
    }
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonTitle>My Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY>
        <IonList>
          <IonItem button onClick={onProfileClick} detail>
            <IonLabel>
              <h4>Profile</h4>
              <p>View your profile</p>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={onAddressClick} detail>
            <IonLabel>
              <h4>Address</h4>
              <p>Manage your home address!</p>
            </IonLabel>
          </IonItem>
          {isLicenseManagerEnabled && (
            <IonItem button onClick={onLicensesClick} detail>
              <IonLabel>
                <h4>Licenses</h4>
                <p>Manage Licenses</p>
              </IonLabel>
            </IonItem>
          )}
          <IonItem button onClick={onDocumentsClick} detail>
            <IonLabel>
              <h4>Documents</h4>
              {pendingActions ? (
                <IonText color="danger">
                  <p>Some documents are missing or expired!</p>
                </IonText>
              ) : (
                <p>Manage your documents</p>
              )}
            </IonLabel>
          </IonItem>
          {isHcpBonusesEnabled && (
            <IonItem button onClick={onHcpBonusesClick} detail>
              <IonLabel>
                <h4>My Bonuses</h4>
                <p>Your progress toward bonuses</p>
              </IonLabel>
            </IonItem>
          )}
          {isSurgeryWorker && (
            <IonItem button onClick={onSpecialitiesClick} detail>
              <IonLabel>
                <h4>Specialities</h4>
                <p>Update your experience</p>
              </IonLabel>
            </IonItem>
          )}
          <IonItem button onClick={onPayroll} detail>
            <IonLabel>
              {shouldBeOnboardedInPaymentService ? (
                <IonText color="danger">
                  <h4>Payroll</h4>
                  <p>Your weekly earnings</p>
                </IonText>
              ) : (
                <>
                  <h4>Payroll</h4>
                  <p>Your weekly earnings</p>
                </>
              )}
            </IonLabel>
          </IonItem>
          <IonItem button onClick={onReferralClick} detail>
            <IonLabel>
              <h4>Refer a Co-Worker</h4>
              <p>Earn a ${referralRate} bonus</p>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={onPrivacyAndNotificationsClick} detail>
            <IonLabel>
              <h4>Privacy and Notifications</h4>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={onSupportClick} detail>
            <IonLabel>
              <h4>Help Center</h4>
            </IonLabel>
            {unreadCount != 0 && (
              <IonBadge color="danger" mode="ios">
                {unreadCount}
              </IonBadge>
            )}
          </IonItem>
          <IonItem button onClick={openConfirmModal} detail>
            <IonLabel>
              <h4>Logout</h4>
            </IonLabel>
          </IonItem>
        </IonList>
        <Version />
        <IonAlert
          message="Are you sure you want to logout?"
          isOpen={isLogoutConfirm}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              handler: closeConfirmModal(false),
            },
            {
              text: "Ok",
              handler: closeConfirmModal(true),
            },
          ]}
        />

        <IonAlert
          isOpen={
            !pushFlag && !isNotificationAlertShown && !pnChecked && isClosed
          }
          onDidDismiss={dismissAlert}
          header="Notifications"
          message="For this app to work, we'll need push notifications to notify you of your upcoming shifts, open shifts and raffles."
          backdropDismiss={false}
          buttons={["Ok"]}
        />
      </IonContent>
    </IonPage>
  );
};

export { MyAccount };
