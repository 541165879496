import { sharedEnvironment } from "./shared";

const sharedEnvironmentStructure = {
  ...sharedEnvironment,
  segment: {
    key: process.env.REACT_APP_SEGMENT_KEY,
    androidKey: process.env.REACT_APP_SEGMENT_ANDROID_KEY,
    iosKey: process.env.REACT_APP_SEGMENT_IOS_KEY,
    webKey: process.env.REACT_APP_SEGMENT_WEB_KEY,
    intercomAppID: process.env.REACT_APP_SEGMENT_INTERCOM_APP_ID,
    intercomAndroidAPIKey: process.env.REACT_APP_SEGMENT_INTERCOM_ANDROID_KEY,
    intercomiOSAPIKey: process.env.REACT_APP_SEGMENT_INTERCOM_IOS_KEY,
    intercomAndroidSecret:
      process.env.REACT_APP_SEGMENT_INTERCOM_ANDROID_SECRET,
    intercomiOSSecret: process.env.REACT_APP_SEGMENT_INTERCOM_IOS_SECRET,
    intercomWebSecret: process.env.REACT_APP_SEGMENT_INTERCOM_WEB_SECRET,
  },
  zendesk: {
    zendeskUrl: process.env.REACT_APP_ZENDESK_SDK_URL,
    appId: process.env.REACT_APP_ZENDESK_SDK_APP_ID,
    clientId: process.env.REACT_APP_ZENDESK_SDK_CLIENT_ID,
    chatAccountKey: process.env.REACT_APP_ZENDESK_SDK_CHAT_ACCOUNT_KEY,
    chatAuthUrl: process.env.REACT_APP_ZENDESK_SDK_CHAT_AUTH_URL,
  },
};

const prodEnvironmentStruture = {
  ...sharedEnvironmentStructure,
  production: true,
  webAppUrl: "https://staging-hcp.clipboard.health",
  baseUrl: "https://api.clipboard.health/api",
  UXCamKey: "katzipt47zl5yc9",
  Sentry: {
    allowed: process.env.REACT_APP_SENTRY_ALLOWED,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: "health.clipboard.worker@2.58.2+200",
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  },
  fileUploadURL: "https://api.cloudinary.com/v1_1/clipboardhealth/auto/upload",
  fileUploadPreset: "qukt90gl",
  firebaseConfig: {
    ...sharedEnvironment.firebaseConfig,
    apiKey: "AIzaSyBm3YKIFcxhKhGKIW4fTDsZ5h9r3_2-eUA",
    authDomain: "clipboard-health.firebaseapp.com",
    databaseURL: "https://clipboard-health.firebaseio.com",
    projectId: "clipboard-health",
    storageBucket: "",
    messagingSenderId: "1098403931480",
    appId: "1:1098403931480:web:cf36f44ee505ab81",
    measurementId: "G-K65B9132JS",
    dynamicShortLinkApiUrl:
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
  },
  dynamicLinkDomain: "cbh.care",
  shift: {
    isLocationCheck: true,
  },
  api: {
    paymentsBaseUrl: "https://cbh-payment.clipboard.health/api",
    base: "https://api.clipboard.health/api",
  },
  mixpanelToken: "547bb68cc1aeb6e1119edd4d163d7c23",
  documentServiceUrl: "https://documents-api.clipboard.health/graphql",
};

const stagingEnvironmentStructure = {
  ...sharedEnvironmentStructure,
  production: false,
  baseUrl: process.env.REACT_APP_BASE_API_URL,
  webAppUrl: process.env.REACT_APP_WEB_APP_URL,
  UXCamKey: "",
  Sentry: {
    allowed: process.env.REACT_APP_SENTRY_ALLOWED,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: "health.clipboard.worker@2.58.2+200",
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  },
  api: {
    paymentsBaseUrl: process.env.REACT_APP_PAYMENTS_API_URL,
    base: process.env.REACT_APP_BASE_API_URL,
  },
  mixpanelToken: "526833eb22638ba90aa0b358e4e4157c",
  documentServiceUrl: process.env.REACT_APP_DOCUMENT_SERVICE_URL,
};

const devEnvironmentStructure = {
  ...sharedEnvironmentStructure,
  mobileCode: "+1",
  production: false,
  baseUrl: process.env.REACT_APP_BASE_API_URL,
  webAppUrl: "http://localhost:8100",
  UXCamKey: "",
  Sentry: {
    allowed: process.env.REACT_APP_SENTRY_ALLOWED,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: "health.clipboard.worker@2.58.2+200",
    tracesSampleRate: 1,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  },
  api: {
    paymentsBaseUrl:
      process.env.REACT_APP_PAYMENTS_API_URL || "http://localhost:3339/api",
    base: "/api",
  },
  mixpanelToken: "526833eb22638ba90aa0b358e4e4157c",
};

export const environment =
  process.env.NODE_ENV === "production"
    ? prodEnvironmentStruture
    : process.env.NODE_ENV === "staging"
    ? stagingEnvironmentStructure
    : devEnvironmentStructure;
