import { IonAlert } from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "@store/store.model";
import { ActionType } from "@store/session";
import "./style.scss";

const ReplacementRejected = () => {
  const popups = useSelector((state: Store) => state?.session?.popups);
  const {
    replacementRejectedPopup: { visible, documentName, note, docUploadDate } = {
      visible: false,
      documentName: "",
      note: "",
      docUploadDate: "",
    },
  } = popups || {};
  const dispatch = useDispatch();

  const onOk = async () => {
    dispatch({ type: ActionType.HIDE_DELETE_DOCUMENT_POPUP });
  };

  return (
    <>
      {visible && (
        <IonAlert
          isOpen={true}
          header="Your replacement was rejected"
          message={`The ${documentName} you uploaded on ${decodeURIComponent(
            String(docUploadDate)
          )} as a replacement was rejected.<br/>${note}`}
          cssClass="alert-popup-container"
          mode="ios"
          backdropDismiss={true}
          onDidDismiss={onOk}
          buttons={[
            {
              text: "OK",
              cssClass: "positive-action",
              handler: onOk,
            },
          ]}
        />
      )}
    </>
  );
};

export { ReplacementRejected };
