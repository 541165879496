import "./style.scss";

import React, { Fragment } from "react";
import { IonLabel } from "@ionic/react";

import { PayRateTextProps } from "./model";

const PayRateText = ({ totalPay, instantPay }: PayRateTextProps) => {
  return (
    <IonLabel className="pay-rate-label">
      <span className="total-pay">{totalPay}</span>
      {instantPay && (
        <Fragment>
          <span className="vertical-bar"></span>
          <span className="instant-pay">{instantPay}</span>
        </Fragment>
      )}
    </IonLabel>
  );
};

export { PayRateText };
