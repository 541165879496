import { environment } from "@env/environment";
import { formatPhone } from "src/lib/utils";
import React from "react";

const SupportText = () => (
  <div>
    <p>
      If you have any issues, give us a call at: <br />
      <span>
        <strong> {formatPhone(environment.support.phone)}</strong>
      </span>
    </p>
  </div>
);

export { SupportText };
