import { datadogRum } from "@datadog/browser-rum";
import { Agent } from "src/lib/interface";
import { environment } from "../../environments/environment";

export const initDatadogRUM = () => {
  if (process.env.NODE_ENV !== "test") {
    datadogRum.init(environment.datadogRUM);
  }
};

export const startDatadogRUMReplayRecording = () => {
  datadogRum.startSessionReplayRecording();
};

export const removeDatadogRUMUser = () => {
  datadogRum.removeUser();
};

export const identifyDatadogRUMUser = (agent: Agent) => {
  datadogRum.setUser({
    id: agent.userId,
    name: agent.name,
    email: agent.email,
  });
};
