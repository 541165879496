import "firebase/auth";

import { isPlatform } from "@ionic/core";
import { cfaSignIn, cfaSignInPhoneOnCodeSent } from "capacitor-firebase-auth";
import firebase from "firebase/app";

import { Firebase } from "./firebase";

interface FirebaseAuthOptions {
  appDomain?: string;
  bundleId: string;
  dynamicLinkDomain: string;
}

interface FirebaseResponse {
  error: string | null;
}

const { Auth, RecaptchaVerifier, PhoneAuthProvider } = firebase.auth;

class FirebaseAuthentication extends Firebase {
  private firebaseAuth: firebase.auth.Auth;
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier | null;
  private verificationId: string = "";
  private options: FirebaseAuthOptions;

  constructor(
    firebaseConfig: Record<string, string>,
    options: FirebaseAuthOptions
  ) {
    super(firebaseConfig);
    this.options = {
      ...options,
      appDomain: options.appDomain || `https://${window.location.hostname}`,
    };
    this.recaptchaVerifier = null;
    this.firebaseAuth = this.FirebaseApp.auth();
    this.firebaseAuth.setPersistence(
      process.env.NODE_ENV === "test"
        ? Auth.Persistence.NONE
        : Auth.Persistence.LOCAL
    );
  }

  initRecaptchaVerifier(
    container = "recaptcha-container",
    captchaRef: HTMLElement | null | undefined
  ) {
    if (isPlatform(window, "capacitor")) return;
    if (!captchaRef) return;

    if (this.recaptchaVerifier) {
      this.recaptchaVerifier.clear();
    }

    captchaRef.innerHTML = `<div id="${container}"></div>`;

    this.recaptchaVerifier = new RecaptchaVerifier(
      container,
      {
        size: "invisible",
      },
      this.FirebaseApp
    );
  }

  signInWithCustomToken(token: string) {
    return this.firebaseAuth.signInWithCustomToken(token);
  }

  signInWithPhoneNumber(phoneNumber: string) {
    if (isPlatform(window, "capacitor")) {
      return this.nativePhoneSignIn(phoneNumber);
    } else {
      return this.webPhoneSignIn(phoneNumber);
    }
  }

  nativePhoneSignIn(phone: string) {
    return new Promise<FirebaseResponse>((resolve) => {
      const handleError = (error: firebase.auth.Error) =>
        resolve(this.onFirebaseError(error));

      const onVerificationId = (verificationId: string) => {
        this.verificationId = verificationId;
        resolve({ error: null });
      };

      cfaSignIn("phone", { phone }).subscribe(null, handleError);
      cfaSignInPhoneOnCodeSent().subscribe(onVerificationId, handleError);
    });
  }

  async webPhoneSignIn(phoneNumber: string) {
    try {
      const confirmationResult = await this.firebaseAuth.signInWithPhoneNumber(
        phoneNumber,
        <firebase.auth.ApplicationVerifier>this.recaptchaVerifier
      );
      this.verificationId = confirmationResult.verificationId;
      return { error: null };
    } catch (error) {
      const err = error as firebase.auth.Error;
      return this.onFirebaseError(err);
    }
  }

  async reAuthWithPhoneNumber(phoneNumber: string) {
    try {
      const confirmationResult =
        await this.firebaseAuth.currentUser?.reauthenticateWithPhoneNumber(
          phoneNumber,
          <firebase.auth.ApplicationVerifier>this.recaptchaVerifier
        );
      this.verificationId = <string>confirmationResult?.verificationId;
      return { error: null };
    } catch (error) {
      const err = error as firebase.auth.Error;
      return this.onFirebaseError(err);
    }
  }

  async confirmPhoneCode(verificationCode: string) {
    const credential = PhoneAuthProvider.credential(
      this.verificationId,
      verificationCode
    );
    return this.firebaseAuth
      .signInWithCredential(credential)
      .then(() => {
        return { error: null };
      })
      .catch(this.onFirebaseError);
  }

  async signInWithEmail(email: string): Promise<FirebaseResponse> {
    const { appDomain, bundleId, dynamicLinkDomain } = this.options;
    const actionCodeSettings = {
      url: `${appDomain}/welcome/login/linkVerify`,
      handleCodeInApp: true,
      iOS: {
        bundleId,
      },
      android: {
        packageName: bundleId,
        installApp: true,
      },
      dynamicLinkDomain,
    };

    return this.firebaseAuth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(this.onEmailSent(email))
      .catch(this.onFirebaseError);
  }

  private onEmailSent = (email: string) => (): FirebaseResponse => {
    localStorage.setItem("emailToVerify", email);
    return { error: null };
  };

  private onEmailVerified = (): FirebaseResponse => {
    localStorage.removeItem("emailToVerify");
    return { error: null };
  };

  async signInWithEmailLink(
    link?: string,
    email?: string
  ): Promise<FirebaseResponse> {
    const emailToLogin = email || localStorage.getItem("emailToVerify");
    const url = link || window.location.href;

    return this.firebaseAuth
      .signInWithEmailLink(<string>emailToLogin, url)
      .then(this.onEmailVerified)
      .catch(this.onFirebaseError);
  }

  private onFirebaseError = (error: firebase.auth.Error): FirebaseResponse => {
    switch (error.code) {
      case "auth/user-disabled":
        return {
          error:
            "Your account has been deactivated, if you believe this is in error please call or text us.",
        };

      case "auth/network-request-failed":
        return {
          error:
            "Network Connectivity error. Check your network connection and try again later.",
        };

      case "auth/quota-exceeded":
        return {
          error: "Verification code error. Try again after after an hour.",
        };

      case "auth/expired-action-code":
        return {
          error: "Verification code expired. Try requesting again",
        };

      case "auth/invalid-email":
        return {
          error: "Invalid email address",
        };

      case "auth/invalid-verification-code":
        return {
          error: "Invalid verification code",
        };

      case "auth/invalid-action-code":
        return {
          error: "Invalid verification link",
        };

      default:
        return {
          error: `Received an error - ${error.code} | ${error.message}`,
        };
    }
  };

  get currentUser() {
    return this.firebaseAuth.currentUser;
  }

  async updateCurrentUserName(name: string) {
    await this.firebaseAuth.currentUser?.updateProfile({ displayName: name });
  }

  async updateCurrentUserEmail(email: string) {
    await this.firebaseAuth.currentUser?.updateEmail(email);
  }

  reloadUser() {
    this.firebaseAuth.currentUser?.reload();
  }

  onAuthStateChanged(callback) {
    return this.firebaseAuth.onAuthStateChanged(callback);
  }

  onIdTokenChanged(callback) {
    return this.firebaseAuth.onIdTokenChanged(callback);
  }

  signOut() {
    return this.firebaseAuth.signOut();
  }
}

export { FirebaseAuthentication };
