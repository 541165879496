import { URLTextComponent } from "src/lib/interface";

const getURLTextComponent = (
  localText,
  localLinkText,
  localLinkURL
): URLTextComponent => {
  if (/^\[.+\]$/.test(localLinkText) && /^\(.+\)$/.test(localLinkURL)) {
    localLinkText = localLinkText.slice(1, localLinkText.length - 1);
    localLinkURL = localLinkURL.slice(1, localLinkURL.length - 1);
  } else {
    localText += `${localLinkText}${localLinkURL}`;
    localLinkText = localLinkURL = "";
  }

  return { localText, localLinkText, localLinkURL };
};
const getParsedURLText = (text): Array<URLTextComponent> => {
  const modText: Array<URLTextComponent> = [];

  let status = {
    LINK_TEXT_START: "LINK_TEXT_START",
    LINK_TEXT_STOP: "LINK_TEXT_STOP",
    LINK_URL_START: "LINK_URL_START",
    LINK_URL_STOP: "LINK_URL_STOP",
  };
  const statusMapping = {
    "[": status.LINK_TEXT_START,
    "]": status.LINK_TEXT_STOP,
    "(": status.LINK_URL_START,
    ")": status.LINK_URL_STOP,
  };

  let localText, localLinkText, localLinkURL;
  localText = localLinkText = localLinkURL = "";
  let currentStatus;
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (statusMapping[char]) {
      currentStatus = statusMapping[char];
    }
    switch (currentStatus) {
      case status.LINK_TEXT_START:
        localLinkText += char;
        break;
      case status.LINK_TEXT_STOP:
        localLinkText += char;
        break;
      case status.LINK_URL_START:
        localLinkURL += char;
        break;
      case status.LINK_URL_STOP:
        localLinkURL += char;
        modText.push(
          getURLTextComponent(localText, localLinkText, localLinkURL)
        );
        localText = localLinkText = localLinkURL = "";
        currentStatus = null;
        break;
      default:
        localText += char;
    }
  }
  if (localText || localLinkURL || localLinkText) {
    modText.push(getURLTextComponent(localText, localLinkText, localLinkURL));
  }
  return modText;
};

export { getParsedURLText };
