import styled from "@emotion/styled";
import { IonButton, IonPage } from "@ionic/react";

const StyledIonPage = styled(IonPage)`
  /* Moved into style.scss because the UI bugged when using Google Map API */
`;
const LoginButton = styled(IonButton)`
  font-size: 16px;
  padding: 0;
  height: 24px;
  font-weight: 600;
  margin-left: 5px;
`;

export { LoginButton, StyledIonPage };
