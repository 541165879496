import "./style.scss";

import { InAppBrowser } from "@ionic-native/in-app-browser";
import { IonButton } from "@ionic/react";
import React from "react";

interface BrowserLinkButtonProps {
  url: string;
  color?: string;
  className?: string;
}

type BrowserTarget = "_system" | "_blank" | "_self";

const openBrowser = (url: string, target: BrowserTarget = "_system") => {
  InAppBrowser.create(url, target);
};

const openInAppBrowser = (url: string, target: BrowserTarget = "_blank") => {
  InAppBrowser.create(url, target, {
    location: "no",
    hidenavigationbuttons: "yes",
    toolbarposition: "bottom",
    closebuttoncaption: "Done",
    closebuttoncolor: "#ffffff",
    toolbarcolor: "#001529",
    toolbartranslucent: "no",
    footer: "yes",
    footercolor: "#001529",
    zoom: "no",
    useWideViewPort: "no",
  });
};

const BrowserLinkButton: React.FC<BrowserLinkButtonProps> = ({
  url,
  color = "primary",
  className,
  children,
}) => {
  const onClick = () => openBrowser(url);

  return (
    <IonButton
      fill="clear"
      onClick={onClick}
      color={color}
      class={`link-button ${className}`}
    >
      {children}
    </IonButton>
  );
};

export {
  BrowserLinkButton,
  BrowserLinkButtonProps,
  openBrowser,
  openInAppBrowser,
};
