import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../constants/userEvents";

// the error comes from superagent.retry's callback, could be anything
export const logFailedApiRetry = (error: any, url: string) => {
  logEvent(USER_EVENTS.API_ERROR_RETRY, {
    url,
    status: error?.["status"],
  });
};
